ajaxService.$inject = ['httpService', 'responseValidatorService'];

function ajaxService(httpService, responseValidatorService) {
    var IpadPublicRemoteService = "IPadPublicRemoteService";
    var BaseRemoteService = "BaseRemoteService";
    var BvJsonCalendarService = "BvJsonCalendarService";
    var BvJsonMessagesService = "BvJsonMessagesService";
    var TaskRemoteService = "TaskRemoteService";
    var approvalService = "BvJsonApprovalService";
    var adminRemoteService = "AdminRemoteService";
    var mergeRemoteService = "BvJsonMergeService";
    var BvJsonAdminService = "BvJsonAdminService";
    var BvJsonPortalService = "BvJsonPortalService";
    var BvJsonPollingService = "BvJsonPollingService";
    var teamRemoteService = "BvJsonTeamService";
    var BVJsonSearchService = "BvJsonSearchService";
    var BvJsonACLService = "BvJsonACLService";
    var BvJsonInboxService = "BvJsonInboxService";

    var conferencServiceMethodStr = "servicename=BvJsonConferenceService&methodname=";
    var ipadServiceMethodStr = "servicename=IPadPublicRemoteService&methodname=";
    var ipadRemoteServiceMethodStr = "servicename=IPadRemoteService&methodname=";
    var approvalServiceMethodStr = "servicename=BvJsonApprovalService&methodname=";
    var documentServiceMethodStr = "servicename=BvJsonDocumentService&methodname=";
    var BvJsonSupportRemoteService = "servicename=BvJsonSupportRemoteService";
    var wallServiceMethodStr = "servicename=BvJsonWallService&methodname=";
    var userProfileService = "servicename=BvJsonLoginService&methodname=";
    var BvJsonChatServiceMethodStr = "servicename=BvJsonChatService&methodname=";
    var BvJsonRulesServiceMethodStr = "servicename=BvJsonRulesService&methodname=";
    var BvJsonSubscriptionServiceMethodStr = "servicename=BvJsonSubscriptionService&methodname=";
    var AuditTrailRemoteServiceMethodStr = "servicename=AuditTrailRemoteService&methodname=";
    var BvJsonPeopleRemoteServiceMethodStr = "servicename=BvJsonPeopleRemoteService&methodname=";
    var BvJsonObjectPushRemoteServiceMethodStr = "servicename=BvJsonObjectPushRemoteService&methodname=";
    var N3ALoginSessionValidationService = "servicename=N3ALoginSessionValidationService&methodname=";
    var BvJsonLoggingService = "servicename=BvJsonLoggingService&methodname=";

    function buildHttpParams(serviceName, methodName, params) {
        if (!serviceName || !methodName) {
            if (window.console) console.log("Invalid Service Name and or Method Name" + serviceName);
            return null;
        }
        var serviceStr = "servicename=" + serviceName + "&methodname=" + methodName;
        if (params) {
            serviceStr += params;
        }
        return serviceStr;
    }

    // to process all the arguments
    // as of now encodeURIComponent
    // can add more modification if needed which is common to all parameters
    // arguments - Array variable is available for all javascript function locally
    // this method will traverse through and encodeURIComponent
    // returns back array will be assigned to arguments variable again and variables can be used as it is.
    function process_parametes(args) {
        for (var i in args) {
            args[i] = encodeURIComponent(args[i]);
        }
        return Array.prototype.slice.call(args);
    }

    var service = {
        login: function (username, password, symmetricKey, timeStamp, secondTierCookie, secondTierSmsCookie, url) {
            //var secondTierCookie='';
            var redirectUrl = url;
            var [username, password, symmetricKey, timeStamp, secondTierCookie, secondTierSmsCookie, url] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "encryptedHTMLLoginJSON";
            var params = "&handle=" + username + "&password=" + password +
                "&symmetricKey=" + symmetricKey + "&timeStamp=" + timeStamp +
                "&timezoneOffset=" + new Date().getTimezoneOffset() +
                "&timeZoneId=" + "&loginType=IPHONE" + "&whichTier=1" + "&secondTierCookie=" + secondTierCookie +
                "&secondTierSmsCookie=" + secondTierSmsCookie + "&encrypt=0&millis=";
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },
        isShowRememberMeForFirstTier: function (url) {
            var servmethod = "servicename=IPadPublicRemoteService&methodname=isShowRememberMeForFirstTier";
            var params = "";
            return httpService.httpRedirectLoginPost(servmethod + params, url);
        },
        getConfigValueForSupportInfo: function (url) {
            var servmethod = "servicename=IPadPublicRemoteService&methodname=getConfigValueForSupportInfo";
            var params = "";
            return httpService.httpRedirectLoginPost(servmethod + params, url);
        },
        //        getSecurityQuestion : function(uuid, url) {
        //            var redirectUrl = url;
        //            var [ uuid, url ] = process_parametes(arguments);
        //            var servmethod = "servicename=IPadPublicRemoteService&methodname=getSecurityQuestionsJSON";
        //            var params="&userBaseUuid=" + uuid;
        //            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        //        },
        getSecurityQuestion: function (uuid, locale, url) {
            var redirectUrl = url;
            var [uuid, locale, url] = process_parametes(arguments);
            var servmethod = "servicename=IPadPublicRemoteService&methodname=getSecurityQuestionsForHTMlJSON";
            var params = "&userBaseUuid=" + uuid + "&locale=" + locale;;
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },
        getChallengeQuestion: function (plink, locale) {
            var [plink, locale] = process_parametes(arguments);
            var servmethod = "servicename=IPadPublicRemoteService&methodname=getChallengeQuestions";
            var params = "&plink=" + plink + "&locale=" + locale;
            return httpService.httpLoginPost(servmethod + params);
        },
        loginSms: function (username, smscode, url) {
            var redirectUrl = url;
            var [username, smscode, url] = process_parametes(arguments);
            var smsAuthenticationCookie = '';
            var servmethod = "servicename=IPadPublicRemoteService&methodname=encryptedHTMLLogin2ndTierJSON";
            var params = "&handle=" + username + "&password=" + "&loginType=IPHONE" + "&whichTier=2" +
                "&securityQuestion=" + "&securityAnswer=" + smscode + "&uid=" + "&rememberSecondTier=" + "&encrypt=0&millis=";
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },
        forgotUserId: function (lastname, email, url) {
            var redirectUrl = url;
            var [lastname, email, url] = process_parametes(arguments);
            var smsAuthenticationCookie = '';
            var servmethod = "servicename=IPadPublicRemoteService&methodname=forgotUserIDForHtmlClient";
            var params = "&lastName=" + lastname + "&emailAddress=" + email;
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },
        forgotPassword: function (id, lastn, emailaddr, url) {
            var redirectUrl = url;
            var [id, lastn, emailaddr, url] = process_parametes(arguments);
            var servmethod = "servicename=IPadPublicRemoteService&methodname=forgotPasswordJSON";
            var params = "&loginID=" + id + "&lastName=" + lastn +
                "&emailAddress=" + emailaddr;
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },
        changeUserPassword: function (companyBaseUuid, currentPassword, newPassword, url) {
            var redirectUrl = url;
            var [companyBaseUuid, currentPassword, newPassword, url] = process_parametes(arguments);
            var smsAuthenticationCookie = '';
            var servmethod = "servicename=BvJsonLoginService&methodname=changeUserPwd";
            var params = "&companyBaseUuid=" + companyBaseUuid + "&currentPassword=" + currentPassword +
                "&newPassword=" + newPassword;
            return httpService.httpRedirectServicePost(servmethod + params, redirectUrl);
        },
        changeChallengeQuestionsAnswer: function (objectBaseuuid, companyBaseUuid, questionAndAnswerList, url) {
            var redirectUrl = url;
            var [objectBaseuuid, companyBaseUuid, questionAndAnswerList, url] = process_parametes(arguments);
            //editSecurityQuestionAnswersJSON(String portalId,String uid, String ecxcompanyid,String questionAndAnswerList
            var servmethod = "servicename=IPadPublicRemoteService&methodname=editSecurityQuestionAnswersJSONForHtmlClient";
            var params = "&portalId= " + "&uid=" + objectBaseuuid + "&ecxcompanyid=" + companyBaseUuid + "&questionAndAnswerList=" + questionAndAnswerList;
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },
        loginThirdFactorAuthentication: function (username, smscode, url) {
            var redirectUrl = url;
            var [username, smscode, url] = process_parametes(arguments);
            var smsAuthenticationCookie = '';
            var servmethod = "servicename=BVAdminRemoteService&methodname=encryptedBvAdminLogin3rdTierJSON";
            var params = "&username=" + username + "&smscode=" + "&securityAnswer=" + smscode + "&encrypt=0&millis=";
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },
        setUpCompanyMasterKey: function (companyMasterKey, companyId, url) {
            var redirectUrl = url;
            var [companyMasterKey, companyId, url] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonAdminService&methodname=setCompanyMasterKeyForHtml";
            var params = "&key=" + companyMasterKey + "&companyBaseUuid=" + companyId;
            return httpService.httpRedirectServicePost(servmethod + params, redirectUrl);
        },
        uploadCompanyEncryptionKey: function (encryptionKey, companyId, url) {
            var servmethod = "servicename=BvJsonAdminService&methodname=uploadCompanyEncryptionKeyForHtml";
            var params = "&key=" + encryptionKey + "&companyBaseUuid=" + companyId;
            return httpService.httpRedirectServicePost(servmethod + params, url);
        },
        loginSecurityQuestion: function (username, securityquestion, securityanswer, rememberSecondTier, url) {
            var redirectUrl = url;
            var [username, securityquestion, securityanswer, rememberSecondTier, url] = process_parametes(arguments);
            var secondTierCookie = '';
            var servmethod = "servicename=IPadPublicRemoteService&methodname=encryptedHTMLLogin2ndTierJSON";
            var params = "&handle=" + username + "&password=" + "&loginType=IPHONE" + "&whichTier=2" + "&securityQuestion=" +
                securityquestion + "&securityAnswer=" + securityanswer + "&uid=" + "&rememberSecondTier=" +
                rememberSecondTier + "&encrypt=0&millis=";
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },
        generateLoginKeyPairForHtmlClient: function (username, url) {
            var redirectUrl = url;
            var [username, url] = process_parametes(arguments);
            var servmethod = "servicename=IPadPublicRemoteService&methodname=generateLoginKeyPairJson";
            var params = "&username=" + username;
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },
        verifyCertificate: function (url) {
            var servmethod = "servicename=IPadPublicRemoteService&methodname=verifyCertificateDesktopClient";
            var params = "";
            return httpService.httpRedirectLoginPost(servmethod + params, url);
        },
        getClientCertificate: function (hostAddress, hostName, operatingSystem) {
            var [hostAddress, hostName, operatingSystem] = process_parametes(arguments);
            var servmethod = "servicename=IPadPublicRemoteService&methodname=generateClientCertificateJSON";
            var params = "&hostAddress=" + hostAddress + "&hostName=" + hostName + "&operatingSystem=" + operatingSystem;
            return httpService.httpLoginPost(servmethod + params);
        },
        registerClientCertificateInDevice: function (hostAddress, hostName, operatingSystem, code) {
            var [hostAddress, hostName, operatingSystem, code] = process_parametes(arguments);
            var servmethod = "servicename=IPadPublicRemoteService&methodname=registerClientDeviceJSON";
            var params = "&hostAddress=" + hostAddress + "&hostName=" + hostName + "&operatingSystem=" + operatingSystem + "&code=" + code;
            return httpService.httpLoginPost(servmethod + params);
        },
        checkRedirectSiteURL: function (id, url) {
            var redirectUrl = url;
            var [id, url] = process_parametes(arguments);
            var servmethod = "servicename=IPadPublicRemoteService&methodname=checkRedirectSiteURL";
            var params = "&loginID=" + id;
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },
        checkInOut: function (documentVO, flag, cancelcheckout) {
            var serviceMethod = "servicename=BvJsonDocumentService&methodname=checkInCheckOut";
            var params = "&documentUuid=" + documentVO.objectBaseUuid + "&checkInOut=" + flag + "&cancelCheckout=" + cancelcheckout;
            return httpService.httpServicePost(serviceMethod + params);
        },
        getSystemAnnouncements: function (langCode, url) {
            var redirectUrl = url;
            var [langCode, url] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "getSystemAnnouncementsJSONForHtml";
            var params = "&langCode=" + langCode;
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },
        checkLockDeviceCode: function (userBaseUuid, code) {
            var [userBaseUuid, code] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "checkLockDeviceCode";
            var params = "&userBaseUuid=" + userBaseUuid + "&code=code";
            return httpService.httpLoginPost(servmethod + params);
        },
        getMyPasswordPreferences: function (url) {
            var servmethod = "servicename=IPadPublicRemoteService&methodname=getMyPasswordPreferencesJSONForHTML";
            var params = "";
            return httpService.httpRedirectLoginPost(servmethod + params, url);
        },
        validateBrowserSupportForHtmlClient: function (url) {
            var servmethod = "servicename=IPadPublicRemoteService&methodname=validateBrowserSupportForHtmlClient";
            var params = "";
            return httpService.httpRedirectLoginPost(servmethod + params, url);
        },
        validateBrowserTypeAndVersionNumber: function (url) {
            var servmethod = "servicename=IPadPublicRemoteService&methodname=validateBrowserTypeAndVersionNumber";
            var params = "";
            return httpService.httpRedirectLoginPost(servmethod + params, url);
        },
        invalidate: function (url) {
            var servmethod = "servicename=BvJsonLoginService&methodname=invalidateSessionJSON";
            var params = "";
            return httpService.httpRedirectServicePost(servmethod + params, url);
        },
        redirectIfHtmlNotEnabled: function () {
            var httpParams = buildHttpParams("BvJsonPortalService", "redirectIfCompanyNotConfiguredForHTML");
            var request = httpService.httpServicePost(httpParams);
            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        getPortalData: function () {
            var httpParams = buildHttpParams(BvJsonPortalService, "getPortalDataJSON");
                
            var request = httpService.httpServicePost(httpParams);
            return request.then(function (response) {
                if (response && responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }  else if(response.data.boName ==="FailureBO"){
                    return response.data;      
                }
            });
        },
        getEnabledFeatures: function() {
            var httpParams = buildHttpParams(BvJsonPortalService, "enabledFeaturesJson");

            var request = httpService.httpServicePost(httpParams);
            return request.then(function (response) {
                if (response && responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        getCurrentUserData: function (userBaseUuid) {
            var servmethod = ipadServiceMethodStr + "getCurrentUserData";
            var params = "";
            var request = httpService.httpServicePost(servmethod + params);
            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        getPortals: function (companyBaseUuid) {
            var [companyBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BaseRemoteService + "&methodname=getPortals";
            var params = "&companyBaseUuid=" + companyBaseUuid;
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });

        },
        getPortalsForHTML: function (companyBaseUuid) {
            var [companyBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BaseRemoteService + "&methodname=getPortalsForHTML";
            var params = "&companyBaseUuid=" + companyBaseUuid;
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        changeTeamSpace: function (companyBaseUuid, portalBaseUuid, setDefault) {
            var [companyBaseUuid, portalBaseUuid, setDefault] = process_parametes(arguments);
            var servmethod = userProfileService + "changeTeamspace";
            var params = "&selectedCompanyId=" + companyBaseUuid + "&selectedPortalId=" + portalBaseUuid + "&setDefault=" + setDefault;
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        changeCompany: function (companyBaseUuid) {
            var [companyBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonLoginService&methodname=changeCompany";
            //var params="&selectedCompanyId="+companyBaseUuid+"&selectedPortalId="+portalBaseUuid+"&setDefault="+setDefault;
            var params = "&selectedCompanyId=" + companyBaseUuid;
            return httpService.httpLoginPost(servmethod + params);
        },
        changeCompanyForNBV: function (companyBaseUuid, loginID) {
            var [companyBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonLoginService&methodname=changeCompanyForNBV";
            //            var servmethod = "servicename=BvJsonLoginService&methodname=changeCompany";
            var params = "&companyBaseUuid=" + companyBaseUuid + "&loginID=" + loginID;
            return httpService.httpServicePost(servmethod + params);
        },
        checkUserByLoginId: function (loginId) {
            var [loginId] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "checkUserByLoginIdJSON";
            var params = "&loginId=" + loginId;
            return httpService.httpLoginPost(servmethod + params);
        },
        registerUser: function (loginVO) {
            var [loginVO] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "userRegistrationJSON";
            var params = "&loginVO=" + loginVO;
            return httpService.httpLoginPost(servmethod + params);
        },
        getUserData: function (plink) {
            var [plink] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "getUserDataJSON";
            var params = "&plink=" + plink;
            return httpService.httpLoginPost(servmethod + params);
        },
        getCountryNameValueList: function () {
            var servmethod = ipadServiceMethodStr + "getCountryNameValueListJSON";
            var params = "";
            return httpService.httpLoginPost(servmethod + params);
        },
        getEmailRegExp: function () {
            var servmethod = ipadServiceMethodStr + "getEmailRegExpJSON";
            var params = "";
            return httpService.httpLoginPost(servmethod + params);
        },
        getPasswordPreferences: function () {
            var servmethod = "servicename=BvJsonPreferencesService&methodname=getPasswordPreferences";
            return httpService.httpServicePost(servmethod);
        },
        getPreferencesMenuOptions: function () {
            var servmethod = "servicename=BvJsonPreferencesService&methodname=getPreferencesMenuOptionsJson";
            return httpService.httpServicePost(servmethod);
        },
        getPasswordPreferencesForPlink: function (plink) {
            var [plink] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "getPasswordPreferencesJSONForHTML";
            var params = "&plink=" + plink;
            return httpService.httpLoginPost(servmethod + params);
        },
        getTreeSearchesJSON: function (tab) {
            var [tab] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonSearchService&methodname=getTreeSearchesJSON";
            var params = "&tab=" + tab;
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        getAllSearchList: function (searchString, uniq) {
            var [searchString] = process_parametes(arguments);
            var servmethod = "servicename=" + BVJsonSearchService + "&methodname=getAllSearchList";
            var params = "&searchString=" + searchString;
            return httpService.httpServicePost(servmethod + params, uniq);
        },
        getMyOpenApprovalsJSON: function () {
            var servmethod = approvalServiceMethodStr + "getMyOpenApprovalsJSON";
            var params = "";
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        getMyClosedApprovalsJSON: function () {
            var servmethod = approvalServiceMethodStr + "getMyClosedApprovalsJSON";
            var params = "";
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        getMyOwnedApprovalsJSON: function () {
            var servmethod = approvalServiceMethodStr + "getMyOwnedApprovalsJSON";
            var params = "";
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
          getMyApprovalsJSON : function(category,page,pageSize,sortColumn,sortDirection) {
            var [ approvalInput ] = process_parametes(arguments);
            var servmethod = approvalServiceMethodStr+"getApprovalsJSONLiteWithPagination";
            var params="&category="+category +"&page="+page +"&pageSize="+pageSize + "&sortColumn="+sortColumn +"&sortDirection="+sortDirection;
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function(response) {
                if(responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        getApprovalDetailJSON: function (baseUuid) {
            var [baseUuid] = process_parametes(arguments);
            var servmethod = approvalServiceMethodStr + "getApprovalDetailJSON";
            var params = "&baseUuid=" + baseUuid;
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    //console.log("response data="+JSON.stringify(response.data));
                    return response.data;
                }
                return null;
            });
        },
        getApprovalCatagoriesJSON: function (baseUuid) {
            var [baseUuid] = process_parametes(arguments);
            var servmethod = approvalServiceMethodStr + "getApprovalCatagoriesJSON";
            var params = "&baseUuid=" + baseUuid;
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        createApprovalResponse: function (approvalObjectBaseUuid, responseObjectBaseUuid, choice, comments, signed) {
            var [approvalObjectBaseUuid, responseObjectBaseUuid, choice, comments, signed] = process_parametes(arguments);
            var servmethod = approvalServiceMethodStr + "createApprovalResponse";
            var params = "&approvalObjectBaseUuid=" + approvalObjectBaseUuid + "&responseObjectBaseUuid=" + responseObjectBaseUuid + "&choice=" + encodeURIComponent(choice) + "&comments=" + encodeURIComponent(comments) + "&signed=" + signed;
            var request = httpService.httpServicePost(servmethod + params);
            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        deleteApproval: function (approvalObjectBaseUuid) {
            var [approvalObjectBaseUuid] = process_parametes(arguments);
            var servmethod = approvalServiceMethodStr + "deleteApprovalJSON";
            var params = "&approvalBaseUuid=" + approvalObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        closeApproval: function (approvalObjectBaseUuid) {
            var [approvalObjectBaseUuid] = process_parametes(arguments);
            var servmethod = approvalServiceMethodStr + "closeApprovalJSON";
            var params = "&approvalBaseUuid=" + approvalObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        reopenApproval: function (approvalObjectBaseUuid) {
            var [approvalObjectBaseUuid] = process_parametes(arguments);
            var servmethod = approvalServiceMethodStr + "reopenApprovalJSON";
            var params = "&approvalBaseUuid=" + approvalObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getAgendaTemplatesFolderUuidForCurrentPortal: function () {
            var servmethod = documentServiceMethodStr + "getAgendaTemplatesFolderUuidForCurrentPortal";
            return httpService.httpServicePost(servmethod);
        },
        findAllAgendaTemplatesForCurrentPortal: function () {
            var servmethod = documentServiceMethodStr + "findAllAgendaTemplatesForCurrentPortal";
            return httpService.httpServicePost(servmethod);
        },
        makeAgendaTemplateDefaultForCurrentPortal: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "makeAgendaTemplateDefaultForCurrentPortal";
            var params = "&templateObjectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getDefaultAgendaTemplateForCurrentPortal: function () {
            var servmethod = documentServiceMethodStr + "getDefaultAgendaTemplateForCurrentPortal";
            return httpService.httpServicePost(servmethod);
        },
        getDocumentRootFolder: function () {
            var servmethod = documentServiceMethodStr + "getDocumentRootFolder";
            return httpService.httpServicePost(servmethod);
        },

        getConfigForUploadFiles: function () {
            var servmethod = documentServiceMethodStr + "getConfigForUploadFiles";
            return httpService.httpServicePost(servmethod);
        },

        getDocumentsJSONForParentBaseUuid: function (parentObjectBaseUuid) {
            var [parentObjectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "getDocumentsJSONForParentBaseUuid";
            var params = "&parentObjectBaseUuid=" + parentObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        moveDocuments: function (fromFolderBaseUuid, toFolderBaseUuid, listofBaseUuids, moveFrom) {
            var [fromFolderBaseUuid, toFolderBaseUuid, listofBaseUuids, moveFrom] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "moveDocuments";
            var params = "&fromFolderBaseUuid=" + fromFolderBaseUuid + "&toFolderBaseUuid=" + toFolderBaseUuid + "&listofBaseUuids=" + listofBaseUuids + "&moveFrom=" + moveFrom;
            return httpService.httpServicePost(servmethod + params);
        },

        getUserSignature: function (businessObjectBaseUuid, userObjectBaseUuid) {
            var [businessObjectBaseUuid, userObjectBaseUuid] = process_parametes(arguments);
            var servmethod = approvalServiceMethodStr + "getUserSignatureJSON";
            var params = "&businessObjectBaseUuid=" + businessObjectBaseUuid + "&userObjectBaseUuid=" + userObjectBaseUuid;
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },

        getDocumentsJSONForBriefCase: function () {
            var servmethod = documentServiceMethodStr + "getDocumentsJSONForBriefCase";
            return httpService.httpServicePost(servmethod);
        },

        getDocumentsCountJSONForBriefCase: function () {
            var servmethod = documentServiceMethodStr + "getDocumentsCountJSONForBriefCase";
            return httpService.httpServicePost(servmethod);
        },

        getDocumentsJSONForNewItems: function (parentObjectBaseUuid) {
            var [parentObjectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "getDocumentsJSONForNewItems";
            return httpService.httpServicePost(servmethod);
        },

        getNewItemsCountForAllTeamspaces: function () {
            var servmethod = documentServiceMethodStr + "getNewItemsCountForAllTeamspaces";
            return httpService.httpServicePost(servmethod);
        },

        getBriefcaseCount: function () {
            var servmethod = documentServiceMethodStr + "getBriefcaseCount";
            return httpService.httpServicePost(servmethod);
        },

        createFolder: function (parentBaseUuid, newFolderName, includeListOfPermissions, listOfPermissions) {
            var [parentBaseUuid, newFolderName] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "createFolderWithPermissions";
            var params = "&parentBaseUuid=" + parentBaseUuid + "&newFolderName=" + newFolderName + "&permissionsJson=";
            if (includeListOfPermissions) {
                params += listOfPermissions;
            }
            return httpService.httpServicePost(servmethod + params);
        },

        recycleListofBaseUuids: function (listofBaseUuids) {
            bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Document, bvUsageUtils.const.Entity.Action.Document.DeleteDocuments, undefined, listofBaseUuids.split(',').length);
            var [listofBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "recycleListofBaseUuids";
            var params = "&listofBaseUuids=" + listofBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },

        addToCollateChildrenJSON: function (listofBaseUuids) {
            var [listofBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "addToCollateChildrenJSON";
            var params = "&listofBaseUuids=" + listofBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },
        checkPdfNonCompliantAndMergeableStatusForHistoricalDocuments : function (listofBaseUuids) {
            var [listofBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "checkPdfNonCompliantAndMergeableStatusForHistoricalDocuments";
            var params = "&objectBaseUuidList=" + listofBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },
        findFoldersAndSearchObjectsForParentUuid: function (parentBaseUuid) {
            var [parentBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "findFoldersAndSearchObjectsForParentUuid";
            var params = "&parentBaseUuid=" + parentBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        findOriginOfCurrentFolder: function (parentBaseUuid) {
            var [parentBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "findOriginOfCurrentFolder";
            var params = "&parentBaseUuid=" + parentBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        //        rename: function(baseuuid, newName) {
        //            var [ baseuuid, newName ] = process_parametes(arguments);
        //            var servmethod = "servicename=" + BaseRemoteService + "&methodname=rename";
        //            var params="&objectBaseUuid="+baseuuid+ "&newName=" + newName;
        //            return  httpService.httpServicePost(servmethod + params);
        //        },

        getFoldersAndDocumentsUsingHashSeparatedUuidsJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "getFoldersAndDocumentsUsingHashSeparatedUuidsJSON";
            var params = "&hashSeparatedUuids=" + objectBaseUuid + "&documentsWithCollateInfo=true";
            return httpService.httpServicePost(servmethod + params);
        },

        getFirstPageAsPdf: function (versionUuid) {
            var [versionUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "getFirstPageAsPdf";
            //var params = "&hashSeparatedUuids=" + objectBaseUuid;
            var params = "&hashSeparatedUuids=" + versionUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getParentNode: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "getParentNode";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        createAgendaForFolder: function (objectBaseUuid, parentObjectBaseUuid, style, templateObjectBaseUuid) {
            var [objectBaseUuid, parentObjectBaseUuid, style, templateObjectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "createAgendaForFolder";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&parentObjectBaseUuid=" + parentObjectBaseUuid + "&style=" + style + "&templateObjectBaseUuid=" + templateObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getHandShakeTokenJSON: function (userBaseUuid, certificate, url) {
            var redirectUrl = url;
            var [userBaseUuid, certificate, url] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "getHandShakeTokenJSON";
            var params = "&userId=" + userBaseUuid + "&certificate=" + certificate;
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },

        verifyCertificateJSON: function (username, objectBaseUuid, certificate, token, url) {
            var redirectUrl = url;
            var [username, objectBaseUuid, certificate, token, url] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "verifyCertificateJSON";
            var params = "&username=" + username + "&objectBaseUuid=" + objectBaseUuid + "&certificate=" + certificate + "&token=" + token;
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },

        getSurveyWithPagination : function(category, page, pageSize, sortColumn, sortDirection) {
            var [ category, page, pageSize, sortColumn, sortDirection ] = process_parametes(arguments);
            var servmethod = buildHttpParams("BvJsonSurveyService", "getSurveyWithPagination")
            var params = "&category=" + category + "&offset=" + page + "&length=" + pageSize + "&sortColumn=" + sortColumn + "&sortDirection=" + sortDirection;
            var request = httpService.httpServicePost(servmethod + params);
            return request.then(function(response) {
                if(responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },

        getMySurveys: function () {
            var httpParams = buildHttpParams("BvJsonSurveyService", "getMyOpenSurveysJSON");
            return httpService.httpServicePost(httpParams);
        },

        getMyClosedSurveysJson: function () {
            var httpParams = buildHttpParams("BvJsonSurveyService", "getMyClosedSurveysJSON");
            return httpService.httpServicePost(httpParams);
        },

        getMyOwnedSurveysJson: function () {
            var httpParams = buildHttpParams("BvJsonSurveyService", "getMyOwnedSurveysJSON");
            return httpService.httpServicePost(httpParams);
        },

        getMyCreatedSurveysJson: function () {
            var httpParams = buildHttpParams("BvJsonSurveyService", "getMyCreatedSurveysJSON");
            return httpService.httpServicePost(httpParams);
        },

        publishSurveys: function (surveyBaseUuidList) {
            var [surveyBaseUuidList] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "publishSurveys", "&surveyBaseUuidList=" + surveyBaseUuidList);
            return httpService.httpServicePost(httpParams);
        },

        deleteSurveyJson: function (surveyBaseUuid) {
            var [surveyBaseUuid] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "deleteSurvey", "&surveyBaseUuid=" + surveyBaseUuid);
            return httpService.httpServicePost(httpParams);
        },

        getSurveyResponseForUsersJson: function (surveyBaseUuid) {
            var [surveyBaseUuid] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "getSurveyResponseForUsers", "&surveyBaseUuid=" + surveyBaseUuid);
            return httpService.httpServicePost(httpParams);
        },

        getSurveyReportUserResponseJson: function (surveyBaseUuid) {
            var [surveyBaseUuid] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "getSurveyReportUserResponse", "&surveyBaseUuid=" + surveyBaseUuid);
            return httpService.httpServicePost(httpParams);
        },

        addSurveyParticipantsJson: function (surveyBaseUuid, userBaseUuids) {
            var [surveyBaseUuid, userBaseUuids] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "addSurveyParticipants", '&surveyBaseUuid=' + surveyBaseUuid + "&userBaseUuids=" + userBaseUuids);
            return httpService.httpServicePost(httpParams);
        },

        getSurveyResponseForUserJson: function (surveyBaseUuid, questionBaseUuidListString, userBaseUuid) {
            var [surveyBaseUuid, questionBaseUuidListString, userBaseUuid] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "getSurveyResponseForUser", "&surveyBaseUuid=" + surveyBaseUuid + "&questionBaseUuidListString=" + questionBaseUuidListString + "&userBaseUuid=" + userBaseUuid);
            return httpService.httpServicePost(httpParams);
        },

        getSurveyDetailsJson: function (surveyBaseUuid) {
            var [surveyBaseUuid] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "getSurveyDetailsJson", "&surveyBaseUuid=" + surveyBaseUuid);
            return httpService.httpServicePost(httpParams);
        },

        getSurveySectionResponsesForUser: function (surveyBaseUuid, sectionBaseUuid, userBaseUuid) {
            var [surveyBaseUuid, sectionBaseUuid, userBaseUuid] = process_parametes(arguments);
            var httpParams = buildHttpParams("SurveyRemoteService", "getSurveyResponseForUser", "&surveyBaseUuid=" + surveyBaseUuid + "&sectionBaseUuid=" + sectionBaseUuid + "&userBaseUuid=" + userBaseUuid);
            return httpService.httpServicePost(httpParams);
        },

        saveSurveyResponseJson: function (surveyBaseUuid, questionBaseUuid, userBaseUuid, surveyResponse, surveyResponseList, comments) {
            var [surveyBaseUuid, questionBaseUuid, userBaseUuid, surveyResponse, surveyResponseList, comments] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "saveSurveyResponseJSON", "&surveyBaseUuid=" + surveyBaseUuid + "&questionBaseUuid=" + questionBaseUuid + "&userBaseUuid=" + userBaseUuid + "&surveyResponse=" + surveyResponse + "&surveyResponseList=" + surveyResponseList + "&surveyComments=" + encodeURIComponent(comments) + "&isMandatoryQuestionsPending=" + "true");
            return httpService.httpServicePost(httpParams);
        },

        submitSurveyResponseJson: function (surveyBaseUuid, isSigned) {
            var [surveyBaseUuid, isSigned] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "submitSurveyResponse", "&surveyBaseUuid=" + surveyBaseUuid + "&isSigned=" + isSigned);
            return httpService.httpServicePost(httpParams);
        },

        copySurveyJson: function (surveyBaseUuid, surveyName, copyType) {
            var [surveyBaseUuid, surveyName, copyType] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "copySurvey", "&surveyBaseUuid=" + surveyBaseUuid + "&surveyName=" + surveyName + "&copyType=" + copyType);
            return httpService.httpServicePost(httpParams);
        },

        createSurveyTemplate: function (surveyBaseUuid, surveyName) {
            var [surveyBaseUuid, surveyName] = process_parametes(arguments);
            var httpParams = buildHttpParams("SurveyRemoteService", "createSurveyTemplate", "&surveyBaseUuid=" + surveyBaseUuid + "&surveyName=" + surveyName);
            return httpService.httpServicePost(httpParams);
        },

        closeOrReopenSurvey: function (surveyBaseUuid, isSurveyClosed) {
            var [surveyBaseUuid, isSurveyClosed] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "closeOrReopenSurvey", "&surveyBaseUuid=" + surveyBaseUuid + "&isSurveyClosed=" + isSurveyClosed);
            return httpService.httpServicePost(httpParams);
        },
        publishCBESurvey: function (surveyUuid) {
            var [surveyUuid] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "publishCBESurvey", "&surveyBaseUuid=" + surveyUuid);
            return httpService.httpServicePost(httpParams);
        },
        sendAletToCBEParticipants: function (surveyUuid) {
            var [surveyUuid] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "sendAletToCBEParticipants", "&surveyBaseUuid=" + surveyUuid);
            return httpService.httpServicePost(httpParams);
        },
        createOrUpdateSurvey: function (parentFolderBaseUuid, surveyVO, surveyOwnersList) {
            var [parentFolderBaseUuid, surveyVO, surveyOwnersList] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "createOrUpdateSurvey", "&parentFolderBaseUuid=" + parentFolderBaseUuid + "&surveyVO=" + encodeURIComponent(surveyVO) + "&surveyOwnersList=" + surveyOwnersList);
            return httpService.httpServicePost(httpParams);
        },
        saveSurveySection: function (surveyBaseUuid, sectionVO) {
            var [surveyBaseUuid, sectionVO] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "saveSurveySection", "&surveyBaseUuid=" + surveyBaseUuid + "&sectionVO=" + encodeURIComponent(sectionVO));
            return httpService.httpServicePost(httpParams);
        },
        createOrUpdateQuestionForSection: function (surveyBaseUuid, sectionBaseUuid, questionVo) {
            var [surveyBaseUuid, sectionBaseUuid, questionVo] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "createOrUpdateQuestionForSection", "&surveyBaseUuid=" + surveyBaseUuid + "&sectionBaseUuid=" + sectionBaseUuid + "&questionVo=" + encodeURIComponent(questionVo));
            return httpService.httpServicePost(httpParams);
        },
        updateSurveyQuestionsSortOrder: function (surveyBaseUuid, sectionVO) {
            var [surveyBaseUuid, sectionVO] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "updateSurveyQuestionsSortOrder", "&surveyBaseUuid=" + surveyBaseUuid + "&sectionVO=" + encodeURIComponent(sectionVO));
            return httpService.httpServicePost(httpParams);
        },
        deleteQuestion: function (surveyBaseUuid, questionBaseUuid) {
            var [surveyBaseUuid, questionBaseUuid] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "deleteQuestion", "&surveyBaseUuid=" + surveyBaseUuid + "&questionBaseUuid=" + questionBaseUuid);
            return httpService.httpServicePost(httpParams);
        },
        deleteSection: function (surveyBaseUuid, sectionBaseUuid) {
            var [surveyBaseUuid, sectionBaseUui] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "deleteSection", "&surveyBaseUuid=" + surveyBaseUuid + "&sectionBaseUuid=" + sectionBaseUuid);
            return httpService.httpServicePost(httpParams);
        },
        getSurveyDetails: function (surveyBaseUuid) {
            var [surveyBaseUuid] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "getSurveyDetails", "&surveyBaseUuid=" + surveyBaseUuid);
            return httpService.httpServicePost(httpParams);
        },

        lockParticipants: function (surveyBaseUuid, participantsList) {
            var [surveyBaseUuid, participantsList] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "lockParticipants", "&surveyBaseUuid=" + surveyBaseUuid + "&participantsList=" + participantsList);
            return httpService.httpServicePost(httpParams);
        },

        unLockParticipants: function (surveyBaseUuid, participantsList) {
            var [surveyBaseUuid, participantsList] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "unLockParticipants", "&surveyBaseUuid=" + surveyBaseUuid + "&participantsList=" + participantsList);
            return httpService.httpServicePost(httpParams);
        },

        deleteSurveyParticipant: function (surveyBaseUuid, userBaseUuid) {
            var [surveyBaseUuid, userBaseUuid] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "deleteSurveyParticipant", "&surveyBaseUuid=" + surveyBaseUuid + "&userBaseUuid=" + userBaseUuid);
            return httpService.httpServicePost(httpParams);
        },
        saveSurveyReport: function (byteArrayList, surveyBaseUuid, parentFolderBaseUuid, name) {
            var [byteArrayList, surveyBaseUuid, parentFolderBaseUuid, name] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "saveSurveyReport", "&byteArrayList=" + byteArrayList + "&surveyBaseUuid=" + surveyBaseUuid + "&parentFolderBaseUuid=" + parentFolderBaseUuid + "&name=" + name);
            return httpService.httpServicePost(httpParams);
        },

        sendSurveyAlert: function (sendAlertUsersList, surveyAlertSubject, surveyAlertComments, attachmentsList, permissionType, sendSecureEmail) {
            var [sendAlertUsersList, surveyAlertSubject, surveyAlertComments, attachmentsList, permissionType, sendSecureEmail] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "sendSurveyAlert", "&toSecurityObjectList=" + sendAlertUsersList + "&subject=" + surveyAlertSubject + "&comments=" + surveyAlertComments + "&attachmentListString=" + attachmentsList + "&permissionType=" + permissionType + "&secureMessage=" + sendSecureEmail);
            return httpService.httpServicePost(httpParams);
        },
        deleteSurveyParticipants: function (surveyBaseUuid, participantBaseUuidList) {
            var [surveyBaseUuid, participantBaseUuidList] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "deleteSurveyParticipants", "&surveyBaseUuid=" + surveyBaseUuid + "&participantBaseUuidList=" + participantBaseUuidList);
            return httpService.httpServicePost(httpParams);
        },
        addParticipantsToSurveys: function (surveyBaseUuidList, participantBaseUuidList) {
            var [surveyBaseUuidList, participantBaseUuidList] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "addParticipantsToSurveys", "&surveyBaseUuidList=" + surveyBaseUuidList + "&participantBaseUuidList=" + participantBaseUuidList);
            return httpService.httpServicePost(httpParams);
        },
        changeSurveyDueDate: function (surveyBaseUuid, dueDate) {
            var [surveyBaseUuid, dueDate] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonSurveyService", "changeSurveyDueDate", "&surveyBaseUuid=" + surveyBaseUuid + "&dueDate=" + dueDate);
            return httpService.httpServicePost(httpParams);
        },
        getUsersSignatureJSON: function (businessObjectBaseUuid, objectBaseUuids) {
            var [businessObjectBaseUuid, objectBaseUuids] = process_parametes(arguments);
            var httpParams = buildHttpParams("BvJsonDocumentService", "getUsersSignature", "&businessObjectBaseUuid=" + businessObjectBaseUuid + "&objectBaseUuids=" + objectBaseUuids);
            return httpService.httpServicePost(httpParams);
        },

        setupPin: function (username, pin, url) {
            var redirectUrl = url;
            var [username, pin, url] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "setupPin";
            var params = "&identity=" + username + "&pin=" + pin;
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },

        forgotPin: function (url) {
            var redirectUrl = url;
            var [url] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "forgotPin";
            return httpService.httpRedirectLoginPost(servmethod, redirectUrl);
        },

        pinLogin: function (username, pin, url) {
            var redirectUrl = url;
            var [username, pin, url] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "pinLogin";
            var params = "&identity=" + username + "&pin=" + pin;
            return httpService.httpRedirectLoginPost(servmethod + params, redirectUrl);
        },

        getBvBtDevices: function (url) {
            var redirectUrl = url;
            var [url] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "getBvBtDevices";
            return httpService.httpRedirectLoginPost(servmethod, redirectUrl);
        },

        getBvBtAuthenticatedUsers: function (peripheralUUID, peripheralName) {
            var [peripheralUUID, peripheralName] = process_parametes(arguments);
            var servmethod = ipadServiceMethodStr + "getBvBtAuthenticatedUsers";
            var params = "&peripheralUUID=" + peripheralUUID + "&peripheralName=" + peripheralName;
            return httpService.httpLoginPost(servmethod + params);
        },

        getAllCalendarEventListLiteJSON: function (selectedMeetingOption, dateFrom, dateTo) {
            var [selectedMeetingOption, dateFrom, dateTo] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getAllCalendarEventListLiteJSON";
            var params = "&selectedMeetingOption=" + selectedMeetingOption + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo;
            return httpService.httpServicePost(servmethod + params);
        },

        getAllCalendarEventListFullJSON: function (dateFrom, dateTo) {
            var [dateFrom, dateTo] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getAllCalendarEventListFullJSON";
            var params = "&dateFrom=" + dateFrom + "&dateTo=" + dateTo;
            return httpService.httpServicePost(servmethod + params);
        },

        getAllCalendarCountJSON: function (dateFrom, dateTo) {
            var [dateFrom, dateTo] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getAllCalendarCountJSON";
            var params = "&dateFrom=" + dateFrom + "&dateTo=" + dateTo;
            return httpService.httpServicePost(servmethod + params);
        },

        getUserCalendarListJSON: function () {
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getUserCalendarListJSON";
            return httpService.httpServicePost(servmethod);
        },
        getCalendarEventAttachmentsJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getCalendarEventAttachmentsJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        loadGuestandResponseForEventJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=loadGuestandResponseForEventJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        loadGuestandResponseForEventWithoutAttachmentsJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=loadGuestandResponseForEventWithoutAttachmentsJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        setCalendarResponseJSON: function (objectVersionUuid, objectBaseUuid, eventResponse) {
            var [objectVersionUuid, objectBaseUuid, eventResponse] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=setCalendarResponseJSON";
            var params = "&objectVersionUuid=" + objectVersionUuid + "&objectBaseUuid=" + objectBaseUuid + "&eventResponse=" + eventResponse;
            return httpService.httpServicePost(servmethod + params);
        },
        composeMessageJSON: function (toSecurityUuidList, subject, comments, attachmentUuidList, permissionType, secureMessage, newitemEnabled, briefcaseEnabled, filesUuidList, selectedExpiry) {
            var [toSecurityUuidList, subject, comments, attachmentUuidList, permissionType, secureMessage, newitemEnabled, briefcaseEnabled, filesUuidList, selectedExpiry] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=composeMessageJSON";
            var params = "&toSecurityUuidList=" + toSecurityUuidList + "&subject=" + subject + "&comments=" + comments + "&attachmentUuidList="
                + attachmentUuidList + "&permissionType=" + permissionType + "&secureMessage=" + secureMessage + "&newitemEnabled=" + newitemEnabled + "&briefcaseEnabled=" + briefcaseEnabled
                + "&filesUuidList=" + filesUuidList + "&selectedExpiry=" + selectedExpiry;
            return httpService.httpServicePost(servmethod + params);
        },
        composeMessageJSONForSignature: function (toSecurityUuidList, subject, comments, attachmentUuidList, permissionType, secureMessage, newitemEnabled, briefcaseEnabled, filesUuidList, selectedExpiry) {
            var [toSecurityUuidList, subject, comments, attachmentUuidList, permissionType, secureMessage, newitemEnabled, briefcaseEnabled, filesUuidList, selectedExpiry] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=composeMessageJSONForSignature";
            var params = "&toSecurityUuidList=" + toSecurityUuidList + "&subject=" + subject + "&comments=" + comments + "&attachmentUuidList="
                + attachmentUuidList + "&permissionType=" + permissionType + "&secureMessage=" + secureMessage + "&newitemEnabled=" + newitemEnabled + "&briefcaseEnabled=" + briefcaseEnabled
                + "&filesUuidList=" + filesUuidList + "&selectedExpiry=" + selectedExpiry;
            return httpService.httpServicePost(servmethod + params);
        },
        deletedUserSharedObjectJSON: function (objectBaseUuidList) {
            var [objectBaseUuidList] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=deletedUserSharedObjectJSON";
            var params = "&objectBaseUuidList=" + objectBaseUuidList;
            return httpService.httpServicePost(servmethod + params);
        },
        deleteSentItemObjectJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=deleteSentItemObjectJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        deleteInboxItemObjectJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=deleteInboxItemObjectJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        recallSentItemObjectJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=recallSentItemObjectJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        markAllInboxItemsAsRead: function () {
            var servmethod = "servicename=" + BaseRemoteService + "&methodname=markAllInboxItemsAsRead";
            var params = "&userBaseUuid=" + "";
            return httpService.httpServicePost(servmethod + params);
        },
        getInboxUnreadCount: function () {
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=getInboxUnreadCount";
            return httpService.httpServicePost(servmethod);
        },
        getSearchLimit: function () {
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=getSearchLimit";
            return httpService.httpServicePost(servmethod);
        },
        getInboxUnreadCountForPolling: function () {
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=getInboxUnreadCountForPolling";
            return httpService.httpServicePost(servmethod);
        },
        replyShareContainerJSON: function (shareParentBaseUuid, toSecurityUuidList, subject, comments, attachmentUuidList, permissionType, secureMessage, newitemEnabled, briefcaseEnabled, filesUuidList) {
            var [shareParentBaseUuid, toSecurityUuidList, subject, comments, attachmentUuidList, permissionType, secureMessage, newitemEnabled, briefcaseEnabled, filesUuidList] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=replyShareContainerJSON";
            var params = "&shareParentBaseUuid=" + shareParentBaseUuid + "&toSecurityUuidList=" + toSecurityUuidList + "&subject=" + subject + "&comments=" + comments + "&attachmentUuidList="
                + attachmentUuidList + "&permissionType=" + permissionType + "&secureMessage=" + secureMessage + "&newitemEnabled=" + newitemEnabled + "&briefcaseEnabled=" + briefcaseEnabled + "&filesUuidList=" + filesUuidList;
            return httpService.httpServicePost(servmethod + params);
        },
        getCountOfNotRespondedOpenApprovalsForCurrentUser: function () {
            var servmethod = "servicename=" + approvalService + "&methodname=getCountOfNotRespondedOpenApprovalsForCurrentUserForPolling";
            return httpService.httpServicePost(servmethod);
        },
        getCountOfNotRespondedOpenSurveysForCurrentUser: function () {
            var httpParams = buildHttpParams("BvJsonSurveyService", "getCountOfNotRespondedOpenSurveysForCurrentUserForPolling");
            return httpService.httpServicePost(httpParams);
        },
        isSecureEnabled: function () {
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=isSecureEnabled";
            return httpService.httpServicePost(servmethod);
        },
        getUsers: function (getusers, getgroup, getportal) {
            var [getusers, getgroup, getportal] = process_parametes(arguments);
            var servmethod = "servicename=" + TaskRemoteService + "&methodname=getUsers";
            var params = "&getusers=" + getusers + "&getgroup=" + getgroup + "&getportal=" + getportal;
            return httpService.httpServicePost(servmethod + params);
        },
        recycleIntive: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=recycleIntive";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        sendEventAlert: function (calendarEventBaseUuid, usersObjectBaseUuidList) {
            var [calendarEventBaseUuid, usersObjectBaseUuidList] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=sendEventAlert";
            var params = "&calendarEventBaseUuid=" + calendarEventBaseUuid + "&usersObjectBaseUuidList=" + usersObjectBaseUuidList;
            return httpService.httpServicePost(servmethod + params);
        },
		sendCustomizedEventAlert: function (calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText, isICSFileIncluded) {
            var [calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=sendCustomizedEventAlert";
            var params = "&calendarEventBaseUuid=" + calendarEventBaseUuid + "&usersObjectBaseUuidList=" + usersObjectBaseUuidList + "&subject=" + subject + "&announcementText=" + announcementText + "&isICSFileIncluded=" + isICSFileIncluded;
            return httpService.httpServicePost(servmethod + params);
        },
        deleteAndSendCustomizedAlertForMeetingCenterCalendarEvent: function (calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText, isICSFileIncluded) {
            var [calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=deleteAndSendCustomizedAlertForMeetingCenterCalendarEvent";
            var params = "&calendarEventBaseUuid=" + calendarEventBaseUuid + "&usersObjectBaseUuidList=" + usersObjectBaseUuidList + "&subject=" + subject + "&announcementText=" + announcementText + "&isICSFileIncluded=" + isICSFileIncluded;
            return httpService.httpServicePost(servmethod + params);
        },
        deleteAndSendAlertForMeetingCenterCalendarEvent: function (calendarEventBaseUuid, usersObjectBaseUuidList) {
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=deleteAndSendAlertForMeetingCenterCalendarEvent";
            var params = "&calendarEventBaseUuid=" + calendarEventBaseUuid + "&usersObjectBaseUuidList=" + usersObjectBaseUuidList;
            return httpService.httpServicePost(servmethod + params);
        },
        getEventsForTheDayForPlink: function (calendarEventBaseUuid) {
            var [calendarEventBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getEventsForTheDayForPlink";
            var params = "&calendarEventBaseUuid=" + calendarEventBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getCalendarEventsForMeetingCenterInSyncCommunicationJSON: function () {
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getCalendarEventsForMeetingCenterInSyncCommunicationJSON";
            return httpService.httpServicePost(servmethod);
        },
        getRunningHostedCalendarEventsForMeetingCenterInSyncCommunicationJSON: function () {
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getRunningHostedCalendarEventsForMeetingCenterInSyncCommunicationJSON";
            return httpService.httpServicePost(servmethod);
        },
        getUsersJSON: function (getusers, getgroup, getportal) {
            var [getusers, getgroup, getportal] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getUsersJSON";
            var params = "&getusers=" + getusers + "&getgroup=" + getgroup + "&getportal=" + getportal;
            return httpService.httpServicePost(servmethod + params);
        },
        getPermissionJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonACLService + "&methodname=getPermissionJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getComputedPermissionJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonACLService + "&methodname=getComputedPermissionJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        isSetFollowersEnabled: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonACLService + "&methodname=isSetFollowersEnabledJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        savePermissionJSON: function (objectBaseUuid, sub, inherit, aclVOsJson) {
            var [objectBaseUuid, sub, inherit, aclVOsJson] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonACLService + "&methodname=savePermissionJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&sub=" + sub + "&inherit=" + inherit + "&aclVOsJson=" + aclVOsJson;
            return httpService.httpServicePost(servmethod + params);
        },
        getPortalGroupsJSON: function () {
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getPortalGroupsJSON";
            return httpService.httpServicePost(servmethod);
        },
        getCurrentRootJSON: function () {
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getCurrentRootJSON";
            return httpService.httpServicePost(servmethod);
        },
        getUsersAndGroupsJSON: function () {
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getUsersAndGroupsJSON";
            return httpService.httpServicePost(servmethod);
        },
        getChildrenJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=getChildrenJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        addRemoveParticipantsToEvents: function (isAdd, usersObjectBaseUuidList, eventsObjectBaseUuidList) {
            var [isAdd, usersObjectBaseUuidList, eventsObjectBaseUuidList] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=addRemoveParticipantsToEvents";
            var params = "&isAddOperation=" + isAdd + "&usersObjectBaseUuidList=" + usersObjectBaseUuidList + "&eventsObjectBaseUuidList=" + eventsObjectBaseUuidList;
            return httpService.httpServicePost(servmethod + params);
        },
        changeTimeForEvents: function (eventsObjectBaseUuidList, changeTime, day) {
            var [eventsObjectBaseUuidList, changeTime, day] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=changeTimeForEvents";
            var params = "&eventsObjectBaseUuidList=" + eventsObjectBaseUuidList + "&changeTime=" + changeTime + "&day=" + day;
            return httpService.httpServicePost(servmethod + params);
        },
        saveMeetingsChanges: function (eventsIdsForTime, changeTime, eventDay, eventsIdsForAddtion, userIdsForAddition, eventUserListForRemoval, inactiveEvents, activeEvents, eventsForCopy, detailsForCopy) {
            var [eventsIdsForTime, changeTime, eventDay, eventsIdsForAddtion, userIdsForAddition, eventUserListForRemoval, inactiveEvents, activeEvents, eventsForCopy, detailsForCopy] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonCalendarService + "&methodname=saveMeetingsChanges";
            var params = "&eventsIdsForTime=" + eventsIdsForTime + "&changeTime=" + changeTime + "&eventDay=" + eventDay + "&eventsIdsForAddtion=" + eventsIdsForAddtion + "&userIdsForAddition=" + userIdsForAddition + "&eventUserListForRemoval=" + eventUserListForRemoval
                + "&inactiveEvents=" + inactiveEvents + "&activeEvents=" + activeEvents + "&eventsForCopy=" + eventsForCopy + "&detailsForCopy=" + detailsForCopy;
            return httpService.httpServicePost(servmethod + params);
        },
        getInboxJSON: function (offset) {
            var [offset] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=getSharedWithMeJSON";
            var params = "&offset=" + offset;
            return httpService.httpServicePost(servmethod + params);
        },
        getSentJSON: function (offset) {
            var [offset] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=getSharedByMeJSON";
            var params = "&offset=" + offset;
            return httpService.httpServicePost(servmethod + params);
        },
        markItemRead: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=markItemRead";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        pullBackSentItem: function (shareContainerObjectBaseUuid) {
            var [shareContainerObjectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=pullBackSentItem";
            var params = "&shareContainerObjectBaseUuid=" + shareContainerObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getSharedContainerWithAttachmentsJSON: function (shareContainerObjectBaseUuid) {
            var [shareContainerObjectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonMessagesService + "&methodname=getSharedContainerWithAttachmentsJSON";
            var params = "&shareContainerObjectBaseUuid=" + shareContainerObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        setModuleSettingsForPortal: function (moduleMapping, defaultLandingModule, defaultJupLandingModule) {
            var [moduleMapping, defaultLandingModule, defaultJupLandingModule] = process_parametes(arguments);
            var servmethod = "servicename=" + adminRemoteService + "&methodname=setModuleSettingsForPortal";
            var params = "&moduleMapping=" + moduleMapping + "&defaultLandingModule=" + defaultLandingModule + "&defaultJupLandingModule=" + defaultJupLandingModule;
            return httpService.httpServicePost(servmethod + params);
        },
        startMergingCompany: function () {
            var servmethod = "servicename=" + mergeRemoteService + "&methodname=startMergingCompany";
            return httpService.httpServicePost(servmethod);
        },
        loginToDirectorDesk: function (loginId, password) {
            var [loginId, password] = process_parametes(arguments);
            var servmethod = "servicename=" + mergeRemoteService + "&methodname=loginToDirectorDesk";
            var params = "&loginId=" + loginId + "&password=" + password;
            return httpService.httpServicePost(servmethod + params);
        },
        getStausValueForMerge: function (state) {
            var [state] = process_parametes(arguments);
            var servmethod = "servicename=" + mergeRemoteService + "&methodname=getStausValueForMigration";
            var params = "&currentState=" + state;
            return httpService.httpServicePost(servmethod + params);
        },
        getMergeStatusForCompanyUsingStateandTime: function (state, lastProcessedTime) {
            var [state, lastProcessedTime] = process_parametes(arguments);
            var servmethod = "servicename=" + mergeRemoteService + "&methodname=getMigrationStatusForCompanyUsingStateandTime";
            var params = "&currentState=" + state + "&lastProcessedTime=" + lastProcessedTime;
            return httpService.httpServicePost(servmethod + params);
        },
        registerDeviceForDirectorDesk: function (code) {
            var [code] = process_parametes(arguments);
            var servmethod = "servicename=" + mergeRemoteService + "&methodname=registerDeviceForDirectorDesk";
            var params = "&code=" + code;
            return httpService.httpServicePost(servmethod + params);
        },
        isDeviceRegisteredForMerge: function () {
            var servmethod = "servicename=" + mergeRemoteService + "&methodname=isDeviceRegisteredForMerge";
            return httpService.httpServicePost(servmethod);
        },
        generateRegistratonCode: function (userEmailId) {
            var [userEmailId] = process_parametes(arguments);
            var servmethod = "servicename=" + mergeRemoteService + "&methodname=generateRegistratonCode";
            var params = "&userEmailId=" + userEmailId;
            return httpService.httpServicePost(servmethod + params);
        },

        getPortalPagesJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonPortalService + "&methodname=getPortalPagesJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        deletePortalPageJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonPortalService + "&methodname=deletePortalPageJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        addPortalPageJSON: function (pageName, count) {
            var [pageName, count] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonPortalService + "&methodname=addPortalPageJSON";
            var params = "&pageName=" + pageName + "&count=" + count;
            return httpService.httpServicePost(servmethod + params);
        },
        updatePortalPageJSON: function (pageVOJson) {
            var [pageVOJson] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonPortalService + "&methodname=updatePortalPageJSON";
            var params = "&pageVOJson=" + pageVOJson;
            return httpService.httpServicePost(servmethod + params);
        },
        addSingleViewToPageJSON: function (objectBaseUuid, view, portletTitle, order, region) {
            var [objectBaseUuid, view, portletTitle, order, region] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonPortalService + "&methodname=addSingleViewToPageJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&view=" + view + "&portletTitle=" + portletTitle + "&order=" + order + "&region=" + region;
            return httpService.httpServicePost(servmethod + params);
        },
        deletePortletJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonPortalService + "&methodname=deletePortletJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getUserProfileConfigurationsJSON: function () {
            var servmethod = "servicename=" + BvJsonPortalService + "&methodname=getUserProfileConfigurationsJSON";
            return httpService.httpServicePost(servmethod);
        },
        updatePagesOrderJSON: function (uuidsOrdered) {
            var [uuidsOrdered] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonPortalService + "&methodname=updatePagesOrderJSON";
            var params = "&uuidsOrdered=" + uuidsOrdered;
            return httpService.httpServicePost(servmethod + params);
        },

        //Start - Team
        getTeamSpaceUsersJSON: function (portalBaseUuid) {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getTeamSpaceUsersJSON";
            var params = "&portalBaseUuid=" + portalBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getGroupsAndTeamspaces: function (userBaseUuid) {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getGroupsAndTeamspaces";
            var params = "&userBaseUuid=" + userBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        inviteUser: function (emailAddress) {
            var [emailAddress] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=inviteUser";
            var params = "&emailAddress=" + emailAddress;
            return httpService.httpServicePost(servmethod + params);
        },
        getSessionPollingMessages: function (lastSetOfPollingMessages, accessFlag, log) {
            var servmethod = "servicename=" + BvJsonPollingService + "&methodname=getSessionPollingMessages";
            var params = "&acknowledgedMessages=" + lastSetOfPollingMessages + "&accessFlag=" + accessFlag + "&log=" + log;
            return httpService.httpServicePollPost(servmethod + params);
        },
        updateSessionPollingForMeetingEditEnd: function(meetingId) {
            var servmethod = "servicename=" + BvJsonPollingService + "&methodname=updateSessionPollingForMeetingEditEnd";
            var params = "&meetingId=" + meetingId;
            return httpService.httpServicePollPost(servmethod + params);
        },
        startMeetingEditPolling: function(meetingDetails) {
            var servmethod = "servicename=" + BvJsonPollingService + "&methodname=startMeetingEditPolling";
            var params = "&meetingDetails=" + meetingDetails;
            return httpService.httpServicePollPost(servmethod + params);
        },
        getUserProfileImage: function (objectBaseUuid, objectVersionUuid) {
            var [objectBaseUuid, objectVersionUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getUserProfileImage";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&objectVersionUuid=" + objectVersionUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getUserProfileImages: function (input) {
            var [input] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getUserProfileImages";
            var params = "&input=" + input;
            return httpService.httpServicePost(servmethod + params);
        },
        getUserDataBybaseUuid: function (userBaseUuid) {
            var [userBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getUserDataBybaseUuid";
            var params = "&objectBaseUuid=" + userBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getUserProfileImageByObjectBaseUuid: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getUserProfileImageByObjectBaseUuid";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        //        subscribe: function(userBaseUuid) {
        //            var [ userBaseUuid ] = process_parametes(arguments);
        //            var servmethod = "servicename=" + teamRemoteService + "&methodname=subscribe";
        //            var params="&objectBaseUuid=" + userBaseUuid;
        //            return  httpService.httpServicePost(servmethod + params);
        //        },
        //        unsubscribe: function(userBaseUuid) {
        //            var [ userBaseUuid] = process_parametes(arguments);
        //            var servmethod = "servicename=" + teamRemoteService + "&methodname=unsubscribe";
        //            var params="&objectBaseUuid=" + userBaseUuid;
        //            return  httpService.httpServicePost(servmethod + params);
        //        },
        unsubscribeFollowMeUsers: function (subscriptionUuids) {
            var [subscriptionUuids] = process_parametes(arguments);
            var serviceMethod = BvJsonSubscriptionServiceMethodStr + "unsubscribeFollowMeUsers";
            var params = "&selectedSubscriberUuids=" + subscriptionUuids;
            return httpService.httpServicePost(serviceMethod + params);
        },
        //        loadMySubscriptions: function() {
        //            var servmethod = "servicename=" + teamRemoteService + "&methodname=loadMySubscriptions";
        //            return  httpService.httpServicePost(servmethod);
        //        },
        removeUserFromPortal: function (userBaseUuid) {
            var [userBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=removeUserFromPortal";
            var params = "&userBaseUuid=" + userBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        makeSpaceOwner: function (userBaseUuid) {
            var [userBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=makeSpaceOwner";
            var params = "&userBaseUuid=" + userBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        removeSpaceOwner: function (userBaseUuid) {
            var [userBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=removeUserFromPortal";
            var params = "&userBaseUuid=" + userBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getSpaceOwners: function () {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getSpaceOwners";
            return httpService.httpServicePost(servmethod);
        },
        getLicenseTypeDetails: function () {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getLicenseTypeDetails";
            return httpService.httpServicePost(servmethod);
        },
        createUser: function (firstName, lastName, loginID, password, emailAddress, licenseType) {
            var [firstName, lastName, loginID, password, emailAddress, licenseType] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=createUser";
            var params = "&firstName=" + firstName + "&lastName=" + lastName + "&loginID=" + loginID + "&password=" + password + "&emailAddress=" + emailAddress + "&licenseType=" + licenseType;
            return httpService.httpServicePost(servmethod + params);
        },
        editProfile: function (userVOJson, bio) {
            var [userVOJson, bio] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=editProfile";
            var params = "&userVOJson=" + userVOJson + "&bio=" + bio;
            return httpService.httpServicePost(servmethod + params);
        },
        editProfileLanguage: function (langValue) {
            var [langValue] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=editProfileLanguage";
            var params = "&langValue=" + langValue;
            return httpService.httpServicePost(servmethod + params);
        },
        enableSmsNotifications: function (enableSms) {
            var [enableSms] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=enableSmsNotifications";
            var params = "&enable=" + enableSms;
            return httpService.httpServicePost(servmethod + params);
        },
        getUserSignatureAndInitials: function () {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getUserSignatureAndInitials";
            return httpService.httpServicePost(servmethod);
        },
        createUserSignatureAndInitials: function (signature, initials) {
            var [signature, initials] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=createUserSignatureAndInitials";
            var params = "&signature=" + signature + "&initials=" + initials;
            return httpService.httpServicePost(servmethod + params);
        },
        updateUserProfileImage: function (img, thumbnailType) {
            var [img, thumbnailType] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=updateUserProfileImage";
            var params = "&img=" + img + "&thumbnailType=" + thumbnailType;
            return httpService.httpServicePost(servmethod + params);
        },
        isPortalOwner: function () {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=isPortalOwner";
            return httpService.httpServicePost(servmethod);
        },
        getAllTSGroupsForPortal: function (portalBaseUuid) {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getAllTSGroupsForPortal";
            var params = "&portalBaseUuid=" + portalBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getAllGroupsOfPortal: function (portalBaseUuid) {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getAllGroupsOfPortal";
            var params = "&portalBaseUuid=" + portalBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getAllGroupsOfUser: function (portalBaseUuid) {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getAllGroupsOfUser";
            var params = "&portalBaseUuid=" + portalBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getAllGroups: function () {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getAllGroups";
            return httpService.httpServicePost(servmethod);
        },
        getGroupChildren: function (teamSpaceGroupId) {
            var [teamSpaceGroupId] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getGroupChildren";
            var params = "&teamSpaceGroupId=" + teamSpaceGroupId;
            return httpService.httpServicePost(servmethod + params);
        },
        createGroup: function (groupName) {
            var [groupName] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=createGroup";
            var params = "&groupName=" + groupName;
            return httpService.httpServicePost(servmethod + params);
        },
        removeUserFromGroup: function (groupBaseuuid, userBaseUuid) {
            var [groupBaseuuid, userBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=removeUserFromGroup";
            var params = "&groupBaseuuid=" + groupBaseuuid + "&userBaseUuid=" + userBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        addGroupToTeamSpace: function (groupBaseuuids) {
            var [groupBaseuuids] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=addGroupToTeamSpace";
            var params = "&groupBaseuuids=" + groupBaseuuids;
            return httpService.httpServicePost(servmethod + params);
        },
        removeGroupFromTeamSpace: function (groupBaseuuid) {
            var [groupBaseuuid] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=removeGroupFromTeamSpace";
            var params = "&groupBaseuuid=" + groupBaseuuid;
            return httpService.httpServicePost(servmethod + params);
        },
        addUserToGroup: function (groupBaseuuid, usersUuidJson) {
            var [groupBaseuuid, usersUuidJson] = process_parametes(arguments);
            var servmethod = "servicename=" + teamRemoteService + "&methodname=addUserToGroup";
            var params = "&groupBaseuuid=" + groupBaseuuid + "&usersUuidJson=" + usersUuidJson;
            return httpService.httpServicePost(servmethod + params);
        },
        //		isGroupManager: function() {
        //			var servmethod = "servicename=" + teamRemoteService + "&methodname=isGroupManager";
        //			return  httpService.httpServicePost(servmethod);
        //		},
        getCompanyUsersJSON: function () {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getCompanyUsersJSON";
            return httpService.httpServicePost(servmethod);
        },
        // End - Team

        getBusinessObjectVOJSONByBaseuuid: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "getBusinessObjectVOJSONByBaseuuid";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getDocPropJSONByBaseUuid: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "getDocPropJSONByBaseUuid";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        removeDocumentFromSelectedUsersFavorite: function (docObjectBaseUuid, usersObjectBaseUuid) {
            var [docObjectBaseUuid, usersObjectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "removeDocumentFromSelectedUsersFavorite";
            var params = "&docObjectBaseUuid=" + docObjectBaseUuid + "&usersObjectBaseUuid=" + usersObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        addDocumentToSelectedUsersFavorite: function (docObjectBaseUuid, usersObjectBaseUuids) {
            var [docObjectBaseUuid, usersObjectBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "addDocumentToSelectedUsersFavorite";
            var params = "&docObjectBaseUuid=" + docObjectBaseUuid + "&usersObjectBaseUuids=" + usersObjectBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },

        removeDocumentsFromUsersFavoriteJSON: function (objectBaseUuids) {
            var [objectBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "removeDocumentsFromUsersFavoriteJSON";
            var params = "&objectBaseUuids=" + objectBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },

        rename: function (baseuuid, newName) {
            var [baseuuid, newName] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "rename";
            var params = "&objectBaseUuid=" + baseuuid + "&newName=" + encodeURIComponent(newName);
            return httpService.httpServicePost(servmethod + params);
        },

        getDocumentThumbnail: function (objectBaseUuid, objectVersionUuid) {
            var [objectBaseUuid, objectVersionUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "getDocumentThumbnail";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&objectVersionUuid=" + objectVersionUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getFavoritesRootFolder: function () {
            var servmethod = documentServiceMethodStr + "getFavoritesRootFolder";
            return httpService.httpServicePost(servmethod);
        },

        addToBriefCase: function (listofBaseUuids) {
            bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Document, bvUsageUtils.const.Entity.Action.Document.AddToBriefcase, undefined, listofBaseUuids.split(',').length);
            var [listofBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "addToBriefCase";
            var params = "&listofBaseUuids=" + listofBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },

        addToNewItems: function (listofBaseUuids) {
            bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Document, bvUsageUtils.const.Entity.Action.Document.AddToNewItems, undefined, listofBaseUuids.split(',').length);
            var [listofBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "addToNewItems";
            var params = "&listofBaseUuids=" + listofBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },
        getAllVersions: function (baseUuid) {
            var [baseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "getAllVersions";
            var params = "&baseUuid=" + baseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getConfiguredTimeLimitForNewItemsToExpire: function () {
            var servmethod = documentServiceMethodStr + "getConfiguredTimeLimitForNewItemsToExpire";
            return httpService.httpServicePost(servmethod);
        },
        makeLatestVersion: function (objectBaseUuid, versionUuid) {
            var [objectBaseUuid, versionUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "makeLatestVersion";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&versionUuid=" + versionUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        createNewFile: function (objectBaseUuid, objectVersionUuid, fileName) {
            var [objectBaseUuid, objectVersionUuid, fileNam] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "createNewFile";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&objectVersionUuid=" + objectVersionUuid + "&fileName=" + fileName;
            return httpService.httpServicePost(servmethod + params);
        },

        cutDocOrFolder: function (listofBaseUuids, parentBaseUuid) {
            var [listofBaseUuids, parentBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "cutDocOrFolder";
            var params = "&listofBaseUuids=" + listofBaseUuids + "&parentBaseUuid=" + parentBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getNodeChildrenForCollationJSON: function (parentObjectBaseUuid) {
            var [parentObjectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "getNodeChildrenForCollationJSON";
            var params = "&parentObjectBaseUuid=" + parentObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        createCollatedDocumentJSON: function (collateJSON) {
            var [collateJSON] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "createCollatedDocumentJSON";
            var params = "&collateJSON=" + collateJSON;
            return httpService.httpServicePost(servmethod + params);
        },

        createBoardbookJSON: function (boardbookJSON) {
            var [boardbookJSON] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "createBoardbookJSON";
            var params = "&boardbookJSON=" + boardbookJSON;
            return httpService.httpServicePost(servmethod + params);
        },

        copyDocOrFolder: function (listofBaseUuids, parentBaseUuid) {
            var [listofBaseUuids, parentBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "copyDocOrFolder";
            var params = "&listofBaseUuids=" + listofBaseUuids + "&parentBaseUuid=" + parentBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        pasteDocOrFolder: function (targetFolderBaseUuid) {
            var [targetFolderBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "pasteDocOrFolder";
            var params = "&targetFolderBaseUuid=" + targetFolderBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getMySignature: function () {
            var servmethod = documentServiceMethodStr + "getMySignature";
            return httpService.httpServicePost(servmethod);
        },

        getTopic: function (parentBaseUuid) {
            var [parentBaseUuid] = process_parametes(arguments);
            var serviceMethod = wallServiceMethodStr + "getTopic&parentBaseUuid=" + parentBaseUuid;
            return httpService.httpServicePost(serviceMethod);
        },
        checkSubscription: function (parentBaseUuid) {
            var [parentBaseUuid] = process_parametes(arguments);
            var serviceMethod = wallServiceMethodStr + "checkSubscription&parentBaseUuid=" + parentBaseUuid;
            return httpService.httpServicePost(serviceMethod);
        },
        postReplyComment: function (objectBaseUuid, feedId, portalBaseUuid, replyCommentHTML, usersIds, wallBaseUuid) {
            var [objectBaseUuid, feedId, portalBaseUuid, replyCommentHTML, usersIds, wallBaseUuid] = process_parametes(arguments);
            var serviceMethod = wallServiceMethodStr + "postReplyComment";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&feedId=" + feedId + "&portalBaseUuid=" + portalBaseUuid + "&replyCommentHTML=" + replyCommentHTML + "&usersIds=" + usersIds + "&wallBaseUuid=" + wallBaseUuid;;
            serviceMethod += params;
            return httpService.httpServicePost(serviceMethod);
        },
        deletePost: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var serviceMethod = wallServiceMethodStr + "deletePost";
            var params = "&baseuuid=" + objectBaseUuid;
            serviceMethod += params;
            return httpService.httpServicePost(serviceMethod);
        },
        getFeedsForDiscussion: function (portalId, eventFilter, offset) {
            var [portalId, eventFilter, offset] = process_parametes(arguments);
            var serviceMethod = wallServiceMethodStr + "getJSONFeedWallForObjectBaseUuid&wallBaseUuid=" + portalId;
            var params = "&eventFilter=" + eventFilter + "&offset=" + offset;
            serviceMethod += params;
            return httpService.httpServicePost(serviceMethod);
        },
        getPostComments: function (parentBaseUuid) {
            var [parentBaseUuid] = process_parametes(arguments);
            var serviceMethod = wallServiceMethodStr + "getPostComments";
            var params = "&parentBaseUuid=" + parentBaseUuid;
            serviceMethod += params;
            return httpService.httpServicePost(serviceMethod);
        },
        postToWall: function (wallBaseUuid, postTextHTML, attachmentBoIds, usersIds) {
            var [wallBaseUuid, postTextHTML, attachmentBoIds, usersIds] = process_parametes(arguments);
            var serviceMethod = wallServiceMethodStr + "postToWall&wallBaseUuid=" + wallBaseUuid + "&attachmentBoIds=" + attachmentBoIds + "&usersIds=" + usersIds;
            var params = "&postTextHTML=" + postTextHTML;
            serviceMethod += params;
            return httpService.httpServicePost(serviceMethod);
        },
        editPost: function (objectBaseUuid, editedHtml, updatedAttachmentBoIds, usersIds, wallBaseUuid) {
            var [objectBaseUuid, editedHtml, updatedAttachmentBoIds, usersIds, wallBaseUuid] = process_parametes(arguments);
            var serviceMethod = wallServiceMethodStr + "editPost&objectBaseUuid=" + objectBaseUuid + "&editedHtml=" + editedHtml + "&updatedAttachmentBoIds=" + updatedAttachmentBoIds + "&usersIds=" + usersIds + "&wallBaseUuid=" + wallBaseUuid;
            return httpService.httpServicePost(serviceMethod);
        },
        getDocumentsForSignature: function () {
            var serviceMethod = documentServiceMethodStr + "getDocumentsJSONForSignatures";
            return httpService.httpServicePost(serviceMethod);
        },
        getSignCommitedDocuments: function () {
            var serviceMethod = documentServiceMethodStr + "getSignCommitedDocuments";
            return httpService.httpServicePost(serviceMethod);
        },
        getOwnedSignatureDocuments: function () {
            var serviceMethod = documentServiceMethodStr + "getOwnedSignatureDocuments";
            return httpService.httpServicePost(serviceMethod);
        },
        getDocumentsForSignatureTabs: function (tab) {
            var viewTab = tab.trim();
            var serviceMethod = "servicename=BvJsonDocumentService&methodname=getSignaturesJson&view=" + viewTab;
            return httpService.httpServicePost(serviceMethod);
        },
        getDocumentsForSignaturesCount: function () {
            var serviceMethod = documentServiceMethodStr + "getDocumentsForSignaturesCount";
            return httpService.httpServicePost(serviceMethod);
        },
        getDocumentEncodedThumbnail: function (objectBaseUuid, objectVersionUuid, size) {
            var [objectBaseUuid, objectVersionUuid, size] = process_parametes(arguments);
            var serviceMethod = wallServiceMethodStr + "getDocumentEncodedThumbnail";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&objectVersionUuid=" + objectVersionUuid + "&size=" + size + "&isAjaxCall=true";
            serviceMethod += params;
            return httpService.httpServicePost(serviceMethod);
        },
        createNewHtmlWikiPage: function (name) {
            var [name] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonHTMLWikiPageService&methodname=createNewHtmlWikiPage&name=" + name;
            return httpService.httpServicePost(serviceMethod);
        },
        getHtmlWikiPage: function (wikiPageId) {
            var [wikiPageId] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonHTMLWikiPageService&methodname=getHtmlWikiPage&wikiPageId=" + wikiPageId;
            return httpService.httpServicePost(serviceMethod);
        },
        saveHtmlWikiPage: function (wikiPageId, wikiHTMLText) {
            var [wikiPageId, wikiHTMLText] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonHTMLWikiPageService&methodname=saveHtmlWikiPage&wikiPageId=" + wikiPageId + "&htmlText=" + wikiHTMLText;
            return httpService.httpServicePost(serviceMethod);
        },
        getOpenInvitations: function () {
            var serviceMethod = "servicename=BvJsonCalendarService&methodname=getOpenInvitations";
            return httpService.httpServicePost(serviceMethod);
        },
        getAllOpenItems: function () {
            var serviceMethod = "servicename=BvJsonDashboardService&methodname=getAllOpenItems";
            return httpService.httpServicePost(serviceMethod);
        },
        getSupportInfo: function () {
            var servmethod = BvJsonSupportRemoteService + "&methodname=getSupportInfo";
            return httpService.httpServicePost(servmethod);
        },
        getSecurePassThroughRefIDJson: function () {
            var servmethod = BvJsonSupportRemoteService + "&methodname=getSecurePassThroughRefIDJson";
            return httpService.httpServicePost(servmethod);
        },
        pinSetupDummy: function () {
            var servmethod = "servicename=IPadRemoteService&methodname=pinSetupDummy";
            return httpService.httpServicePost(servmethod);
        },
        zoomInDummy: function () {
            var servmethod = "servicename=IPadRemoteService&methodname=zoomInDummy";
            return httpService.httpServicePost(servmethod);
        },
        zoomOutDummy: function () {
            var servmethod = "servicename=IPadRemoteService&methodname=zoomOutDummy";
            return httpService.httpServicePost(servmethod);
        },
        actualSizeDummy: function () {
            var servmethod = "servicename=IPadRemoteService&methodname=actualSizeDummy";
            return httpService.httpServicePost(servmethod);
        },
        getNewItemsRootFolder: function () {
            var servmethod = documentServiceMethodStr + "getNewItemsRootFolder";
            return httpService.httpServicePost(servmethod);
        },
        loadRecycleBin: function () {
            var servmethod = documentServiceMethodStr + "loadRecycleBinJSON";
            return httpService.httpServicePost(servmethod);
        },
        emptyTrash: function () {
            var servmethod = "servicename=BaseRemoteService&methodname=emptyTrash";
            return httpService.httpServicePost(servmethod);
        },
        removeFromBriefCase: function (listofBaseUuids) {
            bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Document, bvUsageUtils.const.Entity.Action.Document.RemoveFromBriefcase, undefined, listofBaseUuids.split(',').length);
            var [listofBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "removeFromBriefCase";
            var params = "&listofBaseUuids=" + listofBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },
        removeFromNewItems: function (listofBaseUuids) {
            bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Document, bvUsageUtils.const.Entity.Action.Document.RemoveFromNewItems, undefined, listofBaseUuids.split(',').length);
            var [listofBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "removeFromNewItems";
            var params = "&listofBaseUuids=" + listofBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },
        createShortCut: function (parentObjectBaseUuid, listofBaseUuids) {
            var [parentObjectBaseUuid, listofBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "createShortCut";
            var params = "&listofBaseUuids=" + listofBaseUuids + "&parentObjectBaseUuid=" + parentObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        setHideFolder: function (folderBaseUuid, hideFolder) {
            var [folderBaseUuid, hideFolder] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "setHideFolder";
            var params = "&folderBaseUuid=" + folderBaseUuid + "&hideFolder=" + hideFolder;
            return httpService.httpServicePost(servmethod + params);
        },
        getTeamSpaceLogo: function (teamSpaceBaseUuid) {
            var servmethod = "servicename=BvJsonBrandingService&methodname=getTeamSpaceLogo";
            var params = "&teamSpaceBaseUuid=" + teamSpaceBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getCompanyTSLogo: function (checkCompanyLogo) {
            var [checkCompanyLogo] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonBrandingService&methodname=getCompanyTSLogo";
            var params = "&checkCompanyLogo=" + checkCompanyLogo;
            return httpService.httpServicePost(servmethod + params);
        },
        getCompanyLogo: function () {
            var servmethod = "servicename=BvJsonBrandingService&methodname=getCompanyLogo";
            return httpService.httpServicePost(servmethod);
        },
        getLinkedCompanies: function () {
            var servmethod = "servicename=BvJsonPortalService&methodname=getLinkedCompanies";
            return httpService.httpServicePost(servmethod);
        },
        updatePortalBranding: function (portalBaseUuid, portalVO) {
            var [portalBaseUuid, portalVO] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonBrandingService&methodname=updatePortalBranding";
            var params = "&portalBaseUuid=" + portalBaseUuid + "&portalVO=" + portalVO;
            return httpService.httpServicePost(servmethod + params);
        },

        updateTeamspaceLogo: function (fileTransferVO) {
            var [fileTransferVO] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonBrandingService&methodname=uploadTeamspaceImage";
            var params = "&tvo=" + fileTransferVO;
            return httpService.httpServicePost(servmethod + params);
        },
        setTeamSidebarVisibility: function (visibility) {
            var [visibility] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonBrandingService&methodname=setTeamSidebarVisibility";
            var params = "&visibility=" + visibility;
            return httpService.httpServicePost(servmethod + params);
        },
        isTeamSidebarVisible: function () {
            var servmethod = "servicename=BvJsonBrandingService&methodname=isTeamSidebarVisible";
            return httpService.httpServicePost(servmethod);
        },

        //////////////START CONF////////////////////////////////////////////////////////
        getConferenceParticipantsJSON: function (calendarEventObjectBaseUuid) {
            var [calendarEventObjectBaseUuid] = process_parametes(arguments);
            var servmethod = conferencServiceMethodStr + "getConferenceParticipantsJSON";
            var params = "&calendarEventObjectBaseUuid=" + calendarEventObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        startConferenceJSON: function (calendarEventObjectBaseUuid) {
            var [calendarEventObjectBaseUuid] = process_parametes(arguments);
            var servmethod = conferencServiceMethodStr + "startConferenceJSON";
            var params = "&calendarEventObjectBaseUuid=" + calendarEventObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        joinConferenceJSON: function (calendarEventObjectBaseUuid) {
            var [calendarEventObjectBaseUuid] = process_parametes(arguments);
            var servmethod = conferencServiceMethodStr + "joinConferenceJSON";
            var params = "&calendarEventObjectBaseUuid=" + calendarEventObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        leftConference: function (calendarEventObjectBaseUuid, userObjectBaseUuid) {
            var [calendarEventObjectBaseUuid, userObjectBaseUuid] = process_parametes(arguments);
            var servmethod = conferencServiceMethodStr + "leftConference";
            var params = "&calendarEventObjectBaseUuid=" + calendarEventObjectBaseUuid + "&userObjectBaseUuid=" + userObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        shareDocument: function (synchronizedCommunicationVOJson) {
            var [synchronizedCommunicationVOJson] = process_parametes(arguments);
            var servmethod = conferencServiceMethodStr + "shareDocument";
            var params = "&synchronizedCommunicationVOJson=" + synchronizedCommunicationVOJson;
            return httpService.httpServicePost(servmethod + params);
        },
        synchronizeConferenceJSON: function (userObjectBaseUuid, calendarEventObjectBaseUuid, participantListJson) {
            var [userObjectBaseUuid, calendarEventObjectBaseUuid, participantListJson] = process_parametes(arguments);
            var servmethod = conferencServiceMethodStr + "synchronizeConferenceJSON";
            var params = "&userObjectBaseUuid=" + userObjectBaseUuid + "&calendarEventObjectBaseUuid=" + calendarEventObjectBaseUuid + "&participantListJson=" + participantListJson;
            return httpService.httpServicePost(servmethod + params);
        },
        getSynchronizedCommunicationVOJSON: function (calendarEventObjectBaseUuid) {
            var [calendarEventObjectBaseUuid] = process_parametes(arguments);
            var servmethod = conferencServiceMethodStr + "getSynchronizedCommunicationVOJSON";
            var params = "&calendarEventObjectBaseUuid=" + calendarEventObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        grantshareDesktop: function (userObjectBaseUuid, calendarEventObjectBaseUuid) {
            var [userObjectBaseUuid, calendarEventObjectBaseUuid] = process_parametes(arguments);
            var servmethod = conferencServiceMethodStr + "grantshareDesktop";
            var params = "&userObjectBaseUuid=" + userObjectBaseUuid + "&calendarEventObjectBaseUuid=" + calendarEventObjectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        createDocumentConference: function (selectedDocuments, conferenceParticipants, startDateVO, endDateVO) {
            var [selectedDocuments, conferenceParticipants, startDateVO, endDateVO] = process_parametes(arguments);
            var servmethod = conferencServiceMethodStr + "createDocumentConference";
            var params = "&selectedDocuments=" + selectedDocuments + "&conferenceParticipants=" + conferenceParticipants + "&startDateVO=" + startDateVO + "&endDateVO=" + endDateVO;
            return httpService.httpServicePost(servmethod + params);
        },

        ///////////////END CONF////////////////////////////////////////////////////////

        ///////////////////Start Secure Chat //////////////////////////////////////////
        /****************************Commenting for infosec ticket MEETX-56247*******************************************/
       /* addSecureChatGroup: function (groupChatName, contactBaseuuids) {
            var [groupChatName, contactBaseuuids] = process_parametes(arguments);
            var servmethod = BvJsonChatServiceMethodStr + "addSecureChatGroup";
            var params = "&groupChatName=" + groupChatName + "&contactBaseuuids=" + contactBaseuuids;
            return httpService.httpServicePost(servmethod + params);
        },*/
        addContactsToSecureChatGroup: function (contactBaseuuids, chatGroupBaseuuid) {
            var [contactBaseuuids, chatGroupBaseuuid] = process_parametes(arguments);
            var servmethod = BvJsonChatServiceMethodStr + "addContactsToSecureChatGroup";
            var params = "&contactBaseuuids=" + contactBaseuuids + "&chatGroupBaseuuid=" + chatGroupBaseuuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getMySecureChatGroups: function () {
            var servmethod = BvJsonChatServiceMethodStr + "getMySecureChatGroups";
            return httpService.httpServicePost(servmethod);
        },
        getSecuredChatMessagesForChatContact: function (chatContactBaseuuid) {
            var [chatContactBaseuuid] = process_parametes(arguments);
            var servmethod = BvJsonChatServiceMethodStr + "getSecuredChatMessagesForContact";
            var params = "&chatContactBaseuuid=" + chatContactBaseuuid;
            return httpService.httpServicePost(servmethod + params);
        },
        sendMessagesToMySecureChatContact: function (contactBaseuuid, message, attachmentBoIds) {
            var [contactBaseuuid, message, attachmentBoIds] = process_parametes(arguments);
            var servmethod = BvJsonChatServiceMethodStr + "sendMessagesToMySecureChatContact";
            var params = "&chatContactBaseuuid=" + contactBaseuuid + "&message=" + message + "&attachmentBaseuuids=" + attachmentBoIds;
            return httpService.httpServicePost(servmethod + params);
        },
        removeSecureChatGroup: function (secureChatGroupBaseuuid) {
            var [secureChatGroupBaseuuid] = process_parametes(arguments);
            var servmethod = BvJsonChatServiceMethodStr + "removeSecureChatGroup";
            var params = "&secureChatGroupBaseuuid=" + secureChatGroupBaseuuid;
            return httpService.httpServicePost(servmethod + params);
        },
        removeContactFromSecureGroupChat: function (userBaseUuid, secureChatGroupBaseuuid) {
            var [userBaseUuid, secureChatGroupBaseuuid] = process_parametes(arguments);
            var servmethod = BvJsonChatServiceMethodStr + "removeContactFromSecureGroupChat";
            var params = "&chatGroupMemberBaseuuid=" + userBaseUuid + "&secureChatGroupBaseuuid=" + secureChatGroupBaseuuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getMySecureOneOnOneChatGroupIdWithUser: function (userBaseuuid) {
            var [userBaseuuid] = process_parametes(arguments);
            var servmethod = BvJsonChatServiceMethodStr + "getMySecureOneOnOneChatGroupIdWithUser";
            var params = "&userBaseuuid=" + userBaseuuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getMyUnReadSecureMessagesForChatGroup: function (chatGroupBaseuuid) {
            var [chatGroupBaseuuid] = process_parametes(arguments);
            var servmethod = BvJsonChatServiceMethodStr + "getUnreadMessagesForMySecureChatGroup";
            var params = "&chatGroupBaseuuid=" + chatGroupBaseuuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getChatGroupProperties: function (chatGroupBaseuuid) {
            var [chatGroupBaseuuid] = process_parametes(arguments);
            var servmethod = BvJsonChatServiceMethodStr + "getChatGroupProperties";
            var params = "&chatGroupBaseUuid=" + chatGroupBaseuuid;
            return httpService.httpServicePost(servmethod + params);
        },
        saveTimeLimitForMessagesToExpire: function (chatGroupBaseuuid, timeLimit) {
            var [chatGroupBaseuuid, timeLimit] = process_parametes(arguments);
            var servmethod = BvJsonChatServiceMethodStr + "saveTimeLimitForMessagesToExpire";
            var params = "&chatGroupBaseuuid=" + chatGroupBaseuuid + "&timeLimit=" + timeLimit;
            return httpService.httpServicePost(servmethod + params);
        },
        getMyUnreadMessageCount: function (chatGroupBaseuuid, timeLimit) {
            var [chatGroupBaseuuid, timeLimit] = process_parametes(arguments);
            var servmethod = BvJsonChatServiceMethodStr + "getMyUnreadMessageCount";
            return httpService.httpServicePost(servmethod);
        },

        /////////////////End of Secure Chat //////////////////////////////////////////////
        getCreatedByMeApprovalsJSON: function () {
            var servmethod = documentServiceMethodStr + "getCreatedByMeApprovalsJSON";
            return httpService.httpServicePost(servmethod);
        },
        getCreatedByMeSurveysJSON: function () {
            var servmethod = documentServiceMethodStr + "getCreatedByMeSurveysJSON";
            return httpService.httpServicePost(servmethod);
        },
        loadMySubscriptions: function () {
            var servmethod = "servicename=BvJsonPreferencesService&methodname=loadMySubscriptions";
            return httpService.httpServicePost(servmethod);
        },
        loadSubscriptionsToMe: function () {
            var servmethod = "servicename=BvJsonPreferencesService&methodname=loadSubscriptionsToMe";
            return httpService.httpServicePost(servmethod);
        },
        unsubscribeFromContext: function (subscriberUuid, objectToWatchBaseUuid, subscriptionType) {
            var [subscriberUuid, objectToWatchBaseUuid, subscriptionType] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonPreferencesService&methodname=unsubscribe";
            var params = "&subscriberUuid=" + subscriberUuid + "&objectToWatchBaseUuid=" + objectToWatchBaseUuid + "&subscriptionType=" + subscriptionType;
            return httpService.httpServicePost(servmethod + params);
        },
        getUsersPurgedVaultInstallations: function (userBaseUuid) {
            var [userBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonPreferencesService&methodname=getUsersPurgedVaultInstallations";
            var params = "&userBaseUuid=" + userBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getUserVaultInstallations: function (userBaseUuid) {
            var [userBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonPreferencesService&methodname=getUserVaultInstallations";
            var params = "&userBaseUuid=" + userBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        deleteAllAnnotationsByCreator: function () {
            var servmethod = "servicename=BvJsonPreferencesService&methodname=deleteAllAnnotationsByCreator";
            return httpService.httpServicePost(servmethod);
        },
        deleteAllAnnotationsInPortal: function () {
            var servmethod = "servicename=BvJsonPreferencesService&methodname=deleteAllAnnotationsInPortal";
            return httpService.httpServicePost(servmethod);
        },
        deleteAllAnnotationsInDocument: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonDocumentService&methodname=deleteAllAnnotationsInDocument";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(serviceMethod + params);
        },
        assignModuleCreators: function (moduleCreatorsObjectString, toDelModuleCreatorsObjectString) {
            var [moduleCreatorsObjectString, toDelModuleCreatorsObjectString] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonPreferencesService&methodname=assignModuleCreators";
            var params = "&moduleCreatorsObjectString=" + moduleCreatorsObjectString + "&toDelModuleCreatorsObjectString=" + toDelModuleCreatorsObjectString;
            return httpService.httpServicePost(servmethod + params);
        },
        getModuleCreatorsJSON: function () {
            var servmethod = "servicename=BvJsonPreferencesService&methodname=getModuleCreatorsJSON";
            var params = "";
            return httpService.httpServicePost(servmethod + params);
        },
        //		removeUserFromCreatorListJSON : function(id, userObjectBaseUuid){
        //			var servmethod = "servicename=BvJsonPreferencesService&methodname=removeUserFromCreatorListJSON";
        //			var params = "&id=" + id + "&userObjectBaseUuid=" + userObjectBaseUuid;
        //			return  httpService.httpServicePost(servmethod+params);
        //		},
        getModuleCreatorsForSpecificModuleJSON: function (id) {
            var [id] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonPreferencesService&methodname=getModuleCreatorsForSpecificModuleJSON";
            var params = "&id=" + id;
            return httpService.httpServicePost(servmethod + params);
        },
        checkModuleCreatorsConfigPresentJSON: function (id) {
            var [id] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonPreferencesService&methodname=checkModuleCreatorsConfigPresentJSON";
            var params = "&id=" + id;
            return httpService.httpServicePost(servmethod + params);
        },
        changeUserPasswordFromPreferences: function (currentpwd, newpwd) {
            var [currentpwd, newpwd] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonPreferencesService&methodname=changeUserPasswordFromPreferences";
            var params = "&currentpwd=" + currentpwd + "&newpwd=" + newpwd;
            return httpService.httpServicePost(servmethod + params);
        },
        createCalendarEvent: function (meetingTitle, details, address, callingNumber, startDateTime, endDateTime, attachmentJson, attendeesJson, isActive, codeNumber, location, allDay, host, conferenceLink, eventTimeZone) {
            var [meetingTitle, details, address, callingNumber, startDateTime, endDateTime, attachmentJson, attendeesJson, isActive, codeNumber, location, allDay, host, conferenceLink, eventTimeZone] = process_parametes(arguments);
            var serviceMethod = "servicename=" + BvJsonCalendarService + "&methodname=createCalendarEvent";
            var params = "&meetingTitle=" + encodeURIComponent(meetingTitle) + "&details=" + encodeURIComponent(details) + "&address=" + encodeURIComponent(address) + "&callingNumber=" + encodeURIComponent(callingNumber) + "&startDateTime=" + startDateTime + "&endDateTime=" + endDateTime + "&attachmentJson=" + attachmentJson + "&attendeesJson=" + attendeesJson + "&isActive=" + isActive + "&codeNumber=" + encodeURIComponent(codeNumber) + "&location=" + encodeURIComponent(location) + "&allDay=" + allDay + "&host=" + host
                + "&eventTimezone=" + eventTimeZone
                + "&conferenceLink"
                + "&committeeGroupId"
            return httpService.httpServicePost(serviceMethod + params);
        },
        setCalendarAttendance: function (calendarEventBaseUuid, attendanceJsonList) {
            var [calendarEventBaseUuid, attendanceJsonList] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonCalendarService&methodname=setCalendarAttendance";
            var params = "&calendarEventBaseUuid=" + calendarEventBaseUuid + "&attendanceJsonList=" + attendanceJsonList;
            return httpService.httpServicePost(serviceMethod + params);
        },
        editAndNotifyCalendarEvent: function (objectBaseUuid, meetingTitle, details, address, callingNumber, startDateTime, endDateTime, attachmentJson, attendeesJson, isActive, codeNumber, location, allDay, host, conferenceLink, eventTimeZone) {
            var [objectBaseUuid, meetingTitle, details, address, callingNumber, startDateTime, endDateTime, attachmentJson, attendeesJson, isActive, codeNumber, location, allDay, host, conferenceLink, eventTimeZone] = process_parametes(arguments);
            var serviceMethod = "servicename=" + BvJsonCalendarService + "&methodname=editAndNotifyCalendarEvent";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&meetingTitle=" + encodeURIComponent(meetingTitle) + "&details=" + encodeURIComponent(details) + "&address=" + encodeURIComponent(address) + "&callingNumber=" + encodeURIComponent(callingNumber) + "&startDateTime=" + startDateTime + "&endDateTime=" + endDateTime + "&attachmentJson=" + attachmentJson + "&attendeesJson=" + attendeesJson + "&isActive=" + isActive + "&codeNumber=" + encodeURIComponent(codeNumber) + "&location=" + encodeURIComponent(location) + "&allDay=" + allDay + "&host=" + host
                + "&eventTimezone=" + eventTimeZone
                + "&conferenceLink"
                + "&committeeGroupId"
            return httpService.httpServicePost(serviceMethod + params);
        },
        updateEventInvitiesReadOn: function (calendarEventId, participantId) {
            var [calendarEventId, participantId] = process_parametes(arguments);
            var serviceMethod = "servicename=" + BvJsonCalendarService + "&methodname=updateEventInvitiesReadOn";
            var params = "&calendarEventId=" + calendarEventId + "&participantId=" + participantId;
            return httpService.httpServicePost(serviceMethod + params);
        },
        getOpenItemsCount: function () {
            var serviceMethod = "servicename=BvJsonDashboardService&methodname=getOpenItemsCount"
            return httpService.httpServicePost(serviceMethod);
        },
        purgeRemoteBriefcase: function (companyBaseUuid, userBaseUuid, remoteBaseUuid) {
            var [companyBaseUuid, userBaseUuid, remoteBaseUuid] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonPreferencesService&methodname=purgeRemoteBriefcase";
            var params = "&companyBaseUuid=" + companyBaseUuid + "&userBaseUuid=" + userBaseUuid + "&remoteBaseUuid=" + remoteBaseUuid;
            return httpService.httpServicePost(serviceMethod + params);
        },
        editChallengeQuestions: function (questionAndAnswerList, portalId) {
            var [questionAndAnswerList, portalId] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonPreferencesService&methodname=editChallengeQuestions";
            var params = "&questionAndAnswerList=" + questionAndAnswerList + "&portalId=" + portalId;
            return httpService.httpServicePost(serviceMethod + params);
        },
        getPermalinkOrResourceVersionId: function (plink, resourceId) {
            console.log("Inside getPermalinkOrResourceVersionId ajax service ");
            var [plink, resourceId] = process_parametes(arguments);
            var servmethod = "servicename=" + BvJsonPortalService + "&methodname=getPermalinkOrResourceVersionId";
            var params = "&plink=" + plink + "&resourceId=" + resourceId;
            return httpService.httpServicePost(servmethod + params);
        },
        saveAgendaObject: function (parentBaseUuid, name, content) {
            var [parentBaseUuid, name, content] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonDocumentService&methodname=createOnlineAgenda";
            var params = "&parentBaseUuid=" + parentBaseUuid + "&name=" + name + "&content=" + content;
            return httpService.httpServicePost(serviceMethod + params);
        },

        getCollateJSONForBaseUuid: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonDocumentService&methodname=getCollateJSONForBaseUuid";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(serviceMethod + params);
        },
        editCollatedDocument: function (collateJSON) {
            var [collateJSON] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonDocumentService&methodname=editCollatedDocument";
            var params = "&collateJSON=" + collateJSON;
            return httpService.httpServicePost(serviceMethod + params);
        },
        // new items light weight api //Change for MEETX:11798
        loadLightWeightNewItemsJson:function(objectBaseUuidList){
            var [objectBaseUuidList] = process_parametes(arguments);
        	var servmethod = documentServiceMethodStr + "loadLightWeightNewItemsJson";
            var params = "&objectBaseUuidList=" + objectBaseUuidList;
            return httpService.httpServicePost(servmethod + params);
        },
        //// New Items ////

        getAllNewItemsForMeetXClient: function (selectedNewItemsOption, page, pageSize, isThumbnailRequired, portalBaseUuid) {
            if (MobileUtil.isApp()) {
                var servmethod = documentServiceMethodStr + "loadNewItemsForSelectedTS";
                return httpService.httpServicePost(servmethod);
            } else {
                var servmethod = documentServiceMethodStr + "loadNewItemsForSelectedTSWithPagination";
                var params = "&selectedNewItemsOption=" + selectedNewItemsOption + "&page=" + page + "&pageSize=" + pageSize + "&isThumbnailRequired=" + isThumbnailRequired + "&portalBaseUuid=" + portalBaseUuid;
                return httpService.httpServicePost(servmethod + params);
            }

        },

        //// Rules ////

        createDocumentRules: function (documentBaseuuid, alertNewVersionUsers, alertSecurityChangeUsers,
            isFolder, isAlertNewVersionSelected, isAlertSecurityChangeSelected, isAlertDeleteDocumentSelected,
            deleteDocumentDays) {
            var [documentBaseuuid, alertNewVersionUsers, alertSecurityChangeUsers, isFolder, isAlertNewVersionSelected, isAlertSecurityChangeSelected, isAlertDeleteDocumentSelected, deleteDocumentDays] = process_parametes(arguments);
            var servmethod = BvJsonRulesServiceMethodStr + "createDocumentRules";
            var params = "&documentBaseuuid=" + documentBaseuuid + "&alertNewVersionUsers=" + alertNewVersionUsers + "&alertSecurityChangeUsers=" + alertSecurityChangeUsers +
                "&isFolder=" + isFolder + "&isAlertNewVersionSelected=" + isAlertNewVersionSelected + "&isAlertSecurityChangeSelected=" + isAlertSecurityChangeSelected + "&isAlertDeleteDocumentSelected=" + isAlertDeleteDocumentSelected +
                "&deleteDocumentDays=" + deleteDocumentDays;
            return httpService.httpServicePost(servmethod + params);
        },

        loadRulesForDocumentOrFolder: function (documentBaseUuid) {
            var [documentBaseUuid] = process_parametes(arguments);
            var servmethod = BvJsonRulesServiceMethodStr + "loadRulesForDocumentOrFolder";
            var params = "&documentBaseUuid=" + documentBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getOnlineAgendaContent: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var serviceMethod = documentServiceMethodStr + "getOnlineAgenda";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(serviceMethod + params);
        },
        updateOnlineAgendaContent: function (objectBaseUuid, newContent) {
            var [objectBaseUuid, newContent] = process_parametes(arguments);
            var serviceMethod = documentServiceMethodStr + "saveOnlineAgenda";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&newContent=" + newContent;
            return httpService.httpServicePost(serviceMethod + params);
        },

        //// Followers ////

        getSubscriptionsForObjectId: function (objectBaseuuid) {
            var [objectBaseuuid] = process_parametes(arguments);
            var serviceMethod = BvJsonSubscriptionServiceMethodStr + "getSubscriptionsForObjectId";
            var params = "&objectBaseuuid=" + objectBaseuuid;
            return httpService.httpServicePost(serviceMethod + params);
        },
        getActiveSubscriptionList: function () {
            var serviceMethod = BvJsonSubscriptionServiceMethodStr + "getActiveSubscriptionList";
            return httpService.httpServicePost(serviceMethod);
        },
        saveSubsciptions: function (objectBaseuuid, securityBaseuuids) {
            var [objectBaseuuid, securityBaseuuids] = process_parametes(arguments);
            var serviceMethod = BvJsonSubscriptionServiceMethodStr + "saveSubsciptions";
            var params = "&objectBaseuuid=" + objectBaseuuid + "&securityBaseuuids=" + securityBaseuuids;
            return httpService.httpServicePost(serviceMethod + params);
        },
        unsubscribe: function (selectedDocUuids) {
            var [selectedDocUuids] = process_parametes(arguments);
            var serviceMethod = BvJsonSubscriptionServiceMethodStr + "unsubscribe";
            var params = "&selectedDocUuids=" + selectedDocUuids;
            return httpService.httpServicePost(serviceMethod + params);
        },
        subscribe: function (selectedDocUuids) {
            var [selectedDocUuids] = process_parametes(arguments);
            var serviceMethod = BvJsonSubscriptionServiceMethodStr + "subscribe";
            var params = "&selectedDocUuids=" + selectedDocUuids;
            return httpService.httpServicePost(serviceMethod + params);
        },
        isAlreadySubscribed: function (objectuuids) {
            var [objectuuids] = process_parametes(arguments);
            var serviceMethod = BvJsonSubscriptionServiceMethodStr + "isAlreadySubscribed";
            var params = "&objectuuids=" + objectuuids;
            return httpService.httpServicePost(serviceMethod + params);
        },
        sendNativeEditFile: function () {
            var servmethod = documentServiceMethodStr + "sendNativeEditFile";
            return httpService.httpServicePost(servmethod);
        },
        getBrandingSettings: function (portalBaseUuid) {
            var [portalBaseUuid] = process_parametes(arguments);
            var servmethod = "servicename=BvJsonBrandingService&methodname=getBrandingSettings";
            var params = "&portalBaseUuid=" + portalBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getMediaContent: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var serviceMethod = documentServiceMethodStr + "getMediaContent";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(serviceMethod + params);
        },
        createNewApproval: function (approval, participants, approvalOwners, approvalViewers, dueDate, attachmentUuidList) {
            var [approval, participants, approvalOwners, approvalViewers, dueDate, attachmentUuidList] = process_parametes(arguments);
            var serviceMethod = approvalServiceMethodStr + "createNewApprovalJSON";
            var params = "&approval=" + encodeURIComponent(approval) + "&participants=" + participants + "&approvalOwners=" + approvalOwners
                + "&approvalViewers=" + approvalViewers + "&dueDate=" + dueDate + "&attachmentUuidList=" + attachmentUuidList;
            return httpService.httpServicePost(serviceMethod + params);
        },
        updateApproval: function (approval, participants, approvalOwners, approvalViewers, dueDate, attachmentUuidList) {
            var [approval, participants, approvalOwners, approvalViewers, dueDate, attachmentUuidList] = process_parametes(arguments);
            var serviceMethod = approvalServiceMethodStr + "updateApprovalJSON";
            var params = "&approval=" + encodeURIComponent(approval) + "&participants=" + participants + "&approvalOwners=" + approvalOwners
                + "&approvalViewers=" + approvalViewers + "&dueDate=" + dueDate + "&attachmentUuidList=" + attachmentUuidList;
            return httpService.httpServicePost(serviceMethod + params);
        },
        saveApprovalsChanges: function (approvalsIdsForAddition, userIdsForAddition, approvalUserListForRemoval, inactiveApprovals, activeApprovals) {
            var [approvalsIdsForAddition, userIdsForAddition, approvalUserListForRemoval, inactiveApprovals, activeApprovals] = process_parametes(arguments);
            var servmethod = approvalServiceMethodStr + "saveApprovalsChanges";
            var params = "&approvalsIdsForAddition=" + approvalsIdsForAddition + "&userIdsForAddition=" + userIdsForAddition + "&approvalUserListForRemoval=" + approvalUserListForRemoval + "&inactiveApprovals=" + inactiveApprovals + "&activeApprovals=" + activeApprovals;
            return httpService.httpServicePost(servmethod + params);
        },
        getConfigurationValue: function (key) {
            var [key] = process_parametes(arguments);
            var serviceMethod = "servicename=BaseRemoteService&methodname=getConfigurationValue";
            var params = "&key=" + key;
            return httpService.httpServicePost(serviceMethod + params);
        },
        sessionKeepAlive: function (key) {
            var [key] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonPollingService&methodname=sessionKeepAlive";
            return httpService.httpServicePost(serviceMethod);
        },
        getQuestionList: function (objectBaseuuid, locale) {
            var [objectBaseuuid, locale] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonPreferencesService&methodname=getQuestionList";
            var params = "&objectBaseuuid=" + objectBaseuuid + "&locale=" + locale;
            return httpService.httpServicePost(serviceMethod + params);
        },
        getUserQuestions: function (objectBaseuuid) {
            var [objectBaseuuid] = process_parametes(arguments);
            var serviceMethod = "servicename=BvJsonPreferencesService&methodname=getUserQuestions";
            var params = "&objectBaseuuid=" + objectBaseuuid;
            return httpService.httpServicePost(serviceMethod + params);
        },
        isAdminAllowed: function () {
            var serviceMethod = "servicename=BaseRemoteService&methodname=isAdminAllowed";
            return httpService.httpServicePost(serviceMethod);
        },
        sendSecondFactorAuthCode: function (mode) {
            var [mode] = process_parametes(arguments);
            var serviceMethod = "servicename=BaseRemoteService&methodname=sendSecondFactorAuthCode";
            var params = "&mode=" + mode;
            return httpService.httpServicePost(serviceMethod + params);
        },
        isSecondFactorCookieSetAfterVerification: function (authCode) {
            var [authCode] = process_parametes(arguments);
            var serviceMethod = "servicename=BaseRemoteService&methodname=isSecondFactorCookieSetAfterVerification";
            var params = "&authCode=" + authCode;
            return httpService.httpServicePost(serviceMethod + params);
        },
        getCompanyLogoByShortName: function (url) {
            var redirectUrl = url;
            var [url] = process_parametes(arguments);
            var serviceMethod = "servicename=IPadPublicRemoteService&methodname=getCompanyLogoByShortName";
            return httpService.httpRedirectLoginPost(serviceMethod, redirectUrl);
        },
        getAuditTrailListJSON: function (objectVersionUuid) {
            var [objectVersionUuid] = process_parametes(arguments);
            var servmethod = AuditTrailRemoteServiceMethodStr + "getAuditTrailListJSON";
            var params = "&objectVersionUuid=" + objectVersionUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        addToMultipleFavoritesJSON: function (userBaseUuids, documentBaseUuids) {
            var [userBaseUuids, documentBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "addToMultipleFavoritesJSON";
            var params = "&userBaseUuids=" + userBaseUuids + "&documentBaseUuids=" + documentBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },
        getGroupChildrenJSON: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = BvJsonPeopleRemoteServiceMethodStr + "getGroupChildrenJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        openNativeMobileViewer: function (objectBaseUuid, fileName) {
            var [objectBaseUuid, fileName] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "openNativeViewer";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&fileName=" + fileName;
            return httpService.httpServicePost(servmethod + params);
        },
        openNativeApproval: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "openNativeApproval";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        openNativeSurvey: function (objectBaseUuid) {
            var [objectBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "openNativeSurvey";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        startJupSync: function (startDate, endDate, syncModules) {
            var [startDate, endDate, syncModules] = process_parametes(arguments);
            var servmethod = "servicename=" + IpadPublicRemoteService + "&methodname=startJUPSync";
            var params = "&startDate=" + startDate + "&endDate=" + endDate + "&syncModules=" + syncModules;
            return httpService.httpServicePost(servmethod + params);
        },
        showBiometricEnrollmentDialog: function () {
            var servmethod = "servicename=" + IpadPublicRemoteService + "&methodname=biometricEnrollment";
            return httpService.httpServicePost(servmethod);
        },
        showManagePinDialog: function () {
            var servmethod = "servicename=" + IpadPublicRemoteService + "&methodname=managePin";
            return httpService.httpServicePost(servmethod);
        },
        getSyncProgress: function (s) {
            var servmethod = "servicename=" + IpadPublicRemoteService + "&methodname=getJUPSyncProgress";
            //var params = "&startDate="+startDate+"&endDate="+endDate+"&syncModules="+syncModules;
            var params = "";
            return httpService.httpServicePost(servmethod + params);
        },
        validateIsDesktopClientCertificateInstalled: function () {
            var servmethod = "servicename=" + IpadPublicRemoteService + "&methodname=isCertificateInstalledJSON";
            var params = "";
            return httpService.httpServicePost(servmethod + params);
        },
        getLastSyncJSON: function () {
            var servmethod = "servicename=" + IpadPublicRemoteService + "&methodname=getLastSync";
            return httpService.httpServicePost(servmethod);
        },
        getJupOnlineStatus: function () {
            var servmethod = "servicename=" + BvJsonPollingService + "&methodname=getJupOnlineStatus";
            return httpService.httpServicePost(servmethod);
        },
        getBriefcasePurgedDesktop: function () {
            var servmethod = "servicename=" + IpadPublicRemoteService + "&methodname=getPurgeBriefcaseDesktop";
            return httpService.httpServicePost(servmethod);
        },
        /*getOnlineStatusJSON: function(){
            var servmethod = "servicename=" + IpadPublicRemoteService + "&methodname=getOnlineStatus";
            return  httpService.httpServicePost(servmethod);
        },*/
        resetAllAppDataJSON: function () {
            var servmethod = "servicename=" + IpadPublicRemoteService + "&methodname=resetAllAppData";
            return httpService.httpServicePost(servmethod);
        },
        getAppSuportJSON: function () {
            var servmethod = "servicename=" + IpadPublicRemoteService + "&methodname=getAppSupportInfo";
            return httpService.httpServicePost(servmethod);
        },
        getCompanyTree: function () {
            var servmethod = "servicename=" + teamRemoteService + "&methodname=getCompanyTreeJSON";
            return httpService.httpServicePost(servmethod);
        },
        checkAndDownloadAnnotations: function (parentDocumentVersionUuid) {
            var [parentDocumentVersionUuid] = process_parametes(arguments);
            var servmethod = "servicename=" + IpadPublicRemoteService + "&methodname=checkAndDownloadAnnotations";
            var params = "&parentDocumentVersionUuid=" + parentDocumentVersionUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        restoreFromRecycleBinJSON: function (toFolderBaseUuid, listofBaseUuids) {
            var [toFolderBaseUuid, listofBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "restoreFromRecycleBinJSON";
            var params = "&toFolderBaseUuid=" + toFolderBaseUuid + "&listofBaseUuids=" + listofBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },
        startVirtualEdit: function (objectBaseuuid, bLocale, frameHt, frameWt) {
            var [objectBaseuuid, bLocale, frameHt, frameWt] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "startVirtualEdit";
            var params = "&objectBaseUuid=" + objectBaseuuid + "&bLocale=" + bLocale + "&frameHt=" + frameHt + "&frameWt=" + frameWt;
            return httpService.httpServicePost(servmethod + params);
        },
        cancelVEdit: function (connectionId) {
            var [connectionId] = process_parametes(arguments);
            var servmethod = "methodname=cancelEdit&connId=" + connectionId;
            return httpService.httpVirtualEndServicePost(servmethod);
        },
        saveAgendaLinks: function (objectBaseuuid, agendaLinksJson) {
            var [objectBaseuuid, agendaLinksJson] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "saveAgendaLinks";
            var params = "&objectBaseuuid=" + objectBaseuuid + "&agendaLinksJson=" + agendaLinksJson;
            return httpService.httpServicePost(servmethod + params);
        },
        getConnectionToken: function (id, payload) {
            var [id, payload] = process_parametes(arguments);
            var servmethod = "methodname=getToken&id=" + id + "&payload=" + payload;
            return httpService.httpVirtualServicePost(servmethod);
        },
        checkConnectionAvailable: function (id) {
            var [id] = process_parametes(arguments);
            var servmethod = "methodname=connectionAvailable&UId=" + id;
            return httpService.httpVirtualServicePost(servmethod);
        },
        checkRelationBeforeDeleteJSON: function (objectBaseUuids) {
            var [objectBaseUuids] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "checkRelationBeforeDeleteJSON";
            var params = "&objectBaseUuids=" + objectBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },
        // Push
        createObjectPush: function (objectPushJson) {
            var [objectPushJson] = process_parametes(arguments);
            var servmethod = BvJsonObjectPushRemoteServiceMethodStr + "createObjectPush";
            var params = "&objectPushJson=" + objectPushJson;
            return httpService.httpServicePost(servmethod + params);
        },
        getObjectPushesCreatedByMe: function () {
            var servmethod = BvJsonObjectPushRemoteServiceMethodStr + "getObjectPushesCreatedByMe";
            return httpService.httpServicePost(servmethod);
        },
        getObjectPushesSentToMe: function () {
            var servmethod = BvJsonObjectPushRemoteServiceMethodStr + "getObjectPushesSentToMe";
            return httpService.httpServicePost(servmethod);
        },
        getObjectPushesByPushId: function (pushId) {
            var [pushId] = process_parametes(arguments);
            var servmethod = BvJsonObjectPushRemoteServiceMethodStr + "getObjectPushesByPushId";
            var params = "&pushId=" + pushId;
            return httpService.httpServicePost(servmethod + params);
        },
        getSentToMePushCount: function () {
            var servmethod = BvJsonObjectPushRemoteServiceMethodStr + "getSentToMePushCount";
            return httpService.httpServicePost(servmethod);
        },
        deleteObjectPushCreatedByMe: function (pushId) {
            var [pushId] = process_parametes(arguments);
            var servmethod = BvJsonObjectPushRemoteServiceMethodStr + "deleteObjectPushCreatedByMe";
            var params = "&pushId=" + pushId;
            return httpService.httpServicePost(servmethod + params);
        },
        deleteObjectPushSentToMe: function (pushId) {
            var [pushId] = process_parametes(arguments);
            var servmethod = BvJsonObjectPushRemoteServiceMethodStr + "deleteObjectPushSentToMe";
            var params = "&pushId=" + pushId;
            return httpService.httpServicePost(servmethod + params);
        },
        deleteObjectPushFromUsers: function (pushId, userUuids) {
            var [pushId, userUuids] = process_parametes(arguments);
            var servmethod = BvJsonObjectPushRemoteServiceMethodStr + "deleteObjectPushFromUsers";
            var params = "&pushId=" + pushId + "&userUuids=" + userUuids;
            return httpService.httpServicePost(servmethod + params);
        },
        sxGSTJSON: function (pinValue) {
            var [pinValue] = process_parametes(arguments);
            var servmethod = ipadRemoteServiceMethodStr + "sxGSTJSON";
            return httpService.httpServicePost(servmethod);
        },
        sendNotificationToAnnotationShareesJSON: function (docBaseUuid, sharedAnnotUsers) {
            var servmethod = documentServiceMethodStr + "sendNotificationToAnnotationSharees";
            var params = "&documentBaseUuid=" + docBaseUuid + "&sharedWithAnnotUsersId=" + sharedAnnotUsers;
            return httpService.httpServicePost(servmethod + params);
        },
        sendNotificationToAnnotationShareesWithAnnotationMap: function (docBaseUuid, sharedAnnotUsers,userAnnotMap) {
            var servmethod = documentServiceMethodStr + "sendNotificationToAnnotationShareesWithAnnotationMap";
            var params = "&documentBaseUuid=" + docBaseUuid + "&sharedWithAnnotUsersId=" + sharedAnnotUsers+"&userAnnotMapPayLoad=" + userAnnotMap;
            return httpService.httpServicePost(servmethod + params);
        },
        checkInCheckOut: function (documentVo, checkInOut, cancelcheckout) {
            var [documentVo, checkInOut, cancelcheckout] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "checkInCheckOut";
            var params = "&documentVo=" + documentVo + "&checkInOut=" + checkInOut + "&cancelCheckout=" + cancelcheckout;
            var request = httpService.httpServicePost(servmethod + params);

            return request.then(function (response) {
                if (responseValidatorService.isSuccess(response.data)) {
                    return response.data;
                }
                return null;
            });
        },
        addDocCheckInOutAlertRelationShipType: function (documentBaseUuid) {
            var servmethod = documentServiceMethodStr + "addDocCheckInOutAlertRelationShipType";
            var params = "&documentBaseUuid=" + documentBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        sendDocCheckedInNotification: function (documentBaseUuid) {
            var servmethod = documentServiceMethodStr + "sendDocCheckedInNotification";
            var params = "&documentBaseUuid=" + documentBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        sendDocCancelCheckedOutNotification: function (documentBaseUuid) {
            var servmethod = documentServiceMethodStr + "sendDocCancelCheckedOutNotification";
            var params = "&documentBaseUuid=" + documentBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getSubscriptions: function () {
            var servmethod = "servicename=BvJsonSubscriptionService&methodname=getSubscriptions";
            return httpService.httpServicePost(servmethod);
        },
        saveSubscriptionTypes: function (subscriptionType) {
            var [subscriptionType] = process_parametes(arguments);
            var serviceMethod = BvJsonSubscriptionServiceMethodStr + "saveSubscriptionTypes";
            var params = "&subscriptionType=" + subscriptionType;
            return httpService.httpServicePost(serviceMethod + params);
        },
        loadUsersFollowingMe: function () {
            var servmethod = "servicename=BvJsonSubscriptionService&methodname=loadUsersFollowingMe";
            return httpService.httpServicePost(servmethod);
        },
        getFollowers: function (objectBaseUuid) {
            var servmethod = "servicename=BvJsonSubscriptionService&methodname=getFollowers";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        saveFollowers: function (objectBaseUuid, subscriberBaseUuids) {
            var servmethod = "servicename=BvJsonSubscriptionService&methodname=saveFollowers";
            var params = "&objectBaseUuid=" + objectBaseUuid + "&subscriberBaseUuids=" + subscriberBaseUuids;
            return httpService.httpServicePost(servmethod + params);
        },
        subscribeUser: function (objectBaseUuid) {
            var servmethod = "servicename=BvJsonSubscriptionService&methodname=subscribeUserForMeetXClient";
            var params = "&subscribedUserBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        unSubscribeUser: function (objectBaseUuid) {
            var servmethod = "servicename=BvJsonSubscriptionService&methodname=unSubscribeUserForMeetXClient";
            var params = "&subscribedUserBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        isUserAlreadySubscribed: function (objectBaseUuid) {
            var servmethod = "servicename=BvJsonSubscriptionService&methodname=isUserAlreadySubscribed";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        usersFollowed: function () {
            var servmethod = "servicename=BvJsonSubscriptionService&methodname=usersFollowed";
            return httpService.httpServicePost(servmethod);
        },
        sendAlertToSubscribedObject: function (objectBaseuuid) {
            var servmethod = "servicename=BvJsonSubscriptionService&methodname=sendAlertToSubscribedObject";
            var params = "&objectBaseUuid=" + objectBaseuuid
            return httpService.httpServicePost(servmethod + params);
        },
        getDocumentAndFolderSearchList: function (searchString, uniq) {
            var servmethod = "servicename=BvJsonFullTextSearchService&methodname=getDocumentAndFolderSearchList";
            var params = "&searchString=" + searchString
            return httpService.httpServicePost(servmethod + params, uniq);
        },
        getAdvancedDocumentAndFolderSearchList: function (offset, searchString, lastVisit, startDateMillis, endDateMillis, portalBaseUuidList, phraseSearchText, exculdeSearchText, fileTypes, uniq) {
            var servmethod = "servicename=BvJsonFullTextSearchService&methodname=getAdvancedDocumentAndFolderSearchList";
            var params = "&offset=" + offset + "&searchString=" + searchString + "&isSearchSinceLastVisit=" + lastVisit + "&startDateMillis=" + startDateMillis + "&endDateMillis=" + endDateMillis +
                "&portalBaseUuidList=" + portalBaseUuidList + "&phraseSearchText=" + phraseSearchText + "&exculdeSearchText=" + exculdeSearchText + "&fileTypes=" + fileTypes;
            return httpService.httpServicePost(servmethod + params, uniq);
        },
        getDocumentOrFolderParentObjectIds: function (objectBaseUuid) {
            var servmethod = "servicename=BvJsonDocumentService&methodname=getDocumentOrFolderParentObjectIds";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        searchAddItemsToIndex: function () {
            var servmethod = "servicename=BvJsonFullTextSearchService&methodname=addItemsToIndex";
            return httpService.httpServicePost(servmethod);
        },
        searchRebuildIndex: function () {
            var servmethod = "servicename=BvJsonFullTextSearchService&methodname=rebuildIndex";
            return httpService.httpServicePost(servmethod);
        },
        searchDeleteFullTextSearchIndex: function () {
            var servmethod = "servicename=BvJsonFullTextSearchService&methodname=deleteFullTextSearchIndex";
            return httpService.httpServicePost(servmethod);
        },
        searchEnableFullTextSearch: function () {
            var servmethod = "servicename=BvJsonFullTextSearchService&methodname=enableFullTextSearch";
            return httpService.httpServicePost(servmethod);
        },
        searchDisableFullTextSearch: function () {
            var servmethod = "servicename=BvJsonFullTextSearchService&methodname=disableFullTextSearch";
            return httpService.httpServicePost(servmethod);
        },
        searchGetSearchIndexReport: function () {
            var servmethod = "servicename=BvJsonFullTextSearchService&methodname=getSearchIndexReport";
            return httpService.httpServicePost(servmethod);
        },
        getTaggedPagesForBookmarks: function (documentBaseUuid) {
            var [documentBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "getTaggedPagesForBookmarks";
            var params = "&documentBaseUuid=" + documentBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        tagPageForBookmkark: function (jsonString) {
            var [documentBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "tagPageForBookmkark";
            var params = "&jsonString=" + jsonString;
            return httpService.httpServicePost(servmethod + params);
        },
        toggleIncrementalFetchConfig: function (configValue) {
            var [documentBaseUuid] = process_parametes(arguments);
            var servmethod = documentServiceMethodStr + "toggleIncrementalFetchConfig";
            var params = "&configValue=" + configValue;
            return httpService.httpServicePost(servmethod + params);
        },

        //N3A
        getN3aDomainName: function () {
            var servmethod = "servicename=IPadPublicRemoteService&methodname=getLoginUrl";
            return httpService.httpLoginPost(servmethod);
        },
        getN3aSession: function () {
            var servmethod = N3ALoginSessionValidationService + "verifyN3ASession";
            return httpService.httpServicePost(servmethod);
        },

        //Reports
        getReports: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getFilterData: function (resourceUrl) {
            return httpService.httpReportServiceGet(resourceUrl);
        },
        getUsersFilterData: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getUserActivityReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getUserChangesReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getDocumentListReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getDocumentActivityReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getDocumentPermissionsReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getMeetingListReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getRetentionpoliciesReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getMemberattendancetrackingReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getGroupattendancetrackingReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getUserbriefcasereportReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getSyncedinformationreportReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getFolderPermissionsReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        getUserDetailsReport: function (postData, resourceUrl) {
            return httpService.httpReportServicePost(postData, resourceUrl);
        },
        //CS Inbox
        getEmailUnreadCount: function () {
            var servmethod = "servicename=" + BvJsonInboxService + "&methodname=getUnreadMessagesCount";
            return httpService.httpServicePost(servmethod);
        },

        //Agenda builder
        getDigitalAgendaConfigurations: function (calendarBaseuuid) {
            var servmethod = "servicename=BvJsonDocumentService&methodname=getDigitalAgendaConfigurations";
            var params = "&calendarBaseuuid=" + calendarBaseuuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getAllDigitalAgendasForTheCalendarEvent: function (calendarEventBaseUuid) {
            var servmethod = "servicename=BvJsonDocumentService&methodname=getAllDigitalAgendasForTheCalendarEvent";
            var params = "&calendarEventBaseUuid=" + calendarEventBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getDocumentPageInfoAsJSON: function (objectBaseUuid) {
            var servmethod = "servicename=BvJsonDocumentService&methodname=getDocumentPageInfoAsJSON";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getFileViewedAuditOnClicking: function (objectBaseUuid) {
            var servmethod = "servicename=BvJsonDocumentService&methodname=getFileViewedAuditOnClicking";
            var params = "&objectBaseUuid=" + objectBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getDocumentPageInfoAsJSONForVersion: function (objectVersionUuid) {
            var servmethod = "servicename=BvJsonDocumentService&methodname=getDocumentPageInfoAsJSONForVersion";
            var params = "&documentVersionUuid=" + objectVersionUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        getAnnotationCountForAllVersionsOfTheDocument: function (objecBaseUuid) {
            var servmethod = "servicename=BvJsonDocumentService&methodname=getAnnotationCountForAllVersionsOfTheDocument";
            var params = "&documentBaseUuid=" + objecBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        getConfigurableQuestionsCount: function(userBaseUuid) {
            var servmethod = ipadServiceMethodStr + "getConfigurableQuestionsCount";
            var params = "&userBaseUuid=" + userBaseUuid;
            return httpService.httpRedirectLoginPost(servmethod + params);;
        },

        updateAgendaDocviewedUsers: function (collateUuid,docUuid) {
            var servmethod = "servicename=BvJsonDocumentService&methodname=updateAgendaDocumentViewedUsers";
            var params = "&agendaCollateUuid=" + collateUuid+"&docUuid="+docUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        logMessage: function(level,message){
        	var servmethod = BvJsonLoggingService+"logMessage";
        	var params = "&appName=MeetX&level=" + level + "&message=" + message;
        	return httpService.httpServicePost(servmethod + params);
        },
        getAllDigitalAgendaTemplates: function (portalBaseUuid) {
            var servmethod = "servicename=BvJsonAgendaTemplateService&methodname=getAllDigitalAgendaTemplates";
            var params = "&portalBaseUuid=" + portalBaseUuid;
            return httpService.httpServicePost(servmethod + params);
        },
        deleteDigitalAgendaTemplate: function (digitalAgendaTemplateUuid) {
            var servmethod = "servicename=BvJsonAgendaTemplateService&methodname=deleteDigitalAgendaTemplate";
            var params = "&digitalAgendaTemplateUuid=" + digitalAgendaTemplateUuid;
            return httpService.httpServicePost(servmethod + params);
        },

        //Virtual meetings zoom
        requestZoomOauthAccessToken: function (authorizationCode, redirectURL) {
            var servmethod = "servicename=BvJsonVirtualMeetingService&methodname=requestZoomOauthAccessToken";
            var params = "&authorizationCode=" + authorizationCode + "&redirectURL=" +redirectURL;
            return httpService.httpServicePost(servmethod + params);
        },
        getZoomUserInfo: function () {
            var servmethod = "servicename=BvJsonVirtualMeetingService&methodname=getZoomUserInfo";
            return httpService.httpServicePost(servmethod);
        },
        initEmailToken: function (emailAddress) {
            var servmethod = "servicename=BvJsonTeamService&methodname=initEmailVerificationToken";
            var params = "&email=" + emailAddress;
            return httpService.httpServicePost(servmethod + params);
        },
        disconnectZoom: function () {
            var servmethod = "servicename=BvJsonVirtualMeetingService&methodname=disconnectZoom";
            return httpService.httpServicePost(servmethod);
        },
        completeEmailVerification: function (jsonPayload) {
            var servmethod = "servicename=BvJsonTeamService&methodname=completeEmailVerification";
            var params = "&emailVerificationPayload=" + jsonPayload;
            return httpService.httpServicePost(servmethod + params);
        },
        getDateAndTimeOptions: function () {
            var servmethod = "servicename=BvJsonTeamService&methodname=getDateAndTimeOptions";
            return httpService.httpServicePost(servmethod);
        },
        disconnectGraph: function() {
            var servmethod = "servicename=BvJsonVirtualMeetingService&methodname=disconnectGraph";
            return httpService.httpServicePost(servmethod);
        },
        getMsGraphUserInfo: function() {
            var servmethod = "servicename=BvJsonVirtualMeetingService&methodname=getMsGraphUserInfo";
            return httpService.httpServicePost(servmethod);
        },
        isDocumentAnnotated: function(docUuid) {
            var servmethod = "servicename=BvJsonDocumentService&methodname=checkForAnnotationsInDocument";
            var params = "&documentBaseUuid=" + docUuid;
            return httpService.httpServicePost(servmethod + params);
        }

    };

    return service;

}
