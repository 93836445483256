iconRenderer.$inject = ['$compile', 'imageService', '$timeout', 'homeService', 'context', '$sce', 'legacyUserService'];

function iconRenderer($compile, imageService, $timeout, homeService, context, $sce, legacyUserService){
    return {
        restrict: 'A',
        scope: {
            iconForObject: '=',
            thumbnail: '@',
    	},
//        template: '<img ng-src="{{src}}" style="display: block;"/><img class="badgeImg" ng-src="{{showbadgeSrc}}" ng-if="(showbadge)">',
    	link: function (scope, el, attrs) {
    		var iconForObject_ = {};
            var objectBaseUuid = 'objectBaseUuid';
    		if(objectBaseUuid.includes(scope.iconForObject))
    			iconForObject_ = angular.copy(scope.iconForObject);
    		else
    			iconForObject_ = scope.iconForObject;

    		var renderIcon = function(iconForObject){
                var imgPath = ""
                var businessObject = iconForObject;
                var baseUuid = businessObject.objectBaseUuid;
                var extensionType = iconForObject.extensionType;
                var unicode = iconForObject.unicode;

                if(baseUuid != undefined)
                {

                    // for future use HTML-1368
        //            		else if(context.getModel().antivirusScanEnabled && businessObject.isVirusInfected)
        //            		{
        //            			scope.src = "resources/assets/images/error_red_@2X.png";
        //            			return;
        //            		}

                    scope.hideFolder = false;

                    if(StringUtils.isFolder(baseUuid))
                    {
                        extensionType = 'folder';
                        attrs.$addClass('folder');

                        if(legacyUserService.isNasdaqUI)
                    	{
                        	if(businessObject.isHidden == "true"){
                            	extensionType = "hiddenfolder";
                            }else{
                            	iconForObject.fontIconName = 'FOLDER';
                            }
                    	}
                        if(businessObject.isHidden == "true")
                        {
                               scope.hideFolder = true;
                               extensionType = "hiddenfolder";
                        }
                    }
                    else if(StringUtils.isCollate(baseUuid))
                    {
                        extensionType = 'collate';
                    }
    //                else if(StringUtils.isBoardbook(baseUuid))
    //                {
    //                    var logo = homeService.getCompanyTeamspaceLogo(false, false);
    //                    if(logo.url != ""){
    //                        extensionType = 'boardbook';
    //                    }
    //                    else{
    //                        extensionType = 'boardbook_emboss';
    //                    }
    //                }
                    else if(StringUtils.isApprovalPollQuestion(baseUuid))
                    {
                        extensionType = 'plainApproval';
                    }
                    else if(StringUtils.isSurvey(baseUuid))
                    {
                        extensionType = 'plainsurvey';
                    }

                    scope.showBadge = false;
                       // HTML-1211
                       /*if(businessObject.isShortCut)
                    {
                        scope.showbadge = true;
                        scope.showbadgeSrc = "resources/assets/images/context_createShortcut.png";
                    }
                    else
                        scope.showbadge = false;
                       */
               }




               if(extensionType === undefined){
                   var fileName = iconForObject.name;

                   if(fileName != undefined)
                       extensionType = StringUtils.getExtensionType(fileName);

                   if('iconUrl' in iconForObject && iconForObject.iconUrl != ''){
                       scope.src = iconForObject.iconUrl;
                       extensionType = undefined;
                   }
               }
               else if(iconForObject.type){
                   extensionType = iconForObject.type;
               }

               if(extensionType != undefined){
                   scope.src = imageService.getImageByExtension(extensionType);
               }
                //Changes As part Of NBV-175
	           if(iconForObject.scanStatus === "INFECTED_UNREPAIRED" || iconForObject.scanStatus === "INFECTED_REPLACED")
               {
                   //scope.src = "resources/assets/images/pwd_lg_@2X.png";
                   scope.src = "resources/assets/svgs/documents/infected.svg";
               }
               else if(iconForObject.conversionStatus === "CONVERTING")
                {
                   scope.src = "resources/assets/images/ajax-loader.gif";
                }
                else if(businessObject.isPasswordProtected && businessObject.swfConversionStatus == "CONVERSION_ERROR")
                {
                    //scope.src = "resources/assets/images/pwd_lg_@2X.png";
                    scope.src = "resources/assets/svgs/documents/pwd.svg";
                }
                else if(businessObject.isPartiallyConverted)
                {
                    //scope.src = "resources/assets/images/partial_convert_lg_@2X.png";
                    scope.src = "resources/assets/svgs/documents/ptl_con.svg";
                }
                else if(businessObject.swfConversionStatus == "CONVERSION_ERROR")
                {
                    //scope.src = "resources/assets/images/error_red_lg_@2X.png";
                    scope.src = "resources/assets/svgs/documents/errors_red.svg";
                }
	           
               /*  if(businessObject.swfConversionStatus == "CONVERSION_ERROR" && businessObject.isPdfACompliant==="false")
                {
                    scope.src = "resources/assets/svgs/documents/ptl_con.svg";
                }*/
                //Changes As part Of NBV-175
	           if( (iconForObject.scanStatus === "ERROR_ON_SERVER_WRITE") ||
                   (iconForObject.scanStatus === "ERROR_INPUT_STREAM_OPEN") ||
                   (iconForObject.scanStatus === "ERROR_INVALID_INPUT") ||
                   (iconForObject.scanStatus === "ERROR_SECURE_SOCKET_CREATION") ||
                   (iconForObject.scanStatus === "IP_UNRESOLVED") ||
                   (iconForObject.scanStatus === "ERROR_SOCKET_COMMUNICATION") ||
                   (iconForObject.scanStatus === "ERROR_ON_SERVER_READ") ||
                   (iconForObject.scanStatus === "ERROR_READ_WRITE_TIMEOUT") ||
                   (iconForObject.scanStatus === "ERROR_SERVER_TOO_BUSY") ||
                   (iconForObject.scanStatus === "MAX_TRIES_REACHED") ||
                   (iconForObject.scanStatus === "ERROR_OUTPUT_STREAM_OPEN") ||
                   (iconForObject.scanStatus === "ERROR_FILE_INPUT_STREAM_OPEN") ||
                   (iconForObject.scanStatus === "ERROR_FILE_READ") ||
                   (iconForObject.scanStatus === "ERROR_FILE_INPUT_STREAM_CLOSE") ||
                   (iconForObject.scanStatus === "INTERNAL_SERVER_ERROR") ||
                   (iconForObject.scanStatus === "FILE_ACCESS_FAILED") ||
                   (iconForObject.scanStatus === "FILE_SIZE_TOO_LARGE") ||
                   (iconForObject.scanStatus === "NO_AV_LICENSE")||
                   (iconForObject.scanStatus === "RESOURCE_UNAVAILABLE")
	             )
               {
	        	   scope.src = "resources/assets/svgs/documents/errors_red.svg";
               }

                scope.extensionType = extensionType

                var fontTemplate = '<div ng-bind-html="src"></div>';
	            var imgTemplate = '<img class="{{extensionType}}" name="Document_Icon" ng-src="{{src}}" style="display: block;" width="30px" height="25px"/>'
	                             +'<img class="badgeImg" ng-src="{{showbadgeSrc}}" ng-if="(showbadge)">';
	            var svgTemplate = '<div ng-include="src"></div>'
	                             +'<img class="badgeImg" ng-src="{{showbadgeSrc}}" ng-if="(showbadge)">';
	            var boardbookTemplate = '<div><boardbook /></div>'
	                                   +'<img class="badgeImg" ng-src="{{showbadgeSrc}}" ng-if="(showbadge)">';

	            var template = imgTemplate;
	            if(scope.src && scope.src.toLowerCase().indexOf('.svg') != -1)
	           		template = svgTemplate;

	            if(scope.src && scope.src.toLowerCase().indexOf('&#') != -1)
	           		template = fontTemplate;

	            if(scope.thumbnail && iconForObject.documentThumbnail !== undefined){
                    scope.src = imageService.getThumbnailForObject(iconForObject);
                    template = imgTemplate;
	            }

	            if(StringUtils.isBoardbook(baseUuid) && iconForObject.conversionStatus !== "CONVERTING"){
	            	template = iconForObject.conversionStatus=="CONVERSION_ERROR"?svgTemplate:boardbookTemplate;
//	                template = boardbookTemplate;
	            }

	            if('fontIconName' in iconForObject && iconForObject.fontIconName != ''){
	                var fontIcon = imageService.getFontIconByName(iconForObject.fontIconName);
	                if(!fontIcon.isEmpty()){
                        attrs.$addClass('ss-icon');
                        attrs.$addClass(fontIcon.fontName);
                        if(fontIcon.className){
                            attrs.$addClass(fontIcon.className);
                            template = '';
                        }
                        else{
                            scope.src = $sce.trustAsHtml(fontIcon.unicode);
                            template = fontTemplate;
                        }
                    }
                }

	            el.html(template);
	            $compile(el.contents())(scope);

    		}

    		scope.$watch('iconForObject', function(iconForObject){
				if(scope.iconForObject === undefined )
					return;

				if(iconForObject_ === undefined || (iconForObject_ !== undefined && !angular.equals(iconForObject_, iconForObject))){
					renderIcon(iconForObject);
				}
				else{
					renderIcon(iconForObject_);
				}
    		});

         }

    };
}