angular
.module('i18N.messages', [])
.constant(
    'I18N.MESSAGES',
    {
    	  "ABSTAIN": "Abstain",
    	  "ACCEPT": "Accept",
    	  "ACCEPTED": "Accepted",
    	  "ACCEPTED_UPPERCASE": "ACCEPTED",
    	  "ACCEPT_ON_BEHALF": "Accept on Behalf",
    	  "ACCESS_DENIED": "Access denied.",
    	  "ACCOUNT_LOCKOUT": "After three failed attempts your account was locked.",
    	  "ACCOUNT_INFORMATION": "Account Information",
    	  "ACTIVATED": "Activated",
    	  "ACTIVATED_BRIEFCASES": "Activated Briefcases",
    	  "ACTIVE": "Active",
    	  "ACTIVE_INACTIVE": "Active/Inactive",
    	  "ACTIVE_WINDOWS": "Active Windows",
    	  "ACTUAL_SIZE": "Actual Size",
    	  "ACTION": "Action",
    	  "ADD": "Add",
    	  "ADD_CARDS":"Add Cards",
		  "ADD_MIN_SECTION_QUESTIONS_PREVIEW": "Add at least one question in order to preview your survey",
    	  "ADD_MIN_SECTION_QUESTIONS_TEMPLATE": "Add atleast one section and question to save a template",
    	  "ADDED_BY": "ADDED BY",
    	  "ADDED_BY_FOLLOW": "ADDED BY",
    	  "ADDED_TO_SPACE_OWNER_GROUP": " is added to space owner group.",
    	  "ADDRESS": "Address",
    	  "ADD_ADDITIONAL_CHOICE": "Add additional choice",
    	  "ADD_ATTENDEES": "Add Attendees",
    	  "ADD_CHOICE": "Add Choice",
    	  "ADD_COMMENTS": "Add Comments",
    	  "ADD_CUSTOM": "Add Custom",
    	  "ADD_FILE": "Add File",
    	  "ADD_FILES": "Add Files",
    	  "ADD_FOLDER": "Add Folder",
    	  "FOLDER_PATH_UPPERCASE": "FOLDER PATH",
    	  "ADD_GROUP": "Add Group",
    	  "ADD_INITIAL_WELL": "Add Initial Well",
    	  "ADD_PARTICIPANTS": "Add Participants",
    	  "ADD_PEOPLE": "Add People",
    	  "ADD_PIN": "Add Pin",
    	  "ADD_NEW_SECTION": "Add New Section",
    	  "ADD_NEW_QUESTION": "Add New Question",
    	  "ADD_RESPONDENTS": "Add Respondents",
    	  "ADD_SIGN_WELL": "Add Signature Well",
    	  "ADD_TEXT_NOTE": "Add Text Note",
    	  "ADD_TO_BRIEFCASE": "Add to Briefcase",
    	  "ADD_TO_MY_BRIEFCASE": "Add to My Briefcase",
    	  "ADD_TO_USER_BRIEFCASE": "Add to User Briefcase",
    	  "ADD_TO_GROUP": "Add To Group",
    	  "ADD_TO_NEW_ITEMS": "Add to New Items",
    	  "ADD_USER": "Add User",
    	  "ADD_USERS": "Add Users",
    	  "ADD_USER_TO_GROUP_ERROR": "Adding user to group may have failed for one or more users.",
    	  "ADJUST_TIME": "Adjust Time",
    	  "ADJUST_TIME_FOR_ALL_TOPICS": "Adjust time for all topics",
    	  "ADMIN": "Admin",
    	  "ADMINISTRATOR_AUTHENTICATION": "Administrator Authentication",
    	  "ADMINISTRATOR_AUTHENTICATION_EMAIL_METHOD": "Send Email to the Primary Email Address associated with my BV account",
    	  "ADMINISTRATOR_AUTHENTICATION_SMS_METHOD": "Send a Text Message to the Mobile Phone associated with my BV account",
    	  "ADMINS_ONLY": "Admins Only",
    	  "ADVANCED_SEARCH": "Advanced Search",
    	  "AGENDA": "Agenda",
    	  "AGREE": "Agree",
    	  "AGENDA_BUILDER": "Agenda Builder",
    	  "AGENDA_FOLDER_NOT_ALLOWED": "Folder not allowed to attach.",
    	  "AGENDA_NOT_ALLOWED_ATTACH": "Agenda not allowed to attach",
    	  "AGENDA_STYLE": "Agenda Style",
    	  "AGENDA_TEMPLATES": "Agenda Templates",
    	  "ALERT": "Alert",
    	  "ALERT_FOR_SURVEY_PARTICIPANTS": "Alert for Survey Participants",
    	  "ALERT_NON_RESPONDANTS": "Alert non-respondents",
    	  "ALL": "All",
    	  "ALL_DAY": "All Day",
    	  "ALL-DAY": "All-Day",
    	  "ALL_TEAMSPACES": "All TeamSpaces",
    	  "ALL_TEAM_SPACES": "All Team Spaces",
    	  "ALL_USERS_MUST_HAVE_ROLE": "All users must be assigned a role to continue",
    	  "ALL_REPORTS": "All Reports",
    	  "ALLOW_COMMENT": "Allow Comment",
    	  "ALLOW_MULTIPLE_CHOICE": "Allow Multiple Choice",
    	  "ALLOW_SEQUENTIAL_Number": "Allow sequential number",
    	  "ALLOW_SEQUENTIAL_NUMBERING": "Allow Sequential Numbering",
    	  "ALERT_SENT_SUCCESSFUL": "Alert sent successful.",
    	  "AND": "and",
    	  "ANONYMOUS": "Anonymous",
    	  "ANSWER": "Answer",
    	  "ANSWER1": "Answer1",
    	  "ANSWER2": "Answer2",
    	  "ANSWER3": "Answer3",
    	  "ANSWERED": "Answered",
    	  "ANALYSE": "Analyse",
    	  "ANNOTATIONS": "annotations",
    	  "APP": "App",
    	  "APPLY_TO_SUBFOLDERS_AND_FILES": "Apply to Subfolders and files",
    	  "APPLIED_TEAM_SPACES": "APPLIED TEAM SPACES",
    	  "APPLIED_KEYWORDS": "APPLIED KEYWORDS",
    	  "APPLIED_EXCLUDED_KEYWORDS": "APPLIED EXCLUDED KEYWORDS",
    	  "APPLIED_PHRASES": "APPLIED PHRASES",
    	  "APPROVAL": "Approval",
    	  "APPROVAL_ALERT_SUBJECT": "Please respond to the Approval",
    	  "APPROVAL_ALERT_COMMENTS": "Please respond to the Approval",
    	  "APPROVALS": "Approvals",
    	  "APPROVALS_UPPERCASE": "APPROVALS",
    	  "APPROVAL_CHOICES": "Approval Choices",
    	  "APPROVE": "Approve",
    	  "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_SURVEY_DUE_DATE": "Are you sure you want to change Survey Due Date",
    	  "ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_ANNOTATION": "Are you sure you want to delete all annotations from '\\{\\{teamSpaceName\\}\\}' TeamSpace?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_ANNOTATIOS_FROM_THE_DOCUMENT": "Are you sure you want to delete all annotations?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_YOUR_ANNOTATION": "Are you sure you want to delete all your annotations?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_START_MERGING_COMPANY_DATA": "Are you sure you want to start merging company data?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_DELETE_SURVEY": "Are you sure you want to delete survey?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_DELETE_SECTION": "Are you sure you want to delete section ",
    	  "ARE_YOU_SURE_YOU_WANT_TO_DELETE_QUESTION": "Are you sure you want to delete question",
    	  "ARE_YOU_SURE_YOU_WANT_TO_LOCK_USER": "Are you sure you want to lock user?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_MOVE_SURVEY_TO_CLOSED_CATEGORY": "Are you sure you want to move survey to the closed category?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_PARTICIPANT": "Are you sure you want to remove participant?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_SURVEY_FROM_CLOSED_CATEGORY": "Are you sure you want to remove survey from closed category?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_UNLOCK_USER": "Are you sure you want to unlock user?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_DOCUMENT": "Are you sure you want to remove this document?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_LOCK_ALL_USERS": "Are you sure you want to lock all users?",
    	  "ARE_YOU_SURE_YOU_WANT_TO_UNLOCK_ALL_USERS": "Are you sure you want to unlock all users?",
    	  "ARCHIVE": "Archive",
    	  "ARCHIVED_SUCCESSFUL": "Archived Successful.",
    	  "ATTACHMENT": "Attachment",
    	  "ATTACHMENTS": "Attachments",
    	  "ATTACHMENTS_SUBTILE": "Select the folders or files you want to attach.",
    	  "ATTACHMENTS_TITLE": "Attachments",
    	  "ATTENDANCE_STATUS": "Attendance Status",
    	  "ATTENDACE_TRACKING": "Attendance Tracking",
    	  "ATTENDANCE_STATUS_UPPERCASE": "ATTENDANCE STATUS",
    	  "ATTENDANCE_UPPERCASE": "ATTENDANCE",
    	  "ATTEND": "Attend",
    	  "ATTENDEE": "Attendee",
    	  "ATTENDEES": "Attendees",
    	  "AT_LEAST": "At least",
    	  "ATLEAST_ONE_PARTICIPANT_HAVE_TO_COMMIT_TO_VIEW_SURVEY_REPORT": "Atleast one participant have to commit to view survey report.",
    	  "ATLEAST_ONE_QUESTION_AND_SECTION_FOR_PUBLISHED_SURVEYS": "All sections should have atleast one question for published surveys",
    	  "AUTHENTICATING": "Authenticating",
    	  "AUTHENTICATION_FAILED": "Authentication failed.",
    	  "AUTOMATIC_ZOOM": "Auto Zoom",
    	  "AUTO_GENERATED": "Auto-Generate",
    	  "AUDIT_TRAIL": "Audit Trail",
    	  "AUDIT_TRAIL_FOR": "Audit Trail For:",
    	  "AVAILABLE": "Available",
    	  "ACCOUNT_RESET_SUCCESS": "\\{\\{user\\}\\} account has been reset.",
    	  "ADD_FREE_TEXT": "Add Free Text",
    	  "BACK": "Back",
    	  "BACK_TO_DETAILS": "Back to Details",
    	  "BANNER": "Banner",
    	  "BAR": "Bar",
    	  "BAR_CHART": "Bar Chart",
    	  "INCLUDE_BAR_CHART": "Include Bar chart",
    	  "INCLUDE_PIE_CHART": "Include Pie chart",
    	  "INCLUDE_RESPONSES": "Include Responses",
    	  "INCLUDE_SIGNATURES": "Include Signatures",
    	  "INCLUDE_COMMENTS": "Include Comments",
    	  "INCLUDE_ATTACHMENTS": "Include Attachments",
		  "INCLUDE_NAMES": "Include Names",
    	  "BIO": "Bio",
    	  "BOARDBOOK": "Boardbook",
    	  "BOARDBOOK_CREATED": "Boardbook created.",
    	  "BOARDBOOK_EDIT_SAVED": "Boardbook changes saved.",
    	  "BOARDVANTAGE": "BOARDVantage",
    	  "BOARDVANTAGE2": "Boardvantage",
    	  "BOOKMARKS_VIEW": "Bookmarks View",
    	  "BOOKMARK_PAGE": "Bookmark Page",
		  "USER_BOOKMARK": "Show User Bookmark",
    	  "BORDER": "Border",
    	  "BRANDING_COLOR": "Branding Color",
    	  "BRAND_IMAGE_MARGIN": "Branding Image Margin",
    	  "BRAND_IMAGE_ROTATION": "Branding Image Rotation",
    	  "BRIEFCASE": "Briefcase",
    	  "BRIEFCASE_UPPERCASE": "BRIEFCASE",
    	  "BRIEFCASE_INSTALLATIONS": "Offline Installations",
    	  "BRIEFCASE_PURGED": "A user's briefcase has been purged on this device. All briefcase contents have been removed. Briefcase contents will be re-synced upon login.",
    	  "BROWSER": "Browser",
    	  "BUILD": "Build",
    	  "BY": "By",
    	  "BY_PAGE": "By Page",
    	  "BY_USER": "By User",
    	  "CALENDAR": "Calendar",
    	  "CALENDAR_UPPERCASE": "CALENDAR",
    	  "CALENDAR_INVITE": "Calendar Invite",
    	  "CANCEL": "Cancel",
    	  "CANNOT_ADD_MORE_CHOICES": "Cannot add more choices",
    	  "CANNOT_PUBLISH_SURVEY_NO_SECTION_CREATED": "Cannot publish survey. No section created.",
    	  "CANNOT_PUBLISH_ATLEAST_ONE_QUESTION_TO_BE_ADDED": "Cannot publish survey. At least one question should be added in each section.",
    	  "CANNOT_PUBLISH_NO_RESPONDENT": "Cannot publish survey. No respondent invited.",
    	  "CANNOT_RESPOND": "Cannot Respond",
    	  "CAN_NOT_PASTE_INSIDE_ITSELF": "Can not paste '\\{\\{objectName\\}\\}' inside itself",
    	  "CAPS_COMPLETE": "COMPLETE",
    	  "CARD": "Card",
    	  "CARD_UPPERCASE": "CARD",
    	  "CBE": "CBE",
    	  "CBE_QUESTIONNAIRE": "CBE Questionnaire",
    	  "CBE_QUESTIONNAIRE_LINK": "Please click on the following link to response:",
    	  "CERTIFICATE": "Certificate",
    	  "CERTIFICATE_INSTALL_REQUIRED": "A Digital Client Certificate will be required at next Login",
    	  "CERTIFICATE_REQUIRED_PAGE_1": "You need a Certificate to login to the System.",
    	  "CERTIFICATE_REQUIRED_PAGE_2": "Contact your Administrator for further assistance.",
    	  "CERTIFICATE_NOT_SUPPORTED": "Certificates not supported",
    	  "CERTIFICATE_USER_NOT_ALLOWED": " Certificate user not allowed",
    	  "CERTIFICATE_NOT_VALID": "Not a valid certificate",
    	  "CERTIFICATE_REVOKED": "Certificate Revoked",
    	  "CHALLENGE_QUESTION": "Challenge Question",
    	  "CHALLENGE_QUESTIONS": "Challenge Questions",
    	  "CHANGE": "Change",
    	  "CHANGE_ORDER": "Change order",
    	  "CHANGE_ORDER_TABS": "Change order tabs",
    	  "CHANGE_PASSWORD": "Change Password",
    	  "CHANGE_ROLE": "Change Role",
    	  "CHANGE_STYLE": "Change Style",
    	  "CHARACTERS": " characters",
    	  "CHARACTERS_LONG": "characters long.",
    	  "CHECKBOX_EMPTY": "Empty Checkbox",
    	  "CHECKBOX_MARKED": "Checked Checkbox",
    	  "CHOICE1": "Choice1",
    	  "CHOICE2": "Choice2",
    	  "CHOICE3": "Choice3",
    	  "CHOICE4": "Choice4",
    	  "CHOICE5": "Choice5",
    	  "CHECK_ALL": "Check All",
    	  "CHECK_STATUS": "Check Status",
    	  "CHOICE_CANNOT_BE_EMPTY": "Choice cannot be Empty",
    	  "CHOICE_VALUE_CANNOT_BE_EMPTY": "Choice value cannot be Empty",
    	  "CHOICE_VALUE_SHOULD_BE_NUMBER": "Choice value should be a Number",
    	  "CHECK_OUT": "Check Out",
    	  "CHECK_IN": "Check In",
    	  "CANCEL_CHECK_OUT": "Cancel Check-Out",
    	  "CHOOSE_DIFFERENT_QUESTIONS": "Choose different questions.",
    	  "CHOOSE_USER_FROM_LIST": "Choose User From List",
    	  "CLEAR": "Clear",
    	  "CLEAR_INITIALS": "Clear Initials",
    	  "CLEAR_SIGNATURE": "Clear Signature",
    	  "CLICK_HERE_TO_VIEW_THE_BOARDVANTAGE_USAGE_POLICY": "Click here to view the Boardvantage <span class=\"bv-login-bold-text\">Usage Policy</span>",
    	  "CLOCKWISE": "Clockwise",
    	  "CLOSE": "Close",
    	  "CLOSED": "Closed",
    	  "CLOSE_APPROVAL_CONFIRM_MSG": "Are you sure you want to close this Approval?",
    	  "CLOSED_UPPERCASE": "CLOSED",
    	  "CLOSED_SURVEYS": "Closed Surveys",
    	  "CLOSE_SURVEY": "Close Survey.",
    	  "CODE": "Code",
    	  "COLLABORATE": "Collaborate",
    	  "COLLAPSE": "Collapse",
    	  "COLLATE": "Collate",
    	  "COLLATE_CREATED": "Collate created.",
    	  "COLLATE_EDIT_SAVED": "Collate/Boardbook changes have been saved.",
    	  "COLOR": "Color",
    	  "COMMENTS": "Comments",
    	  "COMMENT_MANDATORY": "Comment Mandatory",
    	  "COMMIT": "Commit",
    	  "COMMITED": "Commited",
    	  "COMMIT_AND_CLOSE": "Commit and Close",
    	  "COMPANY": "Company",
    	  "COMPANY_ICON": "Company Icon",
    	  "COMPANY_LOGO": "Company logo",
    	  "COMPLETE": "Complete",
    	  "COMPLETED": "Completed",
    	  "COMPLETE_REQUIRED_FILEDS": "All required fields must be completed.",
    	  "COMPONENTS": "Components",
    	  "COMPOSE_NEW_MESSAGE": "Compose New Message",
    	  "COMPOSE": "Compose",
    	  "CONFERENCE": "Conference",
    	  "CONFERENCE_UPPERCASE": "CONFERENCE",
    	  "CONFIG": "Collaborate",
    	  "CONFIGURATION": "Configuration",
    	  "CONFIRM_IDENTITY": "Confirm identity",
    	  "CONFIRM_NEW_PASSWORD": "Confirm New Password",
    	  "CONFIRM_PASSWORD": "Confirm Password",
    	  "CONFIRM_PASSWORD_NOTMATCH": "Password and Confirm password do not match.",
    	  "CONNECT": "Connect",
    	  "CONNECTED": "Connected",
    	  "CONTACT_US": "Contact Us",
    	  "CONTAIN_AT_LEAST": "Contain at least",
    	  "CONTAIN_AT_LEAST_ONE_UPPER_CASE_AND_ONE_LOWER_CASE_CHARACTER": "Contain at least one uppercase and one lowercase character",
    	  "CONTINUE": "Continue",
    	  "CONTENT": "Content",
    	  "COPY": "Copy",
    	  "COPY_SURVEY": "Copy Survey",
    	  "COPYLINK": "Copy Link",
    	  "COPY_OF_OPTIONS": "Copy of Options",
    	  "COPY_OF_QUESTIONS_AND_PARTICIPANTS": "Copy questions and participants",
    	  "COPY_OF_QUESTIONS_AND_PARTICIPANTS_AND_RESPONSES": "Copy questions, participants and responses",
    	  "COPY_OF_QUESTIONS_ONLY": "Copy questions only",
    	  "COUNTER_CLOCKWISE": "Counter clockwise",
    	  "COUNTRY": "Country",
    	  "COUNTRY_CODE": "Country Code",
    	  "CREATE": "Create",
    	  "CREATORS": "Creators",
    	  "CREATED_BY": "Created By",
    	  "CREATED_BY_LOWERCASE": "Created by",
    	  "CREATED_BY_UPPERCASE": "CREATED BY",
    	  "CREATED_BY_ME": "Created By Me",
    	  "CREATED_ON": "Created On",
    	  "CREATED_ON_UPPERCASE": "CREATED ON",
    	  "CREATED_TEAMSPACE": "Created Teamspace",
    	  "CREATESHORTCUT": "Create Shortcut",
    	  "CREATE_AGENDA": "Create Agenda",
    	  "CREATE_APPROVAL": "Create Approval",
    	  "CREATE_AGENDA_PLACEHOLDER": "Type in new agenda name",
    	  "CREATE_BOARDBOOK": "Create Boardbook",
    	  "CREATE_COLLATE": "Create Collate",
    	  "CREATE_FOLDER": "Create Folder",
    	  "CREATE_FOLDER_PLACEHOLDER": "Type in new folder name",
    	  "CREATE_GROUP": "Create Group",
    	  "CREATE_GROUPCHAT": "Create Group Chat",
    	  "CREATE_NEW_FILE": "Create New File",
    	  "CREATE_TEAMSPACE": "Create Teamspace",
    	  "CREATE_USER": "Create User",
    	  "CREATE_USER_FAILED": "User creation failed.",
    	  "CREATE_USER_SUCCESS": "User created successfully.",
    	  "CURRENT ONGOING_PRESENTATIONS": "Current Ongoing Presentations",
    	  "CURRENT": "Current",
    	  "CURRENT_TEAMSPACE": "Current TeamSpace",
    	  "CURRENT_INITIALS": "Current Initials",
    	  "CURRENT_LOGO": "Current Logo",
    	  "CURRENT_PASSWORD": "Current Password",
    	  "CURRENT_SIGNATURE": "Current Signature",
    	  "CUT": "Cut",
    	  "CURRENT_LANGUAGE": "Current Language",
    	  "CURRENT_PRESENTER": "Current Presenter",
    	  "CUSTOM_DATE_RANGE": "Custom Date Range",
    	  "CREATE_NEW_SIGNATURE": "Create New Signature",
    	  "COPY_MEETINGS": "Copy Meetings",
    	  "CHANGES_SAVED_SUCCESSFULLY": "Changes saved successfully.",
    	  "DAILY": "Daily",
    	  "DAY": "Day",
    	  "DATA_NOT_AVAILABLE": "Data not available",
    	  "DATE": "Date",
    	  "DATE_UPPERCASE": "DATE",
    	  "DATE_PUBLISHED_UPPERCASE": "DATE PUBLISHED",
    	  "DATE_MODIFIED": "Date Modified",
    	  "DATE_RANGE": "Date Range",
    	  "DAYS": "Days",
    	  "DECLINE": "Decline",
    	  "DECLINED": "Declined",
    	  "DECLINED_UPPERCASE": "DECLINED",
    	  "DEFAULT": "Default",
    	  "DEFAULT_AGENDA_TEMPLATE_SET": "Default agenda template is set.",
    	  "DEFAULT_EXPIRY_LABLE": "Expires in",
    	  "DELETE": "Delete",
    	  "DELETE_AFTER": "Delete after",
    	  "DELETE_ALL_ANNOTATIONS": "Delete All Annotations",
    	  "DELETE_ALL_MY_ANNOTATIONS": "Delete All My Annotations",
    	  "DELETE_APPROVAL_CONFIRM_MSG": "Are you sure you want to delete this Approval?",
    	  "DELETE_COMMENT_CONFIRM_MSG": "Are you sure you want to delete this comment?",
    	  "DELETE_NOTE": "Delete Note",
    	  "DELETE_NOTE_CONFIRM_MSG": "Are you sure you want to delete this note?",
    	  "DELETE_STRING": "Delete",
    	  "DELETE_SURVEY": "Delete Survey.",
    	  "DELETE_TAB": "Delete tab",
    	  "DELETE_PIN": "Delete Pin",
    	  "DELETE_PIN_CONFIRM_MSG": "Are you sure you want to delete drop pin?",
    	  "DENY": "Deny",
		  "DESCRIPTION": "Description",
		  "DE_SELECT_ALL":"Deselect All",
    	  "DETAILS": "Details",
    	  "DEVICE_REGISTERED_SUCCESSFULLY": "Device registered successfully",
    	  "DIAL_CODE": "Dial Code",
    	  "DIAL_IN": "Dial-In",
    	  "DIRECTORY": "Directory",
    	  "DIRECTORY_UPPERCASE": "DIRECTORY",
    	  "DISCUSSIONS": "Discussions",
    	  "DISAGREE": "Disagree",
    	  "DISABLE_SEARCH": "Disable Search",
    	  "DISPLAY": "Display",
    	  "DOCUMENT": "Document",
    	  "DOCUMENTACTIVITY": "Document Activity",
    	  "COMPANY_OWNER_UPPERCASE": "COMPANY OWNER",
    	  "ACTIVITY_UPPERCASE": "ACTIVITY",
    	  "DOCUMENTS": "Documents",
    	  "DOCUMENTS_UPPERCASE": "ALL DOCUMENTS",
    	  "DOCUMENT_NAME_UPPERCASE": "DOCUMENT NAME",
    	  "DOCUMENTS_DETAILS": "Documents Details",
    	  "DOCUMENT_ICON": "Document Icon",
    	  "DOCUMENT_IS_ASSOCIATED": " document is associated with the following collated documents/boardbooks",
    	  "DOCUMENT_CONVERSION_ERROR_PROMPT": "Document conversion error.",
    	  "DOCUMENT_CONVERSION_PROMPT": "Document conversion is in progress.",
    	  "DOCUMENT_PREVIEW": "Document Preview",
    	  "DOCUMENTLIST": "Document List",
    	  "DOCUMENTPERMISSIONS": "Document Permissions",
    	  "DOCUMENT_ANNOTAION_HANDLING": "Document Annotation Handling",
    	  "DOES_NOT_MEET": "Does Not Meet",
    	  "DONE": "Done",
    	  "DOWNLOAD": "Download",
    	  "DOWNLOADED": "Downloaded",
    	  "DOWNLOAD_REPORT": "Download Report",
    	  "ACCESS_RIGHTS_UPPERCASE": "ACCESS RIGHTS",
    	  "DO_NOT_INSTALL_AND_CONTINUE": "Do not install & Continue",
    	  "DO_YOU_WANT_TO_CLOSE_ALL_WINDOWS": "Do you want to close windows?",
    	  "DO_YOU_WANT_TO_INSTALL_JAVA": "Do you want to install Java?",
		  "DO_YOU_WANT_THE_SEND_THE_MESSAGE_WITHOUT_A_SUBJECT": "Do you want the send the message without a subject",
    	  "DRAG_AND_DROP_USER_TO_ADD": "Drag and drop user to add",
    	  "DRAWING_ANNOTATION_TOOL": "Drawing Annotation Tool",
    	  "SHOW_DISCUSSIONS": "Show Discussions",
    	  "DRAW_SIGNATURE": "Draw",
    	  "DRAW_SIGNATURE_AND_INITIAL_BOTH": "Select or draw signature and initials.",
    	  "DUE": "Due",
    	  "DUE_UPPERCASE": "DUE",
    	  "DUE_DATE": "Due Date",
    	  "DUE_DATE_LOWERCASE": "Due date",
    	  "DUE_ON": "Due on",
    	  "DROP_PIN": "Drop Pin",
    	  "DRAWING": "Drawing",
    	  "DRAW_NEW_SIGNATURE_HERE": "Draw new signature here",
    	  "DRAW_NEW_INITIAL_HERE": "Draw new initial here",
    	  "DOCUMENT_AGENDA": "Document Agenda",
    	  "DOCUMENT_THUMB": "Document Thumbnails",
    	  "DOCUMENT_ANNOTATIONS": "Document Annotations",
    	  "DOCUMENT_VERSION_ANNOTATIONS": "Annotated Versions",
    	  "DOCUMENT_SIGNATURES": "Document Signatures",
		  "DOCUMENT_USER_BOOKMARK": "User Bookmark",
    	  "EDIT": "Edit",
    	  "EDIT_BOARDBOOK": "Edit Boardbook",
    	  "EDIT_BOOKMARKS": "Edit Bookmarks",
    	  "EDIT_SECTION_NAMES": "Edit Section Names",
    	  "EDIT_COLLATE": "Edit Collate",
    	  "EDIT_COMMENTS": "Edit Comments",
    	  "EDIT_MEETINGS": "Edit Meetings",
    	  "EDIT_PHOTO": "Edit Photo",
    	  "EDIT_PROFILE": "Edit Profile",
    	  "EDIT_RESPONSE": "Edit Response",
          "EDITS_TO_QUESTIONS_OR_SECTIONS_WILL_REQUIRE_ANYONE_WHO_HAS_RESPONDED_TO_RECOMMIT_THEIR_ANSWERERS_WOULD_YOU_LIKE_TO_PROCEED": "Reopening this survey will require anyone who has submitted their responses to recommit their answers. Would you like to proceed?",
    	  "EDITED": "Edited",
    	  "EMAIL": "Email",
    	  "EMAIL_UPPERCASE": "EMAIL",
    	  "EMAIL_ADDRESS": "Email Address",
    	  "EMAIL_AUTHENTICATION": "Email Authentication",
    	  "ENABLE_DISABLE_HANDTOOL": "On/Off Hand Tool",
    	  "ENABLE_SEARCH": "Enable Search",
    	  "ENABLE_SEARCH_IN_VIEWER_PROMPT": "Search will require re-loading the full document. Do you want to continue?",
    	  "END": "End",
    	  "END_TIME": "To",
    	  "ENGINE_STATUS": "Engine Status",
    	  "EMPTY_TRASH": "Empty Trash",
    	  "ENTER_AUTHENTICATION_CODE": "Enter Authentication Code",
    	  "ENTER_CODE_REGISTER": "Enter Code to Register Device",
    	  "ENTER_AUTHENTICATION_CODE_YOU_JUST_RECEIVED": "Enter authentication code you just received",
    	  "ENTER_PIN": "Enter Pin",
    	  "ENTER_RESPONSE_HERE": "Enter Response Here",
    	  "ENTER_SMS_VERIFICATION_CODE": "Enter SMS Verification Code",
    	  "EVENTS": "Events",
    	  "EXPIRES_IN": "Expires in",
    	  "EXPIRES_ON": "Expires on",
		  "DATE_OF_EXPIRATION":"Date of Expiration",
    	  "EXPIRY": "Expiry",
    	  "EXPIRY_NOT_AVAILABLE": "Expiry not available",
    	  "EXPLICIT_PERMISSIONS": "Explicit Permissions",
    	  "EXTENDED": "Extended",
    	  "EXIT": "Exit",
    	  "EXIT_FULL_SCREEN_MODE": "Exit full screen mode",
    	  "ENTER_USER_ID": "Enter user Id",
    	  "ENTER_CODE": "Enter code",
    	  "FAILED_TO_COPY_LINK": "Failed to copy Link. Press Command + c / Ctrl + c.",
    	  "FAILED_TO_SUBMIT_RESPONSE": "Failed to submit response.",
    	  "FALSE": "False",
    	  "FILE": "File",
    	  "FILE_NAME_EXISTS": "File name already in use",
    	  "FILE_SIZE": "File Size",
    	  "FILE_TYPE": "File Type",
    	  "FILTER": "Filter",
    	  "FIND": "Find",
    	  "FIND_IN_DOCUMENT": "Find in Document",
    	  "FINISH_LATER": "Finish Later",
    	  "FIRST_NAME": "First Name",
    	  "FIRST_10_RESULTS": "First 10 results...",
    	  "FIT_HEIGHT": "Fit Height",
    	  "FIT_WIDTH": "Fit Width",
    	  "FILE_LOAD_ERROR_MSG": "This file is empty. Associated documents might have been deleted",
    	  "FOLDER": "Folder",
    	  "FOLDER_UPPERCASE": "FOLDER",
    	  "FOLDERS": "Folders",
    	  "FOLDER_HIERARCHY": "Folder Hierarchy",
    	  "FOLDER_NAME_EXISTS": "Folder name already in use.",
    	  "FOLLOW": "Follow",
    	  "FREE_TEXT": "Free Text",
    	  "FOLLOW_FOLLOWING_ME": "Follow/Follows Me",
    	  "ITEMS_FOLLOWED": "ITEMS FOLLOWED",
    	  "USERS_FOLLOWED": "USERS FOLLOWED",
    	  "ITEMS_FOLLOWING_ME": "USERS FOLLOWING ME",
    	  "NOTIFICATION_SETTINGS": "NOTIFICATION SETTINGS",
    	  "DATE_ADDED": "DATE ADDED",
    	  "DOCUMENT_NOTIY": "Notify me when a document is",
    	  "DOCUMENT_DEL": "Deleted",
    	  "DOCUMENT_EDIT": "Edited",
    	  "DOCUMENT_MOVED": "Moved",
    	  "DOCUMENT_RULE_ATT": "Has a rule attached",
    	  "DOCUMENT_TRASH": "Move to the trash",
    	  "DOCUMENT_TRASH_RESTORE": "Restore from the trash",
    	  "DOCUMENT_CHECKED_OUT": "Check-out",
    	  "DOCUMENT_REPLACED": "Replaced",
    	  "FOLDER_UPLOAD": "Uploaded",
    	  "FOLDER_MOVE": "Moved",
    	  "FOLDER_NOTIY": "Notify me when a folder is",
    	  "FOLDER_NEW": "Created",
    	  "FOLDER_UPLOAD_MOVE": "uploaded/moved to the folder",
    	  "FOLDER_DEL": "Deleted",
    	  "FOLDER_RULE_ATT": "Has a rule attached",
    	  "USER_NOTIFY": "Notify me when a user",
    	  "USER_FOLLOWS_DOCUMENT": "Follows a document",
    	  "USER_DOCUMENT_EDIT": "Edit a document",
    	  "DOCUMENT_FILE_TYPE": "The file type of this file differs from the existing file. Would you like to continue?",
    	  "FOLLOWING": "Following",
    	  "FOLLOWING_ME": "Following Me",
    	  "FONT_COLOR": "Font Color",
    	  "FORGOT": "Forgot",
    	  "FORGOT_PASSWORD": "Forgot Password",
    	  "FORGOT_USER_ID": "Forgot User ID",
    	  "FRI": "Fri",
    	  "FROM": "From",
    	  "FROM_LIST": "from list",
    	  "FOR_MORE_INFORMATION": "For more information:",
    	  "FOUND": "found",
    	  "FORWARD": "Forward",
    	  "FWD": "Fwd: ",
    	  "FORWARD_MESSAGE": "Forward Message",
    	  "FULLSCREEN": "Fullscren",
    	  "GENERATE_REPORT": "Generate Report",
    	  "GO_OFFLINE": "Go offline",
    	  "GO_TO_FIRST_PAGE": "Go to First Page",
    	  "GRAPHS": "Graphs",
    	  "GREETING": " ",
    	  "GROUPS": "Groups",
    	  "GROUPS_UPPERCASE": "GROUPS",
    	  "APPLIED_GROUPS_UPPERCASE": "APPLIED GROUPS",
    	  "APPLIED_BOARD_AND_TEAMSPACE_UPPERCASE": "APPLIED BOARD AND TEAMSPACE",
    	  "GROUP_ADDED_TO_TEAMSPACE": "Groups added to teamspace.",
    	  "GROUP_CREATED_AND_ADDED_TO_TEAMSPACE": " group is created and added to teamspace ",
    	  "GROUP_MGM_TITLE": "Group Management",
    	  "GROUP_REMOVED_FROM_TEAMSPACE": " group is removed from teamspace",
    	  "HEADER": "Header",
    	  "HIDDENFOLDER": "Hidden Folder",
    	  "HIDE": "Hide",
    	  "HIDEFOLDER": "Hide Folder",
    	  "HIDE_TREE": "Hide Tree",
    	  "HIDE_CHART": "Hide Chart",
    	  "HIGH": "High",
    	  "HIGHLIGHTER_BLUE": "Blue Highlighter",
    	  "HIGHLIGHTER_GREEN": "Green Highlighter",
    	  "HIGHLIGHTER_PINK": "Pink Highlighter",
    	  "HIGHLIGHTER_YELLOW": "Yellow Highlighter",
    	  "HIGHLIGHT_ALL": "Highlight all",
    	  "HOME": "Home",
    	  "HOME_PHONE": "Home Phone",
    	  "HOST": "Host",
    	  "HOST_ADDRESS": "Host Address",
    	  "HOST_NAME": "Host Name",
    	  "HOUR_SHORT": "hour",
    	  "HTML_DOCUMENT": "HTML Document",
    	  "HTML_TAG_ERROR": "Input with '< >' is not allowed.",
    	  "ID": "Id",
    	  "IMAGE": "Image",
    	  "IN_ACTIVE": "Inactive",
    	  "IN_PROGRESS": "In Progress",
    	  "IN_PROGRESS_UPPERCASE": "IN PROGRESS",
    	  "IN_ALL_FILES_FOLDERS": "in All Files/Folders",
    	  "INBOX": "Inbox",
    	  "INBOX_UPPERCASE": "INBOX",
    	  "INCLUDE": "Include",
		  "CHARTS_AND_DATA_TABLE":"Charts and data tables",
    	  "INDICATES_REQUIRED_FIELD": "Indicates required field",
    	  "INDEX_STATUS": "Index Status",
    	  "INDEXABLE_ITEMS": "Indexable Items",
    	  "INDEXED": "INDEXED",
    	  "INFORMATION": "Information",
    	  "INHERITED_PERMISSIONS_FROM_PARENT_FOLDER": "Inherited Permissions from Parent Folder",
    	  "INITIALS": "Initials",
    	  "INSTALL_AND_CONTINUE": "Install & Continue",
    	  "INSTALL_DATE": "Install Date",
    	  "INSTALL_EDIT_LINK": "Install Edit & Link",
    	  "INSTALL_JAVA": "Install Java?",
    	  "INSTALL_DESKTOP_CLIENT": "Install Desktop App",
    	  "INSTALL_DESKTOP_SUITE": "Install Nasdaq Suite for Windows",
    	  "INSTRUCTIONS": "Instructions",
    	  "INVALID_DATA_FOUND": "One or more fields contain invalid data.",
    	  "INVALID_EMAIL": "Invalid email",
    	  "INVALID_ADDITIONAL_EMAILS": "Invalid additional emails",
		  "SELECT_ATLEAST_ONE_ROLE": "Select atleast one profile role",
		  "SELECT_ATLEAST_ONE_COMMITTEE": "Select atleast one committee",
    	  "INVALID_FIRST_NAME": "Invalid first name",
    	  "INVALID_LAST_NAME": "Invalid last name",
    	  "INVALID_COMPANY_NAME": "Invalid company name",
    	  "INVALID_JOB_TITLE": "Invalid job title",
    	  "INVALID_MOBILE_NUMBER": "Invalid mobile number",
    	  "INVALID_WORK_NUMBER": "Invalid work phone number",
    	  "INVALID_CERTIFICATE_REQUEST_CODE": "Invalid certificate request code",
    	  "INVITE": "Invite",
    	  "INVITE_EMAILS_INPUT_HELP": "Use commas to separate addresses of email recipients",
    	  "INVITE_EMAILS_NOT_PROVIDED": "Enter an email address or select users from list to continue.",
    	  "INVITE_EMAILS_PLACE_HOLDER": "Invite Emails ',' seprated",
    	  "INVITE_EMAIL_ALREADY_EXISTS": "One or more email addresses already in use.",
    	  "INVITE_USER": "Invite User",
    	  "INVITE_USERS_SUCCESS": "Users invited.",
    	  "IN_THIS_CASE_ALL_THE_QUESTIONS_ARE_COPIED": "In this case, all the questions are copied",
    	  "IN_THIS_CASE_ALL_THE_SURVEY_QUESTIONS_AND_PARTICIPANTS_ARE_COPIED": "In this case all the survey questions and participants are copied",
    	  "IN_THIS_CASE_ALL_THE_SURVEY_QUESTIONS_AND_PARTICIPANTS_AND_RESPONSES_ARE_COPIED": "In this case all the survey questions, participants and responses are copied",
    	  "IN_WHICH_CITY_WAS_YOUR_HIGH_SCHOOL": "In which city was your high school?",
    	  "ITEMS": "Items",
    	  "INVALID_EMAIL_ADDRESSES": "Invalid email address.",
    	  "IS_EDITING": "is editing",
    	  "ENABLE_INCREMENTAL_FETCH": "Enable Quick View",
    	  "DISABLE_INCREMENTAL_FETCH": "Disable Quick View",
    	  "CHECKING_OUT_BY": "Checked Out By",
    	  "JAVA_NOT_FOUND": "Java not found",
    	  "JAVA_NOT_INSTALLED_MSG": "Java is not installed on your machine or is not configured correctly.",
    	  "JOB_TITLE": "Job Title",
    	  "JOIN": "Join",
    	  "JOIN_PRESENTATION": "Join Presentation",
    	  "JUP": "Jup",
    	  "JOINED": "Joined",
    	  "KEYWORDS": "Keywords",
    	  "KNOWLEDGE_CENTER": "Knowledge Center",
    	  "LABEL": "Label",
    	  "LANDING": "Landing",
    	  "LAND_ON": "Land On",
    	  "LANGUAGE_SETTINGS": "Language Settings",
    	  "LARGE_TEXT": "Large Text",
    	  "LAST_MODIFIED_ON": "Last Modified On",
    	  "LAST_NAME": "Last Name",
    	  "LAST_NAME_AND_EMAIL_FIELDS_ARE_MANDATORY": "Last Name and Email fields are mandatory",
    	  "LAST_SYNC": "Last Sync",
    	  "LAST_SYNCED": "Last Synced",
    	  "LAST_UPDATED": "Last updated",
    	  "LAST_UPDATED_UPPERCASE": "LAST UPDATED",
    	  "LAST_MODIFIED_UPPERCASE": "LAST MODIFIED",
    	  "LAST": "Last",
    	  "LAYOUT": "Layout",
    	  "LEAVE": "Leave",
    	  "LEAVE_EDITED_APPROVAL_WITHOUT_SUBMIT_MSG": "Are you sure you want to leave? Your Approval will not be submitted.",
    	  "LEAVE_EDITED_SURVEY_WITHOUT_SUBMIT_MSG": "Are you sure you want to leave? Your response will not be submitted.",
    	  "LEAVE_MODULE_WITH_UNSAVED_CHANGES_CONFIRM_MESSAGE": "Your changes will not be saved. Are you sure you want to leave?",
    	  "LEAVE_PRESENTATION": "Leave Presentation",
    	  "LETTERS": "letters.",
    	  "LICENSE_TYPE": "License Type",
    	  "LIKERT_SCALE": "Likert Scale",
    	  "LINK": "Link",
    	  "LINK_COPIED": "Link Copied.",
    	  "LIST": "List",
    	  "LIST_UPPERCASE": "LIST",
    	  "LOADING": "Loading",
    	  "LOCATION": "Location",
    	  "LOCATION_UPPERCASE": "LOCATION",
    	  "LOCK_ALL": "Lock All",
    	  "LOCKED": "Locked",
    	  "REGISTER_DEVICE": "Register Device",
    	  "LOCK_USER": "Lock user.",
    	  "LOCK_ALL_USERS": "Lock All Users",
    	  "REGISTER_CODE": "Register Code",
    	  "LOGIN": "Login",
    	  "LOGIN_STATUS_UPPERCASE": "LOGIN STATUS",
		  "STATUS_INFO_UPPERCASE": "STATUS INFO",
    	  "LOGIN_ERROR_SERVER_ERROR": "Please refresh your browser and ensure the information entered is correct.",
    	  "LOGIN_ID": "Login ID",
		  "USERNAME": "Username",
    	  "LOGO": "Logo",
    	  "LOGOFF_TAB_TITLE": "ECX",
    	  "LOG_IN": "Log In",
    	  "LOG_OFF": "Log Off",
    	  "LOG_OUT": "Log Out",
    	  "LOGOUT": "Logout",
    	  "LOW": "Low",
    	  "LOGGED_IN_AS": "Logged in as",
    	  "LANGUAGE": "Language",
		  "ROLES":"Roles (Select up to 2 roles)",
			"COMMITTEE":"Committees (Select up to 2 committees)",
		"SELECT_ROLES":"Select Roles",
		"SELECT_COMMITTEES":"Select Committees",
    	  "MAKE_CURRENT_VERSION": "Make Current Version",
    	  "MAKE_DEFAULT": "Make Default",
    	  "MAKE_PRESENTER": "Make Presenter",
    	  "MAKE_VERSION": "Make Current Version",
    	  "MANAGE_BRIEFCASE_INSTALLATIONS": "Offline Installations",
    	  "MANAGE_DASHBOARDS": "Manage Dashboards",
    	  "MANAGE_MEETINGS": "Manage Meetings",
    	  "MANAGE_SURVEYS": "Manage Surveys",
    	  "MANAGE_USERS": "Manage Users",
    	  "MANAGE_APPROVALS": "Manage Approvals",
    	  "MANDATORY": "Mandatory",
    	  "MATERIALS": "Materials",
    	  "MARK_ALL_READ": "Mark All Read",
    	  "MARK_AS_OWNER": "Mark as Owner",
    	  "MARK_AS_RESPONDENT": "Mark as Respondent",
    	  "MARK_AS_VIEWER": "Mark as Viewer",
    	  "MATCH_CASE": "Match case",
    	  "MEDIA_FILE": "Media File",
    	  "MEDIUM_TEXT": "Medium Text",
    	  "MEDIUM": "Medium",
    	  "MEETING": "Meeting",
    	  "MEETS": "Meets",
    	  "MEETING_IN_PROGRESS": "Meeting in Progress",
    	  "MEETING_IN_PROGRESS_SELECT_TO_JOIN": "Meetings in progress, select Join to enter a meeting.",
    	  "MEETINGLIST": "Meeting List",
    	  "MEETINGS": "Meetings",
    	  "MEETINGS_UPPERCASE": "MEETINGS",
    	  "MEETINGS_ATTENDEE_ERROR": "Atleast one attendee should be included",
    	  "MEETING_EVENT_LIST": "Meeting List",
    	  "MEETINGS_TIME_EQUAL_ERROR": "Start time and End time cannot be equal",
    	  "MEETINGS_TIME_GREATER_ERROR": "Start time cannot be greater than End time.",
    	  "MEETINGS_TITLE_ERROR": "Meeting title cannot be blank.",
    	  "MEETING_TITLE_UPPERCASE": "MEETING TITLE",
    	  "MEETING_ROOM": "Meeting Room",
    	  "MEETING_SETTINGS": "Meeting Settings",
    	  "MEETING_VIEW": "Meeting View",
    	  "MEETING_DETAILS": "Meeting Details",
    	  "MEETINGS_HAVE_BEEN_COPIED": "Meetings have been copied",
    	  "MEETS_DOES_NOT_MEET": "Meets/Does not Meet",
    	  "MESSAGE": "Message",
    	  "MESSAGES": "Messages",
    	  "MINIMIZE": "Minimize",
    	  "MINIMIZE_MODULES": "Minimize Modules",
    	  "MINIMIM_RESPONDANTS": "Minimum 1 Respondent is required",
    	  "MOBILE": "Mobile",
    	  "MOBILE_AUTHENTICATION": "Mobile Authentication",
    	  "MOBILE_CARRIER": "Mobile Carrier",
    	  "MOBILE_ENABLE_SMS": "Enable SMS Notifications",
    	  "MOBILE_PHONE": "Mobile Phone",
    	  "MODIFIED_BY": "Last Modified By",
		  "MODIFIED_UPPERCASE": "MODIFIED",
		  "MODIFIED": "Modified",
		  "ADDED" : "Added",
    	  "MODULES": "Modules",
    	  "MODULES_UPPERCASE": "MODULES",
    	  "MODULES_SETTINGS": "Module Settings",
    	  "MODULE_CREATORS": "Module Creators",
    	  "MODULE_COLOR": "Module Color",
    	  "MON": "Mon",
    	  "MONTH": "Month",
    	  "MONTH_UPPERCASE": "MONTH",
    	  "MONTHLY": "Monthly",
    	  "MONTHLY_VIEW": "Monthly View",
    	  "MONTHS": "Months",
    	  "MORE": "More",
    	  "MOST_RECENT_USER_ID": "Most Recent User ID",
    	  "MOVE": "Move",
    	  "MOVE_SURVEY_TO_CLOSED_CATEGORY": "Move Survey to the closed category",
    	  "MULTIPLE_CHOICE": "Multiple Choice",
    	  "MULTIPLE_CHOICE_MULTI_RESPONSE": "Multiple choice Multi response",
    	  "MULTIPLE_YES_NO": "Multiple Yes/No",
    	  "MULTISELECTIONS_NOT_ALLOWED": "Multiple selections are not supported.",
    	  "MSG_ERROR_LOADING_DOCUMENT_LIST": "Error loading document.",
    	  "MSG_EROR_FILE_TYPES": "this extensions are not supported",
    	  "MSG_BOARDBOOK_MAY_HAVE_BEEN_DELETED": "Boardbook may have been deleted.",
		  "MSG_DELETE_SENT_CONFIRMATION": "Are you sure you want to recall this message?",
		  "MINUTES": "Minutes",
		  "MINUTES_MANAGER": "Minutes Manager",
		  "MINUTES_ACTION_ITEMS": "Action Items",
		  "MINUTES_SETTINGS": "Minutes Settings",
    	  "MINUTE_SHORT": "Min",
    	  "NAME": "Name",
    	  "NAME_UPPERCASE": "NAME",
    	  "NAME_OF_SURVEY": "Name of Survey",
    	  "NAME_OF_TEMPLATE": "Name of Template",
    	  "NAMED_RESPONSES": "Named Responses",
    	  "NEVER": "Never",
    	  "NEVER_EXPIRES": "Never Expires",
    	  "NEW_APPROVAL": "New Approval",
    	  "NEWITEMS": "New Items",
    	  "NEWITEMS_UPPERCASE": "NEW ITEMS",
    	  "NEW_CONTACT": "New Contact name",
    	  "NEW_CUSTOM_FIELD_NAME": "New Custom Field Name",
    	  "NEW_FILE": "Create New File",
    	  "NEW_GROUP_NAME": "New Group name",
    	  "NEW_INITIALS": "New Initials",
    	  "NEW_ITEMS": "New Items",
    	  "NEW_LANGUAGE": "New Language",
    	  "NEW_MEETING": "New Meeting",
    	  "NEW_NOTE": "New Note",
    	  "NEW_PASSWORD": "New Password",
    	  "NEW_SIGNATURE": "New Signature",
    	  "NEW_SURVEY": "New Survey",
    	  "NEXT": "Next",
    	  "NEXT_PAGE": "Next Page",
    	  "NEXT_PAGE_PREVIEW": "Next Page Preview",
    	  "NO": "No",
    	  "NO_PHOTO": "No Photo",
    	  "NON_INDEXED": "NON INDEXED",
    	  "NORMAL": "Normal",
    	  "NOTE": "Note",
    	  "NOTES": "Notes",
    	  "NOTES_UPPERCASE": "NOTES",
    	  "NOTIFY": "Notify",
		  "NOTIFY_ATTENDEES": "Notify Attendees",
    	  "NOTIFY_ME_ON_RESPONSE": "Notify me on response",
    	  "NOT_APPROVE": "Not Approve",
    	  "NOT_ANSWERED": "Not Answered",
    	  "NOT_AVAILABLE": "Not Available",
    	  "NOT_RESPONDED": "Not Responded",
    	  "NOT_SECURE": "Not Secure",
		  "NOT_STARTED": "Not Started",
		  "NOT_SUBMITTED": "Not Submitted",
    	  "NOT_YET_RESPONDED": "Not yet Responded",
    	  "NOT_FOUND": "Not found",
    	  "NO_ATTACHMENTS": "No Attachments",
    	  "NO_OPINION": "No Opinion",
    	  "NO_PERMISSION_MSG": "You do not have permission to share the selected documents.",
    	  "NO_PERMISSION_TO_MOVE_THE_SELECTED_DOCUMENTS": "No permission to move the selected documents",
          "NO_USER_PERMITTED_MESSAGE": "No users have permission to view this file.",
    	  "NO_QUESTION_ATTACHMENTS": "No question Attachments",
    	  "NO_RECIPIENT_MSG": "At least one recipient is required.",
    	  "NO_ROTATION": "No rotation",
    	  "NO_RESPONSE_UPPERCASE": "NO RESPONSE",
    	  "NO_SECTION_ATTACHMENTS": "No Section Attachments",
    	  "NO_SELECTED_INBOX_ITEM_MSG": "No message selected, please choose a message to delete.",
    	  "NO_SIGNATURE_EXIST_WARNING": "There are no signatures for the current user.",
    	  "NO_SUBJECT_MSG": "Subject line is required.",
    	  "NO_SURVEY_ATTACHMENTS": "No Survey Attachments",
    	  "NO_SURVEYS_TO_MANAGE": "No surveys to manage.",
    	  "NO_SHARE_PERMISSION_MSG": "You do not have permission to share this document.",
    	  "NO_RESTORE_PERMISSION_MSG": "You do not have permission to restore to this folder.",
    	  "NUMBERS": "numbers.",
    	  "NO_PREVIEW_AVAILABLE": "No preview available for this document.",
    	  "NO_ADD_TO_NEW_ITEM_PERMISSION": "You do not have permission to add the following documents to New Items.",
    	  "NO_CURRENT_SIGNATURE": "No current signature",
    	  "NO_CURRENT_INITIAL": "No current initial",
    	  "NO_FOLDER_AGENDA_TEMPLATES_MSG": "No templates for teamspace found. Default global template will be used for Agenda creation.",
    	  "NO_SIGNATURE_CREATE_SIGNATURE_CONFIRM": "There is no existing signature. Would you like to create one?",
    	  "NO_ATTENDEES_HAVE_ACCEPTED_AT_THIS_TIME": "No attendees have accepted at this time.",
    	  "NO_ATTENDEES_HAVE_TENTATIVE_AT_THIS_TIME": "No attendees have tentative at this time.",
    	  "NO_ATTENDEES_HAVE_DECLINED_AT_THIS_TIME": "No attendees have declined at this time.",
    	  "NO_ATTENDEES_HAVE_RESPONSE_AT_THIS_TIME": "No attendees have no response at this time.",
    	  "OF": "of",
    	  "OFFLINE": "Offline",
    	  "OK": "OK",
    	  "OLD_PASSWORD_IS_NOT_VALID": "Old password is not valid",
    	  "OLD_AND_NEW_PASSWORD_SHOULD_NOT_BE_SAME": "Old and New password should not be same.",
    	  "MAX_ATTEMPTS_TO_CHANGE_PASSWORD_REACHED": "Maximum attempts to change password have reached.",
    	  "ONE_AGENDA_ATTACH_MESSAGE": "You can attach only one agenda.",
    	  "ONE_OR_MORE_OF_YOUR_APPROVAL_CHOICES_ARE_THE_SAME": "One or more of your approval choices are the same",
    	  "ONLINE": "Online",
    	  "ONLY_HEADER": "Only header",
    	  "OPEN": "Open",
    	  "OPEN_UPPERCASE": "OPEN",
    	  "OPEN_ANSWER": "Open Answer",
    	  "OPEN_APPROVAL": "Open Approval",
    	  "OPEN_FILE": "Open File",
    	  "OPEN_ITEMS": "Open Items",
    	  "OPEN_SURVEYS": "Open Surveys",
    	  "OPENED_AS_PDF":	"Opened as PDF",
    	  "OPTIONS": "Options",
    	  "OPTIONS_FALSE": "Option False",
    	  "OPTIONS_TRUE": "Option True",
    	  "OPEN_FOLDER_IN_DOCUMENT_MODULE": "Open folder in Document Module",
    	  "ON": "On",
    	  "OR": "or",
    	  "OS": "OS",
    	  "OS_USER": "OS User",
    	  "OVERALL_PROGRESS": "Overall Progress",
    	  "OVERVIEW": "Overview",
    	  "OWN": "Own",
    	  "OWN_UPPERCASE": "OWN",
    	  "OWNED_BY_ME": "Owned By Me",
    	  "OWNER": "Owner",
    	  "OWNERS": "Owners",
    	  "OBJECT_PUSH_USERS_ERROR": "At least one recipient should be included",
    	  "ONLY_10_MEETINGS_CAN_BE_COPIED_AT_A_TIME": "Only 10 meetings can be copied at a time",
    	  "OTHERS": "Others",
    	  "PADDING": "Padding",
    	  "PAGE": "Page",
    	  "PAGE_TITLE_FOR_USE_IN_BOARDBOOK_AGENDA_SECTIONS": "Page title for use in Boardbook Agenda Sections",
    	  "PARTICIPANTS": "Participants",
    	  "PASSWORD": "Password",
    	  "PASSWORD_CHANGE_SUCCESS": "Your password has been changed successfully.",
    	  "PASSWORD_MUST_BE_DIFFERENT_FROM_THE_LAST": "Password must be different from the last",
    	  "PASTE": "Paste",
    	  "PASWORDS_USED_LAST": "passwords used last.",
    	  "PDF": "Pdf",
    	  "PDF_UPPERCASE": "PDF",
    	  "PEOPLE": "People",
    	  "PEN": "Pen",
    	  "PENDING": "Pending",
    	  "PEN_BLUE": "Blue Pen",
    	  "PEN_GREEN": "Greeen Pen",
    	  "PEN_GREY": "Grey Pen",
    	  "PEN_RED": "Red Pen",
    	  "PERCENTAGE_OF_USERS_RESPONDED": "So far \\{\\{percentage\\}\\}% of users have responded.",
    	  "PERMISSION": "Permission",
    	  "PERMISSIONS": "Permissions",
          "VIEW_PERMISSIONS": "View Permissions",
          "UPLOAD_PERMISSION_TOOLTIP": "Files added to <span class=\"bv-login-bold-text\">Documents</span> folder will inherit the same permissions as that folder. For further details please review the permissions following your upload.",
          "SEARCH_TO_ADD_USERS": "Search to Add Users",
    	  "PERMISSION_TOOLTIP_COLLABORATE": "Permission to Edit, Forward, Read, Save and Print.",
    	  "PERMISSION_TOOLTIP_CURRENT": "Do not change existing permission.",
    	  "PERMISSION_TOOLTIP_OWN": "Permission to Edit, Forward, Read, Save, Print, Add and Delete. A user who uploads documents or creates content receives Owner status by default.",
    	  "PERMISSION_TOOLTIP_PRINT": "Permission to Read, Save and Print.",
    	  "PERMISSION_TOOLTIP_VIEW": "Permission to Read only.",
    	  "PIE": "Pie",
    	  "PIE_CHART": "Pie Chart",
    	  "PIN": "Pin",
    	  "PIN_LOGIN_HEADER": " ",
    	  "PIN_CODE_LOGIN": "PIN Code Login",
    	  "PIN_SETUP": "Pin Setup",
    	  "PIN_SETUP_HEADER": "Pin Setup",
    	  "PLATFORM": "Platform",
    	  "PLEASE_ANSWER_THE_QUESTION": "Please answer the questions",
    	  "PLEASE_ANSWER_THE_QUESTION_WITH_MINIMUM": "Answers should have a minimum of ",
    	  "PLEASE_ASSIGN_HOST": "Please assign a Host.",
    	  "PLEASE_CHOOSE_UNIQUE_ANSWERS_FOR_EACH_QUESTION": "Each answer should be unique.",
    	  "PLEASE_DEFINED": "Please defined \"itemObjectBaseUuid\"",
    	  "PLEASE_RESPOND_TO_THE_SURVEY": "Please respond to the Survey",
    	  "PLEASE_RESPOND_TO_ALL_MANDATORY_QUESTIONS_BEFORE_COMMITING_SURVEY": "Please respond to all mandatory questions before commiting survey.",
    	  "PLEASE_RESPOND_TO_ALL_MANDATORY_COMMENTS_BEFORE_COMMITING_SURVEY": "Please respond to all mandatory comments before commiting survey.",
    	  "PLEASE_WAIT_TO_UPLOAD_THE_FILE": "Please wait to upload the file",
    	  "PRIVATE_NOTE": "Private Note",
    	  "PRIVATE": "Private",
    	  "PORTAL": "Portal",
    	  "POWERPOINT_DOCUMENT": "MS Powerpoint Document",
    	  "PREFERENCES": "Preferences",
    	  "PRESENT": "Present",
    	  "PRESENTER_MODE": "Presenter Mode",
    	  "PRESENTER": "Presenter",
    	  "PREVIEW": "Preview",
		  "PREVIEW_SURVEY":"Preview Survey",
    	  "PREVIOUS": "Previous",
    	  "PREVIOUS_PAGE": "Previous Page",
    	  "PRE_FILL_SURVEY": "Pre-Fill Survey",
    	  "PRIMARY_COLOR": "Primary Color",
    	  "PRIMARY_COLOR_AND_FONT_COLOR_EXAMPLE": "Primary Color & Font Color Example",
    	  "PRIMARY_EMAIL": "Primary Email",
    	  "PRINT": "Print",
		  "REPORT": "Report",
		  "PRINT_ALL": "Print All",
    	  "PRINTABLE_REPORTS": "Printable reports",
    	  "PRINT_ANNOT_MSG": "Would you like to print annotations also?",
          "PRINT_WATERMARK_MSG": "Would you like to include the watermark?",
    	  "PRIORITY": "Priority",
    	  "PROCEED": "Proceed",
    	  "PROFILE_CHANGES_SAVED": "Profile changes saved.",
    	  "PROFILE_CHANGES_SAVE_FAILED": "Profile changes failed to save.",
    	  "PROGRESS": "Progress",
    	  "PROPERTIES": "Properties",
    	  "PUBLISH": "Publish",
    	  "PUBLISH_UNPUBLISH": "Publish/Unpublish",
    	  "PULL_BACK_SENT_ITEM_CONFIRMATION_MSG": "Do you want to pull back sent item",
    	  "PURGE": "Purge",
    	  "PURGED": "Purged",
    	  "PURGED_BRIEFCASES": "Purged Briefcases",
    	  "PURGE_STATUS": "Purge Status",
    	  "PURGE_ANNOTATIONS": "Purge annotations",
    	  "PUSH": "Push",
    	  "PUSH_BY": "Pushed by",
    	  "PUSHED_BY_ME": "Pushed by me",
    	  "PUSH_DETAILS": "Push Detail",
    	  "PUSH_TO_ALL": "Push to All",
    	  "PUSHED_ON": "Pushed On",
    	  "PUSHED_VERSION": "Pushed Version",
    	  "PHONE": "Phone",
    	  "QUESTION": "Question",
    	  "QUESTIONS": "Questions",
    	  "QUESTION_ATTACHMENT": "Question Attachments",
    	  "QUESTION_MANAGER": "Question Manager",
    	  "QUESTIONS_RESPONDED": "Questions responded",
    	  "QUESTION_NAME_CANNOT_BE_EMPTY": "Question name cannot be empty",
    	  "RE": "Re: ",
    	  "REBUILD_INDEX": "Rebuild Index",
    	  "RECALL": "Recall",
    	  "RECENT": "Recent",
    	  "RECEIVED": "Received",
    	  "RECIPIENTS": "Recipients",
    	  "RECYCLE_BIN_ALERT_ARE_YOU_SURE_TO_DELETE": "Are you sure you want to permanently delete these items?",
    	  "REMEMBER_ME": "Remember me",
    	  "REMOTE_ID": "Remote ID",
    	  "REMOVE": "Remove",
    	  "REMOVE_PUSH": "Remove Push",
    	  "REMOVE_RESPONDENTS": "Remove Respondents",
    	  "REMOVED_FROM_SPACE_OWNER_GROUP": " is removed from space owner group.",
    	  "REMOVEFROMBRIEFCASE": "Remove from Briefcase",
    	  "REMOVEFROMMYBRIEFCASE": "Remove from My Briefcase",
    	  "REMOVEFROMALLBRIEFCASES": "Remove from All Briefcases",
    	  "REMOVEFROMNEWITEMS": "Remove from NewItems",
    	  "REMOVE_ATTENDEES": "Remove Attendees",
    	  "REMOVE_FROM_CLOSED_CATEGORY": "Remove from closed category.",
    	  "REMOVE_FROM_GROUP": "Remove From Group",
    	  "REMOVE_GROUP_CONFIRMATION_MSG": " Are you sure you want to remove ",
    	  "REMOVE_INDEX": "Remove Index",
    	  "REMOVE_ME": "Remove me",
    	  "REMOVE_NEW_ITEM_CONFIRMATION_MSG": "Are you sure you want to remove this document from New Items?",
    	  "REMOVE_PARTICIPANT": "Remove participant.",
    	  "REMOVE_PARTICIPANTS": "Remove Participants",
          "REMOVE_USER": "Remove User",
    	  "REMOVE_USER_CONFIRMATION_MSG": "Are you sure you want to remove ",
    	  "REMOVE_USER_FROM_GROUP_CONFIRMATION_MSG": "Do you want to remove the selected user from this group?",
    	  "RENAME": "Rename",
    	  "REOPEN": "Reopen",
    	  "REOPEN_SURVEY": "Reopen Survey",
    	  "REOPEN_APPROVAL_CONFIRM_MSG": "Are you sure you want to reopen this Approval?",
    	  "REPLACE": "Replace",
    	  "REPLACE_FILE": "Replace File",
    	  "REPORT": "Report",
    	  "REPORTS": "Reports",
    	  "REPORTS_UPPERCASE": "REPORTS",
    	  "FIRST_NAME_UPPERCASE": "FIRST NAME",
    	  "LAST_NAME_UPPERCASE": "LAST NAME",
    	  "DATE_ACCESSED": "DATE ACCESSED",
    	  "IP_ADDRESS": "IP ADDRESS",
    	  "DEVICE": "DEVICE",
    	  "REPORTS_NO_CONTENT_MSG": "No results have been found. Please apply the filters to generate the report.",
    	  "REPOSITORY": "Repository",
    	  "REPLY": "Reply",
    	  "REPLY_ALL": "Reply All",
    	  "REPLY_OPTIONS": "Reply Options",
    	  "REPLY_MANDATORY": "Reply Mandatory",
    	  "RESET": "Reset",
    	  "RESET_ALL_APP_DATA": "Reset All App Data",
    	  "RESET_APP_DATA_SUCCESS": "All data is deleted and app is reset",
    	  "RESPOND": "Respond",
    	  "RESPONDENT": "Respondent",
    	  "RESPONDED": "Responded",
    	  "RESPONDENTS": "Respondents",
    	  "RESPONSE": "Response",
    	  "RESPONSES": "Responses",
    	  "RESPONSE_TYPE": "Response Type",
    	  "RESUBMIT": "Resubmit",
    	  "RESUME": "Resume",
    	  "RESTORE": "Restore",
    	  "RESTORE_TO": "Restore To",
    	  "RESTORING": "Restoring",
    	  "RESTORING_FILES": "Restoring Files",
    	  "RESULTS": "results",
    	  "RESULTS_FOR": "Results for",
    	  "RESORT_BY_RELEVANCY": "Re-sort by Relevancy",
    	  "RETENTION_POLICY_HISTORY_REPORT": "Retention Policy History Report",
    	  "RETENTION_AREA_UPPERCASE": "RETENTION AREA",
    	  "RETENTION_MODULE_UPPERCASE": "RETENTION MODULE",
    	  "OLD_VALUE_UPPERCASE": "OLD VALUE",
    	  "NEW_VALUE_UPPERCASE": "NEW VALUE",
    	  "RETRIEVE_USER_ID": "Retrieve User ID",
    	  "RETRIEVE_PASSWORD": "Retrieve Password",
    	  "RETRY": "Retry",
    	  "RETURN_TO_LOGIN": "Return to Login",
    	  "REVIEW": "Review",
    	  "RE_UPLOAD_EXISTING_KEY": "Re-upload existing key",
    	  "RE_PUSH": "Push Again",
    	  "REFINE_RESULTS": "Refine Results",
    	  "RULES": "Rules",
    	  "RULES_FOR": "Rules for",
    	  "ROTATE_DEVICE_TO_DRAW": "Rotate device to draw",
    	  "SALUTATION": "Salutation",
    	  "SAT": "Sat",
    	  "SAVE": "Save",
    	  "SAVE_AND_FINISH_LATER": "Save and Finish Later",
    	  "SAVING": "Saving",
    	  "SAVE_IN_FOLDER": "Save in Folder",
    	  "SAVE_TO": "Save To",
    	  "SEARCH": "Search",
    	  "SEARCH_FOR_RECIPENTS": "Search For Recipents",
    	  "SEARCH_RESULTS": "Search Results",
    	  "SEARCH_RESULTS_EMPTY": "0 match found",
    	  "SEARCH_RESULTS_FOR_TS": "Search results pertain to current TeamSpace only",
    	  "SEARCH_CURRENT_FOLDER": "Search Current Folder",
    	  "SEARCH_TEAM_SPACES": "Search Team Spaces",
    	  "SEARCH_TEAMSPACES": "Search Teamspaces",
    	  "SEARCH_GROUPS": "Search Groups",
    	  "SELECT_DATE_RANGE": "Select Date Range",
    	  "SEARCH_FILES_WITH_THESE_WORDS": "Search files with these words",
    	  "SEARCH_FILES_WITH_THIS_PHRASE": "Search files with this phrase",
    	  "SEARCH_INDEXING": "Search Indexing",
    	  "SECONDARY_EMAILS": "Secondary Emails",
    	  "SECTION": "Section",
    	  "SECTION_NAME_CANNOT_BE_EMPTY": "Section name cannot be empty",
    	  "SECTIONS": "Sections",
    	  "SECTION_NUMBER": "Section Number",
    	  "SECURE": "Secure",
    	  "SECURE_MESSAGE": "Secure Message",
    	  "SELECT": "Select",
    	  "SELECTED_FILE_IS_THE_CURRENT_VERSION": "Selected file is the current version.",
    	  "SELECTED_USERS": "Selected Users",
    	  "SELECTED_DOCUMENTS_ASSOCIATED": "Selected documents are associated with the following collated documents/boardbooks",
    	  "SELECT_ALL": "Select All",
    	  "SELECT_A_RESPONSE": "Select a Response",
    	  "SELECT_A_VERSION": "Select a version.",
    	  "SELECT_FILE": "Select File",
    	  "SELECT_FILES": "Select Files",
    	  "SELECT_FILES_TO_UPLOAD": "Select files to upload",
    	  "SELECT_FOLDER": "Select Folder",
    	  "SELECT_METHOD": "Select method",
    	  "SELECT_PARTICIPANTS": "Select Participants",
    	  "SELECT_RECIPIENTS": "Select recipients",
    	  "SELECT_RECIPIENT": "Select Recipient",
    	  "SELECT_USER": "Select User",
    	  "SELECT_USERS": "Select Users",
    	  "SELECT_TEAM_SPACES": "Select Team Spaces",
    	  "SELECTED_TEAM_SPACES": "Selected Team Spaces",
    	  "SEND": "Send",
    	  "SEND_ALERT": "Send Alert",
    	  "SEND_APPROVAL_ALERT": "Approval has been saved. Would you like to send an alert?",
    	  "SEND_SURVEY_ALERT": "Survey has been saved. Would you like to send an alert?",
    	  "SEND_MEETING_RECYCLE_ALERT": "This meeting has been deleted, would you like to send an alert?",
    	  "SEND_MEETING_ALERT": "Meeting has been saved. Would you like to send an alert?",
    	  "SEND_SECURE_EMAIL": "Send Secure Email",
    	  "SEND_TO_NEW_ITMES": "Send to New Items",
    	  "SENT": "Sent",
    	  "SENT_UPPERCASE": "SENT",
    	  "SENT_ITEMS": "Sent Items",
    	  "SEPARATOR": "Separator",
    	  "SERVER": "Server",
    	  "SESSION_IDLE_TIMEOUT_MSG": "Your session will timeout in \\{\\{countdown\\}\\} seconds.",
    	  "SESSION_TIMEOUT_MSG": "Your session has timed out",
    	  "SET": "Set",
    	  "SETFOLLOWERS": "Set Followers",
    	  "FOLLOWERS": "Followers",
    	  "SETTINGS": "Settings",
    	  "SET_FOLLOWERS_FOR": "Set Followers for ",
    	  "SET_FOLLOWERS_MSG": "Double click users from left to have them follow the object.",
    	  "SET_LANGUAGE": "Set Language",
    	  "SET_RULES_MSG": "Double click users from left to assign access rights.",
    	  "SHARE": "Share",
    	  "SHARE_WITH": "Share With",
    	  "SHARED_NOTE": "Shared Note",
    	  "SHARED_WITH": "Shared With",
    	  "SHARED": "Shared",
    	  "SHARED_BY_ME": "Shared By Me",
    	  "SHARED_WITH_ME": "Shared With Me",
    	  "SHOW": "Show",
    	  "SHOW_HIDE": "Show/Hide",
    	  "SHOW_ATTACHMENTS": "Show Attachments",
    	  "SHOW_COUNTRY_CODE": " ",
    	  "SHOW_DOCUMENT_ANNOTATIONS": "Show Document Annotations",
    	  "SHOW_DOCUMENT_OUTLINE": "Show Document Outline",
    	  "SHOW_HIDE_ANNOTATIONS": "Show/Hide Annotations",
    	  "SHOW_SIGNATURES": "Show Signature/Initials",
    	  "SHOW_TREE": "Show Tree",
    	  "SHOW_CHART": "Show Chart",
    	  "SHOW_THUMBNAILS": "Show Thumbnails",
    	  "SIGN": "Sign",
    	  "SIGNATURE": "Signature",
    	  "SIGNATURES": "Signatures",
    	  "NO_SIGNATURES": "No Signatures",
    	  "SIGNATURE_TRACKER": "Signature Tracker",
    	  "SIGNEE": "SIGNEE",
    	  "SIGNED_UPPERCASE": "SIGNED",
    	  "SIGNATURES_UPPERCASE": "SIGNATURES",
    	  "SIGNATURE_COMMIT_CONFIRM": "Signatures committed successfully.",
    	  "SIGNATURE_COMMIT_WARNING": "All signature/initial wells must be signed before committing.",
    	  "SIGNATURE_IS_REQUIRED_TO_COMMIT": "Signature is required to commit.",
    	  "SIGNATURE_INITIALS": "Signature/Initials",
    	  "SIGNATURE_REQUIRED": "Signature Required.",
    	  "SIGNATURE_SAVED": "Signature saved.",
    	  "SIGNATURE_SAVE_FAILED": "Signature save failed.",
    	  "SIGNATURE_STATUS": "Signature Status",
    	  "SIGNATURE_WELL": "Signature Well",
    	  "SIGNED": "Signed",
    	  "SIGN_HERE": "Sign Here",
    	  "SITE_BRANDING": "TeamSpace Branding",
    	  "SIZE": "Size",
    	  "SINCE_LAST_VISIT": "Since Last Visit",
    	  "SKIP": "Skip",
    	  "SKIP_CHALLENGE_QUESTIONS": "Skip challenge questions",
    	  "SMALL_TEXT": "Small Text",
    	  "SMALL_SCREEN": "Small Screen",
    	  "SMS_AUTHENTICATION": "SMS Authentication",
    	  "SMS_CODE": "SMS Code",
    	  "SORT": "Sort",
    	  "SORT_QUESTIONS": "Sort Questions",
    	  "SORTING_COMPLETED": "Sorting Completed",
    	  "SORT_BY_NAME": "Sort by Name",
    	  "SORT_BY_DATE": "Sort by Date",
    	  "SORT_BY_DATE_ADDED": "Sort by Date Added",
    	  "SORT_BY_ADDED_BY": "Sort by Added By",
    	  "SORT_BY_DATE_MODIFIED": "Sort by Date Modified",
    	  "SORT_BY_DUE_DATE": "Sort by Due Date",
    	  "SORT_BY_MODIFIED_BY": "Sort by Modified By",
    	  "SORT_BY_PROGRESS": "Sort by Progress",
    	  "SORT_BY_TYPE": "Sort by Type",
    	  "SORT_BY_STATUS": "Sort by Status",
    	  "SORTED_BY_RELEVANCY": "Sorted by Relevancy",
    	  "SPECIAL_CHARACTERS_WITH_EXAMPLE": "special characters (e.g. $&%@)",
    	  "SPECIAL_CHARACTERS_NOT_ALLOWED": "Special characters are not allowed",
    	  "SPECIAL_CHARACTERS_MULTIPLE_CHOICE": "Special Characters like ; and , are not allowed in the choice",
    	  "SPECIFIC_PHRASE": "Specific Phrase",
    	  "START": "Start",
    	  "START_MEETING": "Start Meeting",
    	  "STARTED": "Started",
    	  "STARTING_DATE": "Starting Date",
    	  "START_DATE": "Start Date",
    	  "START_DATE_UPPERCASE": "START DATE",
    	  "END_DATE_UPPERCASE": "END DATE",
    	  "START_PRESENTATION": "Start Presentation",
    	  "START_TIME": "From",
    	  "STATUS": "Status",
    	  "STATUS_UPPERCASE": "STATUS",
    	  "STOP_FOLLOW": "Stop following",
    	  "STOP_PRESENTATION": "Stop Presentation",
    	  "STOP_SHARING": "Stop Sharing",
    	  "STRETCH_PAGE": "Stretch page",
    	  "STRONGLY_AGREE": "Strongly Agree",
    	  "STRONGLY_DISAGREE": "Strongly Disagree",
    	  "STYLE": "Style",
    	  "STYLES": "Styles",
    	  "SUBJECT": "Subject",
		  "SUBMIT": "Submit",
		  "SUBMITTED": "Submitted",
    	  "SUBMIT_CERTIFICATE": "Submit Certificate",
    	  "SUN": "Sun",
    	  "SUPPORT": "Support",
    	  "SURVEY": "Survey",
    	  "SURVEYS": "Surveys",
    	  "SURVEYS_UPPERCASE": "SURVEYS",
    	  "SURVEY_ALERT_SUBJECT": "Please respond to the Survey",
    	  "SURVEY_ALERT_COMMENTS": "Please respond to the Survey",
    	  "SURVEY_BUILDER": "Survey Builder",
    	  "SURVEY_COPIED_SUCCESSFULLY": "Survey Copied Successfully",
    	  "SURVEY_DESCRIPTION": "Survey Description",
    	  "SURVEY_COMPLETE": "Completed Survey",
    	  "SURVEY_IN_PROGRESS": "In-Progress Survey",
    	  "SURVEY_NOT_STARTED": "Survey Not Started",
    	  "SURVEY_REPORT": "Survey Report",
    	  "SURVEY_REPORT_MODAL_TITLE": "Export Survey Data",
    	  "SURVEY_TITLE": "Survey Title",
    	  "SURVEY_TRUE": "True",
    	  "SURVEY_FALSE": "False",
    	  "SURVEY_DETAILS": "Survey Details",
		  "FILE_FORMAT": "File format",
    	  "SWITCH_TO_FULLSCREEN_MODE": "Switch to Fullscreen Mode",
    	  "SYNC": "Sync",
    	  "SYNCING": "Syncing",
    	  "SYNCED_ON": "Synced On",
    	  "SYNCED_VERSION": "Synced Version",
    	  "SUCCESS": "Success",
    	  "SUCCESS_MSG_ADDED_TO_BRIEFCASE": "Documents have been added to Briefcase.",
    	  "SUCCESSFULLY_REGISTERED": "This Device is Successfully Registered.",
    	  "SUMMARY": "Summary",
    	  "SUPPORT_CENTER": "Support Center",
    	  "TALLY": "Tally",
    	  "TASKMANAGEMENT": "Task Management",
    	  "TASKMANAGEMENT_UPPERCASE": "TASK MANAGEMENT",
    	  "TABLE_SUPPORT": "Table Support",
    	  "TASKS": "Tasks",
    	  "TEAMSPACE": "Teamspace",
    	  "TEAMSPACE_UPPERCASE": "TEAMSPACE",
    	  "TEAM_SPACE": "TeamSpace",
    	  "TEAM_SPACES": "Team Spaces",
    	  "TEAM_SPACE_BRANDING": "TeamSpace Branding",
    	  "TEAM_SPACE_GROUPS": "TeamSpace Groups",
    	  "TEMP_PWD_EXPIRED": "Temporary password is expired",
    	  "TENTATIVE": "Tentative",
    	  "TENTATIVE_UPPERCASE": "TENTATIVE",
    	  "TEXT": "Text",
    	  "TEMPLATE": "Template",
    	  "TEMPLATE_SAVED": "Template Saved",
    	  "THE_PASSWORD_YOU_ARE_CREATING_MUST_BE_AT_LEAST": "Password you are creating must be at least ",
    	  "THE_PASSWORD_YOU_ARE_CREATING_MUST_FULFILL_THE_FOLLOWING_REQUIREMENTS": "The Password you are creating must fulfill the following requirements:",
    	  "THU": "Thu",
    	  "THUMBNAIL": "Thumbnail",
    	  "THUMBNAILS": "Thumbnails",
    	  "TITLE": "Title",
    	  "TIME_PICKER": "Time Picker",
    	  "TITLE_CANNOT_BE_EMPTY": "Title cannot be empty.",
    	  "TITLE_OF_SURVEY": "Title of Survey",
    	  "TO": "To",
    	  "TO_TIME": "To",
    	  "TODAY": "Today",
    	  "TOGGLE_SIDEBAR": "Toggle Sidebar",
    	  "TOOLTIP_DENY": "Overrides all permissions granted to the user as part of Group or TeamSpace membership.",
    	  "TOTAL": "Total",
    	  "TOTAL_UPPERCASE": "TOTAL",
    	  "TOTALS": "Totals",
    	  "TOUCH_ID_SETUP": "Touch ID Setup",
    	  "TRASH": "Trash",
    	  "TRACK_ATTENDANCE_UPPERCASE": "TRACK ATTENDANCE",
    	  "TRUE": "True",
    	  "TRUE_FALSE": "True/False",
    	  "TUE": "Tue",
    	  "TYPE": "Type",
    	  "TYPE_UPPERCASE": "TYPE",
    	  "TYPE_CUSTOM_FIELD_NAME": "Type Custom Field Name",
    	  "TYPE_OF_QUESTIONS": "Type of question",
    	  "TYPE_SUBJECT": "Type the subject here",
    	  "TYPE_TO_SEARCH_FOR_ATTENDEES": "Type to search for attendees",
    	  "TEMPLATES": "Templates",
    	  "UNABLE_PASSWORD_PLEASE_VERIFY": "We were unable to retrieve your password. Please verify the information entered is correct.",
    	  "UNABLE_TO_CHANGE_PWD": "Unable to change password. Please verify the data entered is correct.",
    	  "UNABLE_USERID_PLEASE_VERIFY": "We were unable to retrieve your User ID. Please verify the information entered is correct.",
    	  "UNHIDEFOLDER": "Unhide Folder",
    	  "UNIQUE_OPTIONS": "Enter a unique description for each choice",
    	  "UNLOCK_USER": "Unlock user.",
    	  "UN_SUPPORT_CERTIFICATE": "We were unable to support certificates on mobile devices",
    	  "UNPUBLISH": "Unpublish",
    	  "UNSIGNED": "Unsigned",
    	  "UPLOAD": "Upload",
    	  "UPLOAD_AGENDA_TEMPLATE": "Upload Agenda Template",
    	  "UPLOAD_FOLDER": "Upload Folder",
    	  "UPLOAD_FILE": "Upload File",
    	  "UPLOAD_LOGO": "Upload Logo",
    	  "UPLOAD_NEW_KEY": "Upload new key",
    	  "UPLOAD_NEW_LOGO": "Upload New Logo",
    	  "UPLOAD_PHOTO": "Upload Photo",
    	  "USAGE_POLICY": "Usage Policy",
    	  "USER": "User",
    	  "USERS": "Users",
    	  "USERS_UPPERCASE": "USERS",
    	  "APPLIED_USERS_UPPERCASE": "APPLIED USERS",
    	  "USERACTIVITY": "User Activity",
		  "PASSWORDEXPIRY":"Password Expiration Report",
    	  "USERCHANGES": "User Changes",
    	  "CHANGE_DATE_UPPERCASE": "CHANGE DATE",
    	  "ACTIVITY_TYPE": "Activity Type",
    	  "ALL_TEAMSPACES_SELECTED": "All Teamspaces Selected",
    	  "ALL_GROUPS_SELECTED": "All Groups Selected",
    	  "BOARD_TEAMSPACE_FILTER": "Board and Teamspace Filter",
    	  "SEARCH_BOARD_AND_TEAMSPACE": "Search Board and Teamspace",
    	  "ALL_BOARD_AND_TEAMSPACE_SELECTED": "All board and teamspace Selected",
    	  "USER_ID": "User ID",
    	  "USER_IMAGE": "User Image",
    	  "USER_REGISTRATION": "User Registration",
    	  "USER_REMOVED": " has been removed.",
    	  "USE_BRANDING_IMAGE": "Use Branding Image",
    	  "USE_COMMA_SEPARATE_EMAILS": "Use a comma to separate emails",
    	  "UPLOAD_DOWNLOAD_FOLDER": "Upload/Download Folder",
    	  "UPDATED": "Updated",
    	  "UPDATED_UPPERCASE": "UPDATED",
    	  "UPDATE_INDEX": "Update Index",
    	  "TYPE_TO_ADD_COMMENT": "Type to add a comment. Use '@' to tag other users",
    	  "VER": "Ver",
    	  "VERIFICATION_CODE": "Verification Code",
    	  "VERIFY": "Verify",
    	  "VERSION": "Version",
    	  "VERSION_UPPERCASE": "VERSION",
    	  "VERSION_ID": "Version ID",
    	  "VERSION_TRACKER": "Version Tracker",
    	  "VERSION_TRACKER_FOR": "Version Tracker For",
    	  "VIEW": "View",
    	  "VIEW_PROFILE": "View Profile",
    	  "VIEW_MY_PROFILE": "View My Profile",
    	  "VIEWER": "Viewer",
    	  "VIEWERS": "Viewers",
    	  "VIEWAS": "View As",
    	  "VIEW_AS_DOCUMENT_TITLE": "You are viewing this document as",
    	  "VIEW_AS_ACCESS_DENIED": "does not have permission to access this document",
    	  "VIEW_FULL_EVENT": "View Full Event",
    	  "VISIBLE_TO": "Visible to",
    	  "VIRUS_INFECTED": "Virus Infected",
    	  "VIEW_PREVIOUS_CCOMMENTS": "View Previous Comments",
    	  "WAITING_ON_PRESENTER": "Waiting on presenter.",
    	  "WED": "Wed",
    	  "WEEK": "Week",
    	  "WELCOME": "Welcome",
    	  "WHAT_IS_YOUR_FAVORITE_COLOR?": "What is your favorite color?",
    	  "WHAT_IS_YOUR_HOME_TOWN?": "What is your home town?",
    	  "WHAT_IS_YOUR_MOTHERS_NAME?": "What is your Mother's first name?",
    	  "WHAT_IS_YOUR_NICK_NAME?": "What is your nick name?",
    	  "WHAT_IS_YOUR_PETS_NAME?": "What is your pet's name?",
    	  "WHEN_DOCUMENT_PERMISSIONS_CHANGED": "When document permissions are changed ",
    	  "WHEN_NEW_VERSION_DOCUMENT_CREATED": "When a new version of the document is created.",
    	  "WORD_DOCUMENT": "MS Word Document",
    	  "WORK_PHONE": "Work Phone",
    	  "WOULD_LIKE_TO_INCLUDE": "Would like to include",
    	  "WOULD_YOU_LIKE_TO_CLOSE": "Would you like to close?",
    	  "WROTE": "Wrote",
    	  "WARNING": "Warning",
    	  "YEAR": "Year",
    	  "YEARLY": "Yearly",
    	  "YES": "Yes",
    	  "YES_NO": "Yes/No",
    	  "YESTERDAY": "Yesterday",
    	  "YOUR_NEW_SURVEY_HAS_NOT_BEEN_COPIED": "Your new survey has not been copied",
    	  "YOUR_PASSWORD_HAS_BEEN_EMAILED_TO_YOU": "Your Password has been emailed to you",
    	  "YOUR_PASSWORD_HAS_EXPIRED_PLEASE_ENTER_A_NEW_PASSWORD": "Your password has expired. Please change the password.",
    	  "YOUR_PASSWORD_IS_TEMPORARY_PLEASE_ENTER_A_NEW_PASSWORD": "Your password is temporary. Please enter a new password.",
    	  "YOUR_RESPONSE": "Your Response",
    	  "YOUR_USER_ID_HAS_BEEN_EMAILED_TO_YOU": "Your User ID has been emailed to you",
    	  "YOU_ARE": "You are",
    	  "YOU_ARE_FOLLOWING": "Now you are following ",
    	  "YOU_ARE_NOT_FOLLOWING": " Now you are not following ",
    	  "YOU_ARE_NOW_LOGGED_OFF": "You are now logged off",
    	  "YOU_HAVE_BEEN_RESTRICTED_FROM_USING_FORGOT_PASSWORD_FEATURE": "You have been restricted from using the \"Forgot Password\" feature.",
    	  "YOU_HAVE_REACHED_THE_MAXIMUM_OF_CHARACTERS": "You have reached the maximum of 100 characters",
    	  "YOU_HAVE_REACHED_THE_MAXIMUM_OF_5_KEYWORDS": "You have reached the maximum of 5 keywords",
    	  "YOU_HAVE_REACHED_THE_MAXIMUM_OF_5_EXCLUDED_KEYWORDS": "You have reached the maximum of 5 excluded keywords",
    	  "YOU_HAVE_REACHED_THE_MAXIMUM_OF_5_PHRASES": "You have reached the maximum of 5 phrases",
    	  "ZOOM": "Zoom",
    	  "ZOOM_IN": "Zoom In",
    	  "ZOOM_OUT": "Zoom Out",
		  "WORD": "Word",
    	  "EXCEL": "Excel",
    	  "MS_WORD": "MS Word",
    	  "MS_EXCEL": "MS Excel",
    	  "MS_POWERPOINT": "MS Powerpoint",
    	  "MSG_ALERT_CANCEL_ALERT_AFTER_SELECTION": "You have selected users. Are you sure you want to cancel?",
    	  "MSG_ALERT_MEETINGS_CANCEL_ALERT_AFTER_SELECTION": "You have selected users. Are you sure you want to cancel?",
    	  "MSG_ARE_YOU_SURE_TO_DELETE_THIS_MEETING": "Are you sure you want to delete this meeting?",
    	  "MSG_ARE_YOU_SURE_TO_MOVE_FILE_TO_TRASH": "Are you sure you want to delete the selected items?",
    	  "MST_ATLEAST_ONE_FILE_MUST_BE_ADDED_TO_CONTINUE": "At least one file must be added to continue.",
    	  "MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED": "Your changes will not be saved. Are you sure you want to cancel?",
    	  "MSG_CANCEL_EDIT_CHANGES_WILL_NOT_UPDATED": "Are you sure you want to cancel? Changes will not be updated.",
    	  "MSG_COUNTRY_NAME_AND_PHONE_PREFIX": "Countries List and Phone Number prefix",
    	  "MSG_DOWNLOAD_AS_USER": "You are downloading this document as \\{\\{n\\}\\}",
    	  "MSG_EDIT_MEETINGS_CHANGES_NO_CHANGES": "No changes made.",
    	  "MSG_EDIT_MEETINGS_CONFIRM_CHANGES_ON_TAB_CHANGE": "Changes have been made. Would you like to save?",
    	  "MSG_ENCR_KEY_SUCCESSFULLY_UPLOADED": "Key was successfully uploaded!",
    	  "MSG_EXIT_VEDIT": "Your changes will not be saved. Are you sure you want to exit?",
    	  "MSG_FILE_MUST_BE_SELECTED_TO_CONTINUE": "A file must be selected to continue.",
    	  "MSG_INPUT_CONTAINS_INVALID_CHARS": "The input contains invalid characters.",
    	  "MSG_NO_MEETING_ATTACHMENTS": "This meeting has no attachments.",
    	  "MSG_NO_MEETINGS": "No meetings have been scheduled.",
    	  "MSG_NO_SECTIONS": "No sections to show",
    	  "MSG_NO_NEWITEMS": "No items have been added to this module.",
    	  "MSG_NO_BRIEFCASE": "No documents have been added",
    	  "MSG_COMMON_NO_DOCUMENTS": "No documents have been added",
    	  "MSG_NO_APPROVALS": "No action is required in any of your teamspaces.",
    	  "MSG_NO_DOCUMENTS": "No documents have been uploaded to this module.",
    	  "MSG_NO_DOCUMENTS_IN_FOLDER": "No documents are available in this folder.",
    	  "MSG_NO_INBOX_MESSAGES": "There are currently no messages in your Inbox.",
    	  "MSG_NO_SENT_MESSAGES": "There are currently no messages in your Sent.",
    	  "MSG_NO_SIGNATURES": "No signatures required.",
    	  "MSG_NO_SURVEYS": "No action is required.",
    	  "MSG_SELECT_FILE_TO_REPLACE_DOCUMENT": "Select a file to replace document.",
    	  "MSG_SELECT_FILE_TO_CHECKIN_DOCUMENT": "Select a file to checkin document.",
    	  "MSG_TO_SEND_ALERT_SELECT_USER": "To send an alert, at least one user must be selected.",
    	  "MAXIMUM_MARGIN_FOR_LOGO_IS": "Maximum margin for logo is",
    	  "MSG_TO_CONTINUE_USE": "To continue, use one of the following browsers:",
    	  "MSG_TO_UPGRADE_BROWSER": "To continue, upgrade to one of the following versions:",
    	  "MSG_TO_UPGRADE_JAVA": "Your Java version does not meet the minimum required version which is used by this application.",
    	  "MSG_TO_CONTINUE_INSTALL_JAVA": "To continue, upgrade to latest Java version.",
    	  "MSG_UNSUPPORTED_CHROME_VERSION": "Chrome Version 42 and above will not support Java, which is used in this application for the secure storage of certificates.",
    	  "MSG_UNSUPPORTED_FIREFOX_VERSION": "Firefox Version 52 and above will not support Java, which is used in this application for the secure storage of certificates.",
    	  "MSG_UNSUPPORTED_BROWSER_VERSION": "Your browser version does not support the latest HTML5 standards which are in use by this application.",
    	  "MSG_UNSUPPORTED_BROWSER_TYPE_OR_VERSION": "Your browser or version does not meet the minimum required version used by this application.",
    	  "MSG_DELETE_INBOX_CONFIRMATION": "Are you sure you want to delete this message?",
    	  "MSG_DELETE_PUSH_CONFIRMATION": "This push will be pulled back, are you sure you want to delete this push massage?",
    	  "MSG_DELETE_PUSH_BY_RECIPIENT_CONFIRMATION": "Are you sure you want to delete this push massage?",
    	  "MSG_MARK_ALL_READ": "Are you sure you want to mark all messages as read?",
    	  "MSG_ADD_TO_NEWITEM_INCLUDE_FOLDER": "Folders are not supported in Briefcase and New Items. Do you want to continue?",
    	  "MSG_DAYS_NOT_ENTERED": "Number of days must be entered to continue.",
    	  "MSG_DAYS_GREATER_THAN_RENTENTION": "Number of days for this rule can’t be greater than Retention Policy of documents",
    	  "MSG_NO_ATTACHMENT_AND_USER": "At least one file and one user must be added to continue.",
    	  "MSG_NO_USER": "At least one user must be added to continue.",
    	  "MSG_NO_ATTACHMENT": "At least one file must be added to continue.",
    	  "MSG_COMMIT_SIGNATURE": "Select Commit and Close to finalize signatures.",
    	  "MSG_CANCEL_SIGNATURE": "Select Cancel to continue viewing document.",
    	  "MSG_FILE_SIZE": "Following file exceeds the upload limit of \\{\\{n\\}\\} MB.",
    	  "MSG_FILES_SIZE": "The following files exceeds the upload limit of \\{\\{n\\}\\} MB.",
    	  "MSG_INVALID_FILE": "File format not supported.",
    	  "MSG_INVALID_FILE_TYPE": "File type not supported.",
    	  "MSG_DOC_NOT_FOUND_IN_BRIEFCASE": "Documents not found in any Briefcase.",
    	  "MSG_REMOVE_SUCCESSFUL": "Removed successfully.",
    	  "MSG_FILES_NOT_UPLOADED": "Following files not uploaded",
    	  "MSG_PRESENTATION_LEAVE": "Are you sure you want to leave this meeting?",
    	  "MSG_PRESENTATION_MODE_LINK_CLICK": "External Links are not accessible during presentation mode.",
    	  "MSG_PRINT_AS_USER": "You are printing this document as \\{\\{n\\}\\}",
    	  "MSG_PURGE_BRIEFCASE_CHECK": "Are you sure you want to purge?",
    	  "MSG_MIN_SINGLE_SECTION_REQ": "A published survey must contain at least one section having a question.",
    	  "MSG_MIN_SINGLE_QUESTION_REQ": "A published survey must contain at least one question in each section.",
    	  "MSG_SAME_DOCUMENT_CANNOT_ADDED_CONSECUTIVELY": "Same document cannot be added consecutively",
    	  "MSG_SAVE_AGENDA_LINKS": "Are you sure you want save the links and close the dialog?",
    	  "MSG_VIRTUAL_EDIT_CONNECTION_NOT_AVAILABLE": "Connection not available. Please try again in a few minutes.",
    	  "MSG_VIRTUAL_EDIT_INTERNAL_ERROR": "Internal error, please try again.",
    	  "MSG_ADD_PUSHES_TO_ANNOTATION_RECIPIENTS": "Do you want to push this document to annotation users?",
    	  "MSG_WOULD_YOU_LIKE_TO_SAVE_YOUR_CHANGES_BEFORE_CLOSING?": "Would you like to save your changes before closing?",
    	  "MSG_A_SECTION_MUST_HAVE_1_QUESTION_TO_CONTINUE": "A section must have 1 question to continue.",
    	  "MSG_THIS_FEATURE_IS_ONLY_AVAILABLE_ONLINE": "This feature is only available online",
    	  "MSG_COMPOSE_MESSAGE_CONTENT_EXCEED_LIMIT": "Character limit of 20,000 has been reached.",
    	  "MSG_THE_CHARACTERS_ARE_NOT_SUPPORTED": "The characters < and > are not supported.",
    	  "MSG_YOU_ARE_REPLACING_AN_EXISTING_DOCUMENT": "You are replacing an existing document which has been annotated by one or more users.",
    	  "MSG_BEFORE_REPLACING_THIS_DOCUMENT": "Before replacing this document, how would you like to handle previous annotations?",
    	  "MSG_KEEP_ANNOTATIONS_ON_EXISTING_DOCUMENT": "Keep annotations on existing document",
    	  "MSG_MOVE_ANNOTATIONS_TO_UPDATED_DOCUMENT": "Move annotations to updated document",
    	  "MSG_THE_EXISTING_DOCUMENT_WILL_BE_RETAINED": "The existing document will be retained for users who have annotations. They can switch between the existing version and the updated version you are in the proccess of uploading.",
    	  "MSG_ANNOTATIONS_WILL_BE_MOVED": "Annotations will be moved from the existing document to the one you are in the process of uploading and then the existing document will be deleted.",
    	  "MSG_ANNOTATIONS_MADE_ON_THE_EXISTING": "Annotations made on the existing document will be purged.",
    	  "MSG_ANNOTATIONS_MAY_APPEAR_OUT_OF_CONTEXT": "annotations may appear out of context if the document has changed substantially.",
    	  "MSG_ANNOTATIONS_YOU_HAVE_OTHER_ANNOTATED": "You have other annotated versions of this document.",
    	  "MSG_ANNOTATIONS_YOU_ARE_CURRENTLY_VIEWING": "You are currently viewing an older annotated version of this document.",
    	  "MSG_ANNOTATIONS_VERSIONING_DOCS_MESSAGE": "You don't have previously annotated versions of this document.",
    	  "ERRASER": "Erraser",
    	  "ERROR": "Error",
    	  "ERR_ACCESS_RESTRICTED_TO_USERS": "Access is restricted to authorized users only.",
    	  "ERR_ACCOUNT_DATA_PURGED_AFTER_ATTEMPTS_MSG": "You have \\{\\{n\\}\\} login attempts remaining before account data is purged on this device",
    	  "ERR_ALL_FIELDS_ARE_MENDATORY": "All fields are mandatory",
    	  "ERR_APPROVAL_TITLE_CANNOT_BE_BLANK": "Approval title cannot be blank",
    	  "ERR_BLUETOOTH_DEVICES_NOT_AVAILABLE_FOR_PAIRING": "Bluetooth devices are not available for pairing.",
    	  "ERR_CONVERTING_DOC_CANNOT_BE_SPERATOR": "The selected document has not finished converting.",
    	  "ERR_CQ_SHOULD_NOT_BE_SAME": "Identical questions are not allowed.",
    	  "ERR_DIFFERENT_PASSWORD": "Password and Confirm password do not match.",
		  "ERR_DOCUMENT_ACCESS_DENIED": "Document access denied.",
		  "ERR_FOLDER_ACCESS_DENIED": "Folder access denied.",
    	  "ERR_DOC_CANNOT_BE_SEPRATOR": "Selected document can not be added as separator.",
    	  "ERR_DUPLICATE_ENTRIES_SURVEY": "Duplicate Entry for \\{\\{a\\}\\} values : \\{\\{b\\}\\}",
    	  "ERR_EDIT_MEETINGS_MEETING_NOT_SELECTED": "Meeting not selected",
    	  "ERR_EMAIL_EXISTS": "Email already in use.",
    	  "ERR_ENCR_DURING_KEY_UPLOAD": "Error during key upload!, Got invalid key exception from server!",
    	  "ERR_ENCR_DURING_ARCHIVE": "Error during archive.",
    	  "ERR_ENCR_DURING_PUBLISH": "Error during publish.",
    	  "ERR_ENCR_KEY_CANNOT_BE_NULL_OR_EMPTY": "Key cannot be null or empty",
    	  "ERR_ENCR_MASTER_KEY_IS_NOT_SET_FOR_THE_COMPANY": "Company master key is not set for the company",
    	  "ERR_ENCR_UNABLE_TO_LOGIN_CONTACT_ADMINISTRATOR": "Unable to login. Please contact your company administrator.: ERROR_CMK_1001",
    	  "ERR_EVENT_PARTICIPANTS_NOT_SELECTED": "Participants not selected.",
    	  "ERR_FAILED_LOCKED_AND_TRY_AFTER_MSG": "After \\{\\{n\\}\\} failed attempts your account was locked. Please try after \\{\\{m\\}\\} minutes.",
    	  "ERR_GET_MY_PASSWORD_PREFERENCES": "Could not retrieve password preferences.",
    	  "ERR_INCORRECT_ANSWER": "Incorrect Answer.",
    	  "ERR_INTERNAL_ERROR": "An internal error has occurred.",
    	  "ERR_INVALID_CREDENTIALS": "Invalid user id or password.",
    	  "ERR_INVALID_OR_EXPIRED_REQUEST": "Invalid or Expired request",
    	  "ERR_INVALID_PIN_CREDENTIALS": "Invalid pin.",
    	  "ERR_INVALID_REQUEST": "Invalid Request",
    	  "ERR_LACK_OF_PREVILEGE_TO_MOVE_TO_TRASH": "Lack of privileges for moving files to Recycle Bin",
    	  "ERR_LOGIN_ID_EXISTS": "Login ID already in use.",
    	  "ERR_LOGIN_MSG_REFRESH_BROWSER_AND_LOGIN": "Please refresh your browser and re-login",
    	  "ERR_LONG_PASSWORD": "Your new password must be at least 8 characters long.",
    	  "ERR_MIN_REQUIRED_CQ_ANSWER_LEGNTH_IS": "Minimum required challenge question answer length is ",
    	  "ERR_MIN_REQUIRED_PWD_LEGNTH_IS": "Minimum required password length is ",
    	  "ERR_MINIMUM_TWO_APPROVALS_CHOICES_NEEDED": "Minimum two approvals choices needed",
    	  "ERR_MODULE_SEETINGS_NO_MODULES_AVAILABLE": "No modules available.",
    	  "ERR_MODULE_SETTINGS_CHANGES_SAVED_SUCCESSFULLY": "Changes saved successfully.",
    	  "ERR_MODULE_SETTINGS_DEFAULT_JUP_LANDING_MODULE_NOT_VISIBLE_FOR_ALL": "Mobile landing module must be visible to all.",
    	  "ERR_MODULE_SETTINGS_DEFAULT_LANDING_MODULE_NOT_VISIBLE_FOR_ALL": "Landing module must be visible to all.",
    	  "ERR_MODULE_SETTINGS_ERROR_IN_SAVING_MODULE_SETTINGS": "Error in saving module settings. Details",
    	  "ERR_MODULE_SETTINGS_NO_CHANGES_MADE_TO_MODULE_SETTING": "No changes made to module settings.",
    	  "ERR_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH": "Password and Confirm Passsword do not match",
    "ERR_ANSWERS_ARE_REQUIRED_FOR_ALL_CHALLENGE_QUESTIONS"  :  "Answers are required for all challenge questions.",
    	  "ERR_PLEASE_ENTER_FIRST_NAME_LAST_NAME_LOGINID_PASSWORD_CONFIRM_PASSWORD_AND_EMAIL_TO_CONTINUE": "Please enter First Name, Last Name, Login ID, Password, Confirm Password and Email to continue",
    	  "ERR_PRESENTATION_CLOSED_BY_HOST": "Presentation closed by host.",
    	  "ERR_PRESENTATION_EVENT_DETIALS_NOT_AVAILABLE": "Event details are not available.",
    	  "ERR_PRESENTATION_NOT_GOING_ON_CURRENTLY": "Presentation not going on currently.",
    	  "ERR_REQUIRED_FIELD": "Fields cannot be empty.",
    	  "ERR_RESPONSE_NOT_SELECTED": "Please select response.",
    	  "ERR_ROUTE_CHANGE": "Route change error",
    	  "ERR_SIGNATURE_REQUIRED": "Signature is required.",
    	  "ERR_SKIPPED_DOC_IN_COLLATE": "The following documents have not been included because the are password protected or unconverted.",
    	  "ERR_SMS_VERIFICATION_CODE_IS_INVALID": "SMS verification code is invalid.",
    	  "ERR_SURVEY_TITLE_CANNOT_BE_BLANK": "Survey title cannot be blank",
    	  "ERR_SYSTEM_ADMIN_LOGIN": "The authentication code entered was invalid. Please re-enter the code and click Proceed. [Attempt '\\{\\{attemptCount\\}\\}' of 3]",
    	  "ERR_THIS_FIELD_IS_REQUIRED": "This field is required.",
    	  "ERR_UNAUTHORIZED_TO_PROSECUTION": "Unauthorized users may be subject to prosecution.",
    	  "ERR_UPLOARD_EXISTING_FILE_NAME_ALERT": "A file with the same name already exists. Click Continue to replace the original file.",
    	  "ERR_USERS_NOT_AVAILABLE_FOR_SELECTION": "Users are not available for selection.",
    	  "ERR_USERS_NOT_AVAILABLE_IN_PAIRED_DEVICE": "Users are not available in paired bluetooth device.",
    	  "ERR_USERS_NOT_SELECTED_FOR_PRESENTATION": "Users not selected for presentation.",
    	  "ERR_USER_ID_ALREADY_EXISTS": "User id already exists",
    	  "ERR_USER_ALREADY_EXISTS": "User already exists",
    	  "ERROR_UPLOADING_LOGO": "Error Uploading Logo",
    	  "ERR_NO_ACTIVE_TEAMSPACE_ALLOCATED": "No active teamspace allocated",
    	  "ERR_ACCOUNT_HAS_BEEN_LOCKED": "Your account has been locked.",
    	  "ERR_SMS_CODE_INVALID": "SMS verification code is invalid.",
    	  "ERR_SMS_ENABLED_CONTACT_ADMIN": "SMS authentication enabled. Contact your administrator to verify your mobile number and country code.",
    	  "ERR_YOUR_ACCOUNT_LOCKED_ADMIN_AUTHENTICATION": "Your account has been locked. Contact BV Support to unlock your account.",
    	  "ERR_YOU_DO_NOT_HAVE_PERMISSIONS_ON": "You do not have permissions on",
    	  "ERR_OTHER_LOGIN_ERROR": "Login error. Contact your Company Administrator.",
    	  "ERR_REGISTERING_DEVICE": "Error in registering the device",
    	  "ERR_FAILED_TO_RETRIEVE_INSTALLED_CERTIFICATE": "Failed to retrieve the installed certificate",
    	  "EXCEL_DOCUMENT": "MS Excel Document",
    	  "EXCLUDE": "Exclude",
    	  "EXCLUDE_FILES_CONTAINING_THESE_WORDS": "Exclude files containing these words",
    	  "EXPAND": "Expand",
    	  "ERR_INVALID_PASSWORD_CHARS": "'<' and '>' characters are invalid in password.",
    	  "January": "January",
    	  "February": "February",
    	  "March": "March",
    	  "April": "April",
    	  "June": "June",
    	  "July": "July",
    	  "August": "August",
    	  "September": "September",
    	  "October": "October",
    	  "November": "November",
    	  "December": "December",
    	  "Jan": "Jan",
    	  "Feb": "Feb",
    	  "Mar": "Mar",
    	  "Apr": "Apr",
    	  "May": "May",
    	  "Jun": "Jun",
    	  "Jul": "Jul",
    	  "Aug": "Aug",
    	  "Sep": "Sep",
    	  "Oct": "Oct",
    	  "Nov": "Nov",
    	  "Dec": "Dec",
    	  "SEND_MESSAGE": "Send Message",
    	  "SEARCH_TO_REFINE": "Search to refine",
    	  "Add_Task": "Add Task",
    	  "Add_Project": "Add Project",
    	  "ADD_TAGS": "Add Tags",
		  "TAGS": "Tags",
    	  "REPLY_TO_MESSAGE": "Reply to Message",
    	  "FILE_VIEWED_ACTIVITY": "File Viewed",
    	  "FILE_PRINTED": "File Printed",
    	  "FILE_DOWNLOADED": "File Download",
    	  "FILE_EDITED": "File Edited",
    	  "FILE_UPLOADED": "File Uploaded",
    	  "FILE_DELETED": "File Deleted",
    	  "FILE_EMAILED": "File Emailed",
    	  "FILE_MOVED": "File Moved",
    	  "FILE_COPIED": "File Copied",
    	  "FILE_RENAMED": "File Renamed",
    	  "FOLDER_CREATED": "Folder Create",
    	  "FOLDER_DELETED": "Folder Delete",
    	  "FOLDER_MOVED": "Folder Moved",
    	  "FOLDER_COPIED": "Folder Copied",
    	  "FOLDER_RENAMED": "Folder Renamed",
		  "FILE_PDF_DOWNLOADED":"File PDF Downloaded",
    	  "OUT_OF": "Out of",
    	  "SHOWING_RESULTS": "Showing Results:",
    	  "AGO": "ago",
    	  "FEW_SECONDS_AGO": "Few seconds ago",
    	  "SEPERATOR_PAGE": "SEPERATOR PAGE",
    	  "DOWNLOAD_REPORT_MSG": "To view more results Download Report.",
    	  "USERACTIVITY_DESCRIPTION": "The User Activity report is used to audit all current users including their Login history, basic contact information, groups, and date users are added to the platform.",
    	  "PASSWORDEXPIRY_DESCRIPTION": "The password expiration report will allow you to see users within a TeamSpace, or Group and when their passwords will expire.",
    	  "MEETINGLIST_DESCRIPTION": "The Meeting List report contains a listing of all meetings and events, including all descriptive fields for these items.",
    	  "DOCUMENTLIST_DESCRIPTION": "The Document List report details the hierarchical list of folders and documents in your document centers with details such as files size, author, last modified, and more.",
    	  "USERCHANGES_DESCRIPTION": "The User Changes report allow you to audit all user access changes that have been made by account administrators",
    	  "DOCUMENTACTIVITY_DESCRIPTION": "The Document Activity report used to monitor the types of actions that have taken place on all documents such as, viewed, printed, saved, renamed, etc",
    	  "RETENTIONPOLICIES_DESCRIPTION": "The Retention Policies audit report allows you to track all changes made to retention policies including enabling & disabling the main module as well as changes to individual retention policy rules.",
    	  "MEMBERATTENDANCETRACKING_DESCRIPTION": "The Attendance Tracking system allows Meeting Owners to maintain a record of who attended every meeting, as well as create exportable reports about attendance. Member Attendance Tracking report will allow you to audit the attendance on an individual level.",
    	  "GROUPATTENDANCETRACKING_DESCRIPTION": "The Attendance Tracking system allows Meeting Owners to maintain a record of who attended every meeting, as well as create exportable reports about attendance. Group Attendance Tracking report will allow you to audit the attendance on a group or TeamSpace level",
    	  "DOCUMENTPERMISSIONS_DESCRIPTION": "The Document Permissions report allows you to audit and see right for all users and groups for document",
    	  "PRIMARY_EMAIL_ADDRESS": "Primary Email Address",
    	  "DEVICE_TYPE": "Device Type",
    	  "LOGIN_TIME": "Login Time",
    	  "LOGOUT_TIME": "Logout Time",
    	  "LOGIN_DATE": "Login Date",
    	  "FAILURE_REASON": "Failure Reason",
    	  "LICENSE_STATUS": "License Status",
    	  "PRIMARY_PHONE_NUMBER": "Primary Phone Number",
    	  "MEETING_LIST_EXCEL_REPORT_HEADER": "Attendee First and Last Name",
    	  "LAST_EDITED_BY": "Last Edited By",
    	  "DOC_SIZE": "Doc Size (mb)",
    	  "POSTED_BY": "Posted By",
    	  "VERSION_NUMBER": "Version Number",
    	  "IMPACTED_USERS_FIRST_NAME": "Impacted User's First Name",
    	  "IMPACTED_USERS_LAST_NAME": "Impacted User's Last Name",
    	  "ADDITIONAL_INFORMATION": "Additional Information",
    	  "DATE_TIME": "Date & Time",
    	  "USERS_FIRST_NAME": "User's First Name",
    	  "USERS_LAST_NAME": "User's Last Name",
    	  "SAVE_AS_TEMPLATE": "Save as template",
    	  "NO_ATTACHMENT_AVAILABLE_MESSAGE": "There are no attachments available",
    	  "INPUT_BANKID_AUTH_CODE": "Please enter your Personal ID Number (12 digits). Then open the BankID app to complete the authentication and submit your signature.",
    	  "LABEL_BANKID_NAVIGATION_MSG": "Please proceed to your BankID app to enter the security code in order for the signature to be submitted.",
    	  "ATTENDANCE_PRESENT":"Present",
    	  "ATTENDANCE_NOT_PRESENT":"Not Present",
    	  "ATTENDANCE_EXCUSE":"Excused",
    	  "SIGNATURES_COMPLETED":"Signatures Completed",
		  "COLLATE_UNSUPPORTED_FILE_ERROR":"This file type is not supported in Boardbooks or Collated Documents",
    	  "DASHBOARD": "Dashboard",
		  "DASHBOARD_UPPERCASE": "DASHBOARD",
		  "WHOLE_WORDS": "Whole words",
		  "DOCUMENT_HAS_BEEN_REMOVED": "Document has been removed",
		  "FOLDER_HAS_BEEN_REMOVED": "Folder has been removed",
		  "YOU_DO_NOT_HAVE_PERMISSION": "You do not have sufficient permissions to upload. Please contact your administrator",
		  "DISCUSSION": "Discussion",
		  "PRESENTATION": "Presentation",
		  "STANDING": "Standing",
		  "VOTE": "Vote",
		  "DASHBOARDP2": "Dashboard P2",
		  "DASHBOARDP2_UPPERCASE": "DASHBOARD P2",
		  "USER_ADDED":"User Added",
		  "USER_ACTIVATED":"User Activated",
		  "USER_DEACTIVATED":"User Deactivated",
		  "USER_LOCKED":"User Locked",
    	  "USER_UNLOCKED":"User Unlocked",
    	  "USER_GROUP_CHANGED":"User Group Changed",
    	  "USER_TEAMSPACE_CHANGED":"User Teamspace Changed",
    	  "FILE_VIEWED":"File Viewed",
    	  "FILE_PRINTED":"File Printed",
    	  "FILE_DOWNLOADED":"File Downloaded",
    	  "FILE_EDITED":"File Edited",
    	  "FILE_UPLOADED":"File Uploaded",
    	  "FILE_DELETED":"File Deleted",
    	  "FILE_EMAILED":"File Emailed",
    	  "FILE_MOVED":"File Moved",
    	  "FILE_COPIED":"File Copied",
    	  "FILE_RENAMED":"File Renamed",
    	  "FOLDER_CREATED":"Folder Created",
    	  "FOLDER_DELETED":"Folder Deleted",
    	  "FOLDER_MOVED":"Folder Moved",
    	  "FOLDER_COPIED":"Folder Copied",
		  "FOLDER_RENAMED":"Folder Renamed",
		  "RETENTIONPOLICIES":"Retention Policy",
		  "MEMBERATTENDANCETRACKING":"Member Attendance Tracking",
		  "GROUPATTENDANCETRACKING":"Group Attendance Tracking",
		  "USER_ACTIVITY": "User Activity",
		  "PASSWORD_EXPIRY": "Password Expiration Report",
		  "MEETING_LIST": "Meeting List",
		  "DOCUMENT_LIST": "Document List",
		  "USER_CHANGES": "User Changes",
		  "DOCUMENT_ACTIVITY": "Document Activity",
		  "DOCUMENT_PERMISSIONS": "Document Permissions",
          "USERBRIEFCASEREPORT": "User Briefcase Report",
          "USERBRIEFCASEREPORT_DESCRIPTION": "The User Briefcase report will allow you to pull a list of files in a users briefcase and the total size of the combined files.",
          "FILE_NAME_UPPERCASE":"FILE NAME",
          "FILE_SIZE_UPPERCASE":"FILE SIZE",
		  "INVALID_PDF_ERROR_MESSAGE":"The following PDF file is incompatible with the Boardbook. Please flatten or Print-to-PDF the document",
          "ACTIVE_USER_SESSION_ERROR":"No Active User Session",
          "SYNCEDINFORMATIONREPORT": "Synced Information Report",
          "SYNCEDINFORMATIONREPORT_DESCRIPTION": "The Synced Information Report will give you a list of all users within the company and the size of the synced content within the app on their devices.",
          "CONTENT_SIZE":"CONTENT SIZE",
          "BOARDBOOK_SAVE_ERROR": "Error occurred while saving,Collate/Boardbook is not saved. Please try again.",
	      "CREATED":"Created",
          "REPLACED":"Replaced",
	      "MODIFIED_PERMISSIONS":"Modified Permissions",
	      "MOVED":"Moved",
		  "VIEWED":"Viewed",
		  "PREFERENCES_LINK_ACCOUNT":"Link Accounts",
		  "SAVE_AGENDA_MSG":"There was a problem viewing this document. Please have the meeting owner Save the Agenda again in order to proceed",
		  "AGENDA_DOCS_EMPTY_MSG":"There are no attachments added to digital agenda",
		  "ENGAUGE_QUESTIONNAIRE":"EnGauge Questionnaire",
		  "ENGAUGE":"EnGauge",
		  "SENT_TO_NASDAQ": "Sent to Nasdaq",
		  "VIRTUAL_MEETING_CONFIGURATION": "Virtual Meeting Configuration",
		  "ERR_SKIPPED_DOC_IN_COLLATE_NEW": "The following documents have not been included as:",
		  "PASSWORD_PROTECTED_TEXT":"It could be password protected",
		  "NON_COMPLAINT_TEXT":"It is non-complaint PDF document" ,
		  "CONVERSION_PROGRESS_TEXT" :"The document conversion is in progress",
		  "DOCUMENT_LIST_HEADER": "Document List",
		  "PASSWORD_PROTECTED_LABEL":"Password Protected",
		  "NON_COMPLAINT_LABEL":"Non Compliant PDF" ,
		  "CONVERSION_PROGRESS_LABEL" :"Converting",
		  "DOCUMENT_LIST_TEXT":"Document List:",
		  "THIS_SURVEY_HAS_BEEN_CLOSED_BY_THE_OWNER.FURTHER_DETAILS_ARE_AVAILABLE_IN_THE_SURVEY_MODULE.":"This survey has been closed by the owner. Further details are available in the Survey module.",
		  "THIS_SURVEY_HAS_BEEN_LOCKED":"This survey has been locked by the owner. Further details are available in the Survey module.",
		  "PENDING_SIGNATURE_COMMIT_ALERT": "Your Signature is awaited for",
		"ENTER_NEW_PRIMARY_EMAIL":"Enter new primary email",
		"TOKEN":"Token",
		"LOGIN_PASSWORD":"Login password",
		"SAVING_NEW_EMAIL_FAILED": "Saving new email failed. Try later.",
		"SAVING_NEW_EMAIL_SUCCESS": "New email is saved successfully",
		"FAILED_TO_SEND_VERIFICATION_TOKEN": "Failed to send email verification token",
		"EMAIL_SENT_MESSAGE":"Token has been sent to ",
		"NEW_EMAIL_SAME_AS_EXISTING": "New email is same as existing email",
		"TOKEN_AND_PASSWORD_CANNOT_BE_EMPTY":"Token and password cannot be empty",
		"SIGN_IN_PROGRESS":"Signing in progress please try later",
		"MATERIALS_UPPERCASE":"MATERIALS",
        "MORE_DETAIL":"MORE DETAIL",
        "REMOVE_FROM_COLLATE":"Remove from collate",
        "NON_COMPLAINT_ERROR_HEADING":"Some documents need manual conversion to be compatible with the boardbook compilation process.",
        "NON_COMPLAINT_ERROR_BRIEF":"The documents below are not able to be automatically converted to a format that is compatible with the boardbook creation process and will need to be manually converted. Following that conversion interactive elements such as annotations, hyperlinks, videos or form elements will not be functional in the boardbook. To convert the document, open the document in any pdf viewer and create a new document using the 'Print to PDF' option. The file can then be used for boardbook creation.",
        "NON_MERGE_ERROR_HEADING":"Some documents will be converted to be compatible with the board book compilation process.",
        "NON_MERGE_ERROR_BRIEF":"The documents below will be automatically converted to a format that is compatible with the boardbook creation process. Following the conversion process, certain interactive elements such as annotations, hyperlinks, videos or form elements will not be functional in the boardbook. There will be no changes on the original document.",
		"MULTI_FACTOR_AUTHENTICATION":"Multi-Factor Authentication",
		"DD_MIGRATED_SIGNED_DOC_SIGN_MSG": "Signature well cannot be placed on a document migrated from Director's Desk.",
		"DD_MIGRATED_SIGNED_DOC_INIT_MSG": "Initial well cannot be placed on a document migrated from Director's Desk.",
		"SIGNATURE_ALERT_SUBJECT":"Please respond to the Signature request",
		"SIGNATURE_ALERT_COMMENTS":"Your signature is awaited on the document",
		"FOLDERPERMISSIONS":"Folder Permissions",
		"FOLDERPERMISSIONS_DESCRIPTION": "The Folder Permission report allows you to audit and see the permissions of all folders",
		"FOLDERPERMISSIONS_USER":"USER/TEAMSPACE",
		"FOLDERPERMISSIONS_NAME":"FOLDER NAME",	
		"VIRUS_SCAN_FAILURE":"Virus Scan Failure" ,
		"SCANENGINE_CONNECTION_FAILURE":"Scanengine Connection Failure",
		"UPLOAD_ENCRYPTION_KEY":"Upload encryption key",
		"COOKIE_CONSENT_MSG":"We use necessary cookies to ensure that we give you the best experience on our website. If you continue to use this website we assume you accept this use.",

		"USER_DETAILS": "User Details",
		"PROFILE_LAST_UPDATED": "Profile Last Updated",
		"LAST_LOGIN": "Last Login",
		"USERDETAILS": "User Details",
		"USERDETAILS_DESCRIPTION": "User profile information for all users.",
		"DOWNLOAD_XLS": "XLS",
		"DOWNLOAD_CSV" :"CSV",
		"DOWNLOAD_OPIONS": "Download Options",
		"ALERT_MESSAGE_ANNOTATED_DOCUMENT_EDIT": "This document has already been annotated by one or more users. Do you want to proceed?" 

    });
