function configApprovalsController($stateProvider, $locationProvider){
	$stateProvider
		.state('home.approval', {
			url: '/approval',
			//templateUrl : 'modules/home/htmlStructure/Container.tpl.html',
			//controller  : 'ApprovalsListController',
			views: {
			      'content': {
			    	  templateUrl: 'modules/approvals/Approval.tpl.html',
			    	  controller  : 'ApprovalDetailsController'
			      }
			}
		})
		.state('home.createApproval', {
			url : '/approvals/createApproval',
			views: {
			      'content': {
			    	  templateUrl: 'modules/approvals/CreateApproval.tpl.html',
			    	  controller  : 'CreateApprovalController'
			      }
			}
		})
		.state('home.createNewApproval', {
			url : '/approvals/createApproval',
			views: {
			      'content': {
			    	  templateUrl: 'modules/approvals/CreateNewApproval.tpl.html',
			    	  controller  : 'CreateNewApprovalController'
			      }
			},
			params: {
				'objectBaseUuid': null,
				'approvalApiData': null
			}
		})
		.state('home.newApprovalDetails', {
			url : '/approvals/approvalDetails',
			views: {
			      'content': {
			    	  templateUrl: 'modules/approvals/ApprovalDetails.tpl.html',
			    	  controller  : 'NewApprovalDetailsController'
			      }
			},
			params: {
				'objectBaseUuid': null,
				'approvalApiData': null
			}
		})
}

ApprovalsListController.$inject = ['$scope', '$state', '$timeout', '$stateParams', 'approvalsService', 'localizedMessages', 'context', '$modal', 'imageService', 'commonService', 'SortUtility', 'bvDialog', 'bvDialogService','contextMenuModel', 'approvalAlertService', '$filter', 'legacyUserService'];

function ApprovalsListController($scope, $state, $timeout, $stateParams, approvalsService, localizedMessages, context, modal, imageService, commonService, SortUtility, bvDialog, bvDialogService, contextMenuModel, approvalAlertService, $filter, legacyUserService) {

	$scope.middleBarObject.title = StringUtils.capitaliseFirstLetter($state.$current.name.split("home.")[1]);
	$scope.$parent.currentState.value = $state.$current.name;
	$scope.middleBarObject.showBack = false;
	$scope.showCreateApprovalDialog = false;
	$scope.showTitleError = false;

	$scope.arrowIcon = 'resources/assets/svgs/grey_arrow_meeting_in_progress.svg';
	$scope.svgNotStarted = 'resources/assets/svgs/survey_not_started_icon.svg';
	$scope.svgInProgress = 'resources/assets/svgs/survey_in_progress_icon.svg';
	$scope.svgComplete = 'resources/assets/svgs/survey_complete_icon.svg';
	$scope.pageSize="25";
	$scope.openApprovalsLastPage=false;
    $scope.closedApprovalsLastPage=false;
    $scope.ownedApprovalsLastPage=false;
    $scope.isClosedMoreItems=false;
    $scope.isOpenMoreItems=false;
    $scope.isOwnedMoreItems=false;
    $scope.sortColumn='';
    $scope.isStatusClicked=false;

	$scope.ui = {
        label : {
            title : localizedMessages.get('TITLE'),
            dueDate : localizedMessages.get('DUE_DATE'),
            no : localizedMessages.get('NO'),
            priority : localizedMessages.get('PRIORITY'),
            high : localizedMessages.get('HIGH'),
            normal : localizedMessages.get('NORMAL'),
            low : localizedMessages.get('LOW'),
            signatureRequired : localizedMessages.get('SIGNATURE_REQUIRED'),
            instructions : localizedMessages.get('INSTRUCTIONS'),
            attachments : localizedMessages.get('ATTACHMENTS_TITLE'),
            display : localizedMessages.get('DISPLAY'),
            tally : localizedMessages.get('TALLY'),
            responses : localizedMessages.get('RESPONSES'),
            approvalChoices : localizedMessages.get('APPROVAL_CHOICES'),
            approve : localizedMessages.get('APPROVE'),
            abstain : localizedMessages.get('ABSTAIN'),
            notApprove : localizedMessages.get('NOT_APPROVE'),
            addAdditionalChoice : localizedMessages.get('ADD_ADDITIONAL_CHOICE'),
            respondent : localizedMessages.get('RESPONDENT'),
            view : localizedMessages.get('VIEW'),
            viewer : localizedMessages.get('VIEWER'),
            owner : localizedMessages.get('OWNER'),
            attendees : localizedMessages.get('ATTENDEES'),
            errThisFieldIsRequired : localizedMessages.get('ERR_THIS_FIELD_IS_REQUIRED'),
            addPeople : localizedMessages.get('ADD_PEOPLE'),
            dueOn : localizedMessages.get('DUE_ON'),
            btnCancel	:localizedMessages.get("CANCEL"),
            btnAttachments :localizedMessages.get("ATTACHMENTS_TITLE"),
            btnUsers :localizedMessages.get("USERS"),
            btnSave :localizedMessages.get("SAVE"),
            cancelConfirmMsg :localizedMessages.get("MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED"),
            headingDetails			:localizedMessages.get("DETAILS"),
            headingAttachments		:localizedMessages.get("ATTACHMENTS"),
            headingUsers		:localizedMessages.get("USERS"),
            last_sync : localizedMessages.get('LAST_SYNC'),
            open : localizedMessages.get("OPEN"),
            closed : localizedMessages.get("CLOSED"),
            own : localizedMessages.get("OWN")
        }
    };
	   $scope.isScrollBusy = false;
       $scope.openApprovalsCurrentPageNo=0;
       $scope.closedApprovalsCurrentPageNo=0;
       $scope.ownedApprovalsCurrentPageNo=0;
        $scope.loadMore = function(){
	        $scope.isScrollBusy = true;
           if($scope.isStatusClicked){
	            $scope.isScrollBusy = false;
              return;
           }
              
	          if($scope.tabs[0].active && $scope.approvals!= undefined && !$scope.openApprovalsLastPage)
                {
		          $scope.openApprovalsCurrentPageNo++;
		          $scope.getMyOpenApprovals($scope.tabs[0].title,$scope.openApprovalsCurrentPageNo,$scope.pageSize,$scope.sortColumn,$scope.sortDirection);
	           }
             else if($scope.tabs[1].active && $scope.closedApprovals!= undefined &&  !$scope.closedApprovalsLastPage)
                 {
	               $scope.closedApprovalsCurrentPageNo++;
	              $scope.getMyClosedApprovals($scope.tabs[1].title,$scope.closedApprovalsCurrentPageNo,$scope.pageSize,$scope.sortColumn,$scope.sortDirection);
               }else if($scope.tabs[2].active && $scope.ownedApprovals!= undefined && !$scope.ownedApprovalsLastPage){
	               $scope.ownedApprovalsCurrentPageNo++;
	              $scope.getMyOwnedApprovals($scope.tabs[2].title,$scope.ownedApprovalsCurrentPageNo,$scope.pageSize,$scope.sortColumn,$scope.sortDirection);
                }
	//$timeout(function(){
		console.log("testing");
		$scope.isScrollBusy = false;	
	//}, 5000);
	}
	$scope.peopleTabs = [
	                     {title : $scope.ui.label.respondents},
	                     {title : $scope.ui.label.owners},
	                     {title : $scope.ui.label.view}
	                     ];

	$scope.$parent.tileCheckBox = { title : localizedMessages.get("PUBLISH")  , show : false , active : false };
	
	$scope.showDueDateCalender = function()
	{
		var dateFormat = StringUtils.getLocaleDateFormat();
		$( "#datepickerDueDate" ).datepicker({
												showPanelButton:true,
												beforeShow:function(){
													$('.ui-datepicker').css('font-size',11);},
												showAnim: 'slideDown',
												dateFormat:dateFormat,
												minDate: new Date()
											});
		$( "#datepickerDueDate" ).show();
		$( "#datepickerDueDate" ).focus();
		$( "#datepickerDueDate" ).datepicker();

	};

    var getDisplayOptionsForApprovals = function(){
		var options = [];
		options.push({name:localizedMessages.get("SORT_BY_NAME"), value: "name"});
		options.push({name:localizedMessages.get("SORT_BY_DUE_DATE"), value: "dueDateLong"});
		options.push({name:localizedMessages.get("SORT_BY_PROGRESS"), value: "progress"});
		return options;
	}
    $scope.reverseSort = false;
    $scope.sortDirection = '';
	  $scope.selectMenuItem = function(ob)
		{
			bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Approval, bvUsageUtils.const.Entity.Action.Approval.MenuAction, ob.value);
			if($scope.sortDirection=='asc'){
				$scope.sortDirection='desc';
			}else if($scope.sortDirection=='desc'){
				$scope.sortDirection='asc';
			}else{
				$scope.sortDirection='asc';
			}
			if(ob.value == "name")
			{
				$scope.sortColumn="name";
				if($scope.tabs[0].active){
					var elm = document.getElementById('open-approval-list');
				    elm.scrollTop = 0;
					$scope.openApprovalsLastPage=false;
					$scope.openApprovalsCurrentPageNo=0;
					$scope.approvals=[];
					//$scope.approvals = SortUtility.sortByField($scope.approvals, ['name', 'dueDateLong', 'progressBar'], $scope.reverseSort);
					$scope.getMyOpenApprovals($scope.tabs[0].title,'0',$scope.pageSize,$scope.sortColumn,$scope.sortDirection);
					}
				if($scope.tabs[1].active){
					var elm = document.getElementById('closed-approval-list');
				     elm.scrollTop = 0;
					$scope.closedApprovalsLastPage=false;
					$scope.closedApprovalsCurrentPageNo=0;
					$scope.closedApprovals=[];
					$scope.getMyClosedApprovals($scope.tabs[1].title,'0',$scope.pageSize,$scope.sortColumn,$scope.sortDirection);
				}
					//$scope.closedApprovals = SortUtility.sortByField($scope.closedApprovals, ['name', 'dueDateLong', 'progressBar'], $scope.reverseSort);
				if($scope.tabs[2].active){
					
				    var elm = document.getElementById('owned-approval-list');
				    elm.scrollTop = 0;
				    $scope.ownedApprovalsLastPage=false;
                    $scope.ownedApprovalsCurrentPageNo=0;
                    $scope.ownedApprovals=[];
					$scope.getMyOwnedApprovals($scope.tabs[2].title,'0',$scope.pageSize,$scope.sortColumn,$scope.sortDirection);
				}	// = SortUtility.sortByField($scope.ownedApprovals, ['name', 'dueDateLong', 'progressBar'], $scope.reverseSort);
			}
			else if(ob.value == "dueDateLong")
			{
				$scope.sortColumn="dueDateLong";
				if($scope.tabs[0].active){
					var elm = document.getElementById('open-approval-list');
				     elm.scrollTop = 0;
					$scope.openApprovalsLastPage=false;
					$scope.openApprovalsCurrentPageNo=0;
					$scope.approvals=[];
					$scope.getMyOpenApprovals($scope.tabs[0].title,'0',$scope.pageSize,$scope.sortColumn,$scope.sortDirection);
				}
					//$scope.approvals = SortUtility.sortByField($scope.approvals, ['dueDateLong', 'name', 'progressBar'], $scope.reverseSort);
				if($scope.tabs[1].active){
					var elm = document.getElementById('closed-approval-list');
				    elm.scrollTop = 0;
					$scope.closedApprovalsLastPage=false;
					$scope.closedApprovalsCurrentPageNo=0;
					$scope.closedApprovals=[];
					$scope.getMyClosedApprovals($scope.tabs[1].title,'0',$scope.pageSize,$scope.sortColumn,$scope.sortDirection);
					//$scope.closedApprovals = SortUtility.sortByField($scope.closedApprovals, ['dueDateLong', 'name', 'progressBar'], $scope.reverseSort);
				}
				
				if($scope.tabs[2].active){
					var elm = document.getElementById('owned-approval-list');
				     elm.scrollTop = 0;
					$scope.ownedApprovalsLastPage=false;
					$scope.ownedApprovalsCurrentPageNo=0;
					$scope.ownedApprovals=[];
					$scope.getMyOwnedApprovals($scope.tabs[2].title,'0',$scope.pageSize,$scope.sortColumn,$scope.sortDirection);
					//$scope.ownedApprovals = SortUtility.sortByField($scope.ownedApprovals, ['dueDateLong', 'name', 'progressBar'], $scope.reverseSort);
				}
				
			}
			else if(ob.value == "progress")
			{
				if($scope.tabs[0].active){
				    if($scope.isStatusClicked)
					$scope.approvals = SortUtility.sortByField($scope.approvals, ['progressBar', 'name', 'dueDateLong'], $scope.reverseSort);
					else{
						$scope.isStatusClicked=true;
						$scope.getMyOpenApprovals('','','','','',$scope.isStatusClicked);
					}
				}	
				if($scope.tabs[1].active){
					if($scope.isStatusClicked)
					$scope.closedApprovals = SortUtility.sortByField($scope.closedApprovals, ['progressBar', 'name', 'dueDateLong'], $scope.reverseSort);
					else{
						$scope.isStatusClicked=true;
						$scope.getMyClosedApprovals('','','','','',$scope.isStatusClicked);
					}
				}
				if($scope.tabs[2].active){
					if($scope.isStatusClicked)
					$scope.ownedApprovals = SortUtility.sortByField($scope.ownedApprovals, ['progressBar', 'name', 'dueDateLong'], $scope.reverseSort);
					else{
						$scope.isStatusClicked=true;
						$scope.getMyOwnedApprovals('','','','','',$scope.isStatusClicked);
					}
					}
					$scope.reverseSort = !$scope.reverseSort;
			}
			
		}
		$scope.createApproval = function () {
			approvalsService.setCurrentSelectedApprovalFromList(null);
			context.getModel().companyVO.isNewCreateApprovalUIEnabled ? $state.go('home.createNewApproval') : $state.go('home.createApproval');
		 };
		 $scope.editApproval = function (approvalApiData) {
			context.getModel().companyVO.isNewCreateApprovalUIEnabled ? $state.go('home.createNewApproval', { 
				objectBaseUuid: approvalApiData.objectBaseUuid,
				approvalApiData: approvalApiData
			}) : $state.go('home.createApproval');
		 };
		$scope.showApprovalSettings = function(){
			bvDialog.showApprovalSettings($scope.ownedApprovals, $scope.getMyOwnedApprovals, $scope.promise);
		}
		 
		
	var approvalRightMenuList = [];
	var manageApproval = { 
		title:localizedMessages.get('MANAGE_APPROVALS'),
		click: $scope.showApprovalSettings,
		iconUrl: '',
		stateName: 'ManageApprovals'
	};
		
	var getTabs = function () {
		var createNewApprovalItem = {
	 			title : localizedMessages.get("NEW_APPROVAL"),
	 			click : $scope.createApproval,
	 			iconUrl : '',
	 			stateName: 'NewApproval'
	 		};
		var optionsApprovalItem = {
		 		title:localizedMessages.get('OPTIONS'),
		 		click:()=>{}, iconUrl: '',
		 		svgFill: '#fff',
		 		dropDownMenu:getDisplayOptionsForApprovals()
	 		};
		
//		var approvalRightMenuList = [];
//			var approvalRightMenuList = [ {
//	 			title : localizedMessages.get("NEW_APPROVAL"),
//	 			click : $scope.createApproval,
//	 			iconUrl : ''
//	 		},
//	 		{
//		 		title:localizedMessages.get('OPTIONS'),
//		 		click:'', iconUrl: '',
//		 		svgFill: '#fff',
//		 		dropDownMenu:getDisplayOptionsForApprovals()
//	 		}
//	 		];

		var canCurrentUserCreateModule = false;
		var config = "false";

//		if(context.getModel().isUserPortalAdmin == "true" || context.getModel().isUserPortalAdmin == true || context.getModel().isUserPortalAdmin == "TRUE"){
//			approvalRightMenuList.push(createNewApprovalItem);
//			approvalRightMenuList.push(optionsApprovalItem);
//			approvalsService.setIsCurrentUserAllowedToCreateModule(true);
//		}
//		else{
			commonService.checkModuleCreatorsConfigPresentJSON("APPROVALS").then(function(data){
				if(data && data.data && data.data.name == "isModuleCreatorsPrefAllowed"){
					config = data.data.value;
					if(config == "true" || config == true){
						commonService.getModuleCreatorsForSpecificModuleJSON("APPROVALS").then(function(mod){
							if(mod.data){
								var moduleCreators = angular.fromJson(mod.data);
								if(moduleCreators.users != undefined && moduleCreators.users.length>0){
									for(var i=0;i<moduleCreators.users.length;i++){
										if(context.getModel().userVO.objectBaseUuid == moduleCreators.users[i].objectBaseUuid){
											canCurrentUserCreateModule = true;
											approvalsService.setIsCurrentUserAllowedToCreateModule(true);
											break;
										}
									}
									if(canCurrentUserCreateModule){
										approvalRightMenuList.push(createNewApprovalItem);
									}
								}
							}
							approvalRightMenuList.push(optionsApprovalItem);
						});
					}
					else{
						approvalRightMenuList.push(createNewApprovalItem);
						approvalRightMenuList.push(optionsApprovalItem);
						approvalsService.setIsCurrentUserAllowedToCreateModule(true);
					}
				}
			});
//		}
			$scope.middleBarObject.title = StringUtils.capitaliseFirstLetter($state.$current.name.split("home.")[1]);
			$scope.tabs = [ {
				title : localizedMessages.get("OPEN"),
				content : 'openApprovals',
				rightMenuList: approvalRightMenuList
			}, {
				title : localizedMessages.get("CLOSED"),
				content : 'closedApprovals',
				rightMenuList: approvalRightMenuList
			}, {
				title : localizedMessages.get("OWN"),
				content : 'createdByMe',
				rightMenuList: approvalRightMenuList
			} ];
	 		$scope.tabs[0].active = true;
	};

 		getTabs();
 		var tabNum = approvalsService.getSelectedTab();
 		$scope.tabs[tabNum].active = true;
		$scope.approval = {};
		$scope.objectBaseUuid = '';
		
    	$scope.openApproval = function(approvalData) {
			approvalsService.selectedApprovalUuid = approvalData.objectBaseUuid;
    		for(var i=0;i<$scope.tabs.length;i++){
    			if($scope.tabs[i].active == true){
    				approvalsService.setSelectedTab(i);
    				break;
    			}
    		}
    		context.getModel().companyVO.isNewDetailsApprovalUIEnabled ? $state.go('home.newApprovalDetails', { 
				objectBaseUuid: approvalData.objectBaseUuid,
				approvalApiData: approvalData
			}) : $state.go('home.approval');
    	};

		$scope.fetchOpenApproval = function(objectBaseUuid) {
			approvalsService.selectedApprovalUuid = objectBaseUuid;
			approvalsService.getApprovalDetailJSON(objectBaseUuid).then(function(data){
				for(var i=0;i<$scope.tabs.length;i++){
					if($scope.tabs[i].active == true){
						approvalsService.setSelectedTab(i);
						break;
					}
				}
				approvalsService.setCurrentSelectedApprovalFromList(data);
				context.getModel().companyVO.isNewDetailsApprovalUIEnabled ? $state.go('home.newApprovalDetails', { 
					objectBaseUuid: objectBaseUuid,
					approvalApiData: data
				}) : $state.go('home.approval');
			})   		
    	};
    	
    	
    	if(approvalsService.getApprovalOpenFromInbox()){
			if (context.getModel().companyVO.isNewDetailsApprovalUIEnabled) {
				$scope.fetchOpenApproval(approvalsService.getApprovalOpenFromInbox().objectBaseUuid);
			} else {
				$scope.openApproval(approvalsService.getApprovalOpenFromInbox());
			}
			approvalsService.setApprovalOpenFromInbox();
		}

    	var currentUserVO = context.getModel().userVO;

//		$scope.isLoggedInUserOwner = function(approvalOwnersList){
//			for(var i=0;i<approvalOwnersList.length;i++){
//				if(currentUserVO.objectBaseUuid == approvalOwnersList[i].objectBaseUuid){
//					return true;
//				}
//			}
//		}
        $scope.getShortLocalizedDate = function(date) {
			var zone_name =  moment.tz.guess();
			var timezone= " "
			if(zone_name)
			{
				timezone=timezone.concat(moment.tz(zone_name).zoneAbbr());
			}
       		return date != undefined ? moment($filter('date')(date, 'medium')).format('lll')+timezone : "";
    	}
        
		var setLocalizedDueDate = function(approvals) {
			angular.forEach(approvals, function(approval){
				approval.localizedDueDate = $scope.getShortLocalizedDate(approval.dueDateLong);
			});
		}

        var sortObjArray = function(arr, field) {
            arr.sort(
                function compare(a,b) {
                    if (a[field] < b[field])
                        return -1;
                    if (a[field] > b[field])
                        return 1;
                    return 0;
                }
            );
        }
        var removeDuplicatesFromObjArray = function(arr, field) {
            var u = [];
            arr.reduce(function (a, b) {
                if (a[field] !== b[field]) u.push(b);
                return b;
            }, []);
            return u;
        }
        
        $scope.getResponseStatusTitle = function(approvalProgress){
    		var responseTitle = "";
    		if(approvalProgress == 0)
    		{
    			responseTitle = localizedMessages.get('NOT_STARTED');
    		}
    		else if(approvalProgress >= 100)
    		{
    			responseTitle = localizedMessages.get('COMPLETED');	
    		}
    		else
    		{
    			responseTitle = !legacyUserService.isNasdaqUI ? localizedMessages.get('STARTED') : localizedMessages.get('IN_PROGRESS');
    		}

    		return responseTitle;
    	};
    	
        $scope.getMyOpenApprovals = function (tabType,page,pageSize,sortColumn,sortDirection,isStatusClick) {
        	$scope.middleBarObject.title = localizedMessages.get("APPROVALS");
            if(isStatusClick==undefined)
              $scope.isStatusClicked=false;
          if (MobileUtil.isApp() || isStatusClick){
	              approvalsService.getMyOpenApprovalsJSON().then(function(data)
        	{
        		if(data){
        			data = _.filter(data, { isActive : true });
        		}
        		approvalsService.setSelectedTab(0);
//        		$scope.middleBarObject.title = localizedMessages.get("APPROVALS");
        		$scope.approvals = data;
        		$scope.noApprovals = data.length == 0 ? true : false;
        		if(!$scope.noApprovals){
        			 sortObjArray($scope.approvals, "objectBaseUuid");
        		     $scope.approvals = removeDuplicatesFromObjArray($scope.approvals, "objectBaseUuid");
        			for(var i=0;i<$scope.approvals.length;i++){
        				$scope.approvals[i].progressBar = ($scope.approvals[i].noOfRespondents/$scope.approvals[i].totalParticipants)*100;
        				if(isNaN($scope.approvals[i].progressBar)){
        					$scope.approvals[i].progressBar = 0;
        				}
        				var status = $scope.getResponseStatusTitle($scope.approvals[i].progressBar);
        				$scope.approvals[i].progressStatus = $scope.getResponseStatusTitle($scope.approvals[i].progressBar);
        			}
        			setLocalizedDueDate($scope.approvals);
        		}
        		$scope.msg_no_approvals = localizedMessages.get('MSG_NO_APPROVALS');
                        if(isStatusClick){
	                        $scope.approvals = SortUtility.sortByField($scope.approvals, ['progressBar', 'name', 'dueDateLong'], false);
                            $scope.reverseSort = true;
                         }
        	});
            }
          else{
               $scope.closedApprovalsCurrentPageNo=0;
               $scope.ownedApprovalsCurrentPageNo=0;
               $scope.closedApprovalsLastPage=false;
               $scope.ownedApprovalsLastPage=false;
        	approvalsService.getMyApprovalsJSON(tabType,page,pageSize,sortColumn,sortDirection).then(function(data)
        	{
        		/*if(data){
        			data = _.filter(data, { isActive : true });
        		}*/
        		approvalsService.setSelectedTab(0);
//        		$scope.middleBarObject.title = localizedMessages.get("APPROVALS");
                      if(data.length==0 && page>0){
	                    $scope.openApprovalsLastPage=true;
	                  return;
                   }      
                if(page==0){
	               $scope.approvals=[];
	               $scope.approvals = data;
                  }else{
	                  for(var i=0;i<data.length;i++){
				       $scope.approvals.push(data[i]);
				      }
                  }
                if(data.length<pageSize){
	                $scope.openApprovalsLastPage=true;
                 }
               if(data.length>=pageSize){
	                $scope.isOpenMoreItems=true;
                 }
               
        		$scope.noApprovals = data.length == 0 ? true : false;
        		
        		if(!$scope.noApprovals){
        			 //sortObjArray($scope.approvals, "objectBaseUuid");
        		    // $scope.approvals = removeDuplicatesFromObjArray($scope.approvals, "objectBaseUuid");
        			for(var i=0;i<$scope.approvals.length;i++){
        				$scope.approvals[i].progressBar = ($scope.approvals[i].noOfRespondents/$scope.approvals[i].totalParticipants)*100;
        				if(isNaN($scope.approvals[i].progressBar)){
        					$scope.approvals[i].progressBar = 0;
        				}
        				var status = $scope.getResponseStatusTitle($scope.approvals[i].progressBar);
        				$scope.approvals[i].progressStatus = $scope.getResponseStatusTitle($scope.approvals[i].progressBar);
        			}
        			setLocalizedDueDate($scope.approvals);
        		}
        		$scope.msg_no_approvals = localizedMessages.get('MSG_NO_APPROVALS');
        	});
          }
        };
        
        $scope.promise = {};
        $scope.getMyOwnedApprovals = function (tabType,page,pageSize,sortColumn,sortDirection,isStatusClick) {
	      if(isStatusClick==undefined)
              $scope.isStatusClicked=false;
        	$scope.middleBarObject.title = localizedMessages.get("APPROVALS");
          if (MobileUtil.isApp() || isStatusClick){
	           approvalsService.getMyOwnedApprovalsJSON().then(function(data)
        	{
//        		$scope.middleBarObject.title = localizedMessages.get("APPROVALS");
        		$scope.ownedApprovals = data;
        		$scope.noApprovals = data.length == 0 ? true : false;
        		if(!$scope.noApprovals){
        			for(var i=0;i<$scope.ownedApprovals.length;i++){
        				if($scope.ownedApprovals[i].totalParticipants == 0){
        					$scope.ownedApprovals[i].progressBar=0;
        				}else{
        					$scope.ownedApprovals[i].progressBar = ($scope.ownedApprovals[i].noOfRespondents/$scope.ownedApprovals[i].totalParticipants)*100;
            				if(isNaN($scope.ownedApprovals[i].progressBar)){
            					$scope.ownedApprovals[i].progressBar = 0;
            				}
        				}
        			}
        			setLocalizedDueDate($scope.ownedApprovals);
        			!_.find(approvalRightMenuList, { stateName : "ManageApprovals" }) ? approvalRightMenuList.splice(0, 0, manageApproval) : null;
        		}
        		$scope.msg_no_approvals = localizedMessages.get('MSG_NO_APPROVALS');
        		 if(isStatusClick){
	                  $scope.ownedApprovals = SortUtility.sortByField($scope.ownedApprovals, ['progressBar', 'name', 'dueDateLong'], false);
                      $scope.reverseSort = true;
                   }
        		if($scope.promise.func && typeof $scope.promise.func == 'function'){
        			$scope.promise.func($scope.ownedApprovals);
        		}
        	});
	     }else{
		       $scope.openApprovalsCurrentPageNo=0;
               $scope.closedApprovalsCurrentPageNo=0;
                $scope.closedApprovalsLastPage=false;
                $scope.openApprovalsLastPage=false;
		     approvalsService.getMyApprovalsJSON(tabType,page,pageSize,sortColumn,sortDirection).then(function(data)
        	{
//        		$scope.middleBarObject.title = localizedMessages.get("APPROVALS");
                if(data.length==0 && page>0){
	                   $scope.ownedApprovalsLastPage=true;
	                  return;
                   }
                if(page==0){
	               $scope.ownedApprovals = data;
                  }else{
	                  for(var i=0;i<data.length;i++){
				       $scope.ownedApprovals.push(data[i]);
				      }
                  }
                    if(data.length<pageSize){
	               $scope.ownedApprovalsLastPage=true;
                   }
                 
                   if(data.length>=pageSize){
	                $scope.isOwnedMoreItems=true;
                 }


        		$scope.noApprovals = data.length == 0 ? true : false;
        		if(!$scope.noApprovals){
        			for(var i=0;i<$scope.ownedApprovals.length;i++){
        				if($scope.ownedApprovals[i].totalParticipants == 0){
        					$scope.ownedApprovals[i].progressBar=0;
        				}else{
        					$scope.ownedApprovals[i].progressBar = ($scope.ownedApprovals[i].noOfRespondents/$scope.ownedApprovals[i].totalParticipants)*100;
            				if(isNaN($scope.ownedApprovals[i].progressBar)){
            					$scope.ownedApprovals[i].progressBar = 0;
            				}
        				}
        			}
        			setLocalizedDueDate($scope.ownedApprovals);
        			!_.find(approvalRightMenuList, { stateName : "ManageApprovals" }) ? approvalRightMenuList.splice(0, 0, manageApproval) : null;
        		}
        		$scope.msg_no_approvals = localizedMessages.get('MSG_NO_APPROVALS');
        		
        		if($scope.promise.func && typeof $scope.promise.func == 'function'){
        			$scope.promise.func($scope.ownedApprovals);
        		}
        	});
	    }
	         
        	
        };
//        $scope.getMyOwnedApprovals();
        
        $scope.getMyClosedApprovals = function (tabType,page,pageSize,sortColumn,sortDirection,isStatusClick) {
	if(isStatusClick==undefined)
              $scope.isStatusClicked=false;
        	$scope.middleBarObject.title = localizedMessages.get("APPROVALS");
          if (MobileUtil.isApp() || isStatusClick){
	            approvalsService.getMyClosedApprovalsJSON().then(function(data)
        	{
//        		$scope.middleBarObject.title = localizedMessages.get("APPROVALS");
        		$scope.closedApprovals = data;
        		$scope.noApprovals = data.length == 0 ? true : false;
        		if(!$scope.noApprovals){
        			for(var i=0;i<$scope.closedApprovals.length;i++){
        				if($scope.closedApprovals[i].totalParticipants == 0){
        					$scope.closedApprovals[i].progressBar
        				}
        				else{
        					$scope.closedApprovals[i].progressBar = ($scope.closedApprovals[i].noOfRespondents/$scope.closedApprovals[i].totalParticipants)*100;
            				if(isNaN($scope.closedApprovals[i].progressBar)){
            					$scope.closedApprovals[i].progressBar = 0;
            				}
        				}
        			}
        			setLocalizedDueDate($scope.closedApprovals);
        		}
        		$scope.msg_no_approvals = localizedMessages.get('MSG_NO_APPROVALS');
                             if(isStatusClick){
	                          $scope.closedApprovals = SortUtility.sortByField($scope.closedApprovals, ['progressBar', 'name', 'dueDateLong'], false);
                              $scope.reverseSort =true;
                  }
        	});
	       }else{
		            $scope.openApprovalsCurrentPageNo=0;
                    $scope.ownedApprovalsCurrentPageNo=0;
                    $scope.openApprovalsLastPage=false;
                   $scope.ownedApprovalsLastPage=false;
		         approvalsService.getMyApprovalsJSON(tabType,page,pageSize,sortColumn,sortDirection).then(function(data)
        	{
//        		$scope.middleBarObject.title = localizedMessages.get("APPROVALS");
                  if(data.length==0 && page>0){
	                   $scope.closedApprovalsLastPage=true;
	                  return;
                   }
                if(page==0){
	               $scope.closedApprovals = data;
                  }else{
	                  for(var i=0;i<data.length;i++){
				       $scope.closedApprovals.push(data[i]);
				      }
                  }
                      if(data.length<pageSize){
	                   $scope.closedApprovalsLastPage=true;
                   }
                  if(data.length>=pageSize){
	                $scope.isClosedMoreItems=true;
                 }
        		$scope.noApprovals = data.length == 0 ? true : false;
        		if(!$scope.noApprovals){
        			for(var i=0;i<$scope.closedApprovals.length;i++){
        				if($scope.closedApprovals[i].totalParticipants == 0){
        					$scope.closedApprovals[i].progressBar
        				}
        				else{
        					$scope.closedApprovals[i].progressBar = ($scope.closedApprovals[i].noOfRespondents/$scope.closedApprovals[i].totalParticipants)*100;
            				if(isNaN($scope.closedApprovals[i].progressBar)){
            					$scope.closedApprovals[i].progressBar = 0;
            				}
        				}
        			}
        			setLocalizedDueDate($scope.closedApprovals);
        		}
        		$scope.msg_no_approvals = localizedMessages.get('MSG_NO_APPROVALS');
        	});
	       }
        	
        };

        //For Mobile
    	$scope.mobileOpenApprovalsClicked = function(){
    		$scope.getMyOpenApprovals();
    		$scope.tabs[0].active  = true;
    	};

    	$scope.mobileClosedApprovalsClicked = function(){
    		$scope.getMyClosedApprovals();
    		$scope.tabs[1].active  = true;
    	};

    	$scope.mobileOwnApprovalsClicked = function(){
    		$scope.getMyOwnedApprovals();
    		$scope.tabs[2].active  = true;
    	};



    /**
     * Right Click Menu Handler for approval Lisitng
     *
    */

    $scope.currentInstance = "APPROVALS";

	$scope.showApprovalMenu = function(event, approval)
    {
		$scope.showApprovalRightClick = false;
		if (event)
		{
			event.preventDefault();
      		event.stopPropagation();
      		$scope.handleRightClick(event.pageX, event.pageY, approval, event);
    	}
    }

    $scope.handleRightClick = function (xPos, yPos, approval, event)
    {
    	contextMenuModel.parentObject = $scope.currentInstance;
    	$scope.rightClickDispatcher(xPos, yPos, approval, event)
    }

	$scope.showApprovalRightClick = false;
	
	$scope.scrollbarConfig = {
				 autoHideScrollbar: true,
                 theme: 'minimal-dark',
               callbacks: {
                        onScroll: function () {
	                     if(this.mcs.topPct >= 80){
							 $scope.loadMore();
						 }
                         //console.log('onScrolling: ' +JSON.stringify(this.mcs))
                        }
                    }
		}

	$scope.rightClickDispatcher = function (xPos, yPos, selectedContextItem, event)
	{

		if(selectedContextItem === undefined || selectedContextItem == null)
			return;

		approvalsService.getApprovalDetailJSON(selectedContextItem.objectBaseUuid)
		.then(function(data){
			$scope.selectedObjectFromContextMenu = "";

			if(MobileUtil.isSurface())
			{
				xPos = xPos+300;
			}
			contextMenuModel.xPos = xPos;
			contextMenuModel.yPos = yPos;
			contextMenuModel.event = event;
			contextMenuModel.currentInstance = $scope.currentInstance;
			contextMenuModel.selectedObject = data;
			contextMenuModel.contextMenuCheckOptions = {};
			contextMenuModel.multiple = false;

			$scope.showApprovalRightClick = true;
		});
	};

	$scope.$watch('showApprovalRightClick', function(oldValue, newValue){
		console.log(oldValue, newValue);
	}, true);

    $scope.$on('HOME_CONTROLLER_CLICK', function(event){
    	if($scope.showApprovalRightClick)
        	$scope.showApprovalRightClick = false;
    });

	var deleteApprovalConfirm = function(selectedApprovalUuid) {
		bvDialog.confirm(localizedMessages.get("DELETE_APPROVAL_CONFIRM_MSG"), function(){deleteApproval(selectedApprovalUuid)});
	}

	var deleteApproval = function(selectedApprovalUuid) {
		approvalsService.deleteApproval(selectedApprovalUuid).then(function(data){
    		for(var i=0;i<$scope.tabs.length;i++){
    			if($scope.tabs[i].active == true){
    				approvalsService.setSelectedTab(i);
    				break;
    			}
    		}
			$state.reload();
		});
	}
	
	var closeApprovalConfirm = function(selectedApprovalUuid) {
		bvDialog.confirm(localizedMessages.get("CLOSE_APPROVAL_CONFIRM_MSG"), function(){closeApproval(selectedApprovalUuid)});
	}
	
	var closeApproval = function(selectedApprovalUuid) {
		approvalsService.closeApproval(selectedApprovalUuid).then(function(data){
    		for(var i=0;i<$scope.tabs.length;i++){
    			if($scope.tabs[i].active == true){
    				approvalsService.setSelectedTab(i);
    				break;
    			}
    		}
			$state.reload();
		});
	}
	
	var reopenApprovalConfirm = function(selectedApprovalUuid) {
		bvDialog.confirm(localizedMessages.get("REOPEN_APPROVAL_CONFIRM_MSG"), function(){reopenApproval(selectedApprovalUuid)});
	}
		
	var reopenApproval = function(selectedApprovalUuid) {
		approvalsService.reopenApproval(selectedApprovalUuid).then(function(data){
    		for(var i=0;i<$scope.tabs.length;i++){
    			if($scope.tabs[i].active == true){
    				approvalsService.setSelectedTab(i);
    				break;
    			}
    		}
			$state.reload();
		});
	}

	var createPush = function() {
		bvDialog.showPush(contextMenuModel.selectedObject);
	}

 	$scope.menuCallback = function(type, param){
    	switch (type)
    	{
            case 'MENU_STYLE':
            	$scope.menuStyle = {'top': param[0].y + "px",'left': param[0].x + "px"};
                break;
            case 'MENU_ARROW_STYLE':
                $scope.menuLeftArrowStyle = {'top': param.y + "px", 'left': param.x + "px"};
                break;
            case 'VIEW':
            	$scope.openApproval(contextMenuModel.selectedObject);
            	break;
           	case 'EDIT':
	       		$scope.editApproval(contextMenuModel.selectedObject);
            	break;
            case 'ALERT':
            	approvalAlertService.openAlertDialog();
            	break;
            case 'PRINT':
           		bvDialog.printApproval();
            	break;
            case 'DELETE':
            	deleteApprovalConfirm(contextMenuModel.selectedObject.objectBaseUuid);
            	break;
            case 'PUSH':
            	createPush();
            	break;
            case 'CLOSE':
            	closeApprovalConfirm(contextMenuModel.selectedObject.objectBaseUuid);
            	break;
            case 'REOPEN':
            	reopenApprovalConfirm(contextMenuModel.selectedObject.objectBaseUuid);
            	break;
			default:
				break;
		}
    }
 	 $scope.topRepositoryPanel = {
             templateUrl: 'modules/approvals/toppanel/toppanel.template.html',
             events : [
                 { name: 'name',         value : localizedMessages.get('NAME_UPPERCASE')},
                 { name: 'due',          value : localizedMessages.get('DUE_UPPERCASE')},
                 { name: 'status',       value : localizedMessages.get('STATUS_UPPERCASE')},
                 { name: 'nameClk',      value : function(){ $scope.selectMenuItem({ value: "name" }) }},
                 { name: 'dueClk',       value : function(){ $scope.selectMenuItem({ value: "dueDateLong" }) }},
				 { name: 'statusClk',    value : function(){ $scope.selectMenuItem({ value: "progress" })}},
                 { name: 'tabs',         value : $scope.tabs}
             ]
         }		 

	//Init call on page load
	$scope.initApprovalTab = function(tab) {
		var entID = bvUsageUtils.const.Entity.ID.Approval.Open;
		switch (tab) {
			case 'openApprovals':
				entID = bvUsageUtils.const.Entity.ID.Approval.Open;
				$scope.getMyOpenApprovals('open','0',$scope.pageSize);
				break;
			case 'closedApprovals':
				entID = bvUsageUtils.const.Entity.ID.Approval.Closed;
				$scope.getMyClosedApprovals('closed','0',$scope.pageSize);
				break;
			case 'createdByMe':
				entID = bvUsageUtils.const.Entity.ID.Approval.Own;
				$scope.getMyOwnedApprovals('owned','0',$scope.pageSize);
				break;
		}
		bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Approval, bvUsageUtils.const.Entity.Action.Approval.SelectedTab, entID);
	}

  //   $scope.approvalMenuItems = {};
  //   $scope.showApprovalMenu = function(approval) {
  //   	$scope.approvalMenuItems = {};
  //   	return approvalsService.getApprovalDetailJSON(objectBaseUuid)
		// .then(function(data){
		// 	console.log(data);
		// 	var isOwner = false;
		// 	var ownerList = data.ownerList;
		// 	for(i in ownerList) {
		// 		if(ownerList[i].objectBaseUuid == currentUserVO.objectBaseUuid) {
		// 			isOwner = true;
		// 			break;
		// 		}
		// 	}

		// 	$scope.approvalMenuItems.push(viewOption);
		// 	if(isOwner) $scope.approvalMenuItems.push(editOption);
		// 	if(isOwner)	$scope.approvalMenuItems.push(alertOption);
		// 	$scope.approvalRightMenuList.push(printOption);
		// 	if(isOwner)	$scope.approvalMenuItems.push(deleteOption);

		// 	return data;
		// });
  //   }
 	
}

ApprovalDetailsController.$inject = ['$scope', '$state', '$stateParams', 'approvalsService', 'approvalAlertService', 'localizedMessages','imageService', 'context', 'bvDialog','homeService'];

function ApprovalDetailsController($scope, $state, $stateParams, approvalsService, approvalAlertService, localizedMessages, imageService, context, bvDialog, homeService) {

	$scope.middleBarObject.title = localizedMessages.get("APPROVAL");
	$scope.middleBarObject.showBack = true;
	$scope.svgFill = '#FFF';
	var ctrlName = 'ApprovalDetailsController';
	
	var selectedApprovalUuid = approvalsService.selectedApprovalUuid;

	var editApproval = function() {
		const approval = approvalsService.getCurrentSelectedApprovalFromList();
		// 'isNewCreateApprovalUIEnabled' property is dictating whether to use react ui or not
		// for react ui, we move to 'home.createNewApproval' with data. and for angular ui, we move to 'home.createApproval' without any data
		// to remove the angular support, condition checking and 'home.createApproval' state is to be removed
		context.getModel().companyVO.isNewCreateApprovalUIEnabled ? $state.go('home.createNewApproval', { 
			objectBaseUuid: selectedApprovalUuid,
			approvalApiData: approval
		}) : $state.go('home.createApproval');
	}

	var deleteApprovalConfirm = function() {
		bvDialog.confirm(localizedMessages.get("DELETE_APPROVAL_CONFIRM_MSG"), deleteApproval);
	}

	var closeApprovalConfirm = function() {
		bvDialog.confirm(localizedMessages.get("CLOSE_APPROVAL_CONFIRM_MSG"), closeApproval);
	}
	
	var reopenApprovalConfirm = function() {
		bvDialog.confirm(localizedMessages.get("REOPEN_APPROVAL_CONFIRM_MSG"), reopenApproval);
	}
	
	var deleteApproval = function() {
		approvalsService.deleteApproval(selectedApprovalUuid).then(function(data){
			console.log(data);
    		for(var i=0;i<$scope.tabs.length;i++){
    			if($scope.tabs[i].active == true){
    				approvalsService.setSelectedTab(i);
    				break;
    			}
    		}
			$state.go('home.approvals');
		});
		$scope.handleLeftIcon();
	}

	var closeApproval = function() {
		approvalsService.closeApproval(selectedApprovalUuid).then(function(data){
			console.log(data);
    		for(var i=0;i<$scope.tabs.length;i++){
    			if($scope.tabs[i].active == true){
    				approvalsService.setSelectedTab(i);
    				break;
    			}
    		}
			$state.go('home.approvals');
		});
		$scope.handleLeftIcon();
	}
	
	var reopenApproval = function() {
		approvalsService.reopenApproval(selectedApprovalUuid).then(function(data){
			console.log(data);
    		for(var i=0;i<$scope.tabs.length;i++){
    			if($scope.tabs[i].active == true){
    				approvalsService.setSelectedTab(i);
    				break;
    			}
    		}
			$state.go('home.approvals');
		});
		$scope.handleLeftIcon();
	}
	
	var closeAlertDlg = function closeAlertDlg() {
		$scope.alertDlg.close();
	};
	
	var alertApproval = function() {
		approvalAlertService.approval = approvalsService.getCurrentSelectedApprovalFromList();
		approvalAlertService.openAlertDialog();
	}

	var printApproval = function(options) {
		//console.log(options);
		bvDialog.printApproval();
	}

	var editOption = {
		title : localizedMessages.get("EDIT"),
		iconUrl : '',
		click : editApproval,
		stateName: 'ApprovalEdit'
	};

	var alertOption = {
		title : localizedMessages.get("ALERT"),
		iconUrl : '',
		click : alertApproval,
        stateName: 'ApprovalAlert'
	};

	var deleteOption = {
		title : localizedMessages.get("DELETE"),
		click : deleteApprovalConfirm,
		iconUrl : '',
        stateName: 'ApprovalDelete'
	};

	var printOption = {
		title : localizedMessages.get('REPORT'),
		click : printApproval,
		iconUrl: ''
	};

	var closeOption = {
			title : localizedMessages.get('CLOSE'),
			click : closeApprovalConfirm,
			iconUrl : '',
	        stateName: 'ApprovalClose'
	};
	
	var reopenOption = {
			title : localizedMessages.get('REOPEN'),
			click : reopenApprovalConfirm,
			iconUrl : '',
	        stateName: 'ApprovalReopen'
	};
	
	var approvalRightMenuList = [
	];

	$scope.tabs = [ {
		title : localizedMessages.get("APPROVAL"),
		content : 'openApproval',
		rightMenuList:approvalRightMenuList
	}];


    $scope.handleLeftIcon = function()
	{
		if(approvalsService.isApprovalResponseDirty) {
			bvDialog.confirm(localizedMessages.get("LEAVE_EDITED_APPROVAL_WITHOUT_SUBMIT_MSG"),
				function(){
					approvalsService.isApprovalResponseDirty = false;
					homeService.set_current_module_has_unsaved_changes(false, ctrlName);
					$state.go('home.approvals');
				}
			);
		} else {
			$state.go('home.approvals');
		}
	}

	$scope.getApprovalDetailJSON = function (objectBaseUuid) {
		return approvalsService.getApprovalDetailJSON(objectBaseUuid)
			.then(function (data) {
				const pastDueDate = data.dueDateGMTMillis < Date.now();
				const noDueDate = data.dueDateGMTMillis >= 32503708800000;
				const isClosed = data.closed == "true";
				const isActive = data.isActive;
				const isContentOwner = currentUserVO.isContentOwner;
				const isOwner = data.ownerList.some((user) => { return user.objectBaseUuid == currentUserVO.objectBaseUuid });
				if (isOwner) {
					approvalRightMenuList.push(editOption);
				}
				if (isActive && !pastDueDate && (isOwner || isContentOwner)) {
					approvalRightMenuList.push(alertOption);
				}
				if (isActive && noDueDate) {
					if (!isClosed && isOwner) {
						approvalRightMenuList.push(closeOption);
					}
					if (isClosed && (isOwner || isContentOwner)) {
						approvalRightMenuList.push(reopenOption);
					}
				}
				if (isOwner) {
					approvalRightMenuList.push(deleteOption);
				}
				approvalRightMenuList.push(printOption);
				$scope.middleBarObject.title = data.name;
				return data;
			});
	}

	var currentUserVO = context.getModel().userVO;
 
 

	$scope.getApprovalDetailJSON(selectedApprovalUuid);
}

ApprovalMenuController.$inject = ['$scope', 'approvalContextMenu','contextMenuModel', 'bvDialog', 'localizedMessages', 'homeService'];

function ApprovalMenuController($scope, approvalContextMenu, contextMenuModel, bvDialog, localizedMessages, homeService)
{
	var showContextMenu = function()
	{
		$scope.contextMenuOption = approvalContextMenu.getContextMenu(contextMenuModel.multiple, contextMenuModel.selectedObject, contextMenuModel.parentObject, contextMenuModel.contextMenuCheckOptions);
		console.log($scope.contextMenuOption);
		var point = approvalContextMenu.getPosition(contextMenuModel.xPos, contextMenuModel.yPos, contextMenuModel.selectedObject, $scope.contextMenuOption.length, 31, 210);

    	var event = contextMenuModel.event;

    	!homeService.homeContext.expandedMenu ? point.x = point.x - 300 :  point.x;
    	$scope.currentInstance = contextMenuModel.currentInstance;

        $scope.menuParentCallback("MENU_STYLE",[point]);
      	var arrow_point = approvalContextMenu.getArrowPosition(contextMenuModel.xPos, contextMenuModel.yPos,$scope.contextMenuOption.length, 31, 210);
      	!homeService.homeContext.expandedMenu ? arrow_point.x = arrow_point.x - 300 :  arrow_point.x;
        $scope.menuParentCallback("MENU_ARROW_STYLE",arrow_point) ;
	}

 	$scope.openContextMenu = function(type)
    {
    	switch(type)
    	{
    		case 'view':  $scope.menuParentCallback("VIEW",[]);break;
    		case 'edit': $scope.menuParentCallback("EDIT",[]);break;
    		case 'alert': $scope.menuParentCallback("ALERT",[]);break;
    		case 'print': $scope.menuParentCallback("PRINT",[]);break;
            case 'delete': $scope.menuParentCallback("DELETE",[]);break;
            case 'push': $scope.menuParentCallback("PUSH",[]);break;
            case 'close': $scope.menuParentCallback("CLOSE",[]);break;
            case 'reopen': $scope.menuParentCallback("REOPEN",[]);break;
    	}
    }
	showContextMenu(contextMenuModel.xPos, contextMenuModel.yPos, contextMenuModel.selectedContextItem, contextMenuModel.event);
}

ApprovalContextMenu.$inject = ['localizedMessages', 'contextMenuSetting', 'context', 'homeModel','homeContext', 'approvalsService', 'approvalAlertService'];

function ApprovalContextMenu(localizedMessages, contextMenuSetting, context, homeModel, homeContext, approvalsService, approvalAlertService)
{
	var contextMenuOption = {};
	var approvalContextMenu = [];

	contextMenuOption['view'] 	= { title:localizedMessages.get('VIEW'), iconUrl: '', svgFill: '#fff', type: 'view'};
	contextMenuOption['edit'] 	= { title:localizedMessages.get('EDIT'), iconUrl: '', svgFill: '#fff', type: 'edit'};
	contextMenuOption['alert'] 	= { title:localizedMessages.get('ALERT'), iconUrl: '', svgFill: '#fff', type: 'alert'};
	contextMenuOption['print'] 	= { title:localizedMessages.get('REPORT'), iconUrl: '', svgFill: '#fff', type: 'print'};
	contextMenuOption['delete'] = { title:localizedMessages.get('DELETE'), iconUrl: '', svgFill: '#fff', type: 'delete'};
	contextMenuOption['close'] = { title:localizedMessages.get('CLOSE'), iconUrl: '', svgFill: '#fff', type: 'close'};
	contextMenuOption['reopen'] = { title:localizedMessages.get('REOPEN'), iconUrl: '', svgFill: '#fff', type: 'reopen'};
	contextMenuOption['push'] = { title:localizedMessages.get('PUSH'), iconUrl: '', svgFill: '#fff', type: 'push'};
	console.log("Inside ApprovalContextMenu ");

	this.getContextMenu = function(isMultipleSelect, businessObject, parentContainer, contextMenuCheckOptions)
	{
		approvalContextMenu = [];

		if(MobileUtil.isAndroid() || MobileUtil.isIPhone())
		{

		}
		else
		{
			console.log("Inside ApprovalContextMenu else");
			approvalsService.selectedApprovalUuid = businessObject.objectBaseUuid;
			approvalsService.setCurrentSelectedApprovalFromList(businessObject);
			approvalAlertService.approval = businessObject;

			var currentUserVO = context.getModel().userVO;
			var isOwner = false;
			var ownerList = businessObject.ownerList;
			var closed = false;
			var noDueDate = false;
			for(var i in ownerList) {
				if(ownerList[i].objectBaseUuid == currentUserVO.objectBaseUuid) {
					isOwner = true;
					break;
				}
			}
			if (businessObject.dueDateGMTMillis == 32503708800000){
				noDueDate = true;
			}
			if (businessObject.closed == "true"){
				closed = true;
			}

			var pastDueDate = businessObject.dueDateGMTMillis < Date.now();

			approvalContextMenu.push(contextMenuOption['view']);
			if(isOwner && !MobileUtil.isSurface()) approvalContextMenu.push(contextMenuOption['edit']);
			if(businessObject.isActive)
				{
				if((isOwner || currentUserVO.isContentOwner) && !pastDueDate && !MobileUtil.isSurface()) approvalContextMenu.push(contextMenuOption['alert']);
				}
			if(!MobileUtil.isSurface()) approvalContextMenu.push(contextMenuOption['print']);
			if(isOwner && !MobileUtil.isSurface()) approvalContextMenu.push(contextMenuOption['delete']);
			if(businessObject.isActive && noDueDate){
				if(isOwner && !closed && !MobileUtil.isSurface()) approvalContextMenu.push(contextMenuOption['close']);
				if((isOwner || currentUserVO.isContentOwner) && closed && !MobileUtil.isSurface()) approvalContextMenu.push(contextMenuOption['reopen']);
			}
			if((isOwner || currentUserVO.isContentOwner) && context.getModel().pushEnabled) approvalContextMenu.push(contextMenuOption['push']);
			
			if(approvalContextMenu.length==2) {
				let approvalMenu=$("#approval-context-menu");
				$(approvalMenu).addClass("approval-menu-override");
				
			}
			

		  	return approvalContextMenu;
		}
	}

	this.getPosition = function(xPos, yPos, selectedContextItem, length, factor, width)
	{
		return contextMenuSetting.getPosition(xPos, yPos, selectedContextItem, length, factor, width)
	}

	this.getArrowPosition = function(xPos, yPos, length, factor, width)
	{
		return contextMenuSetting.getArrowPosition(xPos, yPos, length, factor, width)
	}
};

function ApprovalMenu(){
    return{
        restrict: 'A',
        scope:{
        	menuStyle: '=',
        	menuParentCallback:'='
        },
        templateUrl: 'directives/contextmenu/ContextMenu.tpl.html',
        controller: 'approvalMenuController'
    }
}

function ContextMenuModel()
{
	this.selectedObject = {};
	this.parentObject = {};
	this.parentFolder = {};
	this.xPos = "";
	this.yPos = "";
	this.event = {};
	this.contextMenuCheckOptions = {};
	this.multiple = false;
	this.currentInstance = "";
}

angular
	.module('approvals',['approvals.service', 'localization.service','nvd3ChartDirectives','image.service', 'bvApprovalDirective', 'infinite-scroll'])
	.config(configApprovalsController)
	.controller('ApprovalsListController', ApprovalsListController)
	.controller('ApprovalDetailsController', ApprovalDetailsController)
	.controller('approvalMenuController', ApprovalMenuController)
    .directive('approvalMenu', ApprovalMenu)
    .service('approvalContextMenu', ApprovalContextMenu)
    .service('contextMenuModel', ContextMenuModel)
	.filter('toTrusted',function() {
		return function(text) {
			if(StringUtils.isNullEmpty(text)) return;
			return text.replace(/<\/?[^>]+>/gi, '');
		}
	});
