angular
	.module('approvalAlertService.factory', [])
	.factory('approvalAlertService', approvalAlertService);

approvalAlertService.$inject = ['localizedMessages','bvDialog', 'templatesService', 'bvDialogService','context','legacyUserService','messagesService'];

function approvalAlertService(localizedMessages, bvDialog, templatesService, bvDialogService, context, legacyUserService, messagesService)
{
	var approval = null;
	var selectedUsersToAlert = [];
	var alertDlg = null;

	var service = {
		approval: approval,
		selectedUsersToAlert : selectedUsersToAlert,
		alertDlg : alertDlg,
		openAlertDialog : openAlertDialog,
		closeAlertDlg : closeAlertDlg,
		userSelectionDone: userSelectionDone,
		cancelAlertAction : cancelAlertAction,
		openComposeAlertWindow : openComposeAlertWindow
	}

	return service;

	function openAlertDialog() {
		
		var name = service.approval.title != undefined ? service.approval.title : service.approval.name;
		var users = service.approval.attendeesList != undefined ? angular.copy(service.approval.attendeesList) : angular.copy(service.approval.respondentList);

		if(users===undefined) {
			users=[];
		}
		
		//MEETX-27690 merge viewerslist as alert users
		if(service.approval.viewerList && service.approval.viewerList.length>0) {
			_.each(service.approval.viewerList, function(item){
				users.push(item);
			});	
		}

		if(service.approval.ownerList && service.approval.ownerList.length>0) {
			_.each(service.approval.ownerList, function(item){
				let  userExistsAarray = users.filter(userItem => userItem.objectBaseUuid === item.objectBaseUuid)
				if(userExistsAarray.length == 0){
					users.push(item);
				}
			});	
		}
		var cancelSelectionForAlertMsg = localizedMessages.get('MSG_ALERT_CANCEL_ALERT_AFTER_SELECTION');

		for(var i = 0; i< users.length; i++)
		{
			if(users[i].selected)
			{
				users[i].selected = false;
			}
		}
		
		service.alertDlg = bvDialog.showAlertUsers(
			localizedMessages.get('ALERT') + ": " + name,
			localizedMessages.get('USERS'),
			users,
			service.userSelectionDone,
			localizedMessages.get('CANCEL'),
			localizedMessages.get('SEND'),
			cancelSelectionForAlertMsg,
			service.openComposeAlertWindow,
			service.cancelAlertAction
		);
	}

	function closeAlertDlg() {
		service.alertDlg.close();
	}

	function userSelectionDone (selectedUsersToAlert) {
		
		service.selectedUsersToAlert = selectedUsersToAlert;
	}

	function cancelAlertAction() {
		if(service.selectedUsersToAlert.length > 0) {
			bvDialog.confirm(localizedMessages.get('MSG_ALERT_CANCEL_ALERT_AFTER_SELECTION'), ()=>{
				service.selectedUsersToAlert = [];
				service.closeAlertDlg();
			}, null);
		} else {
			service.selectedUsersToAlert = [];
			service.closeAlertDlg();
		}
	}

	function openComposeAlertWindow() {
		var selectedUsers = service.selectedUsersToAlert;
		if(service.approval.isAnonymous && selectedUsers.length > 0){
			for(var i=0;i<selectedUsers.length;i++){
				selectedUsers[i].name=localizedMessages.get("ANONYMOUS");
			}
		}
//		console.log(selectedUsers);
		var name = "";
		if(service.approval.title != undefined)
		{
			name = service.approval.name = service.approval.title;
		}
		else
		{
			name = service.approval.title = service.approval.name;
		}
		
		if(selectedUsers && selectedUsers.length > 0 && selectedUsers != undefined && selectedUsers != null) {
			service.closeAlertDlg();


			var bvModalInputs = {};
			bvModalInputs.windowClass = 'bv-modal-window-compose-new-message';
			bvModalInputs.inputs = {};
			bvModalInputs.buttons = [{},{}];
			bvModalInputs.inputs.messageType = 'alert_approval';

			if(legacyUserService.isNasdaqUI)
				bvModalInputs.inputs.businessObjects = service.approval;

			bvModalInputs.inputs.popupTitle = localizedMessages.get("ALERT");
			bvModalInputs.inputs.sharedWithList = selectedUsers;
			bvModalInputs.inputs.name = localizedMessages.get("APPROVAL_ALERT_SUBJECT");
			bvModalInputs.inputs.comments = localizedMessages.get("APPROVAL_ALERT_COMMENTS") + " - " + name;
			bvModalInputs.controller = 'ComposeMessageController';
			bvModalInputs.inputs.alwaysSecure = context.getModel().companyVO.alwaysSendSecureEmail;
			bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessage');

			messagesService.isSecureEnabled().then(function (response) {
				bvModalInputs.inputs.secure = response.data;
				bvDialogService.showBvModal(bvModalInputs);
				console.log(bvModalInputs.buttons);
			}, function(failed) {
				bvModalInputs.inputs.secure = true;//defaults to true
				bvDialogService.showBvModal(bvModalInputs);
				console.log("isSecureEnabled call failed");
				console.log(bvModalInputs.buttons);});

		} else if(selectedUsers.length <= 0) {
			bvDialog.showMessage(localizedMessages.get('MSG_TO_SEND_ALERT_SELECT_USER'));
			bvDialog.showAlertUsers(
					localizedMessages.get('ALERT') + ": " + name,
					localizedMessages.get('USERS'),
					users,
					service.userSelectionDone,
					localizedMessages.get('CANCEL'),
					localizedMessages.get('SEND'),
					cancelSelectionForAlertMsg,
					service.openComposeAlertWindow,
					service.cancelAlertAction
				);
		}
	};
}
