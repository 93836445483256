userManagementToolkitControllerApprovals.$inject = ['$scope', 'localizedMessages', 'context', 'bvDialog', 'templatesService'];

function userManagementToolkitControllerApprovals($scope, localizedMessages, context, bvDialog, templatesService){
	$scope.iconUser = 'resources/assets/svgs/users_small.svg';
	//$scope.leftArrow = 'resources/assets/svgs/backarrow.svg';
	$scope.rightArrow = 'resources/assets/svgs/arrow.svg';
	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
    $scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
    $scope.closeIcon = templatesService.getUrl('closeBtn');
    
    var approvalObject =  $scope.bvModalControllerInputs.approvalObject;
	var cb =  $scope.bvModalControllerInputs.cb;
	var bvModal = $scope.bvModalControllerInputs.bvModal;

	$scope.ui = {
		popupTitle 				: localizedMessages.get('MANAGE_USERS'),
		actionBtnTxt 			: localizedMessages.get('OK'),
		closeBtnTxt 			: localizedMessages.get('CANCEL'),
		users 					: localizedMessages.get('USERS'),
		addLabel				: localizedMessages.get('ADD'),
		allUsersMustHaveRole	: localizedMessages.get('ALL_USERS_MUST_HAVE_ROLE')
	}
	
	$scope.popupTitle = $scope.ui.popupTitle;
	$scope.actionBtnTxt = $scope.ui.actionBtnTxt;
	$scope.closeBtnTxt = $scope.ui.closeBtnTxt;
	$scope.addLabel = $scope.ui.addLabel;
	$scope.showUserList = true;
	
	$scope.usersBtn = function(){
		$scope.showUserList = !$scope.showUserList;
	}
	
	var isUser = {
		respondent : function(user){
			return !!user.isRespondent;
		},
		viewer : function(user){
			return !!user.isViewer;
		},
		owner : function(user){
			return !!user.isOwner;	
		},
		currentUser : function(user){
			return user.objectBaseUuid == context.getModel().userVO.objectBaseUuid;
		},
		oneOwner : function(){
			var list = _.filter($scope.tableOption.data, { isOwner : true });
			if(list.length == 1){
				return true;
			}
			else if(list.length > 1){
				return false;
			}
			else{
				return -1;
			}
		},
		hasRole : function(){
			var list = _.filter($scope.tableOption.data, { isOwner : false, isRespondent : false, isViewer : false });
			if(list.length > 0)
				return false;
			else
				return true;
		}
	}
	
	var click = {
		respondent : function(user){
			if(user.isRespondentDisabled)
					return;
			
			user.isViewerDisabled = user.isOwner || user.isRespondent;
		},
		viewer : function(user){
			if(user.isViewerDisabled)
				return;
			
			if(user.isViewer){
				user.isRespondentDisabled = true;
				user.isOwnerDisabled = true;
			}
			else{
				user.isRespondentDisabled = false;
				user.isOwnerDisabled = false;
			}
		},
		owner : function(user){
			if(user.isOwnerDisabled)
				return;
			
			smartSelectionUtils.disableEnableCheckbox(user, user.isOwner);
			
			user.isViewerDisabled = user.isOwner || user.isRespondent;
			
			fix.oneOwner();
			fix.deleteBtns();
		},
		selectAllRespondent : function(){
			fix.respondents();
		},
		selectAllViewer : function(){
			fix.viewers();
		},
		selectAllOwner : function(){
			if(isUser.oneOwner() == -1){
				$scope.tableOption.data[0].isOwner = true;
				$scope.tableOption.data[0].isViewer = false;
			}
			//fix.oneOwner();
			fix.owners();
			fix.deleteBtns();
			fix.selections();
		},
		
	}
	
	var fix = {
		respondents : function(){
			_.forEach($scope.tableOption.data, function(item){
				click.respondent(item);
			});
		},
		viewers : function(){
			_.forEach($scope.tableOption.data, function(item){
				click.viewer(item);
			});
		},
		owners : function(){
			_.forEach($scope.tableOption.data, function(item){
				if(!item.isOwnerDisabled)
					click.owner(item);
			});
		},
		deleteBtns : function(){
			_.forEach($scope.tableOption.data, function(item){
				if(isUser.owner(item)){
					item.deleteBtnDisabled = true;
				}
				else{
					item.deleteBtnDisabled = false;
				}
			});
		},
		oneOwner : function(){
			var isOneOwner = isUser.oneOwner();
			_.each($scope.tableOption.data, function(item){
				if(isOneOwner){
					item.isOwner ? item.isOwnerDisabled = true : null;
				}
				else{
					item.isOwnerDisabled = false;
				}
				smartSelectionUtils.disableEnableCheckbox(item, item.isOwner);
			});
			fix.selections();
		},
		selections: function(){
			_.each($scope.tableOption.data, function(user){
				if(user.isViewer){
					user.isRespondentDisabled = true;
					user.isOwnerDisabled = true;
				}
				else{
					user.isRespondentDisabled = false;
					!isUser.oneOwner() ? user.isOwnerDisabled = false : null;
				}
				
				if(user.isRespondent || user.isOwner){
					user.isViewerDisabled = true;
				}
				else{
					user.isViewerDisabled = false;
				}
			});
		}
	}
	
	$scope.tableOption = {
		columns : [
 		           	{ 
 		           		title: localizedMessages.get("RESPONDENTS"),
 		           		name: 'name',
 		           		width: 31,
 		           		sorting: true,
 		           		unsortFirstItem : true,
 		           		rows: {
 		           			map: 'name'
 		           		}
 		           	},
 		           	{
 		           		title: localizedMessages.get("RESPOND"),
 		           		name: 'isRespondent',
 		           		width: 23,
 		           		type: 'checkbox',
 		           		sorting: true,
 		           		unsortFirstItem : true,
 		           		click : click.selectAllRespondent,
 		           		rows: {
 		           			type: 'checkbox',
 		           			title: false,
 		           			map: 'isRespondent',
 		           			click: click.respondent
 		           		}
 		           	},
 		           	{
 		           		title: localizedMessages.get("VIEW"),
 		           		name: 'isViewer',
 		           		width: 23,
 		           		type: 'checkbox',
 		           		sorting: true,
 		           		unsortFirstItem : true,
 		           		click : click.selectAllViewer,
 		           		rows: {
		           			type: 'checkbox',
		           			title: false,
		           			map: 'isViewer',
		           			click: click.viewer
		           		}
 		           	},
 		           	{
 		           		title: localizedMessages.get("OWN"),
 		           		name: 'isOwner',
 		           		width: 23,
 		           		type: 'checkbox',
 		           		sorting: true,
 		           		unsortFirstItem : true,
 		           		click : click.selectAllOwner,
 		           		rows: {
		           			type: 'checkbox',
		           			title: false,
		           			map: 'isOwner',
		           			click: click.owner
		           		}
 		           	}
		],
		data : [],
		filter: {
			show: true,
			placeholder: localizedMessages.get('FILTER')
		},
		showColumns : true,
		deleteBtn: {
			enable: true,
			click: function(item){
				_.remove($scope.tableOption.data, {objectBaseUuid: item.objectBaseUuid});
				smartSelectionUtils.selectInList([item], false);
				fix.oneOwner();
			}
		}
	}

	var smartSelectionUtils = {}
	var initialComplete = function(smartSelection){
	    smartSelectionUtils = smartSelection.smartSelectionUtils;
		smartSelectionUtils.selectInList(approvalObject.attendeesList, true);
		approvalObject.attendeesList.forEach(function(item){
			$scope.tableOption.data = angular.copy(approvalObject.attendeesList);
		});
		fix.oneOwner();
		fix.deleteBtns();
		fix.selections();
	}
	
	$scope.smartSelectionOptions = {
		initialComplete: initialComplete,
		checkboxDisableFeature: true,
		disableEmptyNodes: true
	};
	
	$scope.instantSelection = function(objs){
		_.forEach(objs, function(item){
			if(!item.selected){
				var obj = _.find($scope.tableOption.data, {objectBaseUuid: item.objectBaseUuid});
				if(obj && !item.checkboxDisabled){
					 _.remove($scope.tableOption.data, {objectBaseUuid: item.objectBaseUuid});
					fix.oneOwner();
					fix.deleteBtns();
				}
				
				smartSelectionUtils.selectInList([item], false);
			}
			else{
				if(_.findIndex($scope.tableOption.data, { objectBaseUuid : item.objectBaseUuid }) != -1)
					return;
	
				if(item.type == 'User'){
					var user = angular.copy(item);
					user.isRespondent = true;
					user.isViewer = false;
					user.isOwner = false;
					user.isViewerDisabled = user.isRespondent;
					
					isUser.currentUser(user) ? $scope.tableOption.data.splice(0, 0, user) : $scope.tableOption.data.push(user);
				}
			}
		});
	}
	
	$scope.action = function(){
		if(isUser.hasRole()){
			approvalObject.attendeesList = $scope.tableOption.data;
			bvModal.close();
			
			typeof cb == "function" && cb(approvalObject.attendeesList)
		}
		else{
			bvDialog.showMessage( $scope.ui.allUsersMustHaveRole );
		}
		
	}
	
}

angular
	.module('dialogControllers.service')
	.controller('userManagementToolkitControllerApprovals', userManagementToolkitControllerApprovals)
