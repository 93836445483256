userdetailsService.$inject = ['$q', 'ajaxService'];

function userdetailsService($q, ajaxService) {

    var service = {
        getUserDetailsReport: function(postData, restUrl){
            var request = ajaxService.getUserDetailsReport(postData,restUrl);
            return request.then(function(response) {
                if (response) {
                    return response.data;
                }
                return null;
            });
        }

    }
    return service;
}
