function userdetailsConfig($stateProvider, $locationProvider){

}

userdetailsController.$inject = ['$scope', '$state', '$timeout', 'context', 'userdetailsService', 'localizedMessages', 'filtercriteriaService', 'toppanelService'];

function userdetailsController($scope, $state, $timeout, context, userDetailsService, localizedMessages, filtercriteriaService, toppanelService){
    $scope.getUserDetailsReportUrl = "getUserDetails";
    $scope.reportFilterObject;
    $scope.userDetailsReportData = []
    $scope.clickHidePanel = true;
    $scope.isInit = true;
    $scope.noContentMsg = localizedMessages.get('REPORTS_NO_CONTENT_MSG');
    $scope.$parent.currentState.value = $state.$current.name;
    $scope.stateName = $state.$current.name.split("home.reports.")[1];
    $scope.reportTemplateUrl = 'modules/reports/'+ $scope.stateName +'/'+ $scope.stateName +'.report.tpl.html';
    $scope.middleBarObject.title = localizedMessages.get('USER_DETAILS');
    $scope.middleBarObject.showBack = true;
    $scope.title = { allReports : localizedMessages.get('ALL_REPORTS') };
    $scope.showingResultsText = localizedMessages.get('SHOWING_RESULTS');
    $scope.outOfText = localizedMessages.get('OUT_OF');
    $scope.downloadMsg = localizedMessages.get('DOWNLOAD_REPORT_MSG');
    $scope.paginationObj = {};
    var isFetchingReportData = false;
    var isFilterDataReady;
    var tzid = moment.tz.guess();
    $scope.isScrollBusy = false;
    var rightMenuList=[];
    $scope.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    $scope.postData = {
        "pageNumber": 1,
        "size": 50,
        "timezone":tzid,
        "sortField": "lastName",
        "sortOrder": 'asc',
        "filterCriteria" : {
            "users": [
            ],
            "teamspaces": [
            ],
            "groups": [
            ],
            "startDate": null,
            "endDate": null,
            "username": null
        }
    };

    $scope.$on('initStarted', function(event, data){
        isFilterDataReady = false;
        console.log("Message: "+data);
    });
    $scope.$on('initFinished', function(event, data){
        isFilterDataReady = true;
        console.log("Message: "+data);
    });
    var rightMenuObj = {
        title : localizedMessages.get('DOWNLOAD_REPORT'),
        anchorLink : $scope.downloadReportURL,
        iconUrl: ''
    };

    $scope.tabs = [
        {title : $scope.middleBarObject.title, content : $scope.middleBarObject.title, active: true, rightMenuList: rightMenuList}
    ];

    $scope.topUserDetailsPanel = {
        templateUrl: 'modules/reports/toppanel/toppanel.tpl.html',
        name: 'USERDETAILS',
        events : [
            { name: 'lastName',     	    value : localizedMessages.get('LAST_NAME_UPPERCASE')},
            { name: 'firstName',    	    value : localizedMessages.get('FIRST_NAME_UPPERCASE')},
            { name: 'latestVersionName',    value : localizedMessages.get('USER_ID')},
            { name: 'primaryEmail', 	    value : localizedMessages.get('PRIMARY_EMAIL')},
            { name: 'profileLastUpdated',   value : localizedMessages.get('PROFILE_LAST_UPDATED')},
            { name: 'columnSortedClk', 	value : function(titleName){ $scope.sortReportField({ value: titleName }) }},
            { name: 'sortField',    	value : $scope.postData.sortField},
            { name: 'sortOrder',    	value : $scope.postData.sortOrder}
        ]
    }

    //* Data Sets convert to Array
    $scope.getSetAsArr = function (set) {
        var arr = [];
        set.forEach(function (value) {
            arr.push(value);
        });
        return arr;
    };

    //* Data Sets values convert to Array
    $scope.getSetValAsArr = function (set) {
        var arr = [];
        set.forEach(function (value) {
            arr.push(value.ID);
        });
        return arr;
    };



    var options = [];
    options.push({name:localizedMessages.get("DOWNLOAD_CSV"), value: "csv"});
    options.push({name:localizedMessages.get("DOWNLOAD_XLS"), value: "xls"});

    rightMenuObj = {title:localizedMessages.get('DOWNLOAD_OPTIONS'),click:() => {}, iconUrl: '', svgFill: '#fff', dropDownMenu:options}


    $scope.getUserDetailsReport = function(postData, getUserDetailsReportUrl){
        if(isFilterDataReady){
            $scope.postData.pageNumber = 1;
            isFetchingReportData = true;
            userDetailsService.getUserDetailsReport($scope.postData, $scope.getUserDetailsReportUrl).then(function(response) {

                if(response){
                    isFetchingReportData = false;
                    $scope.isScrollBusy = false;
                    $scope.userDetailsReportData = response.content;
                    $scope.postData.pageNumber = response.number;
                    $scope.paginationObj.pageNumber = response.number;
                    $scope.paginationObj.numberOfElements = response.numberOfElements;
                    $scope.paginationObj.size = response.size;
                    $scope.paginationObj.isLastPage = response.last;
                    $scope.paginationObj.totalPages = response.totalPages;
                    $scope.paginationObj.totalElements = response.totalElements;
                    if(response.content.length == 0){
                        $scope.tabs[0].rightMenuList.length = 0;
                    }else{
                        $scope.tabs[0].rightMenuList[0] = rightMenuObj;
                    }
                }
            });
        }
    };

    $scope.init = function(){
        $scope.updatePostData();
        $scope.getUserDetailsReport($scope.postData, $scope.getUserDetailsReportUrl);
    }
    $scope.$on('initFinished', function(event, data){
        $scope.init();
        console.log("Message: "+data);
    });

    $scope.sortReportField = function(sortItem){
        var toppanel = toppanelService.getInstance('USERDETAILS');
        $scope.postData.pageNumber = 1;
        if($scope.postData.sortField === sortItem.value){
            $scope.postData.sortOrder = ($scope.postData.sortOrder == 'desc') ? 'asc': 'desc';
        }else{
            $scope.postData.sortOrder = 'desc';
        }
        $scope.postData.sortField = sortItem.value;
        toppanel.addEvent('sortField', $scope.postData.sortField);
        toppanel.addEvent('sortOrder', $scope.postData.sortOrder);
        $scope.updatePostData();
        $scope.getUserDetailsReport($scope.postData, $scope.getUserDetailsReportUrl);


    }

    $scope.loadMore = function()
    {
        $scope.isScrollBusy = true;
        if(($scope.paginationObj.pageNumber-1)*$scope.paginationObj.size+$scope.paginationObj.numberOfElements >= 1000){
            $scope.exceededLimit = true;
        }else if(!$scope.paginationObj.isLastPage && !isFetchingReportData) {
            $scope.postData.pageNumber = $scope.paginationObj.pageNumber + 1;

            userDetailsService.getUserDetailsReport($scope.postData, $scope.getUserDetailsReportUrl).then(function (response) {
                if (response) {
                    isFetchingReportData = false;
                    var items = response.content;
                    for (var i = 0; i < items.length; i++) {
                        $scope.userDetailsReportData.push(items[i]);
                    }
                    $scope.paginationObj.pageNumber = response.number;
                    $scope.paginationObj.numberOfElements = response.numberOfElements;
                    $scope.paginationObj.size = response.size;
                    $scope.paginationObj.isLastPage = response.last;
                    $scope.paginationObj.totalPages = response.totalPages;
                    $scope.paginationObj.totalElements = response.totalElements;
                    $scope.isScrollBusy = false;
                }
            });
            console.log('Scroll Triggered');
        }
    };
    $scope.closeToaster = function(){
        $scope.exceededLimit = false;
    }

    $scope.handleLeftIcon = function(){
        $state.go('home.reports');
    }

    $scope.updatePostData = function(){
        $scope.postData.filterCriteria.startDate = filtercriteriaService.reportFilterObject.rangeSelection.startDate;
        $scope.postData.filterCriteria.endDate = filtercriteriaService.reportFilterObject.rangeSelection.endDate;
        $scope.postData.filterCriteria.teamspaces = $scope.getSetValAsArr(filtercriteriaService.reportFilterObject.selectedTeamspaces);
        $scope.postData.filterCriteria.groups = $scope.getSetAsArr(filtercriteriaService.reportFilterObject.selectedGroups);
        $scope.postData.filterCriteria.users = $scope.getSetAsArr(filtercriteriaService.reportFilterObject.selectedUsers);
    }

    $scope.$on('filterObjectChanged', function(event, data) {
        if(isFilterDataReady){
            $scope.reportFilterObject = filtercriteriaService.reportFilterObject;
            $scope.updatePostData();
            $scope.getUserDetailsReport($scope.postData, $scope.getUserDetailsReportUrl);
            console.log("Message: " + data);
        }
    });

    $scope.translateDate = StringUtils.translateDate;
}