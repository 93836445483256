NewItemsController.$inject = ['$scope', '$rootScope', '$element', '$q', 'Carousel', '$window', '$state', '$sce', 'newItemsService', 'commonService', 'localizedMessages', 'imageService', 'bvDialogService', '$modal', 'context', 'repositoryService', 'documentContextMenu', 'bvDialog', 'bvSliderService', 'permissionService', 'homeModel', 'repositoryModelService', 'SortUtility', 'templatesService', '$timeout'];

function NewItemsController($scope, $rootScope, $element, $q, Carousel, $window, $state, $sce, newItemsService, commonService, localizedMessages, imageService, bvDialogService, modal, context, repositoryService, documentContextMenu, bvDialog, bvSliderService, permissionService, homeModel, repositoryModelService, SortUtility, templatesService, $timeout) {
	

	$scope.onCarouselInit = function() {
	  console.log('carousel init');
	}

	
	$scope.showNewItemList = false;

	$scope.$parent.currentState.value = $state.$current.name.replace(" ", "");
	var repositoryModel = repositoryModelService.getInstance("NEW_ITEM");
	var defaultTitle = localizedMessages.get("NEW_ITEMS");

	$scope.closeIcon = templatesService.getUrl('closeBtn');
	$scope.MobileUtil = MobileUtil;
	$scope.$on('sliderNextClick', function($event, currentIndex) {
		$scope.getNewItemsNextPage(currentIndex);
	})

	$scope.$on('sliderMousePressedEvent', function($event, currentIndex) {
		$scope.getNewItemsNextPage(currentIndex);
	})

	$scope.$on('sliderArrowClick', function($event, currentIndex) {
		$scope.getNewItemsNextPage(currentIndex);
	})

	$scope.$on('removeSlideConfirmation', function(newitem, $event) {
		$scope.removeConfirmation(newitem, $event);
	})

	$scope.carouselIndex = 0;

	$scope.newItemsForMe = {
		newItems: [],
		selectedNewItem: {},
		newItemSelected: false
	};

	$scope.pageSize = 25;

	var loadMenuForNewItems = function(isALL) {

		var options = [];
		options.push({ name: localizedMessages.get("CURRENT_TEAMSPACE"), value: "showCurrent" });
		options.push({ name: localizedMessages.get("ALL_TEAMSPACES"), value: "showAll" });

		var settingOption = { title: localizedMessages.get('SHOW'), click: () => { }, iconUrl: '', svgFill: '#fff', dropDownMenu: options };

		var rightMenuList = [settingOption];

		$scope.tabs = [
			{ title: localizedMessages.get('NEW_ITEMS'), content: 'all', active: true, rightMenuList: rightMenuList }
		];

		homeModel.briefcaseMobileMenu.list = [
			{ name: localizedMessages.get('CURRENT_TEAMSPACE'), click: function() { }, value: "showCurrent" },
			{ name: localizedMessages.get('ALL_TEAMSPACES'), click: function() { }, value: "showAll" }
		]
	}

	var newItemsList = [];
	
	var isLastPage = false;
	var isEmptyItems = false;
	var handleDulpicateRequest = [];
	var isLongPressed=true;
	var longPressedCount=0;
	$scope.getNewItems = function() {
		newItemsService.getNewItems(homeModel.selectedNewItemsOption, 0, $scope.sliderOptions.pageSize, $scope.sliderOptions.isThumbNailRequired)
			.then(function(response) {
				if (response.data.boName === "FailureBO") { return; }
				
				    newItemsList = [];
					handleDulpicateRequest = [];
					$scope.sliderOptions.items = [];
					isEmptyItems = true;
					isLastPage = false;
					isLongPressed=true;

				for (var i = 0; i < response.data.length; i++) {
					newItemsList.push(response.data[i]);
				}
				
				if (newItemsList.length < $scope.sliderOptions.pageSize) {
					isLastPage = true;
				}
				
				if(response.data.length>0){
				$scope.serverIndex=response.data[0].startIndex;
				}
				if(repositoryModel.moduleClicked()==="NEW ITEMS")
				{
					repositoryModel.repositoryList(response.data);
					repositoryModel.newItemsLoadedFull(false);
				}
				loadNewItems(isEmptyItems, response.data);
			});
	};
	
	//Handle ALL or Current TS selection from right top menu.
	$scope.selectMenuItem = function(ob) {
		bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.NewItem, bvUsageUtils.const.Entity.Action.NewItem.MenuAction, ob.value);
		var userTreeViewPrefKey = context.getModel().userVO.objectBaseUuid + "TREEPREF";
		selectTeamspace(ob.value);
	}

	var selectTeamspace = function(option) {
		if (option === "showCurrent") {
			homeModel.selectedNewItemsOption = "CURRENT";
		}
		else {
			homeModel.selectedNewItemsOption = "ALL";
		}
		   $scope.getNewItems();

		setTitle();
		$rootScope.$emit('reloadCarousel', option);
	};

	var invokeNewItemsService = function(selectedNewItemsOption, index, pageSize, isThumbNailRequired)
	{
		newItemsService.getNewItems(selectedNewItemsOption, index, pageSize, isThumbNailRequired)
			.then(function(response) {
				if (response.data.boName === "FailureBO") { return; }

				if (response.data.length === 0) {
					isLastPage = true;
					return;
				}
				if(response.data[0].startIndex!=0){
					$scope.serverIndex=response.data[0].startIndex;
				}else if($scope.serverIndex!=0){
					$scope.serverIndex=$scope.serverIndex+response.data.length;
				}
				if (response.data.length < $scope.sliderOptions.pageSize) {
					isLastPage = true;
				}
				
				for (var i = 0; i < response.data.length; i++) {
					newItemsList.push(response.data[i]);
				}
				
				var tmpBrowseList=[];
				tmpBrowseList=repositoryModel.repositoryList().browseList;				

				for(var i = 0; i < response.data.length; i++) {
					tmpBrowseList.push(response.data[i]);
				}
				repositoryModel.repositoryList(tmpBrowseList);
			//repositoryModel.repositoryList(response.data);
			loadNewItems(isEmptyItems,response.data);
			bvSliderService.disableNextButton = false;
		});
	};
	
	$scope.getNewItemsNextPage = function(currentIndex, selectedNewItemsOption) {
		if (selectedNewItemsOption != undefined) {
			newItemsList = [];
			handleDulpicateRequest = [];
			isEmptyItems = true;
			isLastPage = false;
		} 
		else {
			isEmptyItems = false;
		}
			
		$scope.noNewitems = false;
		/*if (isLastPage && currentIndex + $scope.sliderOptions.shownItems + 1 == newItemsList.length && bvSliderService.leftClick) {
			bvSliderService.newItemsShowRightIcon = false;
		}*/
		if (isLastPage && currentIndex + $scope.sliderOptions.shownItems == newItemsList.length) {
			bvSliderService.newItemsShowRightIcon = false;
		}

		if (currentIndex != 0) {
			if (handleDulpicateRequest.indexOf(currentIndex) != -1) {
				return;
			}
		}
        
		if (currentIndex != 0) {
			handleDulpicateRequest.push(currentIndex);
		}
		
		if(currentIndex > longPressedCount + 2){
			isLongPressed=true;
		}

		if (!MobileUtil.isApp()) {
			if (!isLastPage) {
				if (currentIndex + 2 + $scope.sliderOptions.shownItems == newItemsList.length) {
					isLongPressed=false;
					bvSliderService.leftClick=false;
					longPressedCount=currentIndex;
					bvSliderService.disableNextButton = true;
					if($scope.serverIndex!=0){
						invokeNewItemsService(homeModel.selectedNewItemsOption,$scope.serverIndex, $scope.sliderOptions.pageSize,$scope.sliderOptions.isThumbNailRequired);
					}else{
					  invokeNewItemsService(homeModel.selectedNewItemsOption,currentIndex + 2 + $scope.sliderOptions.shownItems, $scope.sliderOptions.pageSize,$scope.sliderOptions.isThumbNailRequired);	
					}
					
				
				} else if (currentIndex + $scope.sliderOptions.shownItems == newItemsList.length && isLongPressed) {
					bvSliderService.leftClick=false;
					bvSliderService.disableNextButton = true;
					if($scope.serverIndex!=0){
						invokeNewItemsService(homeModel.selectedNewItemsOption,$scope.serverIndex, $scope.sliderOptions.pageSize,$scope.sliderOptions.isThumbNailRequired);
					}else{
						invokeNewItemsService(homeModel.selectedNewItemsOption,currentIndex + $scope.sliderOptions.shownItems, $scope.sliderOptions.pageSize,$scope.sliderOptions.isThumbNailRequired);
					}
					
				}
			}
		}
	};

	var loadNewItems = function(isEmptyItems, responseList) {
		var newitemData = [];
		
		if (MobileUtil.isApp()) {		
			if (StringUtils.isNullEmpty(homeModel.selectedNewItemsOption) || homeModel.selectedNewItemsOption == "ALL")
				newitemData = responseList;
			else
				newitemData = StringUtils.filterBOForSelectedObjectBaseUuid(newItemsList, context.getModel().portalVO.objectBaseUuid);		
		} else {
			newitemData = responseList;
		}
		
		for (var i = 0; i < newitemData.length; i++) {
			$scope.getDocumentThumbnail(newitemData[i]);
			setIsOwner(newitemData[i]);
		}

		$scope.newItemsForMe.newItems = newitemData;
		if (isEmptyItems) {
			$scope.sliderOptions.items = [];
		}

		$timeout(() => {
			if ($scope.sliderOptions.items.length == 0) {
				$scope.sliderOptions.items = newitemData;
				$scope.newItemsData = $scope.sliderOptions.items;
				$scope.showNewItemList = true;
			}

			else {
				for (var i = 0; i < newitemData.length; i++) {
					if(!isItemDuplicate($scope.sliderOptions.items, newitemData[i].objectBaseUuid))
					$scope.sliderOptions.items.push(newitemData[i]);
				}
				$scope.newItemsData = $scope.sliderOptions.items;
				$scope.showNewItemList = true;
			}
		})
		
		$scope.noNewitems = $scope.newItemsForMe.newItems.length == 0 ? true : false;
		$scope.msg_no_newitems = localizedMessages.get('MSG_NO_NEWITEMS');
		$scope.noItemsMsg = localizedMessages.get("MSG_COMMON_NO_DOCUMENTS");
	}

	bvSliderService.currentIndex = 0;
	$scope.sliderOptions = {
		items: [],
		slideThrough: 1,
		shownItems: 3,
		reachedAtEnd: $scope.getNewItems,
		moduleType: 'newItems',
		pageSize: 7,
		isThumbNailRequired: true
	};

	$scope.$watch('expandedMenu', function(newValue, oldValue) {
		bvSliderService.fixScroll();
	});

	$scope.middleBarObject.showBack = false;

	$scope.handleLeftIcon = function() {
		$scope.middleBarObject.showBack = false;
		$scope.middleBarObject.title = defaultTitle
	}

	$scope.getDocumentThumbnail = function(attachment) {
		return imageService.getThumbnailForObject(attachment, "large");
	}

	$scope.openAttachmentFile = function(attachment) {
		repositoryService.openPdfViewer(attachment);
	}

	$scope.getExpiry = function(item) {
		if (item.beforePurgeFromNewItem == undefined)
			return localizedMessages.get("EXPIRY_NOT_AVAILABLE");

		if (item.beforePurgeFromNewItem == 0)
			return localizedMessages.get("NEVER_EXPIRES");

		return localizedMessages.get("EXPIRES_IN") + " " + item.beforePurgeFromNewItem + " " + localizedMessages.get("DAYS");
	}

	$scope.itemToBeRemoved = {};
	$scope.removeConfirmation = function(e, itemToBeRemoved) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		$scope.itemToBeRemoved = itemToBeRemoved;
		bvDialog.confirm(localizedMessages.get('REMOVE_NEW_ITEM_CONFIRMATION_MSG'), $scope.removeFromNewItems);
		angular.element($window).on('resize');
	}

	$scope.removeFromNewItems = function() {
		var localItemToBeRemoved=$scope.itemToBeRemoved.objectBaseUuid;
		var documentsToJSON = angular.toJson([localItemToBeRemoved])
		newItemsService.removeFromNewItems(documentsToJSON).then(function(data) {
			var index = $scope.sliderOptions.items.findIndex( i => {
				return i.objectBaseUuid === localItemToBeRemoved ;
			});
			$scope.sliderOptions.items.splice(index, 1);
			newItemsList.splice(index, 1);
			if($scope.newitemsBadge && $scope.newitemsBadge.value != 0)
				$scope.newitemsBadge.value -- ;
				
			$scope.noNewitems = $scope.newItemsForMe.newItems.length == 0 ? true : false;
			if(!$scope.noNewitems){
				$rootScope.$emit('reloadCarousel', $scope.noNewitems);
			}
			$scope.msg_no_newitems = localizedMessages.get('MSG_NO_NEWITEMS');
			$scope.noItemsMsg = localizedMessages.get("MSG_COMMON_NO_DOCUMENTS");
		});
	}

	var getThumbnail = function(file) {
		if (file.documentThumbnail == undefined) {
			commonService.getDocumentThumbnail(file.objectBaseUuid, file.objectVersionUuid)
				.then(function(data) {
					if (data != "")
						file.documentThumbnail = data;
				});
		}
	}

	var setIsOwner = function(item) {
		item.isOwner = permissionService.isOwner(item);
	}
	var isItemDuplicate = function(arry, id){
		//arry.find(function(item){
			 for(var i=0;i<arry.length;i++){
				if(arry[i].objectBaseUuid === id)
						{
							return true;
							
						}
			 }
						
			return false;			
		//});	
	}
	$scope.isMobile = MobileUtil.isMobileClient();
	$scope.isSurface = MobileUtil.isSurface();

	$scope.slideValue = $scope.isMobile && !$scope.isSurface ? '1' : '3';
	console.log($scope.slideValue)

	var setTitle = function() {
		if (StringUtils.isNullEmpty(homeModel.selectedNewItemsOption) || homeModel.selectedNewItemsOption == "ALL") {
			$scope.showPortalName = true;
			if ($scope.isMobile)
				$scope.middleBarObject.title = localizedMessages.get("NEW_ITEMS") + ": " + localizedMessages.get('ALL_TEAMSPACES');
			else
				$scope.middleBarObject.title = localizedMessages.get('ALL_TEAMSPACES');
		}
		else {
			$scope.showPortalName = false;
			if ($scope.isMobile)
				$scope.middleBarObject.title = localizedMessages.get('NEW_ITEMS') + ": " + localizedMessages.get('CURRENT_TEAMSPACE');
			else
				$scope.middleBarObject.title = localizedMessages.get('CURRENT_TEAMSPACE');
		}
	}

	$scope.showPortalName = true;
	setTitle();
	$scope.getNewItems();
	loadMenuForNewItems();
	$scope.noItemsMsg = "No documents have been added."

	var fileTypeImage = {
		'doc': 'resources/assets/images/doc_sm_@2X.png',
		'docx': 'resources/assets/images/docx_sm_@2X.png',
		'ppt': 'resources/assets/images/ppt_sm_@2X.png',
		'pptx': 'resources/assets/images/pptx_sm_@2X.png',
		'ppsx': 'resources/assets/images/ppsx_sm_@2X.png',
		'pdf': 'resources/assets/images/pdf_sm_@2X.png',
		'xls': 'resources/assets/images/xls_sm_@2X.png',
		'xlsx': 'resources/assets/images/xlsx_sm_@2X.png',
		'png': 'resources/assets/images/png_sm_@2X.png',
		'potx': 'resources/assets/images/potx_sm_@2X.png',
		'txt': 'resources/assets/images/txt_sm_@2X.png'
	};

	$scope.fileType = function(type) {
		return fileTypeImage[type];
	}

	var sortingByName = function() {
		$scope.newItemsForMe.newItems = SortUtility.sortByField($scope.newItemsForMe.newItems, ['name', 'extensionType', 'lastModifiedOnGMTMillis']);
	}
	var sortingByType = function() {
		$scope.newItemsForMe.newItems = SortUtility.sortByField($scope.newItemsForMe.newItems, 'extensionType');
	}
	var sortingByUpdated = function() {
		$scope.newItemsForMe.newItems = SortUtility.sortByField($scope.newItemsForMe.newItems, ['modifiedBy', 'lastModified']);
	}
	var sortingByStatus = function() {
		$scope.newItemsForMe.newItems = SortUtility.sortByField($scope.newItemsForMe.newItems, 'downloadStatus');
	}

	$scope.newItemTopPanel = {
		templateUrl: 'modules/newitems/toppanel/toppanel.template.html',
		events: [
			{ name: 'type', value: localizedMessages.get('TYPE_UPPERCASE') },
			{ name: 'name', value: localizedMessages.get('NAME_UPPERCASE') },
			{ name: 'updated', value: localizedMessages.get('UPDATED_UPPERCASE') },
			{ name: 'status', value: localizedMessages.get('STATUS_UPPERCASE') },
			{ name: 'nameClk', value: sortingByName },
			{ name: 'typeClk', value: sortingByType },
			{ name: 'updatedClk', value: sortingByUpdated },
			{ name: 'statusClk()', value: sortingByStatus }
		]
	}
}

angular
	.module('newitems',  ['ngGrid',  'ui.carousel', 'ui.bootstrap.carousel', 'newitems.service', 'common.service', 'localization.service', 'context.service', 'repository.service', 'messages.service', 'bvNewItemsCardsDirective', 'bvlitepdfviewer.module'])
	.controller('NewItemsController', NewItemsController)
	.filter('unsafe', function($sce) {
		return function(text) {
			return $sce.trustAsHtml(text);
		};
	});
