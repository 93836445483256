
CreateApprovalController.$inject = ['$scope', '$window','$rootScope', '$state', '$stateParams', 'calendarService', 'localizedMessages', 'modelService','$modal','imageService','companyUserService','approvalsService','context','bvDialog','bvDialogService','$sce','templatesService', 'approvalAlertService','homeService', 'constants', 'repositoryService'];

function CreateApprovalController($scope, $window, $rootScope, $state, $stateParams, calendarService, localizedMessages, modelService, modal, imageService, companyUserService, approvalsService, context, bvDialog, bvDialogService, $sce, templatesService, approvalAlertService, homeService, constants, repositoryService)
{
	$scope.ui = {
		label : {
			title : localizedMessages.get('TITLE'),
			dueDate : localizedMessages.get('DUE_DATE'),
			priority : localizedMessages.get('PRIORITY'),
			timezone : localizedMessages.get('TIMEZONE'),
			high : localizedMessages.get('HIGH'),
			normal : localizedMessages.get('NORMAL'),
			low : localizedMessages.get('LOW'),
			signatureRequired : localizedMessages.get('SIGNATURE_REQUIRED'),
			instructions : localizedMessages.get('INSTRUCTIONS'),
			attachments : localizedMessages.get('ATTACHMENTS_TITLE'),
			display : localizedMessages.get('DISPLAY'),
			tally : localizedMessages.get('TALLY'),
			responses : localizedMessages.get('RESPONSES'),
			approvalChoices : localizedMessages.get('APPROVAL_CHOICES'),
			approve : localizedMessages.get('APPROVE'),
			abstain : localizedMessages.get('ABSTAIN'),
			notApprove : localizedMessages.get('NOT_APPROVE'),
			addAdditionalChoice : localizedMessages.get('ADD_ADDITIONAL_CHOICE'),
			respondent : localizedMessages.get('RESPONDENT'),
			view : localizedMessages.get('VIEW'),
			viewer : localizedMessages.get('VIEWER'),
			owner : localizedMessages.get('OWNER'),
			attendees : localizedMessages.get('ATTENDEES'),
			errThisFieldIsRequired : localizedMessages.get('ERR_THIS_FIELD_IS_REQUIRED'),
			addPeople : localizedMessages.get('ADD_PEOPLE'),
			dueOn : localizedMessages.get('DUE_ON'),
			btnCancel : localizedMessages.get("CANCEL"),
			btnAttachments : localizedMessages.get("ATTACHMENTS_TITLE"),
			btnUsers : localizedMessages.get("USERS"),
			btnSave : localizedMessages.get("SAVE"),
			cancelConfirmMsg : localizedMessages.get("MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED"),
			headingDetails : localizedMessages.get("DETAILS"),
			headingAttachments : localizedMessages.get("ATTACHMENTS"),
			headingUsers : localizedMessages.get("USERS"),
			indicates_req_field : localizedMessages.get("INDICATES_REQUIRED_FIELD"),
			anonymous : localizedMessages.get("ANONYMOUS"),
			allowComment : localizedMessages.get("ALLOW_COMMENT"),
			popupTitle : localizedMessages.get("EXIT") + " " + localizedMessages.get("APPROVAL")
		}
	};
//	$scope.middleBarObject.title = StringUtils.capitaliseFirstLetter($state.$current.name.split("home.")[1]);
//	$scope.$parent.currentState.value = $state.$current.name;
    moment.locale('en');
	$scope.middleBarObject.showBack = false;
	$scope.showTitleError = false;
	$scope.inCreateApproval = true;
	$scope.disableSaveButton=false;
	$scope.canEditApprovalFields = true;
	$scope.isN3aUser = context.getModel().isN3aUser;
	$scope.hideAnonymousCheck = !context.getModel().isOnPrem && !context.getModel().isN3aUser;
	$scope.hideAllowCommentsCheck = !context.getModel().isOnPrem && !context.getModel().isN3aUser;
	$scope.plusIcon 		='resources/assets/svgs/plus_icon.svg';

	var ctrlName = 'CreateApprovalController';
	homeService.set_current_module_has_unsaved_changes(true, ctrlName);

	$scope.scrollbarConfig = {
			autoHideScrollbar : true,
			advanced: {
				autoScrollOnFocus: false
			}
	}

	$scope.editorOptions = {
//	           language: 'en',
	           resize_enabled : true,
	           enterMode : CKEDITOR.ENTER_BR,
	           height:"70px",
	           toolbarCanCollapse:false,
	           toolbarStartupExpanded:false,
	           removePlugins: 'elementspath',
	           toolbar : [
				               { name: 'clipboard', 	items: [ 'Undo', 'Redo' ] },
				               { name: 'basicstyles', 	items: [ 'Bold', 'Italic','Underline' ] },
				               { name: 'color', 		items: [ 'TextColor' ] },
				               { name: 'paragraph',     items: [ 'NumberedList', 'BulletedList'] },
				               { name: 'justify', 		items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ] },
				               { name: 'styles', 		items: [ 'Format', 'Font', 'FontSize' ] }
				           ],
			   extraPlugins : 'colorbutton,colordialog,font,link,basicstyles,toolbar,justify,wordcount',
			   wordcount: {
	               showParagraphs: false,
	               showWordCount: false,
	               showCharCount: true,
	               countSpacesAsChars:true,
	               countHTML:false,
	               maxWordCount: -1,
	               maxCharCount: 10000
	           },
	    };

		// Set focus and blur listeners for all editors to be created.
		CKEDITOR.on('instanceReady', function(e) {
			var editor = e.editor;
		    editor.on('focus', function() {
		        console.log('editor focused');
		    });

		    editor.on('change', function() {
		    	console.log('editor changed');
		    	$scope.editorWordCount = editor.getData().replace(/<[^>]*>|\s/g, '').length;
		    });
		    e.stop(); // Stop the event in case the listener is inserted twice
		});

	if(approvalsService.getCurrentSelectedApprovalFromList() != undefined && approvalsService.getCurrentSelectedApprovalFromList() != null){
		$scope.inCreateApproval = false;
	}

	if (!$scope.inCreateApproval) {
		$scope.editApprovalObject = approvalsService.getCurrentSelectedApprovalFromList();
		$scope.approvalObject = {};
		$scope.approvalObject.title = $scope.editApprovalObject.name;
		$scope.middleBarObject.title = $scope.editApprovalObject.name;
		$scope.approvalObject.approvalChoices = [];
		$scope.approvalObject.instructions = $scope.editApprovalObject.comments;
		$scope.approvalObject.attachmentsList = $scope.editApprovalObject.attachments == undefined ? new Array() : $scope.editApprovalObject.attachments;
		$scope.approvalObject.shareTally = $scope.editApprovalObject.shareTally;
		$scope.approvalObject.shareParticipantResponse = $scope.editApprovalObject.shareParticipantResponse;
		$scope.approvalObject.signatureRequired = $scope.editApprovalObject.signatureRequired;
		$scope.approvalObject.priority = $scope.editApprovalObject.priority;
		$scope.approvalObject.dueDate = $scope.editApprovalObject.dueDateGMTMillis < 32503708800000 ? moment($scope.editApprovalObject.dueDateGMTMillis).format('ll') : moment(new Date()).format('ll');
		$scope.approvalObject.dueDateLocal = $scope.editApprovalObject.dueDateGMTMillis < 32503708800000 ? StringUtils.momentL($scope.editApprovalObject.dueDateGMTMillis).format('ll') : StringUtils.momentL(new Date()).format('ll');
		$scope.approvalObject.startingDate = $scope.editApprovalObject.dueDateGMTMillis < 32503708800000 ? moment($scope.editApprovalObject.dueDateGMTMillis).format('ll') : moment(new Date()).format('ll');
        $scope.approvalObject.startingDateLocal = $scope.editApprovalObject.dueDateGMTMillis < 32503708800000 ? StringUtils.momentL($scope.editApprovalObject.dueDateGMTMillis).format('ll') : StringUtils.momentL(new Date()).format('ll');
		$scope.approvalObject.dueDateLocalNew = moment($scope.approvalObject.startingDateLocal, 'MMMM Do YYYY').toDate();
		$scope.dueTime = moment($scope.editApprovalObject.dueDateGMTMillis).format('hh:mm A');
		$scope.approvalObject.dueDateEnable = $scope.editApprovalObject.dueDateGMTMillis < 32503708800000;
		$scope.approvalObject.isActive = $scope.editApprovalObject.isActive;
		$scope.approvalObject.attendeesList = [];
		$scope.approvalObject.isAnonymous = $scope.editApprovalObject.isAnonymous;
		if($scope.editApprovalObject.isAnonymous == undefined || $scope.editApprovalObject.isAnonymous == null){
			$scope.editApprovalObject.isAnonymous = false;
		}
		if($scope.editApprovalObject.isCommentAllowed == undefined || $scope.editApprovalObject.isCommentAllowed == null){
			$scope.editApprovalObject.isCommentAllowed = true;
		}
		$scope.approvalObject.isCommentAllowed = $scope.editApprovalObject.isCommentAllowed;
		$scope.approvalObject.responseList = $scope.editApprovalObject.responseList;
		if($scope.approvalObject.responseList.length>0)
			if($scope.approvalObject.responseList.length > 0){
				$scope.canEditApprovalFields = false;
			}

		var checkIfAttendeeAlreadyExists = function(attendee) {
			for(var i=0;i<$scope.approvalObject.attendeesList.length;i++){
				if(attendee.objectBaseUuid == $scope.approvalObject.attendeesList[i].objectBaseUuid){
					return true;
				}
			}
			return false;
		}

		var modifyAttendeeList = function(type, objBaseUuid){
			for(var i=0;i<$scope.approvalObject.attendeesList.length;i++){
				if(objBaseUuid == $scope.approvalObject.attendeesList[i].objectBaseUuid){
					if(type == "viewer"){
						$scope.approvalObject.attendeesList[i].isViewer = true;
						break;
					}
					else if(type == "respondent"){
						$scope.approvalObject.attendeesList[i].isRespondent = true;
						break;
					}
				}
			}
		}

		if($scope.editApprovalObject.ownerList && $scope.editApprovalObject.ownerList.length > 0){
			for(var i=0;i<$scope.editApprovalObject.ownerList.length;i++){
				$scope.editApprovalObject.ownerList[i].isOwner = true;
				$scope.editApprovalObject.ownerList[i].isViewer = false;
				$scope.editApprovalObject.ownerList[i].isRespondent = false;
				$scope.approvalObject.attendeesList.push($scope.editApprovalObject.ownerList[i]) ;
			}
		}
		if($scope.editApprovalObject.viewerList && $scope.editApprovalObject.viewerList.length > 0){
			for(var i=0;i<$scope.editApprovalObject.viewerList.length;i++){
				$scope.editApprovalObject.viewerList[i].isViewer = true;
				$scope.editApprovalObject.viewerList[i].isOwner = false;
				$scope.editApprovalObject.viewerList[i].isRespondent = false;
				if(checkIfAttendeeAlreadyExists($scope.editApprovalObject.viewerList[i]))
					modifyAttendeeList("viewer", $scope.editApprovalObject.viewerList[i].objectBaseUuid);
				else
					$scope.approvalObject.attendeesList.push($scope.editApprovalObject.viewerList[i]);
				}
			}
		if($scope.editApprovalObject.respondentList && $scope.editApprovalObject.respondentList.length > 0){
			for(var i=0;i<$scope.editApprovalObject.respondentList.length;i++){
				$scope.editApprovalObject.respondentList[i].isOwner = false;
				$scope.editApprovalObject.respondentList[i].isViewer = false;
				$scope.editApprovalObject.respondentList[i].isRespondent = true;
				if(checkIfAttendeeAlreadyExists($scope.editApprovalObject.respondentList[i]))
					modifyAttendeeList("respondent", $scope.editApprovalObject.respondentList[i].objectBaseUuid);
				else
					$scope.approvalObject.attendeesList.push($scope.editApprovalObject.respondentList[i]);
				}
			}
		for (i = 0; i < $scope.editApprovalObject.choiceList.length; i++) {
			$scope.approvalObject.approvalChoices.push({
				choicePlaceHolder : $scope.editApprovalObject.choiceList[i],
				choice : $scope.editApprovalObject.choiceList[i]
			});
		}
	} else {
		$scope.canEditApprovalFields = true;
		$scope.middleBarObject.title = localizedMessages.get("APPROVALS");
		$scope.approvalObject = {};
		$scope.approvalObject.shareTally = true;
		$scope.approvalObject.shareParticipantResponse = true;
		$scope.approvalObject.signatureRequired = false;
		$scope.approvalObject.priority = $scope.ui.label.normal;
		$scope.approvalObject.attachmentsList = [];
		$scope.approvalObject.attendeesList = [];
		$scope.approvalObject.dueDate = moment(new Date()).format('ll');
		$scope.approvalObject.dueDateLocal = StringUtils.momentL(new Date()).format('ll');
		$scope.approvalObject.startingDate = moment(new Date()).format('ll');
		$scope.approvalObject.startingDateLocal = StringUtils.momentL(new Date()).format('ll');
		$scope.approvalObject.dueDateLocalNew = moment($scope.approvalObject.startingDateLocal, 'MMMM Do YYYY').toDate().setHours(23,59);
		$scope.dueTime = moment($scope.approvalObject.dueDateLocalNew).format('hh:mm A');
		$scope.approvalObject.isAnonymous = false;
		$scope.approvalObject.isCommentAllowed = true;

		$scope.approvalObject.approvalChoices = [ {
			choicePlaceholder : $scope.ui.label.approve,
			choice : $scope.ui.label.approve
		}, {
			choicePlaceholder : $scope.ui.label.abstain,
			choice : $scope.ui.label.abstain
		}, {
			choicePlaceholder : $scope.ui.label.notApprove,
			choice : $scope.ui.label.notApprove
		} ];
	}

	$scope.peopleTabs = [ {
		title : $scope.ui.label.respondents
	}, {
		title : $scope.ui.label.owners
	}, {
		title : $scope.ui.label.view
	} ];

//	$scope.showDueDateCalender = function() {
//		var dateFormat = StringUtils.getLocaleDateFormat();
//		var monthNamesShort = moment.localeData()._monthsShort;
//
//		$("#datepickerDueDate").datepicker({
//			showPanelButton : true,
//			beforeShow : function() {
//				$('.ui-datepicker').css('font-size', 11);
//			},
//			showAnim : 'slideDown',
//			monthNamesShort: monthNamesShort,
//			dateFormat : dateFormat,
//			onSelect: function(dateText, inst) {
//                var parsedDate = $.datepicker.parseDate(inst.settings.dateFormat || $.datepicker._defaults.dateFormat, dateText, inst.settings);
//                $scope.approvalObject.dueDate = moment(parsedDate).format('ll');
//                $scope.approvalObject.dueDateLocal = StringUtils.momentL(parsedDate).format('ll');
//                $scope.approvalObject.dueDateLocalNew = moment($scope.approvalObject.dueDateLocal, 'MMMM Do YYYY').toDate().setHours(11,50);
//            },
//			minDate : new Date()
//		});
//		$("#datepickerDueDate").show();
//		$("#datepickerDueDate").focus();
//		$("#datepickerDueDate").datepicker();
//
//	};

	$scope.instructionsEditorOptions = {
		language : 'en',
		height : '160px',
		enterMode : CKEDITOR.ENTER_BR,
		resize_enabled : false,
		toolbarLocation : 'bottom',
		toolbarCanCollapse : false,
		toolbarStartupExpanded : false,
		removePlugins : 'elementspath',
		toolbar : [
				{
					name : 'basicstyles',
					items : [ 'Bold', 'Italic', '-', 'Underline', '-' ]
				},
				{
					name : 'colors',
					items : [ 'TextColor' ]
				},
				{
					name : 'styles',
					items : [ 'FontSize' ]
				},
				{
					name : 'links',
					items : [ 'Link', 'Unlink' ]
				},
				{
					name : 'paragraph',
					items : [ 'BulletedList', '-', 'JustifyLeft',
							'JustifyCenter', 'JustifyRight', 'JustifyBlock',
							'-' ]
				} ],
		extraPlugins : 'save,addfile,colorbutton,colordialog,font,link,basicstyles,toolbar,justify,wordcount'
	};

	$scope.deleteAttachment = function(index) {
		var from = index;
		var to = 0;
		var rest = $scope.approvalObject.attachmentsList.slice((to || from) + 1
				|| $scope.approvalObject.attachmentsList.length);
		$scope.approvalObject.attachmentsList.length = from < 0 ? $scope.approvalObject.attachmentsList.length
				+ from
				: from;
		$scope.approvalObject.attachmentsList.push.apply(
				$scope.approvalObject.attachmentsList, rest);
	}

	$scope.deleteChoice = function(index) {
		var from = index;
		var to = 0;
		var rest = $scope.approvalObject.approvalChoices.slice((to || from) + 1 || $scope.approvalObject.approvalChoices.length);
		$scope.approvalObject.approvalChoices.length = from < 0 ? $scope.approvalObject.approvalChoices.length + from : from;
		$scope.approvalObject.approvalChoices.push.apply($scope.approvalObject.approvalChoices, rest);
	}

	$scope.openAttachmentChooser = function() {
		bvDialog.attachDocument(onSelection);
	};

	$scope.addChoice = function() {
		if ($scope.approvalObject.approvalChoices.length < 7) {
			$scope.approvalObject.approvalChoices.push({
				choicePlaceholder : "",
				choice : ""
			});
		} else {
			bvDialog.showMessage(localizedMessages.get("CANNOT_ADD_MORE_CHOICES"));
		}
	}

	var checkHTMLTagError = function(approval) {
		var htmlTagError = localizedMessages.get("HTML_TAG_ERROR");
		var html_tag_regex = constants.get("HTML_TAG_REGEX");

		approval.name = $scope.approvalObject.title = $scope.approvalObject.title;
		approval.description = $scope.approvalObject.instructions;

		for (var i = 0; i < $scope.approvalObject.approvalChoices.length; i++) {

			approval.choiceList[i] = $scope.approvalObject.approvalChoices[i].choice;

		}
	}

	$scope.saveApproval = function() {
		$scope.showTitleError = false;
		var approval = {};
		//console.log($scope.approvalObject.title);
		//console.log($scope.approvalObject.title.length);

		if ($scope.approvalObject.title != null && $scope.approvalObject.title.length>0) {
			approval.name = $scope.approvalObject.title;
		} else {
			$scope.showTitleError = true;
			if($scope.showTitleError){
				bvDialog.showMessage(localizedMessages.get('ERR_APPROVAL_TITLE_CANNOT_BE_BLANK'));
				return;
			}
		}
		var choiceList = [];

		for (var i = 0; i < $scope.approvalObject.approvalChoices.length; i++) {
			var currentChoice = $scope.approvalObject.approvalChoices[i].choice;
			if (!currentChoice || 0 === currentChoice.length) {
				//bvDialog.showMessage(localizedMessages.get('ERR_MINIMUM_TWO_APPROVALS_CHOICES_NEEDED'));
				// remove blank choices and then check for miminum number of choices - match flex behaviour [HTML-3864]
				$scope.approvalObject.approvalChoices.splice(i, 1);
				i--;
			} else {
				for (var j in choiceList) {
					if (currentChoice == choiceList[j]) {
						bvDialog.showMessage(localizedMessages.get('ONE_OR_MORE_OF_YOUR_APPROVAL_CHOICES_ARE_THE_SAME'));
						return;
					}
				}
				choiceList.push(currentChoice);
			}
		}

		if($scope.approvalObject.approvalChoices.length < 2) {
			bvDialog.showMessage(localizedMessages.get('ERR_MINIMUM_TWO_APPROVALS_CHOICES_NEEDED'));
			return;
		}

//		approval.attachments = $scope.approvalObject.attachmentsList;
		var attachmentBaseUuids = '';
		if($scope.approvalObject.attachmentsList && $scope.approvalObject.attachmentsList.length > 0){
			for(var i=0;i<$scope.approvalObject.attachmentsList.length;i++){
				attachmentBaseUuids += $scope.approvalObject.attachmentsList[i].objectBaseUuid + "#";
			}
			attachmentBaseUuids = attachmentBaseUuids.substring(0,attachmentBaseUuids.length - 1)
		}
		approval.description = $scope.approvalObject.instructions != undefined ? $scope.approvalObject.instructions : "";
		approval.priority = $scope.approvalObject.priority;
		approval.shareTally = $scope.approvalObject.shareTally;
		approval.shareParticipantResponse = $scope.approvalObject.shareParticipantResponse;
		approval.signatureRequired = $scope.approvalObject.signatureRequired;
		approval.isCommentAllowed = $scope.approvalObject.isCommentAllowed;
		approval.isAnonymous = $scope.approvalObject.isAnonymous;
		approval.isActive = $scope.approvalObject.isActive;
		var participants = [];
		var approvalOwners = [];
		var approvalViewers = [];
		var countRespondents=0;
		if ($scope.approvalObject.attendeesList != null)
			for (var i in $scope.approvalObject.attendeesList) {
				var item = $scope.approvalObject.attendeesList[i];
				if (item.isOwner) {
					approvalOwners.push(item.objectBaseUuid);
				}
				if (item.isRespondent) {
					participants.push(item.objectBaseUuid);
				}
				if (item.isViewer) {
					approvalViewers.push(item.objectBaseUuid);
				}
			}
		if (approvalOwners.indexOf(currentUserVO.objectBaseUuid) == -1) {
			approvalOwners.push(currentUserVO.objectBaseUuid);
		}
		if(participants.length<1)
		{
			bvDialog.showMessage(localizedMessages.get('MINIMIM_RESPONDANTS'));
			return;
		}
		approval.choiceList = choiceList;
		
        if(angular.element('#idFromTimePicker'))
        {
           var time = angular.element('#idFromTimePicker').val();
           var hours = Number(time.match(/^(\d+)/)[1]);
           var minutes = Number(time.match(/:(\d+)/)[1]);
           var AMPM = time.match(/\s(.*)$/)[1];
           if(AMPM == "PM" && hours<12) hours = hours+12;
           if(AMPM == "AM" && hours==12) hours = hours-12;
           $scope.sHours = hours.toString();
           $scope.sMinutes = minutes.toString();
           if(hours<10) $scope.sHours = "0" + $scope.sHours;
           if(minutes<10) $scope.sMinutes = "0" + $scope.sMinutes;
		}

		$scope.approvalObject.dueDateLocalNew = StringUtils.momentL($scope.approvalObject.startingDateLocal, 'MMMM Do YYYY').toDate().setHours($scope.sHours,$scope.sMinutes);
		var date = new Date($scope.approvalObject.dueDateEnable ? $scope.approvalObject.dueDateLocalNew : 32503708800000);

		checkHTMLTagError(approval);
		bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Approval, bvUsageUtils.const.Entity.Action.Approval.ApprovalRequested, undefined, participants.length);
		$scope.disableSaveButton=true;
		if(!$scope.inCreateApproval){
			if (approvalOwners.indexOf(currentUserVO.objectBaseUuid) == -1) {
				approvalOwners.push(currentUserVO.objectBaseUuid);
			}
			approval.objectBaseUuid = $scope.editApprovalObject.objectBaseUuid;
			approvalsService.updateApproval(angular.toJson(approval),
					angular.toJson(participants), angular.toJson(approvalOwners),
					angular.toJson(approvalViewers), date.getTime(), attachmentBaseUuids).then(
					function(data) {
						if(!data.boName){
							$scope.approvalObject.objectBaseUuid = data.objectBaseUuid;
							homeService.set_current_module_has_unsaved_changes(false, ctrlName);
							if(approval.isActive)
								{
									confirmForSendingAlert();
								}
							else
							{
								$state.go('home.approvals');
							}
						}
						else{
							if(data.boName == "FailureBO" && data.message.indexOf('does not have own permissions on the following documents') > -1){
								$scope.disableSaveButton=false;
								var message = data.message;
								var filesStr = message.substring(message.indexOf("["), message.indexOf("]") + 1);
								bvDialog.showMessage(localizedMessages.get("ERR_YOU_DO_NOT_HAVE_PERMISSIONS_ON") + ":" + filesStr);
							}

							// if(data.boName == "FailureBO"){
							// 	if(pendoTrackEvent != undefined)
							// 		pendoTrackEvent("UpdateApprovalError",{Message:"Error while updating approval"});
							// }

						}
					});
		}else {
				approvalsService.createNewApproval(angular.toJson(approval),
				angular.toJson(participants), angular.toJson(approvalOwners),
				angular.toJson(approvalViewers), date.getTime(), attachmentBaseUuids).then(
				function(data) {
					if(!data.boName){
						$scope.approvalObject.objectBaseUuid = data.objectBaseUuid;
						homeService.set_current_module_has_unsaved_changes(false, ctrlName);
						if(data.isActive)
						{
							confirmForSendingAlert();
						}
						else
						{
							$state.go('home.approvals');
						}
					}
					else{
						if(data.boName == "FailureBO" && data.message.indexOf('does not have own permissions on the following documents') > -1){
							disableSaveButton=false;
							var message = data.message;
							var filesStr = message.substring(message.indexOf("["), message.indexOf("]") + 1);
							bvDialog.showMessage(localizedMessages.get("ERR_YOU_DO_NOT_HAVE_PERMISSIONS_ON") + ":" + filesStr);
						}

						// if(data.boName == "FailureBO"){
						// 	if(pendoTrackEvent != undefined)
						// 		pendoTrackEvent("SaveApprovalError",{Message:"Error while saving approval"});
						// }

					}
				});
		}
	}

	var confirmForSendingAlert = function() {
		var alert_msg = localizedMessages.get("SEND_APPROVAL_ALERT");
		bvDialog.confirm(alert_msg, function() {
			approvalAlertService.approval= $scope.approvalObject;
			approvalAlertService.openAlertDialog()
		});
		$state.go('home.approvals');
	}

	$scope.$parent.tileCheckBox.show = true;
	$scope.$parent.tileCheckBox.active = $scope.approvalObject.isActive;

	$scope.$parent.toggleActive = function()
	{
		$scope.approvalObject.isActive = !$scope.approvalObject.isActive;

		$scope.tileCheckBox.active = $scope.approvalObject.isActive;
	}

	$scope.toggleIsAnonymous = function()
	{
		$scope.approvalObject.isAnonymous = !($scope.approvalObject.isAnonymous);
	}


	// var openAlertDialog = function() {
	// 	bvDialog.itemListSelection(
	// 		localizedMessages.get('ALERT') + ": " + $scope.approvalObject.title,
	// 		false,
	// 		openComposeAlertWindow,
	// 		"SHOW_CUSTOM_USERS",
	// 		false,
	// 		$scope.approvalObject.attendeesList,
	// 		false,
	// 		localizedMessages.get("SEND")
	// 	);
	// }

	// var openComposeAlertWindow = function(selectedUsers)
	// {
	// 	console.log(selectedUsers);

	// 	if(selectedUsers != undefined && selectedUsers != null) {
	// 		var bvModalInputs = {}
	// 		bvModalInputs.windowClass = 'bv-modal-window-compose-new-message';
	// 		bvModalInputs.inputs = {};
	// 		bvModalInputs.buttons = [{},{},{},{}];
	// 		bvModalInputs.inputs.messageType = 'compose';
	// 		bvModalInputs.inputs.popupTitle = localizedMessages.get("ALERT");
	// 		bvModalInputs.inputs.sharedWithList = selectedUsers;
	// 		bvModalInputs.inputs.name = localizedMessages.get("APPROVAL_ALERT_SUBJECT");
	// 		bvModalInputs.inputs.comments = localizedMessages.get("APPROVAL_ALERT_COMMENTS") + " - " + $scope.approvalObject.title;
	// 		bvModalInputs.inputs.secure = true;
	// 		bvModalInputs.controller = 'ComposeMessageController';
	// 		bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessage');
	// 		bvDialogService.showBvModal(bvModalInputs);
	// 	}
	// };

	$scope.getDocIcon = function(attachment) {
		return imageService.getImageForObject(attachment);
	};
	$scope.openAttachmentForPreview = function(attachment) {
		bvDialog.showDocumentPreview(attachment.objectBaseUuid, attachment);
	};

	$scope.cancelEdit = function() {
		homeService.set_current_module_has_unsaved_changes(false, ctrlName);
		$state.go('home.approvals');
	}
	$scope.cancelCreateApproval = function() {
		bvDialog.confirm($scope.ui.label.cancelConfirmMsg, $scope.cancelEdit, undefined, $scope.ui.label.popupTitle);
	}
	$scope.documentSortableOptions = {
		handle : '> .drag-handle',
		update : function(e, ui) {
			//console.log(ui.item);
		}
	};

	$scope.scrollbarConfig = {
		autoHideScrollbar : true
	}

	$scope.openAttachment = function(scope) {
		repositoryService.openPdfViewer(($scope.isAgendaDialog) ? scope.$modelValue.attachment : scope.$modelValue);
	};

	$scope.toggleSignatureRequired = function() {
		if (!$scope.approvalObject.signatureRequired)
			$scope.approvalObject.signatureRequired = true;
		else
			$scope.approvalObject.signatureRequired = false;
	}

	$scope.toggleCommentRequired = function() {
		if (!$scope.approvalObject.isCommentAllowed)
			$scope.approvalObject.isCommentAllowed = true;
		else
			$scope.approvalObject.isCommentAllowed = false;
	}

	$scope.toggleTallyDisplay = function() {
		if (!$scope.approvalObject.shareTally)
			$scope.approvalObject.shareTally = true;
		else
			$scope.approvalObject.shareTally = false;
	}

	$scope.toggleResponsesDisplay = function() {
		if (!$scope.approvalObject.shareParticipantResponse)
			$scope.approvalObject.shareParticipantResponse = true;
		else
			$scope.approvalObject.shareParticipantResponse = false;
	}

	$scope.approvalPriorityList = [ $scope.ui.label.high, $scope.ui.label.normal, $scope.ui.label.low ];
	$scope.showCreateApprovalDialog = true;
	$scope.tabs = [];
	$scope.tabs.push({
		title : $scope.middleBarObject.title,
		content : ''
	});

	$scope.checkAttendeeAlreadyExists = function(objectBaseUuid) {
		try {
				var ownersList = $scope.approvalObject.ownerList != undefined ? $scope.approvalObject.ownerList : new Array();;
				var respondentList = $scope.approvalObject.participantBaseUuidList != undefined ? $scope.approvalObject.participantBaseUuidList : new Array();
				var viewerList = $scope.approvalObject.viewerList != undefined ? $scope.approvalObject.viewerList : new Array();;
				$scope.approvalObject.attendeesList = ownersList.concat(respondentList).concat(viewerList);

			for (var index in $scope.approvalObject.attendeesList) {
				if ($scope.approvalObject.attendeesList[index].objectBaseUuid == objectBaseUuid) {
					return true;
				}
			}
		} catch (err) {
			console.log(err.message);
			return false;
		}

		return false;
	}
	$scope.addUserInAttendeesList = function(attendee) {
		var attendeeObj = {
			objectBaseUuid : attendee.objectBaseUuid,
			name : attendee.name || attendee.displayName,
			isRespondent : attendee.isRespondent,
			isOwner : attendee.isOwner,
			isViewer : attendee.isViewer
		};

		if ($scope.checkAttendeeAlreadyExists(attendeeObj.objectBaseUuid) == false)
			$scope.approvalObject.attendeesList.push(attendeeObj);
	}
	$scope.addSelectedUser = function(selectedItems) {
		$scope.selectedUsers = selectedItems;
		if ($scope.selectedUsers != undefined
				&& $scope.selectedUsers.length > 0) {
			for (var i in $scope.selectedUsers) {

				if ($scope.selectedUsers[i].type == 'TeamSpaceGroup') {
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid);
				} else if ($scope.selectedUsers[i].type == 'Group') {
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid);
				} else if ($scope.selectedUsers[i].type == 'Portal') {
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid);
				} else {
					$scope.addUserInAttendeesList($scope.selectedUsers[i]);
				}

			}
		}
	};

	var addCreatorAsAttendees = function() {
		var creator = context.getModel().userVO;
		creator.isOwner = true;
		$scope.addSelectedUser([ creator ]);
	};
	if ($scope.inCreateApproval) {
		addCreatorAsAttendees();
	}

	$scope.approval = {};
	$scope.objectBaseUuid = '';
	$scope.openApproval = function(objectBaseUuid) {
		approvalsService.selectedApprovalUuid = objectBaseUuid;
		$state.go('home.approval');
	};

	var currentUserVO = context.getModel().userVO;

	$scope.isLoggedInUserOwner = function(approvalOwnersList) {
		for ( var i = 0; i < approvalOwnersList.length; i++) {
			if (currentUserVO.objectBaseUuid == approvalOwnersList[i].objectBaseUuid) {
				return true;
			}
		}
	}

	var onSelection = function(selectedNodes) {
		try {
			if (selectedNodes != undefined && selectedNodes.length > 0) {
				for (var i in selectedNodes) {
					var attachment = selectedNodes[i];

					if (!$scope
							.checkFileAlreadyExists(attachment.objectBaseUuid)) {
						//True: Attachment is not a folder.
						if (attachment.boName.indexOf('Folder') == -1)
							$scope.approvalObject.attachmentsList
									.push(attachment);
					}

				}
			}
		} catch (err) {
			console.log(err.message);
		}
		//$scope.attachmentListModal.dismiss('close');
		$scope.sliderOptions.items = $scope.approvalObject;
	};

	$scope.sliderOptions = {
		items : [],
		slideThrough : 1,
		smallButtons : true
	}

	$scope.checkFileAlreadyExists = function(uuid) {
		try {
			for (var i in $scope.approvalObject.attachmentsList) {
				var attachment = $scope.approvalObject.attachmentsList[i];
				if (attachment.objectBaseUuid == uuid)
					return true;
			}
		} catch (err) {

		}

		return false;
	}

	$scope.showApprovalUsersOptionDialog = function() {
		$scope.popupTitle = localizedMessages.get("USERS");
		bvDialog.showUserManagementToolkitApprovals($scope.approvalObject);
		//bvDialog.showApprovalUsersOption($scope.approvalObject);
	};

	$scope.removeAttendee = function(index) {
		var from = index;
		var to = 0;
		var rest = $scope.approvalObject.attendeesList.slice((to || from) + 1 || $scope.approvalObject.attendeesList.length);
		$scope.approvalObject.attendeesList.length = from < 0 ? $scope.approvalObject.attendeesList.length + from : from;
		$scope.approvalObject.attendeesList.push.apply($scope.approvalObject.attendeesList, rest);
	};

	$scope.addGroupUsersInAttendeesList = function(groupId) {
		var users = companyUserService.getGroupUsers(groupId);
		if(users && users.length> 0)
		{
			for(var k in users){
				$scope.addUserInAttendeesList(users[k].objectBaseUuid, users[k].name);
			}
		}
	};

	$scope.remove = function(index) {
		var from = index;
		var to = 0;
		var rest = $scope.approvalObject.attendeesList.slice((to || from) + 1 || $scope.approvalObject.attendeesList.length);
		$scope.approvalObject.attendeesList.length = from < 0 ? $scope.approvalObject.attendeesList.length + from : from;
		$scope.approvalObject.attendeesList.push.apply($scope.approvalObject.attendeesList, rest);
	};

	$scope.getDocumentThumbnail = function(attachment) {
		if (attachment.documentThumbnail != undefined) {
			return "data:image/png;base64," + attachment.documentThumbnail;
		} else {
			if (attachment.boName == "FolderBO")
				attachment.extensionType = "folderAttachment";
			return imageService.getImageByExtension(attachment.extensionType);
		}
	};

	$scope.toggleDueDate = function(){
		$scope.approvalObject.dueDateEnable = !$scope.approvalObject.dueDateEnable;
	};
	$scope.getLoalTimeZone = function(){
		var getUTC = moment(new Date()).tz(moment.tz.guess(true)).format('UTCZ');
		var getTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return "("+getUTC+")"+" "+getTimezone;
	};
	$scope.maxTime1 = "23:59";
	$scope.spanFromClicked = false;
    $scope.spanFromClick = function(){
    	$scope.spanFromClicked = true;
    };
	
}

angular
.module('CreateApproval', ['approvals.service','localization.service','nvd3ChartDirectives','image.service', 'bvApprovalDirective', 'constants.service'])
.controller('CreateApprovalController', CreateApprovalController)
