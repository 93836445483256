viewerContextMenu.$inject = ['contextMenuModel', 'contextMenuHandler' , 'bvDialog' , 'repositoryService','localizedMessages'];

function viewerContextMenu(contextMenuModel, contextMenuHandler,bvDialog,repositoryService,localizedMessages) {

	function alertMessageForEditingAnnotatedFile(type, document , parentFolder){

        function action_Edit_CheckIn_Replace() {
            switch(type)
            {
                case 'edit': contextMenuHandler.editFile(document);break;
                case 'checkin': contextMenuHandler.checkin(document, parentFolder);break;
                case 'replace':contextMenuHandler.replaceFile(document, parentFolder);break;
            }
        }
        
		if(document){
			repositoryService.checkIfTheDocumentHasAnnotation(document.objectBaseUuid).then(function(data){
				if(data) {
					let Alertmessage = localizedMessages.get('ALERT_MESSAGE_ANNOTATED_DOCUMENT_EDIT');
					bvDialog.confirm(Alertmessage,action_Edit_CheckIn_Replace,null,null,"Annotation Alert");
				}
				else{
					action_Edit_CheckIn_Replace();
				}
			});
		}
    }

	this.openContextMenu = function (type, selectedDocument) {
		contextMenuModel.selectedObject = selectedDocument;
		switch (type) {
			case 'edit': alertMessageForEditingAnnotatedFile("edit", contextMenuModel.selectedObject);break;
			case 'open': openDocument(contextMenuModel.selectedObject); break;
			case 'pdf': contextMenuHandler.openPdf(contextMenuModel.selectedObject); break;
    		/*case 'rename': contextMenuHandler.renameSelectedItem(contextMenuModel.selectedObject).then(function(data)
		        	{
    					$scope.menuParentCallback("RENAME",[data]);
	    				//repositoryModel.repositoryList(data.data);
		        	});break;*/
			case 'move': contextMenuHandler.openFolderChooser(); break;

			case 'present': contextMenuHandler.openDocumentPresenter(contextMenuModel.selectedObject); break;

			case 'permission': contextMenuHandler.openPermission(contextMenuModel.selectedObject); break;

			case 'addToFavorites': contextMenuHandler.addToFavorites(contextMenuModel.selectedObject); break;

			case 'share': contextMenuHandler.shareDocuments(contextMenuModel.selectedObject); break;

			case 'addToNewItems': contextMenuHandler.addToNewItems(contextMenuModel.selectedObject); break;

			case 'download': contextMenuHandler.download(contextMenuModel.selectedObject); break;

			case 'versionTracker': contextMenuHandler.versionTracker(contextMenuModel.selectedObject); break;

			case 'copy': contextMenuHandler.copy(contextMenuModel.selectedObject, contextMenuModel.parentFolder); break;

			case 'cut': contextMenuHandler.cut(contextMenuModel.selectedObject, contextMenuModel.parentFolder); break;

			case 'paste': contextMenuHandler.paste(contextMenuModel.parentFolder); break;

			case 'properties': contextMenuHandler.properties(contextMenuModel.selectedObject); break;

			case 'removeFromBriefCase': contextMenuHandler.removeFromBriefCase(contextMenuModel.selectedObject); break;

			case 'removeFromAllBriefCases': contextMenuHandler.removeFromAllBriefCases(contextMenuModel.selectedObject); break;

			case 'removeFromNewItems': contextMenuHandler.removeFromNewItems(contextMenuModel.selectedObject); break;

			case 'rules': contextMenuHandler.rules(contextMenuModel.selectedObject); break;

			case 'setFollowers': contextMenuHandler.setFollowers(contextMenuModel.selectedObject); break;

			case 'copylink': contextMenuHandler.copylink(contextMenuModel.selectedObject); break;

			case 'replace': alertMessageForEditingAnnotatedFile("replace",contextMenuModel.selectedObject, contextMenuModel.parentFolder);break;

			case 'follow': contextMenuHandler.follow(contextMenuModel.selectedObject); break;

			case 'stopFollow': contextMenuHandler.stopFollow(contextMenuModel.selectedObject); break;

			case 'deleteAllAnnotations': contextMenuHandler.deleteAllAnnotationsInDocument(contextMenuModel.selectedObject, true);
				break;
			case 'addToUserFavorites': contextMenuHandler.addToUserFavorites(contextMenuModel.selectedObject, true); break;
			case 'auditTrail': contextMenuHandler.auditTrail(contextMenuModel.selectedObject, true); break;
			case 'checkout': contextMenuHandler.checkout(contextMenuModel.selectedObject); break;
			case 'cancelcheckout': contextMenuHandler.cancelCheckout(contextMenuModel.selectedObject); break;
			case 'checkin': alertMessageForEditingAnnotatedFile("checkin",contextMenuModel.selectedObject, contextMenuModel.parentFolder); break;
		}
	}

}