angular
.module('collate.module',['ui.sortable','constants.service'])
.service('collateModelService', [CollateModelService])
.controller('CollateController',  CollateController)
.directive('collate', Collate)

CollateController.$inject = ['$scope','context','repositoryService','localizedMessages','collateModelService','SortUtility','$modal','bvDialog','bvDialogService','constants', 'templatesService', 'repositoryModelService', 'contextMenuHandler'];

function CollateController($scope,context, repositoryService, localizedMessages, collateModelService, SortUtility, modal, bvDialog, bvDialogService, constants, templatesService, repositoryModelService, contextMenuHandler)
{
    var collateDisplayObject = collateModelService.getInstance();
    $scope.collateUI = {};
    $scope.collateList = [];
    var collateArr = [];
    var isSeperatorSelected = -1;
    var isUnFlaggedSeperatorSelected = -1;
    $scope.isAllSepratorSelected = false;
    $scope.selectedFolderName = "";
    var folderSelection = false;
    $scope.isEdit = collateDisplayObject.action == 'EDIT' ? true : false;

    $scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
    $scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
   /* $scope.bvcheckfalsedisabled = 'resources/assets/svgs/bvcheckfalsedisabled.svg';
    $scope.bvchecktruedisabled = 'resources/assets/svgs/bvchecktruedisabled.svg';
    $scope.isAllNonCompliantDocs = false;*/
    $scope.closeIcon = templatesService.getUrl('closeBtn');
    $scope.plusIcon 		='resources/assets/svgs/plus_icon.svg';
    $scope.folderIcon='resources/assets/svgs/folder_icon_new.svg';
    $scope.customSort = 'custom';
    $scope.nameSort = 'name';
    $scope.typeSort = 'extensionType';
    $scope.isBBKComplianceCheckEnabled = context.getModel().companyVO.isBBKComplianceCheckPageEnabled;

    $scope.requiredFieldsLable = localizedMessages.get('INDICATES_REQUIRED_FIELD');
    $scope.clickDisabled = false;
    $scope.isCollateCreateAction  = false;
    $scope.more_detial = localizedMessages.get('MORE_DETAIL');
    $scope.remove_collate = localizedMessages.get('REMOVE_FROM_COLLATE');
    $scope.incompatible_heading = localizedMessages.get('NON_COMPLAINT_ERROR_HEADING');
    $scope.incompatible_brief = localizedMessages.get('NON_COMPLAINT_ERROR_BRIEF');
    $scope.nonmerge_heading = localizedMessages.get('NON_MERGE_ERROR_HEADING');
    $scope.nonmerge_brief = localizedMessages.get('NON_MERGE_ERROR_BRIEF');
    $scope.unFlaggedCollateUuidList = [];

    
    $scope.labels = {
        title: localizedMessages.get('TITLE'),
        save_in_folder: localizedMessages.get('SAVE_IN_FOLDER'),
        save: localizedMessages.get('SAVE'),
        select_folder: localizedMessages.get('SELECT_FOLDER'),
        add_file: localizedMessages.get('ADD_FILE'),
        cancel: localizedMessages.get('CANCEL'),
        type: localizedMessages.get('TYPE'),
        separator: localizedMessages.get('SEPARATOR'),
        documents: localizedMessages.get('DOCUMENTS'),
        remove: localizedMessages.get('REMOVE'),
        saveTo: localizedMessages.get('SAVE_TO'),
        type: localizedMessages.get('TYPE_UPPERCASE'),
        name: localizedMessages.get('NAME_UPPERCASE')
    };

    var collateInit = function()
    {
        if (collateDisplayObject.action == 'CREATE')
        {
            //stepTitleText = StringUtils.skipFileExtention(collateDisplayObject.parentFolder);

            if(collateDisplayObject.isDocumentCollate) {
                $scope.collateList = [{}];

                $scope.collateList[0].document = collateDisplayObject.collateList[0];
                $scope.collateList[0].hasSeperator = false;
                $scope.collateList[0].hasOwnProperty = true;

                var listOfBaseUuids = StringUtils.getBaseUuidsFromBusinessObjects(collateDisplayObject.collateList);

                var businessObjectToJSON = angular.toJson(listOfBaseUuids);


                repositoryService.addToCollateChildrenJSON(businessObjectToJSON).then(function (data) {
                    if (data.boName != "FailureBO")
                        getCollateChildrenResultHandler(data);
                    else {
                        bvDialog.showMessage(localizedMessages.get("MSG_ERROR_LOADING_DOCUMENT_LIST"));
                    }

                });

                if ($scope.collateList[0] && !$scope.collateList[0].document.isCollatedDocument && !$scope.collateList[0].document.isDynamicBoardBook && $scope.collateList[0].document.extensionType == "pdf" && !$scope.collateList[0].document.isMergablePDFDoc && !$scope.collateList[0].document.isNonCompliantDoc) {
                    repositoryService.checkPdfNonCompliantAndMergeableStatusForHistoricalDocuments(businessObjectToJSON).then(function (data) {
                    if (data.boName != "FailureBO")
                    {
                        $scope.collateList.filter(l=>l.document.objectVersionUuid==data[0].objectBaseUuid)[0].document.isNonCompliantDoc=data[0].isNonCompliantDoc;
                        $scope.collateList.filter(l=>l.document.objectVersionUuid==data[0].objectBaseUuid)[0].document.isMergablePDFDoc=data[0].isMergablePDFDoc;
                    }
                    else
                        {
                               bvDialog.showMessage(localizedMessages.get("MSG_ERROR_LOADING_DOCUMENT_LIST"));
                        }
                     });
                }
            }
            else
            {
                repositoryService.getNodeChildrenForCollationJSON(collateDisplayObject.businessObject.objectBaseUuid).then(function(data)
                {
                    if(data.data.boName != "FailureBO")
                    getCollateChildrenResultHandler(data.data);
                    else
                    {
                        bvDialog.showMessage(localizedMessages.get("MSG_ERROR_LOADING_DOCUMENT_LIST")+"."+localizedMessages.get("MSG_BOARDBOOK_MAY_HAVE_BEEN_DELETED"));
                    }
                });
            }
        }
        else if(collateDisplayObject.action == 'EDIT')
        {
            repositoryService.getCollateJSONForBaseUuid(collateDisplayObject.businessObject.objectBaseUuid).then(function(data)
            {
                getCollateJSONForBaseUuid(data.data);
            });
        }

        $scope.selectedFolderName = collateDisplayObject.parentFolder.name;
    }

    $scope.openFileChooser = function()
    {
    	//document.getElementById("saveBtn").disabled = false; //to re-enable save button which was disabled when user tries to save collate/BBk without documents
        $scope.clickDisabled = false;
    	if(isSeperatorSelected != -1)
        {
            bvDialog.attachDocument($scope.selectedFiles, true, false, localizedMessages.get("ADD_FILE"));
        }
        else
        bvDialog.attachDocument($scope.selectedFiles, true, true, localizedMessages.get("ADD_FILE"));
    };

    $scope.openFolderChooser  = function() {
        bvDialog.openFolderChooser(localizedMessages.get("SAVE_TO"), parentFolderSelect, null);
    }
	var sortSelectedFile=false
    var verifySortOrder = function() {
        $scope.currentSort = $scope.customSort;
        $scope.reverseSort = false;

        if ($scope.collateList.length > 1) {
            var nameSorted = true
            var reversedNameSorted = true
            var prevName = $scope.collateList[0].document.name
            var typeSorted = true
            var reversedtypeSorted = true
            var prevType = $scope.collateList[0].document.extensionType

            angular.forEach($scope.collateList, function(file)
            {
                if (file.document.name < prevName) nameSorted = false;
                if (file.document.name > prevName) reversedNameSorted = false;
                if (file.document.extensionType < prevType) typeSorted = false;
                if (file.document.extensionType > prevType) reversedtypeSorted = false;
                prevName = file.document.name
                prevType = file.document.extensionType
            })

            $scope.reverseSort = reversedtypeSorted || reversedNameSorted
            if (nameSorted || reversedNameSorted) {
                $scope.currentSort = $scope.nameSort
                sortSelectedFile=true;
            }else if (typeSorted || reversedtypeSorted){
                $scope.currentSort = $scope.typeSort
                sortSelectedFile=true;
            }
        }
        
    }

    $scope.sortingByName = function(){
        sortSelectedFile=true;
        if ($scope.currentSort == $scope.nameSort) {
            $scope.reverseSort = !$scope.reverseSort;
        } else {
            $scope.currentSort = $scope.nameSort;
            $scope.reverseSort = false;
        }
        sortDocuments();
    }
    $scope.sortingByType = function(){
        if ($scope.currentSort == $scope.typeSort) {
            $scope.reverseSort = !$scope.reverseSort;
        } else {
            $scope.currentSort = $scope.typeSort;
            $scope.reverseSort = false;
        }
        sortDocuments();
    }
    $scope.inCompatibleFiles= false;
    $scope.showIncompatible = function () {
        $scope.inCompatibleFiles = !$scope.inCompatibleFiles;
    };
    $scope.flatteningFiles= false;
    $scope.showFlattening = function () {
        $scope.flatteningFiles = !$scope.flatteningFiles;
    };
    var sortDocuments = function(){
        if ($scope.currentSort != $scope.customSort) {
            $scope.collateList = SortUtility.sortByField($scope.collateList, 'document.' + $scope.currentSort, $scope.reverseSort);
        }
    }

    var parentFolderSelect = function(selectedFolder)
    {
        collateDisplayObject.parentFolder = selectedFolder;
        $scope.selectedFolderName = collateDisplayObject.parentFolder.name;
        isSeperatorSelected = -1;
    }

    $scope.selectedFiles = function(files)
    {
        if(StringUtils.isNullEmpty(files) || files.length == 0)
        {
            // $scope.userListModal.dismiss('close');
            folderSelection = false;
            return;
        }

        var validmediatype=true;
        files.forEach(function(item){
            if(item.extensionType == "pdf" && !item.isCollatedDocument && !item.isDynamicBoardBook && (!item.isMergablePDFDoc || !item.isNonCompliantDoc))
            {
                $scope.unFlaggedCollateUuidList.push(item);
            }
    		if(StringUtils.isMultiMediaVO(item.objectBaseUuid))
    			{
    			 		bvDialog.showMessage(localizedMessages.get('COLLATE_UNSUPPORTED_FILE_ERROR'), null, true);
    			 		validmediatype=false;
    			 		return;
    			}           
            });
        if($scope.unFlaggedCollateUuidList.length>0)
        {
            if(isSeperatorSelected != -1)
            {
                isUnFlaggedSeperatorSelected=isSeperatorSelected;
            }
            var listOfUnflaggedBaseUuids = StringUtils.getBaseUuidsFromBusinessObjects($scope.unFlaggedCollateUuidList);
            var unflaggedbusinessObjectToJSON = angular.toJson(listOfUnflaggedBaseUuids);
            repositoryService.checkPdfNonCompliantAndMergeableStatusForHistoricalDocuments(unflaggedbusinessObjectToJSON).then(function (data) {
                if (data.boName != "FailureBO")
                {
                    angular.forEach(data, function(doc) {
                        if(isUnFlaggedSeperatorSelected != -1)
                        {
                            $scope.collateList[isUnFlaggedSeperatorSelected].seperator.isNonCompliantDoc=doc.isNonCompliantDoc;
                            $scope.collateList[isUnFlaggedSeperatorSelected].seperator.isMergablePDFDoc=doc.isMergablePDFDoc;
                        }
                        else {
                            var tempList = $scope.collateList.filter(l => l.document.objectVersionUuid == doc.objectBaseUuid);
                            if (tempList.length > 0) {
                                if (tempList[0].document && tempList[0].document.objectVersionUuid) {
                                    if (doc &&!doc.isCollatedDocument && !doc.isDynamicBoardBook && doc.extensionType=="pdf" && doc.isNonCompliantDoc && doc.isMergablePDFDoc) {
                                            $scope.collateList.filter(l => l.document.objectVersionUuid == doc.objectBaseUuid)[0].document.isNonCompliantDoc = doc.isNonCompliantDoc;
                                            $scope.collateList.filter(l => l.document.objectVersionUuid == doc.objectBaseUuid)[0].document.isMergablePDFDoc = doc.isMergablePDFDoc;
                                    }
                                }
                            }
                        }
                        })
                    isUnFlaggedSeperatorSelected=-1;
                }
                else
                {
                    bvDialog.showMessage(localizedMessages.get("MSG_ERROR_LOADING_DOCUMENT_LIST"));
                }
            });
        }
        $scope.unFlaggedCollateUuidList=[];
        if(validmediatype)
        {
	        angular.forEach(files, function(doc)
	        {
	            if(isSeperatorSelected != -1)
	            {
	                addSeperator(doc);
	            }
	            else
	            {
	                var docSeperatorVO = {};
	
	                docSeperatorVO.document = doc;
	//                docSeperatorVO.hasSeperator = true;
	//                docSeperatorVO.isDefaultSeparator = true;
	                docSeperatorVO.seperator = null;
	
	                $scope.collateList.push(docSeperatorVO);
	                if(sortSelectedFile){
                    sortDocuments();
                    }
	            }
	        })
        }
        isSeperatorSelected = -1;
//        disableAllSeparatorCheckBox();
        // $scope.userListModal.dismiss('close');
    }

    var addSeperator = function(doc)
    {
        //isFileSupported = true;

        if( StringUtils.isFolder(doc.objectBaseUuid) || StringUtils.isCollate(doc.objectBaseUuid) || StringUtils.isMultiMediaVO(doc.objectBaseUuid) || StringUtils.isWikiPage(doc.objectBaseUuid) )
        {
            bvDialog.showMessage(localizedMessages.get("ERR_DOC_CANNOT_BE_SEPRATOR"));
            //  		ngDialog.open({
            // 	template: '<div style="text-align:center">' + localizedMessages.get("ERR_DOC_CANNOT_BE_SEPRATOR") +  '</div>',
            // 	plain: true
            // });
        }
        else if(doc.conversionStatus == 'CONVERTING')
        {
            bvDialog.showMessage(localizedMessages.get("ERR_CONVERTING_DOC_CANNOT_BE_SPERATOR"));
            //  		ngDialog.open({
            // 	template: '<div style="text-align:center">' + localizedMessages.get("ERR_CONVERTING_DOC_CANNOT_BE_SPERATOR") +  '</div>',
            // 	plain: true
            // });
        }else{
            if(doc.extensionType == null)
            {
                doc.extensionType = doc.name.split('.')[1];
            }
            $scope.collateList[isSeperatorSelected].seperator=doc;
        }
    }

    var removeSeperatorIfUnchacked = function (arrList)
    {
        for(var i = 0; i < arrList.length; i++)
        {
            if(arrList[i].hasSeperator == false)
            {
                arrList[i].seperator = null;
            }
        }
        return arrList;
    }

    $scope.okHandler = function()
    {
        $scope.clickDisabled = true;
        if ($scope.collateUI.title == undefined || $scope.collateUI.title.length == 0)
        {
            bvDialog.showMessage(localizedMessages.get("TITLE_CANNOT_BE_EMPTY"));
            //  		ngDialog.open({
            // 	template: '<div style="text-align:center">' + localizedMessages.get("TITLE_CANNOT_BE_EMPTY") +  '</div>',
            // 	plain: true
            // });
            $scope.clickDisabled = false;
            return;
        }

//        if($scope.collateUI.title.search(constants.get("HTML_TAG_REGEX")) != -1) {
//            bvDialog.showMessage(localizedMessages.get("MSG_INPUT_CONTAINS_INVALID_CHARS"));
//            return;
//        }
        
        $scope.collateUI.title = StringUtils.replaceNotSupportCharWithDash($scope.collateUI.title);
         
        //cleaning any single quote in this collate document
        angular.forEach($scope.collateList, function(collate) {
        	var re = new RegExp("\'", 'g');
        	Object.keys(collate.document).forEach(function(key) {
        		if(typeof collate.document[key] == 'string') {
        			collate.document[key] = collate.document[key].replace(re, '&#39;');
        		}
        	});
        });

        if(checkForSameFileConsec($scope.collateList))
        {
        	$scope.clickDisabled = false;
            return;
        }
        //		if(checkIfDocisStillUploading(collateArr))
        //		{
        //			return;
        //		}
        //isNavigationButtonsEnabled = false;
        createOrEditCollateBoardBook();
    }

    var checkForSameFileConsec =  function(collateList)
    {
        collateArr = [];

        var fileList = "";

        var boolChk = false;

        if (collateList.length == 1)
        {
            collateArr.push(collateList[0]);
        }
        else if (collateList.length > 1)
        {
            collateArr.push(collateList[0]);

            var fileArray = [];

            for (var i = 0; i < collateList.length - 1; i++)
            {
                var firstItemId = getExactVO(collateList[i]).objectBaseUuid;
                var secondItemId = getExactVO(collateList[i + 1]).objectBaseUuid;

                if (firstItemId != secondItemId)
                {
                    collateArr.push(collateList[i + 1]);
                }else
                {
                    fileArray.push( getExactVO(collateList[i]).name );
                    boolChk = true;
                }
            }

            if(boolChk)
            {
                bvDialog.showMessage(localizedMessages.get("MSG_SAME_DOCUMENT_CANNOT_ADDED_CONSECUTIVELY") + ":\n\n" + fileArray.join(", "));
            }
        }
        return boolChk;
    }

    var getExactVO = function(docsepVO)
    {
        var businessVO = {};

        if (!StringUtils.isNullEmpty(docsepVO.document))
        businessVO = docsepVO.document;

        return businessVO;
    }
    
    $scope.createCollate = function()
    {
        repositoryService.createCollatedDocumentJSON($scope.collateJSON).then(function(data)
        {
            bvDialog.showMessage(localizedMessages.get("COLLATE_CREATED"), null, true);
            //  		ngDialog.open({
            // 	template: '<div style="text-align:center">' + localizedMessages.get("COLLATE_CREATED") +  '</div>',
            // 	plain: true
            // });
            $scope.onSaveDone();
            //getCollateChildrenResultHandler(data.data);
        });
    }
     $scope.createBoardBook = function()
    {   
        try{
           repositoryService.createBoardbookJSON($scope.boardbookJSON).then(function(data)
                {
                    bvDialog.showMessage(localizedMessages.get("BOARDBOOK_CREATED"), null, true);
                    //  		ngDialog.open({
                    // 	template: '<div style="text-align:center">' + localizedMessages.get("BOARDBOOK_CREATED") +  '</div>',
                    // 	plain: true
                    // });
                    $scope.onSaveDone();
                    //getCollateChildrenResultHandler(data.data);
                }
                ,
                //Added as partof MEETX-26659
				function(error) {
				     bvDialog.showMessage(localizedMessages.get("BOARDBOOK_SAVE_ERROR"), null, true);
                     $scope.clickDisabled = false;
				});
        }         
       //Added as partof MEETX-26659
       catch(err){
         bvDialog.showMessage(localizedMessages.get("BOARDBOOK_SAVE_ERROR"), null, true);
         $scope.clickDisabled = false;
       }      
    }
    
      /**Added as part of MEEETX-26659 to remove &#39; from document Name*/
     $scope.removeEncodingFromDocumentName = function(documentName)
    {
       if(documentName !== undefined  && documentName !== null)  
         return documentName.replace(/&#39;/g, "'");
    }
    
    var createOrEditCollateBoardBook = function()
    {
      try{
        var newDocSeperatorVOList = [];
        var skippedDocuments = "";

        var collateVO = {};
        collateVO.collateTitle = $scope.collateUI.title;
        collateVO.docSeperatorVOList = removeSeperatorIfUnchacked(collateArr);

        var convertingText = localizedMessages.get("CONVERSION_PROGRESS_TEXT");
        var documentListText = localizedMessages.get("DOCUMENT_LIST_TEXT");
    	var passwordProtectedText = localizedMessages.get("PASSWORD_PROTECTED_TEXT"); 
    	var convertingLabel = localizedMessages.get("CONVERSION_PROGRESS_LABEL");
    	var passwordProtectedLabel = localizedMessages.get("PASSWORD_PROTECTED_LABEL"); 
    	
        angular.forEach(collateVO.docSeperatorVOList, function(docSeperatorVO)
        {
        	if(docSeperatorVO.seperator){
        		if(docSeperatorVO.seperator.conversionStatus == "CONVERTING"){
                skippedDocuments = skippedDocuments +convertingLabel +"&nbsp"+"-"+"&nbsp" + docSeperatorVO.seperator.name +'<br/>';
        		} else if(docSeperatorVO.seperator.isPasswordProtected) {
                skippedDocuments = skippedDocuments +passwordProtectedLabel +"&nbsp"+"-"+"&nbsp" + docSeperatorVO.seperator.name +'<br/>';
            }
        		
        	}
        		
            if(docSeperatorVO.conversionStatus == "CONVERTING"){
                skippedDocuments = skippedDocuments +convertingLabel +"&nbsp"+"-"+"&nbsp" + docSeperatorVO.document.name +'<br/>';
        	}
        	else if(docSeperatorVO.document.isPasswordProtected) {
                skippedDocuments = skippedDocuments +passwordProtectedLabel +"&nbsp"+"-"+"&nbsp" + docSeperatorVO.document.name +'<br/>';
            }
             else{
                newDocSeperatorVOList.push(docSeperatorVO);
            }
        });

        collateVO.docSeperatorVOList = newDocSeperatorVOList;
        

        if(skippedDocuments != null && skippedDocuments.length > 0) {
        	var messageText = localizedMessages.get("ERR_SKIPPED_DOC_IN_COLLATE_NEW")+"<br/>";
        	messageText = messageText+"&nbsp&nbsp"+"-"+passwordProtectedText+"<br/>";
        	messageText = messageText+"&nbsp&nbsp"+"-"+convertingText+"<br/>";
        	messageText = messageText+documentListText+"<br/>";
        	messageText = messageText + skippedDocuments;
        	
        	bvDialog.showMessage(messageText,"bv-bbk-edit-warning",null,null,true);
            $scope.clickDisabled = false;
            // ngDialog.open({
            // 	template: '<div style="text-align:center">' + localizedMessages.get("ERR_SKIPPED_DOC_IN_COLLATE") + '<br>' + skippedDocuments + '</div>',
            // 	plain: true
            // });
        }
        
        if (collateDisplayObject.action == 'CREATE')
        {   
            collateVO.parentFolderID = collateDisplayObject.parentFolder.objectBaseUuid;
            var repositoryModel = repositoryModelService.getInstance('DOCUMENTS'); 
            var replist = repositoryModel.repositoryList();
            $scope.alertMsg = localizedMessages.get('ERR_UPLOARD_EXISTING_FILE_NAME_ALERT');
            var fileExisting = false;
            var collateVOName = collateVO.collateTitle + ".pdf";

	            if(collateDisplayObject.isCollate)
	            {
	            	
	                
	                for(var i = 0; i < replist.browseList.length; i++){
						if(replist.browseList[i].boName == "DocumentBO" && collateVOName.toUpperCase() == replist.browseList[i].name.toUpperCase() ) 
						{
							fileExisting = true;
						}
					}
	                $scope.collateJSON = angular.toJson(collateVO);
	                if(fileExisting)
	            	{
	            		bvDialog.showContinue($scope.alertMsg, localizedMessages.get('CONTINUE'), $scope.createCollate);
	            		$scope.clickDisabled = false;
	            	} else {
	            		if(collateVO.docSeperatorVOList != null && collateVO.docSeperatorVOList.length > 0){
	            			$scope.createCollate();
	            		}else{
	                    	 $scope.clickDisabled = true;
	                    }
	            	}
	            	
	            }
	            else
	            {
	                //boardbook creation
	            	if(collateVO.docSeperatorVOList != null && collateVO.docSeperatorVOList.length > 0)
	            	{
	                    collateVO.agenda = collateVO.docSeperatorVOList[0].document;
	            	}
	                $scope.boardbookJSON = angular.toJson(collateVO);
	                for(var i = 0; i < replist.browseList.length; i++)
	                {
	                if(replist.browseList[i].boName == "DocumentBO" && collateVOName.toUpperCase() == replist.browseList[i].name.toUpperCase() ) 
						{
							fileExisting = true;
						}
	                }
	                if(fileExisting)
	                {
	                    bvDialog.showContinue($scope.alertMsg, localizedMessages.get('CONTINUE'), $scope.createBoardBook);
	                    $scope.clickDisabled = false;
	                }
	                else {
	                	if(collateVO.docSeperatorVOList != null && collateVO.docSeperatorVOList.length > 0){
	                		$scope.createBoardBook();
	            		}else{
	                    	//document.getElementById("saveBtn").disabled = true;
	                    	 $scope.clickDisabled = true;
	                    }
	                }
	            
	            }

        }
        else if (collateDisplayObject.action == 'EDIT')
        {
            // collate and boardbook edit is same
            collateVO.objectBaseUuid = collateDisplayObject.businessObject.objectBaseUuid;
            if(collateVO.docSeperatorVOList != null && collateVO.docSeperatorVOList.length > 0){
	            var collateJSON = angular.toJson(collateVO);
	            repositoryService.editCollatedDocument(collateJSON).then(function(data)
	            {
	                bvDialog.showMessage(localizedMessages.get("COLLATE_EDIT_SAVED"), null, true);
	                // ngDialog.open({
	                // 	template: '<div style="text-align:center">' + localizedMessages.get("COLLATE_EDIT_SAVED") +  '</div>',
	                // 	plain: true
	                // });
	                $scope.onSaveDone();
	                //getCollateChildrenResultHandler(data.data);
	            }
	             ,
                //Added as partof MEETX-26659
				function(error) {
				     bvDialog.showMessage(localizedMessages.get("BOARDBOOK_SAVE_ERROR"), null, true);
                     $scope.clickDisabled = false;
				});
            }else{
            	//document.getElementById("saveBtn").disabled = true;
            	 $scope.clickDisabled = true;
            }
        }
      }
     //Added as partof MEETX-26659
      catch(err){
         bvDialog.showMessage(localizedMessages.get("BOARDBOOK_SAVE_ERROR"), null, true);
         $scope.clickDisabled = false;
        }  
    }


    $scope.cancel = function() {
        bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.onCancelDone );
    }

    $scope.changeSeperator = function(selectedIndex)
    {
        isSeperatorSelected = selectedIndex;

        $scope.openFileChooser();
    }

    $scope.toggleSeperator = function(index)
    {
        $scope.collateList[index].hasSeperator = !$scope.collateList[index].hasSeperator;
        $scope.isAllSepratorSelected = false;
    }

    $scope.selectAllSeprator = function()
    {
        $scope.isAllSepratorSelected = !$scope.isAllSepratorSelected;

        console.log($scope.collateList);

        for(var i=0; i<$scope.collateList.length; i++)
        {
            /*if($scope.collateList[i].document.isPdfACompliant != "false")*/
              $scope.collateList[i].hasSeperator = $scope.isAllSepratorSelected;
        }
    }

    $scope.documentSortableOptions = {
        // placeholder: "document-placeholder-highlight",
        handle: '> .drag-handle',
        update: function(e, ui)
        {
            //console.log(ui.item);
            $scope.reverseSort = false;
            $scope.currentSort = $scope.customSort;
        }
    };

    var getCollateJSONForBaseUuid = function(resultData)
    {
        $scope.collateUI.title = resultData.name;
        $scope.collateList = resultData.docSeperatorVOList;
        console.log(resultData);
      //  disableAllSeparatorCheckBox();
        //verifySortOrder();
    }
    
     /*var disableAllSeparatorCheckBox = function()
    {
      let count = 0;
      if(angular.isDefined($scope.collateList) && $scope.collateList.length > 0){
          angular.forEach($scope.collateList, function(value, key){
             if(angular.isDefined(value.document) && value.document.isPdfACompliant==="false"){
                 count++;
             }
    	  });
    	  if(count === $scope.collateList.length){
    	     $scope.isAllNonCompliantDocs = true;
    	  }else{
    	     $scope.isAllNonCompliantDocs = false;
    	  }
      }
    }*/

    var getCollateChildrenResultHandler = function(resultData)
    {
        //var docSeperatorVOList = resultData;

        //var prevObjectBaseUuid:String;

        $scope.collateList = resultData;


        $scope.collateList.forEach(function(item){
            if(item.document.extensionType == "pdf" && !item.document.isCollatedDocument && !item.document.isDynamicBoardBook && (!item.document.isMergablePDFDoc || !item.document.isNonCompliantDoc))
            {
                $scope.unFlaggedCollateUuidList.push(item.document);
            }
        });
        if($scope.unFlaggedCollateUuidList.length>0)
        {
            if(isSeperatorSelected != -1)
            {
                isUnFlaggedSeperatorSelected=isSeperatorSelected;
            }
            var listOfUnflaggedBaseUuids = StringUtils.getBaseUuidsFromBusinessObjects($scope.unFlaggedCollateUuidList);
            var unflaggedbusinessObjectToJSON = angular.toJson(listOfUnflaggedBaseUuids);
            repositoryService.checkPdfNonCompliantAndMergeableStatusForHistoricalDocuments(unflaggedbusinessObjectToJSON).then(function (data) {
                if (data.boName != "FailureBO")
                {
                    angular.forEach(data, function(doc) {
                        if(isUnFlaggedSeperatorSelected != -1)
                        {
                            $scope.collateList[isUnFlaggedSeperatorSelected].seperator.isNonCompliantDoc=doc.isNonCompliantDoc;
                            $scope.collateList[isUnFlaggedSeperatorSelected].seperator.isMergablePDFDoc=doc.isMergablePDFDoc;
                        }
                        else {
                            var tempList = $scope.collateList.filter(l => l.document.objectVersionUuid == doc.objectBaseUuid);
                            if (tempList.length > 0) {
                                if (tempList[0].document && tempList[0].document.objectVersionUuid) {
                                    if (doc && !doc.isCollatedDocument && !doc.isDynamicBoardBook && doc.extensionType == "pdf" && doc.isNonCompliantDoc && doc.isMergablePDFDoc) {
                                        $scope.collateList.filter(l => l.document.objectVersionUuid == doc.objectBaseUuid)[0].document.isNonCompliantDoc = doc.isNonCompliantDoc;
                                        $scope.collateList.filter(l => l.document.objectVersionUuid == doc.objectBaseUuid)[0].document.isMergablePDFDoc = doc.isMergablePDFDoc;
                                    }
                                }
                            }
                        }
                    })
                    isUnFlaggedSeperatorSelected=-1;
                }
                else
                {
                    bvDialog.showMessage(localizedMessages.get("MSG_ERROR_LOADING_DOCUMENT_LIST"));
                }
            });
        }
        $scope.unFlaggedCollateUuidList=[];

        return;

        //		if(docSeperatorVOList && docSeperatorVOList.length)
        //		{
        //			for(var i:int = 0;i<docSeperatorVOList.length;i++)
        //			{
        //				if(docSeperatorVOList[i] && docSeperatorVOList[i].hasOwnProperty('document')
        //					&& docSeperatorVOList[i].document.hasOwnProperty('objectBaseUuid')
        //					&& docSeperatorVOList[i].document)
        //					{
        //						if(prevObjectBaseUuid == docSeperatorVOList[i].document.objectBaseUuid)
        //						{
        //							docSeperatorVOList.removeItemAt(i);
        //						}else
        //						{
        //							prevObjectBaseUuid = docSeperatorVOList[i].document.objectBaseUuid;
        //						}
        //					}
        //			}
        //			docSeperatorVOList.refresh();
        //
        //			angular.forEach(resultData, function(businessObjectVO)
        //        	{
        //				if(docSeperatorVOList[i] && docSeperatorVOList[i].hasOwnProperty('document')
        //						&& docSeperatorVOList[i].document.hasOwnProperty('objectBaseUuid')
        //						&& docSeperatorVOList[i].document)
        //						{
        //							if(prevObjectBaseUuid == docSeperatorVOList[i].document.objectBaseUuid)
        //							{
        //								docSeperatorVOList.removeItemAt(i);
        //							}else
        //							{
        //								prevObjectBaseUuid = docSeperatorVOList[i].document.objectBaseUuid;
        //							}
        //						}
        //        	});
        //
        //		}
        //		resultData.docSeperatorVOList = docSeperatorVOList;
        //
        //		if(resultData is BoardBookVO)
        //		{
        //			updatedAgendaVO = (resultData as BoardBookVO).agenda;
        //		}
        //		collateList = resultData.docSeperatorVOList as ArrayCollection;

        //		}else{
        //			collateList = resultData as ArrayCollection;
        //		}
        //
        //		defaultCheckboxValue = checkDefaultValue();
    }

    $scope.removeItemFromCollateList = function(removeIndex){
        $scope.collateList.splice( removeIndex, 1 );
       // disableAllSeparatorCheckBox();
    }
 
    $scope.previewAttachment = function(docId,docObject) {
         $scope.openDocument(docObject);
    }

    $scope.removeNonComplaintDocs = function(){
        $scope.collateList =  $scope.collateList.filter((m) => {
        if(m.document && m.seperator){
            $scope.removeSeperator();
            return !(!m.document.isDynamicBoardBook && !m.document.isCollatedDocument && m.document.extensionType == "pdf" && m.document.isNonCompliantDoc == "true" && m.document.isMergablePDFDoc== "false");
        }
        else if(m.seperator){
            $scope.removeSeperator();
        }
        else if(m.document){
            return !(!m.document.isDynamicBoardBook && !m.document.isCollatedDocument && m.document.extensionType == "pdf" && m.document.isNonCompliantDoc == "true" && m.document.isMergablePDFDoc== "false");
         }
        })
       }
       
    $scope.removeSeperator = function(){ 
    for (var i = $scope.collateList.length - 1; i >= 0; i--) {
    if ($scope.collateList[i].seperator && !$scope.collateList[i].seperator.isDynamicBoardBook && !$scope.collateList[i].seperator.isCollatedDocument && $scope.collateList[i].seperator.extensionType == "pdf" && $scope.collateList[i].seperator.isNonCompliantDoc == "true" && $scope.collateList[i].seperator.isMergablePDFDoc== "false") {
            $scope.collateList[i].seperator = null;
            }
        }
    }

     $scope.isComplaint = function(el){
        if(el.document) {
        return !el.document.isDynamicBoardBook && !el.document.isCollatedDocument && el.document.extensionType == "pdf" && el.document.isNonCompliantDoc == "true" && el.document.isMergablePDFDoc == "false";
        }
      };
      $scope.isSepComplaint = function(el){
        if(el.seperator){
         return !el.seperator.isDynamicBoardBook && !el.seperator.isCollatedDocument && el.seperator.extensionType == "pdf" && el.seperator.isNonCompliantDoc == "true" && el.seperator.isMergablePDFDoc == "false";
        }
      };
     $scope.isNotmerge = function(el){
         if(el.document) {
             return !el.document.isDynamicBoardBook && !el.document.isCollatedDocument && el.document.extensionType == "pdf" && el.document.isNonCompliantDoc == "true" && el.document.isMergablePDFDoc == "true";
         }
      };
      $scope.isSepNotmerge = function(el){
        if(el.seperator){ 
            return !el.seperator.isDynamicBoardBook && !el.seperator.isCollatedDocument && el.seperator.extensionType == "pdf" && el.seperator.isNonCompliantDoc == "true" && el.seperator.isMergablePDFDoc == "true";
        }
      };
      
     $scope.fileSizeInMb = function(fileSize){
          var sizeInMB = fileSize / Math.pow(1024,2);
          return sizeInMB.toFixed(2)+"MB";
      };
    $scope.openDocument = function (document)
    {
        console.log(document);      

        if($scope.repositoryDisplayObjects && $scope.repositoryDisplayObjects.disableOpen)
            return;
       // MEETX-29910- changes start 
        //if(document.extensionType=="csv")
        //{
         //   console.log(document);
         //   bvDialog.showMessage(localizedMessages.get("MSG_EROR_FILE_TYPES"));
         //   return;
        //}
		// MEETX-29910- changes end 
        contextMenuHandler.openFile(document);
    }; 

    collateInit();
}
function CollateModelService()
{
    var collateDisplayObject = {};
    collateDisplayObject.isCollate = false;
    collateDisplayObject.collateList = [];
    collateDisplayObject.parentFolder = {};
    collateDisplayObject.businessObject = {};
    collateDisplayObject.isDocumentCollate = false;
    collateDisplayObject.action = ";"

    this.getInstance = function()
    {
        return collateDisplayObject;
    }
}

function Collate(){
    return{
        restrict: 'A',
        scope:{
            onSaveDone		: '=',
            onCancelDone	: '='
        },
        templateUrl: 'modules/repository/Collate.tpl.html',
        controller: 'CollateController'
    }
}
