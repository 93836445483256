CreateNewApprovalController.$inject = ['$scope', '$stateParams', 'reactInterface', 'localizedMessages','homeService'];

function CreateNewApprovalController($scope, $stateParams, reactInterface, localizedMessages, homeService)
{

	$scope.reactInterface = reactInterface;
	$scope.approvalBaseUuid = $stateParams.objectBaseUuid;
	$scope.approvalApiData = $stateParams.approvalApiData;

    $scope.tabs = [];
	$scope.tabs.push({
		title : localizedMessages.get("APPROVAL"),
		content : ''
	});
	$scope.middleBarObject.showBack = true;
	$scope.handleLeftIcon = () => {
        const { backButton } = reactInterface.callReactApproval
        backButton()
    }
}
angular
.module('CreateNewApproval', ['react.interface'])
.controller('CreateNewApprovalController', CreateNewApprovalController)
